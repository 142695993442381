import React, { useState, useEffect, useRef } from 'react';
import "../styles/deliveryDashboard.css"
import Sidebar from "../components/sidebar.js";
import { TextField } from "@mui/material";
import axios from 'axios';
import { APIConfig } from "../api/apiConfig/apiConfig";
import { Typography } from '@material-ui/core';
import MapSection from "../qcpanel/components/mapSection";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiService from '../api/services/ApiService.js';
import { constants } from '../utils/constants.js';
import { alertDangerConfirmMessage } from '../components/customComponent/ConfirmationMessage/ConfirmationMessage.js';
import LoadingSpinner from '../components/loader/loader.js';


const DeliveryDashboard = () => {
    const { baseUrl, deliveryOrderDetails, nearbyDeliveryAgents, assignDeliveryAgents, getMapAgents } = APIConfig;
    const [orderID, setOrderID] = useState("");
    const [response1, setResponse1] = useState({});
    const [timeData, setTimeData] = useState({});
    const [response2, setResponse2] = useState([]);
    const [nearbyAgentFlag, setnearbyAgentFlag] = useState(0);
    const dropDownRef = useRef(null);
    const [mapAgentData, setMapAgentData] = useState([]);
    const [liveOrderData, setLiveOrderData] = useState([]);
    const [liveSearch, setLiveSearch] = useState("");
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [queryAOffset, setQueryAOffset] = useState(0);
    const [queryBOffset, setQueryBOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [storedValue, setStoredValue] = useState([
        { aOffset: 0, bOffset: 0 },
        { aOffset: 0, bOffset: 0 }
    ])
    const limit = 10;
    const { requestTimedOutError } = constants;

    const constantmessage = {
        merchantNotExist: "Merchant not exist",
        releasingAgentFailed: "Releasing old agent failed",
        selfDelivery: "Self delivery",
        loadShare: "Loadshare",
        delivered: "Delivered",
        orderIDNotFound: "Order ID is not found",
        orderNotAssigned: "This order is not assigned to anyone",
        merchantNotFound: "Merchant details not found",
        agentNotFound: "No nearby delivery agents found",
        assigningAgentFailed: "Assigning to new agent failed",
        assigningFailed: "Assigning Failed",
        success: "Success",
        noNearbyAgent: 1,
        agentRole: 2,
        noAgentsOnline: "No Agents Online",
        error: "Error fetching data:",
        mapTab: "2",
        liveOrderTab: "1",
        noOrdersAvailable: "No Orders Available",
        vipCheck: "2",
        status: "1",
        forceApp: 0,
        checkoutOrder: "Checkout Order",
        regular: "Regular",
        cbDelivery: "CB Delivery",
        homeDelivery: "Home Delivery",
        assigned: "Assigned",
        arrived: "Arrived",
        pickedUp: "Picked Up",
        reached: "Reached",
        rejected: "Rejected",
        cancelled: "Cancelled",
        requested: "Requested",
        VIP: "VIP",
        nonVIP: "Non VIP",
        pending: "Pending",
        ForceApp: "ForceApp",
        porter: 'Porter',

    }

    const getDeliveryOrderDetails = async () => {
        await axios
            .post(baseUrl + deliveryOrderDetails, {
                order_id: orderID,
            }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
            .then((response) => {
                var response1Status = response.data.status;
                if (response1Status == 1) {
                    alert(constantmessage.orderIDNotFound);
                } else {
                    setTimeData(response.data.data);
                    var dataResponse1 = response.data.response;
                    setResponse1(dataResponse1);
                    if (dataResponse1.AssignedTo != null) {
                        getNearbyDeliveryAgents(orderID);
                    } else {
                        getNearbyDeliveryAgents(orderID);
                        alert(constantmessage.orderNotAssigned);
                    }
                }

            })
    }

    async function getNearbyDeliveryAgents(orderId) {
        await axios
            .post(baseUrl + nearbyDeliveryAgents, {
                orderId: orderId,
            }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
            .then((response) => {
                var response2Status = response.data.status;
                if (response2Status == 1) {
                    if (response.data.message == constantmessage.merchantNotExist) {
                        setnearbyAgentFlag(1);
                        alert(constantmessage.merchantNotFound);
                    } else {
                        setnearbyAgentFlag(1);
                        alert(constantmessage.agentNotFound);
                    }
                } else {
                    var dataResponse2 = response.data.data;
                    setResponse2(dataResponse2);
                }

            })
    }


    const assignDeliveryAgent = async () => {
        var selectedNumber = dropDownRef.current.value;
        var regex = /\|\|([^|]+)\|\|/;
        // Use match to find the value between || and ||
        var match = selectedNumber.match(regex);
        // If a match is found, return the captured value, otherwise return null
        var phoneNumber = match ? match[1].trim() : null;
        console.log(phoneNumber);
        await axios
            .post(baseUrl + assignDeliveryAgents, {
                orderId: orderID,
                mobNumber: phoneNumber,
            }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
            .then((response) => {
                var assignResponseStatus = response.data.status;
                if (assignResponseStatus == 1) {
                    var assignResponseMessage = response.data.message;
                    if (assignResponseMessage == constantmessage.releasingAgentFailed) {
                        alert(constantmessage.releasingAgentFailed);
                    } else {
                        alert(constantmessage.assigningAgentFailed);
                    }
                } else {
                    alert(constantmessage.success);
                    window.location.reload();
                }

            })
            .catch(function (error) {
                console.log(error);
                alert(constantmessage.assigningFailed);
            });
    }

    // Map part api's
    const [activeTab, setActiveTab] = useState('1');

    const openTab = (tabName) => {
        setActiveTab(tabName);
        if (tabName === '1') {
            setQueryAOffset(0)
            setQueryBOffset(0)
            const newArray = storedValue.slice(0, 2);
            setStoredValue(newArray);
            setPage(1);
        }
    }



    const getMapAgent = async () => {
        try {
            const response = await axios.get(baseUrl + getMapAgents, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' });
            const agentStatus = response.data.status;
            if (agentStatus !== 1) {
                setMapAgentData(response.data.data);
                console.log(response.data.data);
            } else {
                window.alert(constantmessage.noAgentsOnline);
            }
        } catch (error) {
            console.error(constantmessage.error, error);
        }
    };

    useEffect(() => {
        if (activeTab === constantmessage.mapTab) {
            // Initial call when the component mounts
            getMapAgent();

            // Execute the API call every 2 minutes
            const intervalId = setInterval(getMapAgent, 2 * 60 * 1000);

            // Cleanup function to clear the interval when the component unmounts
            return () => {
                clearInterval(intervalId);
            };
        } else if (activeTab === constantmessage.liveOrderTab) {
            // Initial call when the component mounts
            getLiveOrder();
            setPage(1);
            // Execute the API call every 2 minutes
            const intervalId = setInterval(getLiveOrder('interval'), 2 * 60 * 1000);

            // Cleanup function to clear the interval when the component unmounts
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [activeTab]);

    const getLiveOrder = async (val) => {
        setIsLoading(true);
        var data = {};
        if (liveSearch !== '') {
            data = { idNumber: liveSearch }
        } else {
            if (val === 'prev') {
                data = {
                    limit: limit,
                    queryAOffset: storedValue[page - 1].aOffset, // if prev clicked, api called using previously stored offset values
                    queryBOffset: storedValue[page - 1].bOffset
                }
            } else if (val === 'interval') {
                data = {
                    limit: limit,
                    queryAOffset: 0,
                    queryBOffset: 0
                }
            } else {
                data = {
                    limit: limit,
                    queryAOffset: queryAOffset,
                    queryBOffset: queryBOffset
                }
            }
        }
        try {
            const result = await ApiService.getLiveOrders(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setLiveOrderData(result.data.data);
                setQueryAOffset(result.data.queryAOffset);
                setQueryBOffset(result.data.queryBOffset);
                if (result.data.data.length < 10) {
                    setLastPage(true);
                } else {
                    setLastPage(false);
                }
                if (val === 'prev') {
                    const newArrayWithoutLast = storedValue.slice(0, -1);
                    setStoredValue(newArrayWithoutLast);
                } else if (val === 'interval') {
                    const newArray = storedValue.slice(0, 2);
                    setStoredValue(newArray);
                    setPage(1);
                }
            } else {
                alertDangerConfirmMessage(constantmessage.noOrdersAvailable)
                setLastPage(true);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (value) => {
        if (value === 'next') { // handling for next click
            setPage(page + 1)
            const queryA = queryAOffset;
            const queryB = queryBOffset;
            getLiveOrder()
            let val = { aOffset: queryA, bOffset: queryB }
            setStoredValue(prevState => [...prevState, val]); // storing each received offset for usage of offsets in prev call
        } else { // handling for prev click
            setPage(page - 1)
            getLiveOrder('prev')
        }
    };


    return (

        <div>
            <div className='sidebar'>
                <Sidebar />
            </div>
            {isLoading && (
                <LoadingSpinner />
            )}
            <div className='page-container'>
                <div className='mainContainer'>

                    <div className='insideTab'>
                        <div className="tabs">
                            <button
                                className={activeTab === '1' ? 'tablink active' : 'tablink'}
                                onClick={() => { openTab('1'); }}
                            >
                                Live Orders
                            </button>
                            <button
                                className={activeTab === '2' ? 'tablink active' : 'tablink'}
                                onClick={() => { openTab('2'); }}
                            >
                                Live Force Riders
                            </button>
                            <button
                                className={activeTab === '3' ? 'tablink active' : 'tablink'}
                                onClick={() => { openTab('3'); }}
                            >
                                Edit Order Status
                            </button>
                        </div>



                        {/* Live Orders */}
                        <div id="1" className={`tabcontents ${activeTab === '1' ? 'active' : ''}`}>
                            <div className='tableHeaderAlign'>
                                <div className='vip_history_input_container'>
                                    <TextField className='vip_history_input' label="Search By Order ID, Merchant Mobile or User mobile" onChange={(e) => setLiveSearch(e.target.value)} />
                                    <button className={liveSearch.length !== 0 ? 'vip_history_submit_btn' : 'vip_history_submit_btn-disabled'} onClick={liveSearch.length !== 0 ? getLiveOrder : null}>Submit</button>
                                </div>
                                <div >
                                    <table
                                        style={{ marginTop: '20px', marginLeft: 0 }}
                                    >
                                        <tr>
                                            <th colSpan="1" className="liveOrderth normal-cell">Order ID</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Child Order ID</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Order Type</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Delivery Type</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Delivery Status</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Merchant Ph.no & Status</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Amount paid & Status</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Shop Name</th>
                                            <th colSpan="1" className="liveOrderth address-cell">Pickup Address</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Pickup Lat</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Pickup Long</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">User Number</th>
                                            <th colSpan="1" className="liveOrderth address-cell">User Address</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Drop Lat</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Drop Long</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Assigned To</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Rider Name</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Rider Number</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">FM</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">LM</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Order Created At</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Order Requested At</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Assigned At</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Picked Up At</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Delivered At</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Image before Pickup</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">Porter Delivery Link</th>
                                            <th colSpan="1" className="liveOrderth normal-cell">ONDC Status</th>
                                        </tr>
                                        <tbody>
                                            {liveOrderData && liveOrderData.map((data, index) => (
                                                <tr className="tableRowAlign" key={index}>
                                                    <td className="tableDataAlign">{data.order_id}</td>
                                                    <td className="tableDataAlign">{data.parent_order_id ? `C - ${data.parent_order_id}` : 'none'}</td>
                                                    <td className="tableDataAlign">{data.is_checkout_order === 0 ? constantmessage.checkoutOrder : constantmessage.regular}</td>
                                                    <td className="tableDataAlign">{data.delivery_mode === 0 ? constantmessage.cbDelivery : constantmessage.homeDelivery}</td>
                                                    <td className="tableDataAlign">{data.shipment_status === 1 ? constantmessage.assigned : data.shipment_status === 2 ? constantmessage.arrived :
                                                        data.shipment_status === 3 ? constantmessage.pickedUp : data.shipment_status === 4 ? constantmessage.reached : data.shipment_status === 5 ? constantmessage.delivered :
                                                            data.shipment_status === 6 ? constantmessage.rejected : data.shipment_status === 7 ? constantmessage.cancelled : data.set_delivery_mode_datetime !== null ? constantmessage.requested : ''}</td>
                                                    <td className="tableDataAlign">{data.mainmobilenumber}<br />{data.subscription_flag >= constantmessage.vipCheck ? constantmessage.VIP : constantmessage.nonVIP}</td>
                                                    <td className="tableDataAlign">{data.total_amount}<br />{data.status === constantmessage.status ? constantmessage.pending : constantmessage.success}</td>
                                                    <td className="tableDataAlign">{data.shopname}</td>
                                                    <td className="tableDataAlign address-text">{data.addressline1}<br /> {data.addressline2}</td>
                                                    <td className="tableDataAlign">{data.latitude}</td>
                                                    <td className="tableDataAlign">{data.longitude}</td>
                                                    <td className="tableDataAlign">{data.user_mob_num}</td>
                                                    <td className="tableDataAlign">
                                                        {data.delivery_mode === 2 ? (
                                                            <>
                                                                <p className='address-text'>{data.olabel}</p>
                                                                {data.apartment_or_area ? <p>{data.apartment_or_area}</p> : ''}
                                                                <p className='address-text'>{data.oaddress_line1}</p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className='address-text'>{data.useradd1}</p>
                                                                <p className='address-text'>{data.useradd2}</p>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="tableDataAlign">{data.userlat}</td>
                                                    <td className="tableDataAlign">{data.userlong}</td>
                                                    <td className="tableDataAlign">{data.request_id !== null ? (data.is_from_force_app === 1 ? constantmessage.loadShare : constantmessage.porter) : data.is_from_force_app === constantmessage.forceApp ? constantmessage.ForceApp : ''}</td>
                                                    <td className="tableDataAlign">{data.name}</td>
                                                    <td className="tableDataAlign">{data.mobile_number}</td>
                                                    <td className="tableDataAlign">{data.fm}</td>
                                                    <td className="tableDataAlign">{data.lm}  </td>
                                                    <td className="tableDataAlign address-text">{data.order_created_date}</td>
                                                    <td className="tableDataAlign address-text">{data.set_delivery_mode_datetime}</td>
                                                    <td className="tableDataAlign address-text">{data.cb_allocation_time}</td>
                                                    <td className="tableDataAlign address-text">{data.cb_picked_up_time}</td>
                                                    <td className="tableDataAlign address-text">{data.cb_delivered_time}</td>
                                                    <td className="tableDataAlign">
                                                        {data.merchant_goods_images && data.merchant_goods_images[0] && (
                                                            <img className='delivery-pickup-image' src={data.merchant_goods_images && data.merchant_goods_images[0]} alt='NA' />
                                                        )}

                                                    </td>
                                                    <td className="tableDataAlign">{data.porter_tracking_link && data.porter_tracking_link}</td>
                                                    <td className="tableDataAlign">{data.is_ondc_order ? 'Yes' : 'No'}</td>
                                                </tr>
                                            )
                                            )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='pagination-div'>
                                <div onClick={page === 1 ? null : () => handlePageChange('prev')} className='next-prev-container'>
                                    <FontAwesomeIcon className={page === 1 ? 'next-prev-icon-disabled' : 'next-prev-icon'} icon={faChevronLeft} />
                                    <p className={page === 1 ? 'next-prev-text-disabled' : ''}>Prev</p>
                                </div>
                                <p>Page {page}</p>
                                <div onClick={lastPage ? null : () => handlePageChange('next')} className='next-prev-container'>
                                    <p className={lastPage ? 'next-prev-text-disabled' : ''}>Next</p>
                                    <FontAwesomeIcon className={lastPage ? 'next-prev-icon-disabled' : 'next-prev-icon'} icon={faChevronRight} />
                                </div>
                            </div>
                        </div>


                        {/* Live Force Riders (map) */}
                        <div id="2" className={`tabcontents ${activeTab === '2' ? 'active' : ''}`}>
                            <div className='flexAlignMapData'>
                                <div >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='tableHeaderAlign'>Rider Name</th>
                                                <th className='tableHeaderAlign'>Rider Number</th>
                                                <th className='tableHeaderAlign'>Rider Lat</th>
                                                <th className='tableHeaderAlign'>Rider Long</th>
                                                <th className='tableHeaderAlign'>Last Updated At</th>
                                                <th className='tableHeaderAlign'>Order Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mapAgentData && mapAgentData.map((data, index) => (
                                                <tr className="tableRowAlign" key={index}>
                                                    <td className="tableDataAlign">{data.name}</td>
                                                    <td className="tableDataAlign">{data.user_mob_num}</td>
                                                    <td className="tableDataAlign">{data.latitude}</td>
                                                    <td className="tableDataAlign">{data.longitude}</td>
                                                    <td className="tableDataAlign">{data.updated_at}</td>
                                                    <td className="tableDataAlign">{data.is_having_order === 1 ? 'Idle' : 'On Duty'}</td>
                                                </tr>
                                            ))}
                                            {/* Add more rows as needed */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mapStyle'>
                                    {/* MapSection component */}
                                    {activeTab === '2' && <MapSection mapAgentData={mapAgentData} activeTab={activeTab} />}
                                </div>
                            </div>

                        </div>

                        {/* Edit Order Tab */}
                        <div id="3" className={`tabcontents ${activeTab === '3' ? 'active' : ''}`}>
                            <div className='editOrderTabData'>
                                <div className='left'>
                                    <div className='textFieldStyle'>
                                        <TextField className='textBoxStyle'
                                            type='number'
                                            label="Enter Order ID"
                                            onChange={(e) => setOrderID(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <button className={orderID.length !== 0 ? 'searchButton' : 'searchButtonDisabled'} onClick={orderID.length !== 0 ? getDeliveryOrderDetails : null}>Search</button>
                                    </div>
                                </div>
                                {Object.keys(response1).length !== 0 && (
                                    <div className='right'>

                                        <div className='box-border'>
                                            <Typography>Order Type: {Object.keys(response1).length !== 0 && response1.orderType}</Typography>
                                        </div>
                                        <div className='box-border'>
                                            <Typography>Order Status: {Object.keys(response1).length !== 0 && response1.orderStatus}</Typography>
                                            <div className='all-box-container'>
                                                <div className='time'>
                                                    {timeData.set_delivery_mode_datetime != null &&
                                                        (timeData.set_delivery_mode_datetime).length !== 0 &&
                                                        <Typography>Requested Time: {timeData.set_delivery_mode_datetime}</Typography>}
                                                    {timeData.cb_allocation_time != null &&
                                                        (timeData.cb_allocation_time).length !== 0 &&
                                                        <Typography>Assigned Time: {timeData.cb_allocation_time}</Typography>}
                                                    {timeData.cb_arrived_time != null &&
                                                        (timeData.cb_arrived_time).length !== 0 &&
                                                        timeData.shipment_status >= 2 &&
                                                        <Typography>Arrived Time: {timeData.cb_arrived_time}</Typography>}
                                                    {timeData.cb_picked_up_time != null &&
                                                        (timeData.cb_picked_up_time).length !== 0 &&
                                                        timeData.shipment_status >= 3 &&
                                                        <Typography>Picked Up Time: {timeData.cb_picked_up_time}</Typography>}
                                                    {timeData.cb_reached_time != null &&
                                                        (timeData.cb_reached_time).length !== 0 &&
                                                        timeData.shipment_status >= 4 &&
                                                        <Typography>Reached Destination Time: {timeData.cb_reached_time}</Typography>}
                                                    {timeData.cb_delivered_time != null &&
                                                        (timeData.cb_delivered_time).length !== 0 &&
                                                        <Typography>Delivered Time: {timeData.cb_delivered_time}</Typography>}
                                                </div>
                                                <div className='box'>
                                                    <Typography>{Object.keys(response1).length !== 0 && response1.orderType}</Typography>
                                                    <Typography>Order Status: {Object.keys(response1).length !== 0 && response1.orderStatus}</Typography>
                                                </div>

                                            </div>
                                        </div>
                                        {(response1.orderType !== constantmessage.selfDelivery && response1.AssignedTo !== null) &&
                                            (
                                                <div className='box-border'>
                                                    <Typography>Assigned To: {Object.keys(response1).length !== 0 && response1.AssignedTo}  {Object.keys(response1).length !== 0 && response1.riderName}  {Object.keys(response1).length !== 0 && response1.riderNumber}</Typography>
                                                </div>
                                            )}
                                        {(response1.orderStatus !== constantmessage.delivered) &&
                                            (response1.AssignedTo !== constantmessage.loadShare) &&
                                            (nearbyAgentFlag !== constantmessage.noNearbyAgent) &&
                                            (timeData.set_delivery_mode_datetime !== null) &&
                                            (
                                                <div className='changeRider box-border'>
                                                    <div>
                                                        <Typography>Change Rider:</Typography>
                                                    </div>
                                                    <div className='dropDown'>
                                                        <select ref={dropDownRef}>
                                                            {response2.map((data, index) => (
                                                                <option key={index}>
                                                                    {data.working_role === constantmessage.agentRole ? 'DP' : 'BE'} -- {data.name} || {data.user_mob_num} || {(data.distance / 1000).toFixed(3)} Km
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <button className='searchButton' onClick={assignDeliveryAgent}>Submit</button>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </div>
                        </div> {/* end of 3rd tab */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliveryDashboard;