import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import "../../Dashboard.css";
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Moment from 'react-moment';
import LoadingSpinner from "../../components/loader/loader.js";
import { alertDangerConfirmMessage, alertSuccessMessageMerchant } from "../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js";
import ApiService from "../../api/services/ApiService.js";
import { constants } from "../../utils/constants.js";


const ChequeValidation = (props) => {
  const [canchequeurl, setCanchequeurl] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accNum, setAccNum] = useState("");
  const [reaccNum, setReaccNum] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [ticket_no, setTicket_no] = useState("");
  const [showCanchequeurl, setShowCanchequeurl] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [showShopBoard, setshowShopBoard] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rightImage, setRightImage] = useState(false);
  const [updatedTimestamp, setupdatedTimestamp] = useState("");
  const [status, setstatus] = useState("");
  const [disable, setdisable] = useState(true);
  const [mobileNo, setMobileNo] = useState("");
  const [agent_id, setAgent_id] = useState("");
  const [licenseQc, setLicenseQc] = useState();
  const [panQc, setPanQc] = useState();
  const [bankQc, setBankQc] = useState();
  const [accVerifyStatus, setAccVerifyStatus] = useState();
  const [selfieQc, setSelfieQc] = useState();
  const [isLicenseUpdated, setIsLicenseUpdated] = useState("")
  const [isPanUpdated, setIsPanUpdated] = useState("")
  const [isBankUpdated, setIsBankUpdated] = useState("")
  const [isSelfieUpdated, setIsSelfieUpdated] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const { riderQcChequeErrorMsgs, requestTimedOutError } = constants;

  const handleCloseCanchequeurl = () => setShowCanchequeurl(false);

  const handleShowCanchequeurl = () => setShowCanchequeurl(true);

  useEffect(() => {
    setCanchequeurl(props.data.can_cheque_url);
    seterrorMessage("");
    setTicket_no(props.data.ticket_no);
    setupdatedTimestamp(props.data.bank_updated_at || props.data.created_at);
    setstatus(props.data.bank_qc);
    setMobileNo(props.data.mobile_no);
    setAgent_id(props.data.agent_id);
    setBankName(props.data.bank_name)
    setAccNum(props.data.acc_number)
    setIfscCode(props.data.bankifsc)
    setAccountName(props.data.acc_name)
    setPanQc(props.data.pan_qc)
    setLicenseQc(props.data.license_qc)
    setSelfieQc(props.data.selfie_qc)
    setBankQc(props.data.bank_qc)
    setIsLicenseUpdated(props.data.is_license_updated)
    setIsPanUpdated(props.data.is_pan_updated)
    setIsBankUpdated(props.data.is_bank_updated)
    setIsSelfieUpdated(props.data.is_selfie_updated)
    setAccVerifyStatus(props.data.acc_verify_status)
  }, [props]);

  const handleAprove = () => {
    setshowShopBoard(false);
    setRightImage(true);
    setApproved(true);
    setBankQc(0);
    setdisable(false);
  };

  const handleReject = () => {
    setshowShopBoard(true);
    setRightImage(false);
    setApproved(false);
    setBankQc(1);
    setdisable(false);
  };


  const accName = (event) => {
    setAccountName(event.target.value);
  };

  const accbankName = (event) => {
    setBankName(event.target.value);
  };

  const accNumber = (event) => {
    setAccNum(event.target.value);
  };

  const reaccNumber = (event) => {
    setReaccNum(event.target.value);
  };

  const accifscCode = (event) => {
    setIfscCode(event.target.value);
  };

  const submitCheque = async (event) => {
    setIsLoading(true)
    if (approved) {
      if (accountName === "") {
        setIsLoading(false)
        seterrorMessage(riderQcChequeErrorMsgs.accountName);
      } else if (bankName === "") {
        setIsLoading(false)
        seterrorMessage(riderQcChequeErrorMsgs.bankName);
      } else if (accNum === "") {
        setIsLoading(false)
        seterrorMessage(riderQcChequeErrorMsgs.accountNumber);
      } else if (reaccNum === "") {
        setIsLoading(false)
        seterrorMessage(riderQcChequeErrorMsgs.reAccountNumber);
      } else if (reaccNum !== accNum) {
        setIsLoading(false)
        seterrorMessage(riderQcChequeErrorMsgs.accountNumberMatch);
      }
      else if (ifscCode === "") {
        setIsLoading(false)
        seterrorMessage(riderQcChequeErrorMsgs.ifscCode);
      } else {
        const chequeVerification = {
          bank_name: bankName,
          bankIFSC: ifscCode,
          acc_name: accountName,
          acc_number: accNum,
          mobile_no: mobileNo,
          ticket_no: ticket_no,
          canChequeUrl: canchequeurl,
          agent_id: agent_id,
          bank_qc: bankQc,
          ticketStatus: isLicenseUpdated == 1 && isPanUpdated == 1 && isBankUpdated == 1 && isSelfieUpdated == 1 ? 2 : 1
        };
        const token = localStorage.getItem('sessionId');
        try {
          setIsLoading(true)
          const result = await ApiService.approveRejectBank(token, chequeVerification);
          if (result.code === requestTimedOutError) {
            alertDangerConfirmMessage(result.message)
          } else if (result.data.code === 0) {
            alertSuccessMessageMerchant("Success")
            window.location.reload();
          } else {
            alertDangerConfirmMessage("ERROR")
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      const chequeVerification = {
        bank_name: bankName,
        bankIFSC: ifscCode,
        acc_name: accountName,
        acc_number: accNum,
        mobile_no: mobileNo,
        ticket_no: ticket_no,
        canChequeUrl: canchequeurl,
        agent_id: agent_id,
        bank_qc: bankQc,
        ticketStatus: isLicenseUpdated == 1 && isPanUpdated == 1 && isBankUpdated == 1 && isSelfieUpdated == 1 ? 2 : 1
      };
      const token = localStorage.getItem('sessionId');
      try {
        setIsLoading(true)
        const result = await ApiService.approveRejectBank(token, chequeVerification);
        if (result.data.code === 0) {
          window.location.reload();
        } else {
          alertDangerConfirmMessage("ERROR")
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

  };

  return (
    <div className=" background_validation">
      <Row>
        <Col className="mt-4">
          <div className="pb-3 pt-2 header">
            <b className="pt-3 title">Section -3: Verify Bank details</b>
            <div className="d-inline-block float-right w-25  border border-secondary">
              <div className="d-flex flex-row border-bottom border-secondary last_update">
                <p className="pl-2 mb-0"><b>Last Update</b></p>

              </div>
              <div className="d-flex flex-row mt-2">
                <p className="pl-2">Status : </p>
                <p>{bankQc == 2 ? "New" : (accVerifyStatus == 0 ? "Approved" : "Resubmit")}</p>
              </div>
              <div className="d-flex flex-row">
                <p className="pl-2">Timestamp : </p>
                <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
              </div>
            </div>

          </div>
        </Col>
      </Row>
      <br />
      <div className="font-weight-bold pt-3 pl-3 mb-4">Cancelled cheque photo</div>
      <Row>
        <Col md={3} className=" pb-2">
          {console.log('canchequeurl===', typeof (canchequeurl))}
          {canchequeurl !== 'undefined' ? <div className="color_border_validation height_cheque  position-absolute">
            <img className="w-100 h-1w-100 h-100  cursor-pointer" src={canchequeurl} onClick={handleShowCanchequeurl} alt="cheque" />
          </div> :
            <div>
              <span>No Cheque Uploaded</span>
            </div>}
        </Col>
        <Col md={1} className='pb-5'>

          <div className="image_wrong_div mt-4 mx-auto" onClick={handleAprove} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}>
            <img className="image_wrong cursor-pointer" src={right} alt="Approve" />
          </div>
          <div className="text-center pt-2">
            <b>OK</b>
          </div>
        </Col>

        <Col md={1}>
          <div className="image_wrong_div mt-4 image_validation " style={{ backgroundColor: showShopBoard == true ? "#FD626B " : "" }}>
            <img className="image_wrong cursor-pointer" src={wrong} onClick={handleReject} alt="Reject" />
          </div>
          <div className="text-center pt-2">
            <b>Reject</b>
          </div>
        </Col>

      </Row>
      <br />
      <Row className="pl-3 pt-5">
      </Row>

      {rightImage && (
        <p className="font-weight-bold pl-3 mt-3">Enter details: From cancelled cheque, enter details:</p>
      )}

      <Row>
        {rightImage && (
          <Col md={8}>
            <div class="form-group">
              <div className="pt-3">
                <label className="label_cheque text-justify">Name as per bank account:</label>
                <input className="input_cheque mt-0" name="bankname" onChange={accName} value={accountName} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">Bank name: </label>
                <input className="input_cheque mt-0" name="bankname" onChange={accbankName} value={bankName} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">Acount number: </label>
                <input className="input_cheque mt-0" name="accountnum" onChange={accNumber} value={accNum} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">Re-enter Acount number: </label>
                <input className="input_cheque mt-0" name="accountnum" onChange={reaccNumber} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">IFSC Code: </label>
                <input className="input_cheque mt-0" name="ifsccode:" onChange={accifscCode} value={ifscCode} />
              </div>
            </div>
          </Col>
        )}


      </Row>
      <Row>
        {(approved &&
          <Col style={{ display: "none", justifyContent: "end" }}>
            <button className="cheque_submit btn pt-2 pb-2 mt-0 " onClick={submitCheque}>
              Verify
            </button>
            <div className="text-center pt-2">
              <b>Status: </b>Verified / Not verified
            </div>
            <p className="text-danger text-center pt-3">{errorMessage}</p>
          </Col>
        )}
        <Col style={{ display: "grid", justifyContent: "end" }}>

          <span>
            <button className="cheque_submit btn pt-2 pb-2 mt-0" disabled={disable} onClick={submitCheque}>
              Submit
            </button>
          </span>
          <p className="text-danger text-center pt-3">{errorMessage}</p>
        </Col>
      </Row>
      {isLoading && (
        <LoadingSpinner />
      )}
      <Modal show={showCanchequeurl} onHide={handleCloseCanchequeurl}>
        <Modal.Body>
          <TransformWrapper>
            <TransformComponent>
              <img className="w-100 h-100" src={canchequeurl} alt="cheque" />
            </TransformComponent>
          </TransformWrapper>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const Dashboard = withRouter(ChequeValidation);
export default Dashboard;
