import { Fetch } from "../apiConfig/Fetch";
import { APIConfig } from "../apiConfig/apiConfig";

const searchService = {
    orderIdSearch: async (data) => {
        const { baseUrl, searchByOrderId } = APIConfig;
        const url = baseUrl + searchByOrderId;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            orderId:data.orderId
        });
        const OrderIDSearchPromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_OrderIDSearch",
            true
        );
        return OrderIDSearchPromise;

    },
    merchantMobileNumsearch: async (data) => {
        const { baseUrl, getOrderDetailsByMerchantMobileNo } = APIConfig;
        const url = baseUrl + getOrderDetailsByMerchantMobileNo;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            merchantNumber:data.merchantNumber
        });
        const merchantMobileNumsearchPromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_merchantMobileNumsearch",
            true
        );
        return merchantMobileNumsearchPromise;

    },
    customerMobileNumsearch: async (data) => {
        const { baseUrl, getOrderDetailsByUserMobileNo } = APIConfig;
        const url = baseUrl + getOrderDetailsByUserMobileNo;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            customerNumber:data.customerNumber
        });
        const customerMobileNumsearchPromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_customerMobileNumsearch",
            true
        );
        return customerMobileNumsearchPromise;

    },
};
export default searchService