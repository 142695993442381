import { Fetch } from "../apiConfig/Fetch";
import { APIConfig } from "../apiConfig/apiConfig";

const LogisticsService = {
  getStates: async () => {
    const { baseUrl, getStates } = APIConfig;

    const url = baseUrl + getStates;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const getStatesServicePromise = await Fetch(url, "GET", headers, "services_ getStates", true);
    return getStatesServicePromise;
  },

  getCitiesByState: async (data) => {
    const { baseUrl, getCitiesByState } = APIConfig;

    const url = baseUrl + getCitiesByState;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      state: data.state,
    });

    const getCitiesByStateServicePromise = await Fetch(url, "POST", headers, params, "services_ getCitiesByState", true);
    return getCitiesByStateServicePromise;
  },

  getPincodes: async (data) => {
    const { baseUrl, getPincodes } = APIConfig;

    const url = baseUrl + getPincodes;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      city: data.city,
    });

    const getPincodesServicePromise = await Fetch(url, "POST", headers, params, "services_ getPincodes", true);
    return getPincodesServicePromise;
  },

  getMerchantConnectedUser: async (data) => {
    const { baseUrl, getMerchantConnectedUser } = APIConfig;

    const url = baseUrl + getMerchantConnectedUser;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      city: data.city,
    });

    const getMerchantConnectedUserPromise = await Fetch(url, "POST", headers, params, "services_ getMerchantConnectedUser", true);
    return getMerchantConnectedUserPromise;
  },

  getUserMerchantChannels: async (data) => {
    const { baseUrl, getUserMerchantChannels } = APIConfig;

    const url = baseUrl + getUserMerchantChannels;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      userNo: data,
    });

    const getUserMerchantChannelsPromise = await Fetch(url, "POST", headers, params, "services_ getUserMerchantChannels", true);
    return getUserMerchantChannelsPromise;
  },

  

  getLogisticslist: async () => {
    const { baseUrl, getLogistics } = APIConfig;

    const url = baseUrl + getLogistics;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const getLogisticsServicePromise = await Fetch(url, "POST", headers);
    return getLogisticsServicePromise;
  },

  getLogistics: async (data) => {
    const { baseUrl, getLogistics } = APIConfig;

    const url = baseUrl + getLogistics;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      pincode: data.pincode,
    });

    const getLogisticsServicePromise = await Fetch(url, "POST", headers, params, "services_ getLogistics", true);
    return getLogisticsServicePromise;
  },

  addUpdateLogistics: async (data) => {
    const { baseUrl, addUpdateLogistics } = APIConfig;

    const url = baseUrl + addUpdateLogistics;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      id: data.id,
      state: data.state,
      city: data.city,
      pincode: data.pincode,
      cb_delivery: data.cb_delivery,
      base_km: data.base_km,
      base_charges: data.base_charges,
      charges_per_additional_km: data.charges_per_additional_km,
      free_delivery_count: data.free_delivery_count,
      GST_on_logistics_rate: data.GST_on_logistics_rate,
    });

    const addUpdateLogisticsServicePromise = await Fetch(url, "POST", headers, params, "services_ addUpdateLogistics", true);
    return addUpdateLogisticsServicePromise;
  },
};

export default LogisticsService;
