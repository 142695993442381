import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Sidebar from "./sidebar.js";
import '../Dashboard.css';
import axios from 'axios';
import view from "../images/view.png";
import edit from "../images/edit.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import merchantService from "../api/services/MerchantService.js";
import CategoryService from '../api/services/CategoryService';
import Moment from 'react-moment';
import 'moment-timezone';
import {
  alertSearchFailMessage,
  alertDangerConfirmMessage,
  alertSuccessMessageMerchant,
  alertDangerMessage,
  alertSuccessMessage
} from "./customComponent/ConfirmationMessage/ConfirmationMessage";
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { APIConfig } from "../api/apiConfig/apiConfig.js";
import { Dialog } from "@mui/material";
import close_icon from "../../src/payout/close.png";
import ApiService from "../api/services/ApiService.js";
import { constants } from "../utils/constants.js";

const shopCloseValues = [
  { id: 0, value: 'Closed' },
  { id: 1, value: 'Open' },
]

const ONDCvalues = [
  { id: 0, value: true, name: 'Active' },
  { id: 1, value: false, name: 'Inactive' },
]

const Dashboard = (props) => {

  const [merchentList, setMerchentList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [l3Data, setL3Sata] = useState([]);
  const [l1Data, setL1Data] = useState([])
  localStorage.removeItem('L3CategoryData');
  localStorage.removeItem('L1CategoryData');
  const [offset, setOffSet] = useState(0);
  const [totalMerchant, setTotalMerchant] = useState('');
  const [limt, setlimt] = useState(50);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = useState('');
  const [displayStart, setDisplayStart] = useState('');
  const [displayEnd, setDisplayEnd] = useState('');
  const [displayPagination, setDisplayPagination] = useState(false);
  const [shopClose, setShopClose] = useState([]);
  const [showCashbackModal, setShowCashbackModal] = useState(false);
  const [mobileNumForCashback, setMobileNumForCashback] = useState(null);
  const [cashbackAmt, setCashbackAmt] = useState(null);
  const { baseUrl, tiggerMerchantCashback } = APIConfig;
  const [narration, setNarration] = useState('');
  const [errorText, setErrorText] = useState('');
  const [ondcSelectKey, setOndcSelectKey] = useState(null);
  const ondcSelect = useRef(null);
  const { sfyTexts } = constants
  const {cashbackMessages} = constants;

  useEffect(() => {
    getViewMerchant(offset, limt);
  }, []);

  const getCategoryTree = async () => {
    await CategoryService.getCategoryTree()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                let array = []
                let l1Array = []
                {
                  result.data && result.data.map(item => {
                    { item && l1Array.push(item) }
                    {
                      item.children && item.children.map(it => {
                        {
                          it.children && it.children.map(data => {
                            // console.log("meow",data.name)
                            {
                              data &&
                                array.push(data)
                            }
                          })
                        }
                      })
                    }
                  })
                }
                setL3Sata([...array]);
                setL1Data([...l1Array]);
                console.log(l1Array);
              } else {
                console.log(result.message);
              }
            })
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getViewMerchant = (offset2, limt2) => {
    axios.get(window.baseUrl + 'viewMerchant?limit=' + limt2 + '&offset=' + offset2,{timeout:30000,timeoutErrorMessage:'Request Timedout'})
      .then((response) => {
        setDisplayPagination(true);
        setTotalMerchant(response.data.totalMerchants);
        let pageCount1 = response.data.totalMerchants / 50;
        pageCount1 = Math.ceil(pageCount1);
        if (pageCount != pageCount1) {

          setPageCount(pageCount1);
          setDisplayStart('1');
          setDisplayEnd('50');
        }

        var res = response.data.data;
        let arr = [];
        res.map(mer => {
          arr.push(mer.is_shop_closed);
        })
        setShopClose(arr);

        // for (var i = 0; i < res.length; i++) {
        //   console.log(res[0].createddate);
        //   res.sort(function (a, b) {

        //     return new Date(b.createddate) - new Date(a.createddate)
        //   })
        setMerchentList(response.data.data);
        // }

      });
    getCategoryTree();
  }

  const handleSearchInput = (event) => {
    setSearchInput(event);
    if (event == "" || event == "NULL") {
      let offset1 = 0;
      setlimt(50);
      let limt1 = 50;
      setOffSet(offset1);
      getViewMerchant(offset1, limt1);
    }
  }
  const handleChange = (event, value) => {
    setPage(value);
    let offset1 = offset;
    let offset2 = (value) * 50;
    let limt1 = limt;

    if (value == 1) {
      offset1 = 0;
      setlimt(50);
      limt1 = 50;
      setDisplayStart('1');
      setDisplayEnd('50');
    } else
      if (offset2 > totalMerchant) {
        offset1 = (value - 1) * 50;
        limt1 = totalMerchant - ((value - 1) * 50);
        setlimt(offset1);
        setDisplayStart(((value - 1) * 50) + 1);
        setDisplayEnd(totalMerchant);
      }
      else {
        offset1 = ((value - 1) * 50);
        limt1 = 50;
        setlimt(50);
        setDisplayStart(((value - 1) * 50) + 1);
        setDisplayEnd(value * 50);
      }

    setOffSet(offset1);
    getViewMerchant(offset1, limt1);

  };

  const handleSearch = (event) => {
    if (searchInput.trim().length == 0) {
      setErrorText('* This field cannot be empty');
    } else {
      setErrorText('');
    }
    if (searchInput.trim().length !== 0) {
      axios.get(window.baseUrl + 'viewMerchant?search=' + searchInput,{timeout:30000,timeoutErrorMessage:'Request Timedout'})
        .then((response) => {
          if (response.data.message == "Empty List") {

            alertSearchFailMessage(`${response.data.message}`);

          }
          else {
            setDisplayPagination(false);
            setMerchentList(response.data.data);
            let arr = [];
            response.data.data.map(mer => {
              arr.push(mer.is_shop_closed);
            })
            setShopClose(arr);
          }
          console.log("bow", response);
        });
    }

  };

  const updateShopClosingTime = async (mob, key) => {
    let value = shopClose[key];
    await axios
      .post(baseUrl + "updateShopClosingTime", {
        is_shop_closed: value,
        merch_mob_num: mob,
        is_from_panel: 0,
      },
      {timeout:30000,timeoutErrorMessage:'Request Timedout'}
      )
      .then(function (response) {
        console.log("shopCloseRes", response)
        if (response.data.code === 0) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleShopCloseChange = (key, e) => {
    setShopClose(prev => {
      const arr = [...prev];
      arr[key] = e.target.value;
      return arr;
    })
  }

  const handleCashbackAddClick = (mob) => {
    setShowCashbackModal(true);
    setMobileNumForCashback(mob);
  }

  const handleCashback = () => {
    const token = localStorage.getItem('sessionId');
    const headers = { token };
    if (cashbackAmt > 1000) {
      alertDangerMessage(cashbackMessages.cashbackLimitErr)
    } else {
      axios
        .post(baseUrl + tiggerMerchantCashback, {
          merchantNo: mobileNumForCashback,
          amount: cashbackAmt,
          narration: narration,
        }, { headers,timeout:30000,timeoutErrorMessage:'Request Timedout'  })
        .then(function (response) {
          if (response.data.status === 0) {
            console.log(response.data)
            setShowCashbackModal(false);
            setCashbackAmt(null);
            setNarration('');
            alertSuccessMessageMerchant(cashbackMessages.cashbackSuccess)
          } else {
            alertDangerConfirmMessage(cashbackMessages.cashbackFailed)
          }

        })
        .catch(function (error) {
          alertDangerConfirmMessage(error.message)
          console.log(error);
        });
    }

  }

  const handleCashbackClose = () => {
    setShowCashbackModal(false);
    setCashbackAmt(null);
    setNarration('');
  }

  const handleBlur = () => {
    setErrorText('');
  };

  const changeOndcStatus = async (mobNumber) => {
    const data = {
      ondcEligibility: ondcSelect.current.value,
      merchantMobNo: mobNumber
    }
    try {
      const result = await ApiService.updateMerchEligibilityForOndc(data);
      if (result.data.code === 0) {
        alertSuccessMessage(sfyTexts.editSuccessText)
      } else {
        alertDangerConfirmMessage(sfyTexts.editFailText)
      }
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  return (
    <>
      <div>
            <div>
                <Sidebar />
            </div>
            <div className="tittle ">
              <h1>Merchant List</h1>
            </div>
            <div className="Merchentlist" style={{ marginBottom: '5%' }}>
              <div className="search-input-container">
                <TextField error={!!errorText} helperText={errorText ? errorText : ''} className="merchant-list-search-input" onBlur={handleBlur} id="outlined-basic" variant="outlined" placeholder="Business name/phone/MID" value={searchInput} onChange={(event) => handleSearchInput(event.target.value)} InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }} style={{ width: '70%' }} />
                <button onClick={handleSearch} className="master-catalog-btn qc-panel-search-btn">Search</button>
                {/* <Button variant="outlined" className="Search_button" onClick={handleSearch}>Search</Button> */}
              </div>
              <table border="1" cellpadding="0" cellSpacing="0" className="merchentList_table">
                <tr className="merchentList_tr">
                  <th className="merchentList_th">Business Name</th>
                  <th className="merchentList_th"> Address</th>
                  <th className="merchentList_th">Merchant ID</th>
                  <th className="merchentList_th">Merchant Name</th>
                  <th className="merchentList_th">Category Name</th>
                  <th className="merchentList_th">Mobile Number</th>
                  <th className="merchentList_th">Date & time</th>
                  <th className="merchentList_th">Status</th>
                  <th className="merchentList_th">Action</th>
                  <th className="merchentList_th">Shop Status</th>
                  <th className="merchentList_th">Cashback</th>
                  <th className="merchentList_th">ONDC</th>
                </tr>
                <tbody>
                  {merchentList.map((val, key) => {
                    return (
                      <tr className="merchentList_tr" key={key}>
                        <td className="merchentList_td">{val.shopname}</td>
                        <td className="merchentList_td">{val.addressline1},{val.addressline2},{val.city}</td>
                        <td className="merchentList_td">{val.merchantid}</td>
                        <td className="merchentList_td">{val.merchantname}</td>
                        <td className="merchentList_td">{val.l1_categoryname},{val.l2_categoryname},{val.l3_categoryname},{val.l4_categoryname}</td>
                        <td className="merchentList_td">{val.mainmobilenumber}</td>
                        <td className="merchentList_td"><Moment format="MMM Do YYYY hh:mm A">{val.createddate}</Moment></td>
                        <td className="merchentList_td">{val.bizstatus === 0 ? 'Active' : 'In Active'}</td>

                        <td className="merchentList_td">
                          {val.merchantid != null ?
                            <a className="pr-2" href={`/merchentDetails/${val.merchantid}`}>
                              <img className="view_icon" src={view} alt="view" />
                            </a> : ''}

                          {/* <a className="px-2" href={`/editmerchentDetails/${val.merchantid}`}> */}
                          {val.merchantid != null ?
                            <Link to={{
                              pathname: `/editmerchentDetails/${val.merchantid}`,
                              state: val,
                              l3Data: l3Data,
                              l1Data: l1Data
                            }}>
                              <img className="edit-icon" src={edit} alt="edit" />
                            </Link> : ''}
                          {/* </a> */}
                        </td>
                        {/* <a href={`/merchentDetails/${key}`}> */}
                        <td className="merchentList_td">
                          <select v-model="selected" className="editInput" value={shopClose[key]} onChange={(e) => handleShopCloseChange(key, e)}>
                            {shopCloseValues.map(item => (
                              <option value={item.id} >
                                {item.value}
                              </option>
                            ))}
                          </select>
                          <button className="shop_status_submit_btn" onClick={() => updateShopClosingTime(val.mainmobilenumber, key)}>Submit</button>


                        </td>
                        <td className="merchentList_td">
                          <button className="cashback_btn" onClick={() => handleCashbackAddClick(val.mainmobilenumber)}>Add Cashback/<br />Refund</button>
                        </td>
                        <td className="merchentList_td">
                          {ondcSelectKey === key ? (
                            <>
                              <select ref={ondcSelect} v-model="selected" className="ONDC-select" defaultValue={val.ondc_eligibility}>
                                {ONDCvalues.map(data => (
                                  <option key={data.id} value={data.value} >
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                              <button className="shop_status_submit_btn" onClick={() => changeOndcStatus(val.mainmobilenumber)}>Submit</button>
                            </>
                          ) : (
                            <>
                              <p className="ONDC-select">{val.ondc_eligibility === true ? 'Active' : 'Inactive'}</p>
                              <button className="shop_status_submit_btn" onClick={() => setOndcSelectKey(key)}>Change</button>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </div>
            <Dialog onClose={() => setShowCashbackModal(false)} open={showCashbackModal}>
              <img
                className="close_icon"
                src={close_icon}
                onClick={handleCashbackClose}
                alt="close"
              />
              <div className="cashback_dialog">
                <div className="buttons-container">
                  <TextField onChange={(e) => setCashbackAmt(e.target.value)} className='vip_history_input single-input' type="number" label="Enter Amount" />
                  <TextField multiline={true} onChange={(e) => setNarration(e.target.value)} maxRows={4} inputProps={{ maxLength: 40 }} className='vip_history_input' label="Narration (max 40 characters)" />
                </div>
                {(cashbackAmt !== null && narration !== '') ? (
                  <button onClick={handleCashback} className='vip_history_submit_btn cashback-submit-btn'>Submit</button>
                ) : (
                  <button disabled className='cashback-submit-btn-disabled'>Submit</button>
                )}

              </div>

            </Dialog>

            {displayPagination == true ?
              <Stack spacing={2} style={{ display: 'block', width: '100%', marginBottom: '5px', left: 0, right: '0px', position: 'fixed', bottom: '0px', backgroundColor: '#CDECF4', padding: '5px 3px ' }}>
                <Typography style={{ display: 'inline-block', width: '30%' }}>Showing Results {displayStart} to {displayEnd} of {totalMerchant} </Typography>
                <Pagination style={{ display: 'inline-block', marginTop: '-3px', float: 'right' }} count={pageCount} page={page} className="PaginationCheck" onChange={handleChange} />
              </Stack>
              : ''}
      </div>
    </>
  );
};
export default Dashboard