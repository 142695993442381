import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar.js";
import './RiderIncentive.css'
import { constants } from '../../utils/constants.js';
import ApiService from '../../api/services/ApiService.js';
import { alertDangerConfirmMessage, alertSuccessMessageMerchant } from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js';
import LoadingSpinner from '../../components/loader/loader.js';

const RiderIncentive = () => {
    const { riderIncentiveCities } = constants;
    const [btnEnable, setBtnEnable] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [rateCardData, setRateCardData] = useState({});
    const [rateCardDataCopy, setRateCardDataCopy] = useState({});
    const [city, setCity] = useState('');

    useEffect(() => {
        if (city !== 'Select City' && city !== '') {
            getRateCardByCity();
        }
    }, [city])

    const getRateCardByCity = async () => {
        setIsLoading(true);
        var data = { city: city };
        const token = localStorage.getItem('sessionId');
        try {
            const result = await ApiService.getRateCardByCity(token, data);
            if (result.data.code === 0) {
                setRateCardData(result.data.data);
                setRateCardDataCopy(result.data.data);
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const updateForceAppRateCard = async () => {
        setIsLoading(true);
        var data = {
            city: city,
            baseKm: rateCardData.base_km,
            baseCharge: rateCardData.base_charge,
            variableCharge: rateCardData.variable_charge,
            nightCharge: rateCardData.night_charge
        };
        const token = localStorage.getItem('sessionId');
        try {
            const result = await ApiService.addUpdateForceAppRateCard(token, data);
            if (result.data.code === 0) {
                alertSuccessMessageMerchant("Success");
                setBtnEnable(null);
                await getRateCardByCity()
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const handleInputChange = (num, value) => {
        if (num === 1) {
            setRateCardData((prevData) => ({
                ...prevData,
                base_km: value,
            }));
        } else if (num === 2) {
            setRateCardData((prevData) => ({
                ...prevData,
                base_charge: value,
            }))
        } else if (num === 4) {
            setRateCardData((prevData) => ({
                ...prevData,
                night_charge: value,
            }))
        } else {
            setRateCardData((prevData) => ({
                ...prevData,
                variable_charge: value,
            }));
        }
    }

    const handleCancelBtn = () => {
        setBtnEnable(null)
        setRateCardData(rateCardDataCopy);
    }

    const handleSaveBtn = () => {
        updateForceAppRateCard()
    }

    return (
        <div>
            <div className='sidebar'>
                <Sidebar />
            </div>
            {isLoading && (
                <LoadingSpinner />
            )}
            <div>
                <select v-model="selected" className="delivery-details-select incentive-select" onChange={(e) => { setCity(e.target.value); setBtnEnable(null); }}>
                    {riderIncentiveCities.map(data => (
                        <option hidden={data.id === 0} key={data.id} value={data.value} >
                            {data.value}
                        </option>
                    ))}
                </select>
                <div className='table-container'>
                    <table className='delivery-table'>
                        <tbody>
                            <tr className='table-data-row'>
                                <td className="incentive-table-cell first-cell-incentive">Base Km</td>
                                <td className="incentive-table-cell">
                                    <div className='center-align'>
                                        {console.log("RATECARD", rateCardData)}
                                        <input type='number' onChange={(e) => handleInputChange(1, e.target.value)} disabled={btnEnable !== 1} value={rateCardData?.base_km} className='incentive-inputs' />
                                    </div>
                                </td>
                                <td className="incentive-table-cell-btn">
                                    {btnEnable === 1 ? (
                                        <button onClick={handleSaveBtn} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Save</button>
                                    ) : (
                                        <button onClick={() => setBtnEnable(1)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Edit</button>
                                    )}
                                    {btnEnable === 1 && (
                                        <button onClick={handleCancelBtn} className='incentive-cancel-btn'>Cancel</button>
                                    )}
                                </td>
                            </tr>
                            <tr className='table-data-row'>
                                <td className="incentive-table-cell first-cell-incentive">Base Rate</td>
                                <td className="incentive-table-cell">
                                    <div className='center-align'>
                                        <input type='number' onChange={(e) => handleInputChange(2, e.target.value)} disabled={btnEnable !== 2} value={rateCardData?.base_charge} className='incentive-inputs' />
                                    </div>
                                </td>
                                <td className="incentive-table-cell-btn">
                                    {btnEnable === 2 ? (
                                        <button onClick={handleSaveBtn} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Save</button>
                                    ) : (
                                        <button onClick={() => setBtnEnable(2)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Edit</button>
                                    )}
                                    {btnEnable === 2 && (
                                        <button onClick={handleCancelBtn} className='incentive-cancel-btn'>Cancel</button>
                                    )}
                                </td>
                            </tr>
                            <tr className='table-data-row'>
                                <td className="incentive-table-cell first-cell-incentive">Addition Payout per Km</td>
                                <td className="incentive-table-cell">
                                    <div className='center-align'>
                                        <input type='number' onChange={(e) => handleInputChange(3, e.target.value)} disabled={btnEnable !== 3} value={rateCardData?.variable_charge} className='incentive-inputs' />
                                    </div>
                                </td>
                                <td className="incentive-table-cell-btn">
                                    {btnEnable === 3 ? (
                                        <button onClick={handleSaveBtn} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Save</button>
                                    ) : (
                                        <button onClick={() => setBtnEnable(3)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Edit</button>
                                    )}
                                    {btnEnable === 3 && (
                                        <button onClick={handleCancelBtn} className='incentive-cancel-btn'>Cancel</button>
                                    )}
                                </td>
                            </tr>
                            <tr className='table-data-row'>
                                <td className="incentive-table-cell first-cell-incentive">Late night bonus</td>
                                <td className="incentive-table-cell">
                                    <div className='center-align'>
                                        <input onChange={(e) => handleInputChange(4, e.target.value)} className='incentive-inputs' value={rateCardData?.night_charge} disabled={btnEnable !== 4} />
                                    </div>
                                </td>
                                <td className="incentive-table-cell-btn">
                                    {btnEnable === 4 ? (
                                        <button onClick={handleSaveBtn} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Save</button>
                                    ) : (
                                        <button onClick={() => setBtnEnable(4)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Edit</button>
                                    )}
                                    {btnEnable === 4 && (
                                        <button onClick={() => setBtnEnable(null)} className='incentive-cancel-btn'>Cancel</button>
                                    )}
                                </td>
                            </tr>
                            <tr className='table-data-row'>
                                <td className="incentive-table-cell first-cell-incentive">Festival bonus per Order</td>
                                <td className="incentive-table-cell">
                                    <div className='center-align'>
                                        <input className='incentive-inputs' disabled={true} />
                                    </div>
                                </td>
                                <td className="incentive-table-cell-btn">
                                    {btnEnable === 5 ? (
                                        <button onClick={() => setBtnEnable(5)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Save</button>
                                    ) : (
                                        <button disabled={true} onClick={() => setBtnEnable(5)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Edit</button>
                                    )}
                                    {btnEnable === 5 && (
                                        <button onClick={() => setBtnEnable(null)} className='incentive-cancel-btn'>Cancel</button>
                                    )}
                                </td>
                            </tr>
                            <tr className='table-data-row'>
                                <td className="incentive-table-cell first-cell-incentive">Rain bonus</td>
                                <td className="incentive-table-cell">
                                    <div className='center-align'>
                                        <input className='incentive-inputs' disabled={true} />
                                    </div>
                                </td>
                                <td className="incentive-table-cell-btn">
                                    {btnEnable === 6 ? (
                                        <button onClick={() => setBtnEnable(6)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Save</button>
                                    ) : (
                                        <button disabled={true} onClick={() => setBtnEnable(6)} className={false ? 'action-btn-disabled' : 'master-catalog-btn incentive-btn'}>Edit</button>
                                    )}
                                    {btnEnable === 6 && (
                                        <button onClick={() => setBtnEnable(null)} className='incentive-cancel-btn'>Cancel</button>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default RiderIncentive
