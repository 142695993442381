import React , {useState} from 'react';
import AwsService from './../api/services/AwsImageService';
const UploadImageToS3WithReactS3 = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileInput = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            // let image = URL.createObjectURL(img)
            console.log(e.target.files[0])
            setSelectedFile(img);
        }
    }
    const handleUpload = async (file) => {
        AwsService.uploadImageToS3(file)
        .then(data => console.log(data))
        .catch(err => console.error(err))
        console.log(file);
    }
    return <div>
        <div>React S3 File Upload</div>
        <input type="file" onChange={handleFileInput}/>
        <button onClick={() => handleUpload(selectedFile)}> Upload to S3</button>
    </div>
}
export default UploadImageToS3WithReactS3;
