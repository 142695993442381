import React, { useEffect, useState, Component } from "react";
import { Container, Row, Col, Card, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../../components/sidebar.js";
import "../../Dashboard.css";
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import resubmit from "../../images/resubmit.png";
import ask from "../../images/ask.png";
import axios from "axios";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Typography, TextField, Button } from "@mui/material";
import Select from "react-select";
import Moment from 'react-moment';


const ChequeValidation = (props) => {
  const [canchequeurl, setCanchequeurl] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accNum, setAccNum] = useState("");
  const [reaccNum, setReaccNum] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [merchantid, setmerchantid] = useState("");
  const [ticket_no, setTicket_no] = useState("");
  const [bank_qc, setbank_qc] = useState("");
  const [bank_qcCheck, setbank_qcCheck] = useState("");
  const [showCanchequeurl, setShowCanchequeurl] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [check_bankQc, setcheck_bankQc] = useState("");
  const [showShopBoard, setshowShopBoard] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rightImage, setRightImage] = useState(false);
  const [selectReason, setSelectReason] = useState();
  const [remark, setRemark] = useState(props.Remark);
  const [sec3_reason, setSec3_reason] = useState("");
  const [updatedTimestamp, setupdatedTimestamp] = useState("");
  const [status, setstatus] = useState("");
  const [disable, setdisable] = useState(true);
  const [l1chategory, setL1chategory] = useState("Fixed Shop");
  const [mobileNo, setMobileNo] = useState("");
  const [shopName, setshopName] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [agent_id, setAgent_id] = useState("");
  const Resturant_id = "8e827a10-737b-11ec-9806-93163a488dc9";
  const CakeAndBakery_id = "c205e5d0-737f-11ec-9806-93163a488dc9";
  const SweetShop_id = "57991b70-7381-11ec-9806-93163a488dc9";





  const reason_chequevalidation = [
    { value: 0, label: "Photo  hazy" },
    { value: 1, label: "Photo has incomplete details" },
    { value: 2, label: "Wrong photo" },
    { value: 3, label: "No photo" },
    { value: 4, label: "Others" }
  ];
  const sec3_reasonChange = (item) => {
    setSelectReason(item.label)
  }
  const handleCloseCanchequeurl = () => setShowCanchequeurl(false);
  const handleShowCanchequeurl = () => setShowCanchequeurl(true);
  React.useEffect(() => {
    setL1chategory(props.data.l1_categoryname);
    setCanchequeurl(props.data.canchequeurl);
    seterrorMessage("");
    setmobileNumber(props.data.mainmobilenumber);
    setmerchantid(props.data.merchantid);
    setTicket_no(props.data.ticket_no);
    setbank_qc(props.data.bank_qc);
    setupdatedTimestamp(props.data.sec3_updated_at);
    setstatus(props.data.bank_qc);
    setSec3_reason(props.data.sec3_reason);
    setMobileNo(props.data.mainmobilenumber);
    setshopName(props.data.shopname);
    setAgent_id(props.data.agent_id);
    if (props.data.categoryl3 == SweetShop_id || props.data.categoryl3 == CakeAndBakery_id || props.data.categoryl3 == Resturant_id) {
      setCategory_id(props.data.categoryl3);
    } else {
      setCategory_id('');
    }

    setRemark(props.Remark);
  }, [props]);

  const handleAprove = () => {
    setshowShopBoard(false);
    setRejected(false);
    setRightImage(true);
    setApproved(true);
    setbank_qc(0);
    setdisable(false);
  };

  const handleReject = () => {
    setshowShopBoard(true);
    setRejected(true);
    setRightImage(false);
    setApproved(false);
    setcheck_bankQc(1);
    setbank_qc(1);
    setdisable(false);
    if (rejected === true) {
      setshowShopBoard(false);
      setRejected(false);
    }
  };

  const checqueImage = (event) => {
    if (check_bankQc == 1) {
      setbank_qc(0);
      setcheck_bankQc(0);
    } else {
      setbank_qc(1);
      setcheck_bankQc(1);
    }
  };
  const accName = (event) => {
    setAccountName(event.target.value);
  };
  const accbankName = (event) => {
    setBankName(event.target.value);
  };
  const accNumber = (event) => {
    setAccNum(event.target.value);
  };
  const reaccNumber = (event) => {
    setReaccNum(event.target.value);
  };
  const accifscCode = (event) => {
    setIfscCode(event.target.value);
  };
  const submitCheque = (event) => {
    if (((selectReason == undefined) || (selectReason == '')) && (check_bankQc == 1)) {

      seterrorMessage("Select reason");

    }
    else
      if (accountName == "" && check_bankQc != 1) {
        seterrorMessage("Enter Account name");
      } else if (bankName == "" && check_bankQc != 1) {
        seterrorMessage("Enter Bank name");
      } else if (accNum == "" && check_bankQc != 1) {
        seterrorMessage("Enter Account Number");
      } else if (reaccNum == "" && check_bankQc != 1) {
        seterrorMessage("Re-enter Account Number");
      } else if (reaccNum != accNum && check_bankQc != 1) {
        seterrorMessage("Account number and Re-enter Account Number is not same");
      }
      else if (ifscCode == "" && check_bankQc != 1) {
        seterrorMessage("Enter IFSC code");
      } else {
        console.log("p");
        const chequeVerification = {
          bankName: bankName,
          bankIfsc: ifscCode,
          accName: accountName,
          accNumber: accNum,
          mobileNo: mobileNumber,
          merchantid: merchantid,
          ticket_no: ticket_no,
          status: bank_qc,
          reason: selectReason,
          remark: remark,
          type: l1chategory,
          checkUrl: canchequeurl,
          shopname: shopName,
          category_id: category_id,
          agent_id: agent_id,

        };
        console.log(chequeVerification);
        const headers = {
          "Content-Type": "application/json",
        };
        axios
          .post(window.baseUrl + `updateStage3`, chequeVerification, {
            headers: headers,
            timeout:30000,timeoutErrorMessage:'Request Timedout'
          })
          .then((res) => {
            console.log(res.data);
            console.log(res.data.message);
            // window.location.reload();
            if (res.data.status == 1) {
              seterrorMessage(res.data.message);
            }
            else {
              window.location.reload();
            }
          });
      }
  };

  const handlesubmit = () => {
    // console.log("value", value);
  };
  return (
    <div className=" background_validation">
      <Row>
        <Col className="mt-4">
          <div className="pb-3 pt-2 header">
            <b className="pt-3 title">Section -3: Verify Bank details</b>
            <div className="d-inline-block float-right w-25  border border-secondary">
              <div className="d-flex flex-row border-bottom border-secondary last_update">
                <p className="pl-2 mb-0"><b>Last Update</b></p>

              </div>
              <div className="d-flex flex-row mt-2">
                <p className="pl-2">Status : </p>
                <p>{status == 0 ? "Approved" : status == 1 ? "Resubmit" : "New"}</p>
              </div>
              <div className="d-flex flex-row">
                <p className="pl-2">Reason : </p>
                <p style={{ width: '70%' }}>{sec3_reason == null ? 'Null' : sec3_reason}</p>
              </div>
              <div className="d-flex flex-row">
                <p className="pl-2">Timestamp : </p>
                <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
              </div>
            </div>

          </div>
          <div className="section_instruction instruction  position-absolute ">
            <b>Instructions:</b> <span>Cheque photo is clear</span>
            <Typography style={{ marginLeft: "80px", fontSize: "0.875rem" }}>All details of bank account are visible on cheque</Typography>
          </div>
        </Col>
      </Row>
      <br />
      <div className="font-weight-bold pt-3 pl-3 mb-4">Cancelled cheque photo</div>
      <Row>
        <Col md={3} className=" pb-2">
          <div className="color_border_validation height_cheque  position-absolute">
            <img className="w-100 h-1w-100 h-100  cursor-pointer" src={canchequeurl} onClick={handleShowCanchequeurl}></img>
          </div>
        </Col>
        <Col md={1} className='pb-5'>

          <div className="image_wrong_div mt-4 mx-auto" onClick={handleAprove} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}>
            <img className="image_wrong cursor-pointer" src={right} ></img>
          </div>
          <div className="text-center pt-2">
            <b>Approved</b>
          </div>
        </Col>

        <Col md={1}>
          <div className="image_wrong_div mt-4 image_validation " style={{ backgroundColor: showShopBoard == true ? "#FD626B " : "" }}>
            <img className="image_wrong cursor-pointer" src={resubmit} onClick={handleReject}></img>
          </div>
          <div className="text-center pt-2">
            <b>Resubmit</b>
          </div>
        </Col>
        {rejected && (
          <Col >
            {/* <Select name="reasons"className="float-right reason_shopimage" defaultValue={value} options={Reason} value={value} onChange={setValue} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.name} /> */}
            <Select options={reason_chequevalidation} className="float-right reason_shopimage" onChange={sec3_reasonChange}></Select>
          </Col>
        )}
      </Row>
      <br />
      <Row className="pl-3 pt-5">
        <Col className="instruction">
          <b>Instructions:</b> <span>If you resubmit,please call the merchant to update new photo on ChattyBao partnerApp from settings section</span>
        </Col>
      </Row>

      {(approved &&
        <p className="font-weight-bold pl-3 mt-3">Enter details: From cancelled cheque, enter details:</p>
      )}
      <Row>
        {(approved &&
          <Col md={8}>
            <div class="form-group">
              <div className="pt-3">
                <label className="label_cheque text-justify">Name as per bank account:</label>
                <input className="input_cheque mt-0" name="bankname" onChange={accName} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">Bank name: </label>
                <input className="input_cheque mt-0" name="bankname" onChange={accbankName} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">Acount number: </label>
                <input className="input_cheque mt-0" name="accountnum" onChange={accNumber} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">Re-enter Acount number: </label>
                <input className="input_cheque mt-0" name="accountnum" onChange={reaccNumber} />
              </div>
              <div className="pt-3">
                <label className="label_cheque text-justify">IFSC Code: </label>
                <input className="input_cheque mt-0" name="ifsccode:" onChange={accifscCode} />
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        {(approved &&
          // <Col style={{ display: "grid", justifyContent: "end" }}>
          <Col style={{ display: "none", justifyContent: "end" }}>
            <button className="cheque_submit btn pt-2 pb-2 mt-0 " onClick={submitCheque}>
              Verify
            </button>
            <div className="text-center pt-2">
              <b>Status: </b>Verified / Not verified
            </div>
            <p className="text-danger text-center pt-3">{errorMessage}</p>
          </Col>
        )}
        <Col style={{ display: "grid", justifyContent: "end" }}>

          <span>
            <button className="cheque_submit btn pt-2 pb-2 mt-0" disabled={disable} onClick={submitCheque}>
              Submit
            </button>
          </span>
          <p className="text-danger text-center pt-3">{errorMessage}</p>
        </Col>
      </Row>
      <Modal show={showCanchequeurl} onHide={handleCloseCanchequeurl}>
        <Modal.Body>
          <TransformWrapper>
            <TransformComponent>
              <img className="w-100 h-100" src={canchequeurl}></img>
            </TransformComponent>
          </TransformWrapper>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const Dashboard = withRouter(ChequeValidation);
export default Dashboard;
