import { Fetch } from "../apiConfig/Fetch";
import { APIConfig } from "../apiConfig/apiConfig";

const QcPanelService = {
  getAgentDetail: async (data) => {
    const { baseUrl, getAgentDetail } = APIConfig;

    const url = baseUrl + getAgentDetail;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      mobileNo: data.mobileNo,
    });

    const getAgentDetailServicePromise = await Fetch(url, "POST", headers, params, "services_ getAgentDetail", true);
    return getAgentDetailServicePromise;
  },

  addAgent: async (data) => {
    const { baseUrl, addAgent } = APIConfig;

    const url = baseUrl + addAgent;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      name: data.name,
      agentId: data.agentId,
      status: data.status,
      mobile_no: data.mobile_no,
      email_id: data.email_id,
      role: data.role,
      location: data.location,
      supervisior_name: data.supervisior_name,
      supervisior_mobile: data.supervisior_mobile,
    });

    const addAgentServicePromise = await Fetch(url, "POST", headers, params, "services_ addAgent", true);
    return addAgentServicePromise;
  },
  getQcuserlist: async () => {
    const { baseUrl, getAgents } = APIConfig;

    const url = baseUrl + getAgents;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const getQcuserlistServicePromise = await Fetch(url, "GET", headers, "services_ getAgents", true);
    return getQcuserlistServicePromise;
  },

  updateAgentDetail: async (data) => {
    const { baseUrl, updateAgentDetail } = APIConfig;

    const url = baseUrl + updateAgentDetail;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      key_id: data.key_id,
      name: data.name,
      agentId: data.agentId,
      mobile_no: data.mobile_no,
      email_id: data.email_id,
      role: data.role,
      location: data.location,
      supervisior_name: data.supervisior_name,
      supervisior_mobile: data.supervisior_mobile,
      status: data.status,
    });

    const updateAgentDetailServicePromise = await Fetch(url, "POST", headers, params, "services_ updateAgentDetail", true);
    return updateAgentDetailServicePromise;
  },
};

export default QcPanelService;
