// MapSection.js
import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from '../../../node_modules/leaflet/dist/images/marker-icon.png';

// Define an enum for icon properties
const IconProperties = {
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
};

// Define an enum for map view values
const MapViewValues = {
    center: [26.846695, 80.946167],
    zoom: 12,
};

const MapSection = ({ activeTab, mapAgentData }) => {
    const [map, setMap] = useState(null);

    useEffect(() => {
        if (!map) {
            const newMap = L.map('map');
            newMap.setView(MapViewValues.center, MapViewValues.zoom);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; OpenStreetMap contributors'
            }).addTo(newMap);

            setMap(newMap);
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            map.eachLayer(layer => {
                if (layer instanceof L.Marker) {
                    map.removeLayer(layer);
                }
            });

            if (activeTab === '2') {
                if (mapAgentData) {
                    mapAgentData.forEach((data) => {
                        const { latitude, longitude, name, user_mob_num } = data;
                        if (latitude !== null && longitude !== null && latitude !== "null" && longitude !== "null") {
                            const marker = L.marker([parseFloat(latitude), parseFloat(longitude)], {
                                icon: L.icon({
                                    iconUrl: markerIcon,
                                    ...IconProperties,
                                })
                            }).addTo(map);
        
                            marker.bindPopup(`<b>Name:</b> ${name}<br><b>Mobile:</b> ${user_mob_num}`);
                        }
                        
                    });
                }
                
            }
        }
    }, [map, activeTab, mapAgentData]);

    return <div id="map" style={{ height: '600px', width: '1000px' }} />;
};

export default MapSection;