
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    // apiKey: "AIzaSyDwxUkwe9AjKDb7TrCvxYZq85CymrnZ5-A",
    // authDomain: "arched-forest-205610.firebaseapp.com",
    // projectId: "arched-forest-205610",
    // storageBucket: "arched-forest-205610.appspot.com",
    // messagingSenderId: "248088197882",
    // appId: "1:248088197882:web:9cdf6bb8dbaca829586743"
    apiKey: "AIzaSyChttU4WKvtq98lgjcHi1lP6RKKvpUlKjE",
    authDomain: "chattybao-web.firebaseapp.com",
    projectId: "chattybao-web",
    storageBucket: "chattybao-web.appspot.com",
    messagingSenderId: "154500749344",
    appId: "1:154500749344:web:49c8e5964ea79ad11b8445"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export const authentication = getAuth(app);
 
  