
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../components/sidebar.js";
import '../Dashboard.css';
import right from "../images/check.png";
import wrong from "../images/close.png";
import ask from "../images/ask.png"



const Ticket = props => {
  const [Child_mob_1, setChild_mob_1] = useState("");
  const [Businessname, setBusinessName] = useState("");
  const [Shopimage, setShopImage] = useState("");
  const [TicketNo, setTicketNo] = useState("");
  const [remarkcheck, setremarkcheck] = useState("");
  

  React.useEffect(() => {
    setChild_mob_1(props.data.mainmobilenumber);
    setBusinessName(props.data.shopname);
    setShopImage(props.data.picurl);
    setTicketNo(props.data.ticket_no);
    setremarkcheck(props.data.remark);
    console.log(props.data.mainmobilenumber);
  })
  //   const qaRemark=(event)=>{
  //     props.setRemark(event.target.value);
  // }
  return (

    <Row>
      <Col className="color_border_validation">
        <div className='pb-1 pt-2'><b>Ticket number</b></div>
        <div className='pb-3 pt-2'>{TicketNo}</div>
      </Col>
      <Col className="color_border_validation">
        <div className='pb-1 pt-2'><b>Agent Id</b></div>
        <div className='pb-3 pt-2'>{Businessname}</div>
      </Col>
      <Col className="color_border_validation">
        <div className='pb-1 pt-2'><b>Business name</b></div>
        <div className='pb-3 pt-2'>{Businessname}</div>
      </Col>
      <Col className="color_border_validation">
        <div className='pb-1 pt-2'><b>Merchant Phone:</b></div>
        <div className='pb-3 pt-2'>{Child_mob_1}</div>
      </Col>
      <Col className="color_border_validation">
        <div className='pb-1 pt-2'><b>Remarks</b></div>
        <div className='pb-3 pt-2'>
          <input className="input_cheque mt-0 w-100" name="remarks" defaultValue={remarkcheck} onChange={props.handler} />
        </div>
      </Col>
    </Row>
  );
};

const Dashboard = withRouter(Ticket);
export default Dashboard