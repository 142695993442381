//window.baseUrl="http://localhost:4000/v1/";
window.baseUrl = "https://dev.chattybao.com/v1/";
window.addressURL="https://dev.chattybao.com/v2/";
// window.baseUrl = "https://api.chattybao.com/v1/";
// window.addressURL = "https://api.chattybao.com/v2/";
// if((window.localStorage.getItem('sessionId')!=undefined)&&(window.localStorage.getItem('sessionId')!="")&&(window.localStorage.getItem('sessionId')!=null))
// {
// window.token=window.localStorage.getItem('sessionId');
// }

const APIConfig = {
  getCategoryTree: "getCategoryTree?type=web",
  addUpdateCategory: "addUpdateCategory",
  updateMerchant: "updateMerchant",
  viewMerchant: "viewMerchant",
  getStates: "getStates",
  getAgents: "getAgents",
  getCBEmployee: "getCBEmployee",
  getCitiesByState: "getCitiesByState",
  getPincodes: "getPincodes",
  getLogistics: "getLogistics",
  addUpdateLogistics: "addUpdateLogistics",
  addCbEmployee: "addCbEmployee",
  getCbEmployeeDetail: "getCbEmployeeDetail",
  updateCbEmployeeDetail: "updateCbEmployeeDetail",
  getAgentDetail: "getAgentDetail",
  addAgent: "addAgent",
  updateAgentDetail: "updateAgentDetail",
  getOrderDetailsForPanel: "getOrderDetailsForPanel",
  getOrderDetailsListForPanel: "getOrderDetailsListForPanel",
  postOrderSummary: "getBusinessSummary",
  searchByOrderId: "searchByOrderId",
  getOrderDetailsByMerchantMobileNo: "getOrderDetailsByMerchantMobileNo",
  getOrderDetailsByUserMobileNo: "getOrderDetailsByUserMobileNo",
  getMerchantConnectedUser: "getMerchantConnectedUser",
  getUserMerchantChannels: "getUserMerchantChannels",
  deliveryOrderDetails: "getDeliveryOrderDetails",
  nearbyDeliveryAgents: "getNearbyDeliveryAgents",
  assignDeliveryAgents: "assignDeliveryAgent",
  addUpdateFSEMerchants: 'addUpdateFSEMerchants',
  tiggerMerchantCashback: 'tiggerMerchantCashback',
  bucketName: 'categoryvisitingcards',
  qcPayoutCSV: 'qcPayoutCSV',
  getMapAgents: 'getMapAgents',
  getL3ForMasterCataloguePanel: 'getL3ForMasterCataloguePanel',
  getMasterCatalogueS1Categories: 'getMasterCatalogueS1Categories',
  getMasterCatalogueForPanel: 'getMasterCatalogueForPanel',
  getMasterCatalogue: 'getMasterCatalogue',
  addMasterCatalog: 'addMasterCatalog',
  searchInMasterCatalogue: 'searchInMasterCatalogue',
  getLiveOrders: 'getLiveOrders',
  rejectCheckoutOrder: 'rejectCheckoutOrder',
  getMerchantCatlogues: 'getMerchantCatlogues',
  updateMerchantCataloguePrice: 'updateMerchantCataloguePrice',
  getCityByMerchandId: 'getCityByMerchandId',
  getSpecialMerchants: 'getSpecialMerchants',
  addUpdateSpecialMerchant: 'addUpdateSpecialMerchant',
  deleteSpecialMerchant: 'deleteSpecialMerchant',
  updateMerchEligibilityForOndc: 'updateMerchEligibilityForOndc',
  ondcApiUrl: 'https://ondc-seller-preprod.chattybao.com/ondc/bpp/',
  ondcAdminLogin: 'ondc-admin-login',
  ondcOrders: 'ondc-orders',
  ondcIssues: 'ondc-issues',
  getUserDeliveryAddress: 'getUserDeliveryAddress',
  arrangeFromAnotherMerchants: 'arrangeFromAnotherMerchants',
  updatesOrderSummary: 'updatesOrderSummary',
  restartRleay: 'restartRleay',
  getOrderDetailsForOrderHistoryv1: 'getOrderDetailsForOrderHistoryv1',
  getOrderDetailsForDeliveryDashboard: 'getOrderDetailsForDeliveryDashboard',
  getOrdersSummary: 'getOrdersSummary',
  updateRiderStatus: 'updateRiderStatus',
  arrangeForceRider: 'arrangeForceRider',
  getTodaysSummary: 'getTodaysSummary',
  customRefund: 'customRefund',
  createPreSignedURL: 'createPreSignedURL',
  approveRejectLicense: 'approveRejectLicense',
  approveRejectPan: 'approveRejectPan',
  approveRejectBank: 'approveRejectBank',
  approveRejectSelfie: 'approveRejectSelfie',
  getRateCardByCity: 'getRateCardByCity',
  addUpdateForceAppRateCard: 'addUpdateForceAppRateCard',
  logOutRider: 'logOutRider',
  getUpdatedPennyStatus: 'getUpdatedPennyStatus',
  getAutomationMerchantData: 'getAutomationMerchantData',
  addCatalogueByMapping: 'addCatalogueByMapping',
  getCatalogStatus: 'getCatalogStatus',
  getMatchedUnmatchedProducts: 'getMatchedUnmatchedProducts',
  updateMatchedProductsToUnmatch: 'updateMatchedProductsToUnmatch',
  getCatalogSuggestion: 'getCatalogSuggestion',

  //Test
  baseUrl: "https://dev.chattybao.com/v1/",
  ApiToken: 'd7da3aa4c2750034642de167063557200f770c52',
  sendbirdBaseUrl: `https://api-17E6AA94-8E87-4DB9-BCE5-F727DD481D50.sendbird.com/v3`,

  //Prod
  // baseUrl: "https://api.chattybao.com/v1/",
  // ApiToken: 'a1dbe1f77ca24b9f428e150c2b8d83792c9fbc26',
  // sendbirdBaseUrl: `https://api-8766441F-3EB0-4B2F-9AD7-D080362CD92F.sendbird.com/v3`,
};
export const SENDBIRD_APP_ID = {
  appId: "17E6AA94-8E87-4DB9-BCE5-F727DD481D50", // TEST
  // appId: "8766441F-3EB0-4B2F-9AD7-D080362CD92F", // PROD
};

export { APIConfig };
