import moment from "moment";

export const protectFromXSS = (text) => {
  return text.replace(/\&/g, "&amp;").replace(/\</g, "&lt;").replace(/\>/g, "&gt;").replace(/\"/g, "&quot;").replace(/\'/g, "&apos;");
};

export const handleEnterPress = (event, callback) => {
  if (event.key === "Enter") {
    callback();
  }
};

function toTimestamp(dateStr) {
  return Date.parse(dateStr);
}

export const timestampToTime = (prevDate) => {
  let preDt = prevDate;
  if (isNaN(prevDate)) {
    preDt = toTimestamp(prevDate);
  }
  const diff = Number(new Date()) - preDt;
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;
  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000);
      return "a few seconds ago";
    case diff < hour:
      return Math.round(diff / minute) + " minutes ago";
    case diff < day:
      return Math.round(diff / hour) + " hours ago";
    case diff < month:
      return Math.round(diff / day) + " days ago";
    case diff < year:
      return Math.round(diff / month) + " months ago";
    case diff > year:
      return Math.round(diff / year) + " years ago";
    default:
      return "";
  }
};

const getDuration = (startDate, endDate) => moment.duration(moment(endDate).diff(moment(startDate)));

export const compareDateAndTime = (startDate) => {
  console.log("startDate================", startDate);
  startDate = moment(startDate).utc().format();
  let endDate = new Date().toLocaleString();
  endDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
  console.log("endDate================", endDate);

  endDate = moment(endDate).utc().format();
  console.log("start===============", startDate, endDate);
  const duration = getDuration(endDate, startDate);
  const years = duration.years();
  const months = duration.months();
  const weeks = duration.weeks();
  const days = duration.subtract(weeks, "w").days();
  const hours = duration.hours();
  const minuntes = duration.minutes();
  const seconds = duration.seconds();

  console.log("years", years);
  console.log("months", months);
  console.log("weeks", weeks);
  console.log("days", days);
  console.log("hours", hours);
  console.log("minuntes", minuntes);
  console.log("seconds", seconds);
  console.log("duration", duration.asSeconds());

  return duration.asSeconds();
};
