import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import '../../Dashboard.css';
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import { Typography } from "@mui/material";
import Moment from 'react-moment';
import '../style/Qc.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoadingSpinner from "../../components/loader/loader.js";
import swal from "sweetalert";
import ApiService from "../../api/services/ApiService.js";
import { alertDangerConfirmMessage } from "../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js";
import { constants } from "../../utils/constants.js";


const LicenseImageChack = props => {
    const [TicketNoCheck, setTicketNoCheck] = useState("");
    const [show, setShow] = useState(false);
    const [wrongImage, setWrongImage] = useState(false);
    const [rightImage, setRightImage] = useState('');
    const [TicketNo, setTicketNo] = useState("");
    const [agent_id, setAgentID] = useState("");
    const [updatedTimestamp, setupdatedTimestamp] = useState("");
    const [disable, setDisable] = useState(true);
    const [errormsgShopboard, seterrormsgShopboard] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [name, setName] = useState("");
    const [licenseQc, setLicenseQc] = useState();
    const [panQc, setPanQc] = useState();
    const [bankQc, setBankQc] = useState();
    const [selfieQc, setSelfieQc] = useState();
    const [dlNumber, setDlNumber] = useState();
    const [dlExpDate, setDlExpDate] = useState("")
    const [enableEdit, setEnableEdit] = useState(false)
    const [licenseFrontImage, setLicenseFrontImage] = useState("")
    const [licenseBackImage, setLicenseBackImage] = useState("")
    const [isLicenseUpdated, setIsLicenseUpdated] = useState("")
    const [isPanUpdated, setIsPanUpdated] = useState("")
    const [isBankUpdated, setIsBankUpdated] = useState("")
    const [isSelfieUpdated, setIsSelfieUpdated] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [enlargedImage, setEnlargedImage] = useState('');
    const { requestTimedOutError } = constants;

    const handleClose = () => setShow(false);

    const handleShow = (image) => {
        setShow(true);
        setEnlargedImage(image);
    }

    useEffect(() => {
        setDlNumber(props.data.dl_number)
        if (props.data.dl_image) {
            setLicenseFrontImage(props.data.dl_image.split(',')[0])
            setLicenseBackImage(props.data.dl_image.split(',')[1])
        }
        setTicketNoCheck(props.data.ticket_no);
        setTicketNo(props.data.ticket_no);
        setAgentID(props.data.agent_id);
        setupdatedTimestamp(props.data.license_updated_at || props.data.created_at);
        setMobileNo(props.data.mobile_no);
        setName(props.data.name);
        setLicenseQc(props.data.license_qc)
        setSelfieQc(props.data.selfie_qc)
        setIsLicenseUpdated(props.data.is_license_updated)
        setIsPanUpdated(props.data.is_pan_updated)
        setIsBankUpdated(props.data.is_bank_updated)
        setIsSelfieUpdated(props.data.is_selfie_updated)
        setDlExpDate(props.data.dl_exp_date)
        if (props.data.acc_verify_status === 0) {
            setBankQc(0)
        } else {
            setBankQc(props.data.bank_qc)
        }

        setPanQc(props.data.pan_qc)

    }, [props]);

    const right_image = (event) => {
        setIsLicenseUpdated(1)
        setEnableEdit(true)
        event.preventDefault();
        setWrongImage(false);
        setRightImage(true);
        setLicenseQc(0);
        setDisable(false);
    }

    const wrong_image = (event) => {
        setIsLicenseUpdated(1)
        event.preventDefault();
        setWrongImage(true);
        setRightImage(false);
        setLicenseQc(1);
        setDisable(false);
    }

    const submit_stage1 = (event) => {
        final_submit();
    }

    async function final_submit() {
        const data = {
            ticket_no: TicketNoCheck,
            license_qc: licenseQc,
            pan_qc: panQc,
            bank_qc: bankQc,
            selfie_qc: selfieQc,
            mobile_no: mobileNo,
            name: name,
            agent_id: agent_id,
            dl_number: dlNumber,
            dl_exp_date: dlExpDate,
            ticketStatus: isLicenseUpdated == 1 && isPanUpdated == 1 && isBankUpdated == 1 && isSelfieUpdated == 1 ? 2 : 1
        };
        const token = localStorage.getItem('sessionId');
        try {
            setIsLoading(true)
            const result = await ApiService.approveRejectLicense(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                swal({
                    title: "",
                    text: "Success",
                    icon: "success",
                    className: "swal-modal-succuess-small",
                })
                window.location.reload();
            } else {
                alertDangerConfirmMessage("ERROR")
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="background_validation pb-4">

            <Row>
                <Col >
                    <div className="d-inline-block w-75">
                        <p className='pt-2'><b className=" title">Section -1:  Verify Driving license</b></p>
                    </div>
                    <div className="d-inline-block float-right w-25 mt-3 border border-secondary">
                        <div className="d-flex flex-row border-bottom border-secondary last_update">
                            <p className="pl-2 mb-0"><b>Last Update</b></p>

                        </div>
                        <div className="d-flex flex-row mt-2">
                            <p className="pl-2">Status : </p>
                            <p>{props.data.license_qc == 0 ? "Match" : props.data.license_qc == 1 ? "Resubmit" : "New"}</p>
                        </div>
                        <div className="d-flex flex-row">
                            <p className="pl-2">Timestamp : </p>
                            <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={3} className="pt-4" style={{ display: 'flex', flexDirection: 'column', }}>
                    <Row>
                        <div className=" color_border_validation height_cheque ">
                            <img className="w-100 h-100 cursor-pointer" src={licenseFrontImage} onClick={() => handleShow(licenseFrontImage)} alt="DL-front" />
                        </div>
                    </Row>
                    <Row className="pt-4">
                        <div className=" color_border_validation height_cheque">
                            <img className="w-100 h-100  cursor-pointer" src={licenseBackImage} onClick={() => handleShow(licenseBackImage)} alt="DL-back" />
                        </div>
                    </Row>
                </Col>
                <Col style={{ border: '0px solid red' }} >
                    <Row>
                        <Col md={1} className="pt-4 pl-0">
                            <div className="image_wrong_div  mx-auto" onClick={right_image} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}><img className="image_wrong cursor-pointer" src={right} alt="NA" /></div>
                            <div className="text-center pt-1"><b>OK</b></div>
                        </Col>
                        <Col md={1} className="pt-4 pl-0" >
                            <div className="image_wrong_div  mx-auto" onClick={wrong_image} style={{ backgroundColor: wrongImage == true ? "#FD626B" : "" }}><img className="image_wrong cursor-pointer" src={wrong} alt="NA" /></div>
                            <div className="text-center pt-1"><b>Reject</b></div>
                        </Col>
                    </Row>

                    {rightImage && (
                        <Col md={8}>
                            <div class="form-group">
                                <div className="pt-3">
                                    <label className="label_cheque text-justify">Enter Name on DL :</label>
                                    <input className="input_cheque mt-0" disabled={!enableEdit} name="bankname" onChange={(e) => setName(e.target.value)} value={name} />
                                </div>
                                <div className="pt-3">
                                    <label className="label_cheque text-justify">Enter DL Number : </label>
                                    <input className="input_cheque mt-0" name="bankname" disabled={!enableEdit} onChange={(e) => setDlNumber(e.target.value)} value={dlNumber} />
                                </div>
                                <div className="pt-3">
                                    <label className="label_cheque text-justify">Enter DL Expiry Date : </label>
                                    <input className="input_cheque mt-0" name="accountnum" type="date" disabled={!enableEdit} onChange={(e) => setDlExpDate(e.target.value)} value={dlExpDate} />
                                </div>

                            </div>
                        </Col>
                    )}

                </Col>
            </Row>

            <Row>
                {console.log("DLEXP", dlExpDate)}
                <Col className="mt-5" style={{ display: "grid", justifyContent: "end" }}>
                    <span>
                        {rightImage ? (
                            <button className="btn" disabled={((disable && rightImage) || rightImage === '' || name === '' || dlNumber === '' || !dlExpDate)} onClick={submit_stage1}>
                                Submit
                            </button>
                        ) : (
                            <button className="btn" disabled={disable} onClick={submit_stage1}>
                                Submit
                            </button>
                        )}

                    </span>
                    <p className="text-danger text-center pt-3">{errormsgShopboard}</p>
                </Col>
            </Row>

            {isLoading && (
                <LoadingSpinner />
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <TransformWrapper>
                        <TransformComponent>
                            <img className="w-100 h-100" src={enlargedImage} alt="NA" />
                        </TransformComponent>
                    </TransformWrapper>
                </Modal.Body>

            </Modal>

        </div>

    );
};
const Dashboard = withRouter(LicenseImageChack);
export default Dashboard