import axios from 'axios';
import ApiService from './ApiService';
import { constants } from '../../utils/constants';

const {bucketNames, awsfileNames, apiFileType, awsUploadFileType, apiRequestTimeOut} = constants;

const AwsPDFService = {
  
  uploadImageToS3: async (file) => {

    const timestamp = Date.now();
    const contentDisposition = 'inline;filename="' + file.fileName + '"';

    try {
      const token = localStorage.getItem('sessionId');
        const data = {
          fileName: awsfileNames.pdf + timestamp,
          fileType: apiFileType.pdf,
          bucketName: bucketNames.pdf
        };

      try {
        const result = await ApiService.createPreSignedURL(data, token);
        if (result.data.code === 0) {
          const signedURL = result.data.presignedUrl;
          const parts = signedURL.split('?');
          const pdfUrl = parts[0];
          try {
            await axios({
              method: 'PUT',
              url: signedURL,
              data: file,
              headers: {
                'Content-Type': awsUploadFileType.pdf,
                'Content-Disposition': contentDisposition
              },
              timeout: 15000,
              timeoutErrorMessage: apiRequestTimeOut
            });
            return { uri: pdfUrl };
          } catch (error) {
            console.error("Error uploading image to S3:", error);
          }
        } else if (result.code === 1) {
          console.log("err")
        } else if (result.code === 'ECONNABORTED') {
          alert(apiRequestTimeOut)
        }
      } catch (error) {
        console.error("Error creating pre-signed URL:", error);
      }
    } catch (error) {
      console.error("Error reading file:", error);
    }
  },

  uploadCsv: async (file, merchantId) => {
    const contentDisposition = `inline;filename="${merchantId}.csv"`;

    try {
      const token = localStorage.getItem('sessionId');
        const data = {
          fileName: merchantId,
          fileType: apiFileType.csv,
          bucketName: bucketNames.csvBucketName
        };

      try {
        const result = await ApiService.createPreSignedURL(data, token);
        if (result.data.code === 0) {
          const signedURL = result.data.presignedUrl;
          const parts = signedURL.split('?');
          const csvUrl = parts[0];
          try {
            await axios({
              method: 'PUT',
              url: signedURL,
              data: file,
              headers: {
                'Content-Type': awsUploadFileType.csv,
                'Content-Disposition': contentDisposition
              },
              timeout: 15000,
              timeoutErrorMessage: apiRequestTimeOut
            });
            return { uri: csvUrl };
          } catch (error) {
            console.error("Error uploading image to S3:", error);
          }
        } else if (result.code === 1) {
          console.log("err")
        } else if (result.code === 'ECONNABORTED') {
          alert(apiRequestTimeOut)
        }
      } catch (error) {
        console.error("Error creating pre-signed URL:", error);
      }
    } catch (error) {
      console.error("Error reading file:", error);
    }
  },

}
export default AwsPDFService; 
