import React, { useEffect, useState } from 'react'
import Sidebar from "../../components/sidebar.js";
import './OrderDetails.css'
import ApiService from '../../api/services/ApiService.js';
import { constants } from '../../utils/constants.js';
import { Dialog } from "@mui/material";
import close_icon from '../../images/cross-icon.png';
import { BeatLoader } from "react-spinners";
import LoadingSpinner from '../../components/loader/loader.js';
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import { alertDangerConfirmMessage } from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js';

const OrderDetails = () => {
    const [orderDetails, setOrderDetails] = useState([]);
    const [orderSummaryData, setOrderSummaryData] = useState([]);
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { deliveryDetailPageTexts, currency, requestTimedOutError } = constants;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderID = queryParams.get('orderID');


    const getOrderDetail = async () => {
        setIsLoading(true);
        var data = { orderId: orderID };
        try {
            const result = await ApiService.getOrderDetailsForDeliveryDashboard(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                const liveOrderStatus = result.data.status;
                if (liveOrderStatus !== 1) {
                    setOrderDetails(result.data.data);
                } else {
                    console.log("Error")
                }
            } else {
                console.log("Error fetching data")
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const getUserSummary = async (orderId) => {
        const token = localStorage.getItem('sessionId');
        const data = {
            orderId: orderID,
            isCheckoutOrder: 1,
        }
        try {
            const result = await ApiService.getOrderDetailsForOrderHistoryv1(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setOrderSummaryData(result.data.orderData)
                setShowOrderSummary(true);
            } else {
                console.log("Error fetching data")
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrderDetail();
    }, [])

    const formatDateTime = (dateTimeString) => {
        const parsedDateTime = moment(dateTimeString);
        const formattedDateTime = parsedDateTime.format('DD/MM/YYYY hh:mm A');
        return formattedDateTime;
    };

    return (
        <div>
            <div className='sidebar'>
                <Sidebar />
            </div>
            {isLoading && (
                <LoadingSpinner />
            )}
            <h6 className='order-details-header'>Order Details:</h6>
            <table className='delivery-table individual-table'>
                <tbody className='table-data-row'>
                    <tr>
                        <td className="first-row first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Order Id:</p>
                                <p className='data-text'>{orderID}</p>
                            </div>
                        </td>
                        <td className="first-row">
                            <div className='row-flex'>
                                <p className='data-header'>Time:</p>
                                <p className='data-text'>{orderDetails.cb_allocation_time && formatDateTime(orderDetails.cb_allocation_time)}</p>
                            </div>
                        </td>
                        <td className='first-row'>
                            <div className='row-flex'>
                                <p className='data-header'>Source:</p>
                                <p className='data-text'>{orderDetails.is_ondc_order === true ? 'ONDC' : (orderDetails.delivery_mode === 2 ? 'App' : (orderDetails.is_from_app === 0 ? 'App' : 'Web / Whatsapp'))}</p>
                            </div></td>
                        <td className='first-row'>
                            <div className='row-flex'>
                                <p className='data-header'>Type:</p>
                                <p className='data-text'>{orderDetails.delivery_mode === 0 ? 'CB Delivery' : orderDetails.delivery_mode === 1 ? 'Self Delivery' : 'Home Delivery'}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-last-row first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Amount:</p>
                                {orderDetails.total_amount && (
                                    <p className='data-text'>{currency} {orderDetails.total_amount}</p>
                                )}
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex'>
                                <p className='data-header'>Refund:</p>
                                {orderDetails.refund_amount && (
                                    <p className='data-text'>{currency} {orderDetails.refund_amount}</p>
                                )}
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex'>
                                <p className='data-header'>Child Order Id:</p>
                                {orderDetails.parent_order_id && (
                                    <p className='data-text'>C - {orderDetails.parent_order_id}</p>
                                )}
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex'>
                                {orderDetails.is_checkout_order === 0 && (
                                    <p className='order-summ-text' onClick={getUserSummary}>Order Summary</p>
                                )}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='delivery-table individual-table'>
                <tbody className='table-data-row'>
                    <h6 className='shadow-container-header'>Business Details:</h6>
                    <tr>
                        <td className="first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Name:</p>
                                <p className='data-text'>{orderDetails.shopname && orderDetails.shopname}</p>
                            </div>
                        </td>
                        <td>
                            <div className='row-flex'>
                                <p className='data-header'>VIP Plan:</p>
                                <p className='data-text'>{orderDetails.subscription_flag >= 2 ? 'VIP' : 'non VIP'}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Phone:</p>
                                <p className='data-text'>
                                    {orderDetails.mainmobilenumber && orderDetails.mainmobilenumber}
                                    {orderDetails.teammobilenumbers && orderDetails.teammobilenumbers.length !== 0 && orderDetails.teammobilenumbers.map(number => {
                                        return `, ${number}`
                                    })}
                                </p>
                            </div>
                        </td>
                        <td>
                            <div className='row-flex'>
                                <p className='data-header'>Address:</p>
                                <p className='data-text'>{orderDetails.addressline1}, {orderDetails.addressline2}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-last-row first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Delivery Charges:</p>
                                <p className='data-text'>{currency} {orderDetails.total_user_logistic_charge}</p>
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex'>
                                <p className='data-header'>Delivery Payment Mode:</p>
                                <p className='data-text'>Free</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='delivery-table individual-table'>
                <tbody className='table-data-row'>
                    <h6 className='shadow-container-header'>Customer Details:</h6>
                    <tr>
                        <td className="first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Name:</p>
                                <p className='data-text'>{orderDetails.user_name && orderDetails.user_name}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-last-row first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Phone:</p>
                                <p className='data-text'>{orderDetails.user_mob_num && orderDetails.user_mob_num}</p>
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex'>
                                <p className='data-header'>Address:</p>
                                {orderDetails.delivery_mode === 2 ? (
                                    <>
                                        <p className='address-text data-text'>{orderDetails.olabel}</p>
                                        {orderDetails.apartment_or_area ? <p>{orderDetails.apartment_or_area}</p> : ''}
                                        <p className='address-text data-text'>{orderDetails.oaddress_line1}</p>
                                    </>
                                ) : (
                                    <>
                                        <p className='address-text data-text'>{orderDetails.useradd1}</p>
                                        <p className='address-text data-text'>{orderDetails.useradd2}</p>
                                    </>
                                )}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='delivery-table individual-table'>
                <tbody className='table-data-row'>
                    <h6 className='shadow-container-header'>Delivery Details:</h6>
                    <tr>
                        <td className="first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Status:</p>
                                <p className='data-text'>{orderDetails.shipment_status === 1 ? deliveryDetailPageTexts.assigned : orderDetails.shipment_status === 2 ? deliveryDetailPageTexts.arrived :
                                    orderDetails.shipment_status === 3 ? deliveryDetailPageTexts.pickedUp : orderDetails.shipment_status === 4 ? deliveryDetailPageTexts.reached : orderDetails.shipment_status === 5 ? deliveryDetailPageTexts.delivered :
                                        orderDetails.shipment_status === 6 ? deliveryDetailPageTexts.rejected : orderDetails.shipment_status === 7 ? deliveryDetailPageTexts.cancelled : orderDetails.set_delivery_mode_datetime !== null ? deliveryDetailPageTexts.requested : ''}</p>
                            </div>
                        </td>
                        <td>
                            <div className='row-flex'>
                                <p className='data-header'>FM (km):</p>
                                <p className='data-text'>{orderDetails.deliveryDetails && Number(orderDetails.deliveryDetails[0].fm).toFixed(1)}</p>
                            </div>
                        </td>
                        <td>
                            <div className='row-flex'>
                                <p className='data-header'>LM (km):</p>
                                <p className='data-text'>{orderDetails.deliveryDetails && orderDetails.user_merch_distance}</p>
                            </div></td>
                    </tr>
                    <tr>
                        <td className="table-last-row first-cell">
                            <div className='row-flex'>
                                <p className='data-header'>Rider Name:</p>
                                <p className='data-text'>{orderDetails.deliveryDetails && orderDetails.deliveryDetails[0].name}</p>
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex'>
                                <p className='data-header'>Phone:</p>
                                <p className='data-text'>{orderDetails.deliveryDetails && orderDetails.deliveryDetails[0].mobile_number}</p>
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex'>
                                <p className='data-header'>Company:</p>
                                <p className='data-text'>{orderDetails.is_from_force_app === 0 ? deliveryDetailPageTexts.forceApp : orderDetails.is_from_force_app === 1 ? deliveryDetailPageTexts.loadShare : deliveryDetailPageTexts.porter}</p>
                            </div>
                        </td>
                        <td className="table-last-row"></td>
                    </tr>
                    <tr>
                        <td className="table-last-row first-cell">
                            <div className='row-flex items-align'>
                                <p className='data-header'>Order Image:</p>
                                {(orderDetails.deliveryDetails && orderDetails.deliveryDetails[0].merchant_goods_images.length !== 0) && (
                                    <img src={orderDetails.deliveryDetails[0].merchant_goods_images[0]} className='order-img' alt='order-pic' />
                                )}
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex items-align'>
                                <p className='data-header'>Delivery Tracking:</p>
                                {orderDetails.porter_tracking_link && (
                                    <p className='data-text'>{orderDetails.porter_tracking_link}</p>
                                )}
                            </div>
                        </td>
                        <td className="table-last-row">
                            <div className='row-flex items-align'>
                                <p className='data-header'>Porter Payload:</p>
                                {orderDetails.potterpayload && (
                                    <p className='data-text'>{orderDetails.potterpayload}</p>
                                )}
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>

            <Dialog onClose={() => setShowOrderSummary(false)} open={showOrderSummary}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => setShowOrderSummary(false)}
                    alt="close"
                />
                <div className="order-summary-dialog">
                    {!isLoading ? (
                        <div>
                            {orderSummaryData.order_summary && (
                                <h6 className='order-details-head'>Order Details ({orderSummaryData.order_summary.length} Items)</h6>
                            )}
                            <table className='order-summ-table'>
                                <tbody>
                                    {orderSummaryData.order_summary && orderSummaryData.order_summary.map((data, index) => (
                                        <tr key={index}>
                                            <td colSpan={2} className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                <div className='pdname-image-container left-padding'>
                                                    <img src={data.image_url[0]} className='order-mod-img' alt='NA' />
                                                    <div>
                                                        <p className='order-item-name'>{data.item_name}</p>
                                                        <p className='req-qty-text'>Req Qty x{data.originalCount}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                {data.count === data.originalCount ? (
                                                <p className='order-summ-count'>x {data.count}</p>
                                                ) : (
                                                <p className='order-summ-count-changed'>x {data.count}</p>
                                                )}
                                            </td>
                                            <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                {(data.discounted_rate && data.discounted_rate !== "0") ? (
                                                    <div className="price-container-view">
                                                        <p className="cutout-price">{currency}{data.rate}</p>
                                                        <p>{currency}{data.discounted_rate}</p>
                                                    </div>
                                                ) : (
                                                    <div className="price-container-view">
                                                        <p>{currency}{data.rate}</p>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='bill-container'>
                                <div className='bill-header-container'>
                                    <h6>Bill Details</h6>
                                    <div className='items-saving-container'>
                                        <h6>{orderSummaryData.order_summary && orderSummaryData.order_summary.length} Items </h6>
                                        {Number(orderSummaryData.full_amount_bf_modify) - Number(orderSummaryData.order_total_amount_bf_modify) > 0 && (
                                            <>
                                                <h6>|</h6>
                                                <h6 className='green-text-savings'>{currency} {Number(orderSummaryData.full_amount_bf_modify) - Number(orderSummaryData.order_total_amount_bf_modify)} Savings</h6>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    {Number(orderSummaryData.full_amount_bf_modify) - Number(orderSummaryData.order_total_amount_bf_modify) > 0 && (
                                        <div className='bill-data-container'>
                                            <p className='bill-data-grey-text'>MRP</p>
                                            <p className='bill-data-grey-text'>{currency}{orderSummaryData.full_amount_bf_modify}</p>
                                        </div>
                                    )}
                                    {Number(orderSummaryData.full_amount_bf_modify) - Number(orderSummaryData.order_total_amount_bf_modify) > 0 && (
                                        <div className='bill-data-container'>
                                            <p className='bill-data-grey-text'>Savings</p>
                                            <h6 className='bill-data-green-text'>{currency}{Number(orderSummaryData.full_amount_bf_modify) - Number(orderSummaryData.order_total_amount_bf_modify)}</h6>
                                        </div>
                                    )}
                                    <div className='bill-data-container'>
                                        <p className='bill-data-grey-text'>Item Total</p>
                                        <p className='bill-data-grey-text'>{currency}{orderSummaryData.order_total_amount_bf_modify}</p>
                                    </div>
                                    <div className='grey-divider' />
                                    {orderSummaryData.total_user_logistic_charge_bf_modify !== 'null' && orderSummaryData.total_user_logistic_charge_bf_modify && (
                                        <div className='bill-data-container'>
                                            <p className='bill-data-grey-text'>Delivery Charge</p>
                                            <p className='bill-data-grey-text'>{currency}{orderSummaryData.total_user_logistic_charge_bf_modify}</p>
                                        </div>
                                    )}
                                    {orderSummaryData.govt_gst_bf_modify !== 'null' && orderSummaryData.govt_gst_bf_modify && Number(orderSummaryData.govt_gst_bf_modify) !== 0 && (
                                        <div className='bill-data-container'>
                                            <p className='bill-data-grey-text'>Govt Taxes</p>
                                            <p className='bill-data-grey-text'>{currency}{orderSummaryData.govt_gst_bf_modify}</p>
                                        </div>
                                    )}
                                    <div className='grey-divider' />
                                    <div className='bill-data-container'>
                                        <p className='amount-paid-text'>Amount Paid</p>
                                        <p className='amount-paid-text'>{currency}{orderSummaryData.amt_to_pay}</p>
                                    </div>
                                    {/* {orderSummaryData.cashback && orderSummaryData.cashback != 0 && orderSummaryData.cashback != 'undefined'} */}
                                    {(orderSummaryData.refundAmount && Number(orderSummaryData.refundAmount) !== 0) ? (
                                        <div>
                                            <div className='grey-divider' />
                                            <div className='bill-data-container'>
                                                <h6 className='green-text-savings'>Refunded</h6>
                                                <h6 className='green-text-savings'>{currency}{orderSummaryData.refundAmount}</h6>
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="beat-loader-container">
                            <BeatLoader color="#36d7b7" loading={true} />
                        </div>
                    )}
                </div>

            </Dialog>
        </div>
    )
}

export default OrderDetails
