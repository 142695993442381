import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./../components/sidebar.js";
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import { Container } from "reactstrap";
import { Row, Col, Form } from "react-bootstrap";
import CBAddEmployeeService from "./../api/services/CBAddEmployeeService";
import "../Dashboard.css";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import LogisticsService from "./../api/services/LogisticsService";
import { ExportToExcel } from "./../components/ExportToExcel";

const employeeStatus = [
  { value: 0, label: "Active", isCheck: false },
  { value: 1, label: "Inactive", isCheck: false },
];
export default function AddEmployee() {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm();

  const [mobileNumber, setMobileNumber] = useState("");
  const [state, setState] = useState([]);

  const [selectCity, setSelectCity] = useState("");
  const [selectPincode, setSelectPincode] = useState("");
  const [selectState, setSelectState] = useState("");
  const [city, setCity] = useState([]);
  const [name, setName] = useState("");
  const [l1Manager, setL1Manger] = useState("");
  const [l1MobileNumber, setL1MobileNumber] = useState("");
  const [l2Manager, setL2Manger] = useState("");
  const [l2MobileNumber, setL2MobileNumber] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [pincode, setPincode] = useState([]);
  const [selectStatus, setSelectStatus] = useState();

  const [employeeID, setEmployeeID] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [id, setID] = useState("");
  const [DataForExcel, setDataForExcel] = useState([]);
  const [errorText, setErrorText] = useState('');
  // const [field_employee, setField_employee] = useState('');

  const getCBEmployeelist = async () => {
    await CBAddEmployeeService.getCBEmployeelist()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                setDataForExcel(result.data);
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCityByState = async (newValue) => {
    console.log(newValue);
    if (newValue === null || newValue === undefined) {
      setTimeout(() => {
        setSelectCity("");
        setCity([]);
        setPinCode([]);
        setSelectPincode("");
      }, 500);
    } else {
      let data = { state: newValue && newValue.state };
      console.log(data);
      await LogisticsService.getCitiesByState(data)
        .then(async (res) => {
          console.log(res);
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  console.log(result.data);
                  setCity([...result.data]);
                  setSelectCity("");
                  setPinCode([]);
                  setSelectPincode("");
                } else {
                  console.log(result.message);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const selectValues = [
    {
      value: 'Yes',
    },
    {
      value: 'No'
    }
  ];

  const handlePincodeByCity = async (newValue) => {
    if (newValue === null || newValue === undefined) {
      setTimeout(() => {
        setSelectPincode("");
      }, 500);
    } else {
      let data = { city: newValue && newValue.city };
      await LogisticsService.getPincodes(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  setPincode([...result.data]);
                  setSelectPincode("");
                } else {
                  console.log(result.message);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const checkMobileNumber = async (e) => {
    if (mobileNumber.trim().length == 0) {
      setErrorText('* This field cannot be empty');
    } else {
      setErrorText('');
    }
    if (mobileNumber.trim().length !== 0) {
      let data = { mobileNo: mobileNumber };
      await CBAddEmployeeService.getCBemployee(data).then(async (res) => {
        console.log(res);
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);

              if (result.data !== "Cb Employee not exist") {
                console.log(result);
                setUpdateStatus("1");
                setMobileNumber(result.data.mobile_no);
                //setCity(result.data.city);
                setName(result.data.name);
                setL1Manger(result.data.l1_manager);
                setL1MobileNumber(result.data.l1_mobile_no);
                setL2Manger(result.data.l2_manager);
                setL2MobileNumber(result.data.l2_mobile_no);
                //  setPinCode(result.data.pincode);
                setEmployeeID(result.data.e_code);
                setID(result.data.id);
                // console.log("Field Employee Type======>", typeof result.data.field_employee)
                // setField_employee(result.data.field_employee)
                var filterData_emp = [];
                const emp_status = result.data.status;
                console.log(emp_status);
                if (emp_status != null) {
                  const emp_statusItem = employeeStatus.find(
                    (it) => it.value === parseInt(emp_status)
                  );

                  filterData_emp.push(emp_statusItem);
                }
                console.log(filterData_emp);
                setSelectStatus(filterData_emp[0]);
                var filterData = [];
                var filterPincode = [];
                var state_check = result.data.state;
                if (state_check != null) {
                  var state_checkItem = state.find(
                    (it) => it.state === state_check
                  );

                  filterData.push(state_checkItem);
                }
                // setInterval(handleCityByState(filterData[0]), 3000);
                var city_check = result.data.city;
                var pincode_check = result.data.pincode;
                let data = { state: filterData[0] && filterData[0].state };
                console.log(data);
                await LogisticsService.getCitiesByState(data)
                  .then(async (res) => {
                    console.log(res);
                    if (res.status === 200) {
                      try {
                        res.text().then(async (res) => {
                          let result = JSON.parse(res);
                          if (result.code === 0) {
                            console.log(result.data);
                            setCity([...result.data]);

                            var filterCity = [];

                            if (city_check != null) {
                              var city_checkItem = result.data.find(
                                (it) => it.city === city_check
                              );

                              filterCity.push(city_checkItem);
                              setSelectCity(filterCity[0]);
                            }
                            let data = {
                              city: filterCity[0] && filterCity[0].city,
                            };
                            await LogisticsService.getPincodes(data).then(
                              async (res) => {
                                console.log(res);
                                if (res.status === 200) {
                                  try {
                                    res.text().then(async (res) => {
                                      let result = JSON.parse(res);
                                      if (result.code === 0) {
                                        console.log(result.data);
                                        setPincode([...result.data]);

                                        var filter_Pincode = [];

                                        if (pincode_check != null) {
                                          var pincode_checkItem =
                                            result.data.find(
                                              (it) => it.pincode === pincode_check
                                            );

                                          filter_Pincode.push(pincode_checkItem);
                                          setSelectPincode(filter_Pincode[0]);
                                        }
                                      } else {
                                        console.log(result.message);
                                      }
                                    });
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }
                              }
                            );
                          } else {
                            console.log(result.message);
                          }
                        });
                      } catch (err) {
                        console.log(err);
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                setSelectState(filterData[0]);
              } else {
                swal({
                  title: "",
                  text: "Cb Employee not exist",
                  icon: "error",
                  className: "swal-modal-error-small",
                  // timer: 4000,
                }).then(function () {
                  // window.location.reload();

                  setUpdateStatus("0");
                  setName("");
                  setL1Manger("");
                  setL1MobileNumber("");
                  setL2Manger("");
                  setL2MobileNumber("");
                  setPinCode("");
                  setEmployeeID("");
                  setSelectState("");
                  setCity([]);
                  setPinCode([]);
                  setSelectPincode("");
                  setSelectCity("");
                  // setField_employee(1)
                });
              }
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          setUpdateStatus("0");
          setName("");
          setL1Manger("");
          setL1MobileNumber("");
          setL2Manger("");
          setL2MobileNumber("");
          setPinCode("");
          setEmployeeID("");
        }
      });
    }
  };
  const handleMobileNumber = async (e) => {
    setMobileNumber(e);
    setUpdateStatus("");
  };
  const getStates = async () => {
    await LogisticsService.getStates()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                setState([...result.data]);
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleName = async (e) => {
    setName(e);
  };
  const handleL1Manager = async (e) => {
    setL1Manger(e);
  };
  const handleL1MobileNumber = async (e) => {
    setL1MobileNumber(e);
  };
  const handleL2Manager = async (e) => {
    setL2Manger(e);
  };
  const handleL2MobileNumber = async (e) => {
    setL2MobileNumber(e);
  };
  // const handlepincode = async (e) => {
  //     setPinCode(e);
  // };
  const handleEmployeeID = async (e) => {
    setEmployeeID(e);
  };

  // const handleFieldEmployee = (event) => {
  //   let val = '';
  //   if (event.target.value === 'Yes') {
  //     val = 0;
  //   } else {
  //     val = 1;
  //   }
  //   setField_employee(val)
  // }

  const handlesubmit = async (datas) => {
    console.log(datas);
    if (updateStatus === "0") {
      let data = {
        name: name,
        e_code: employeeID,
        mobile_no: mobileNumber,
        city: selectCity.city,
        l1_manager: l1Manager,
        l1_mobile_no: l1MobileNumber,
        l2_manager: l1Manager,
        l2_mobile_no: l2MobileNumber,
        pincode: selectPincode.pincode,
        state: selectState.state,
        status: "0",
        // field_employee: field_employee,
      };
      await CBAddEmployeeService.addCBemployee(data).then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.message == "CbEmployee id already exist") {
                swal({
                  title: "",
                  text: "CB Employee id already exist",
                  icon: "error",
                  className: "swal-modal-succuess-small",
                  // timer: 4000,
                }).then(function () {
                  //window.location.reload();
                });
              } else if (result.code === 0) {
                swal({
                  title: "",
                  text: "New employee  added successfully",
                  icon: "success",
                  className: "swal-modal-succuess-small",
                  // timer: 4000,
                }).then(function () {
                  window.location.reload();
                });
              } else {
                alert(result.message);
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        } else {
        }
      });
    } else if (updateStatus == 1) {
      let data = {
        key_id: id,
        name: name,
        e_code: employeeID,
        mobile_no: mobileNumber,
        city: selectCity.city,
        l1_manager: l1Manager,
        l1_mobile_no: l1MobileNumber,
        l2_manager: l1Manager,
        l2_mobile_no: l2MobileNumber,
        pincode: selectPincode.pincode,
        state: selectState.state,
        status: selectStatus.value,
        // field_employee: field_employee,
      };
      console.log(data);
      await CBAddEmployeeService.updateCBemployee(data).then(async (res) => {
        console.log(res);
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              console.log(res);
              let result = JSON.parse(res);
              console.log(result.code);
              if (result.CbEmployee_status == "Employee id already exist") {
                swal({
                  title: "",
                  text: "CB Employee id already exist",
                  icon: "error",
                  className: "swal-modal-succuess-small",
                  // timer: 4000,
                }).then(function () {
                  // window.location.reload();
                });
              } else if (result.code === 0) {
                swal({
                  title: "",
                  text: "updated employee  successfully",
                  icon: "success",
                  className: "swal-modal-succuess-small",
                  // timer: 4000,
                }).then(function () {
                  window.location.reload();
                });
              } else {
                alert(result.message);
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        } else {
        }
      });
    }
  };

  React.useEffect(() => {
    getStates();
    getCBEmployeelist();
  }, []);

  const handleBlur = () => {
    setErrorText('');
  };

  return (
    <div fluid>
        <div className='sidebar'>
          <Sidebar />
        </div>
          <div className="App">
            <h1 className="center">Add CB Employee</h1>
            <div className="cb-employee-btn-container">
              <div className="export-excel-btn">
                <ExportToExcel
                  apiData={DataForExcel}
                  fileName="CB Employee Listing"
                />
              </div>
              
            </div>
            <div className="add-employee-inputs-container">
              <form onSubmit={handleSubmit(handlesubmit)}>
                {/* Mobile Number */}
                <div className="textfields-row-container">
                  <span>
                    <TextField
                      className="input_text_fields"
                      error={!!errorText}
                      helperText={errorText ? errorText : ''}
                      onBlur={handleBlur}
                      id="outlined-basic"
                      label="Mobile Number"
                      variant="outlined"
                      type="text"
                      value={mobileNumber}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) =>
                        handleMobileNumber(event.target.value)
                      }
                    />
                  </span>
                  {/* Name */}

                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      type="text"
                      value={name}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleName(event.target.value)}
                    />
                  </span>
                  {/* Employee ID */}

                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Employee ID"
                      variant="outlined"
                      type="text"
                      value={employeeID}
                      disabled={updateStatus == "" ? true : false}
                      required
                      onChange={(event) => handleEmployeeID(event.target.value)}
                    />
                  </span>
                </div>

                <div className="textfields-row-container">
                  <span>
                    <Autocomplete
                      id="tags-standard"
                      value={selectState ? selectState : null}
                      onChange={(event, newValue) => {
                        setSelectState(newValue);
                        handleCityByState(newValue);
                      }}
                      options={state}
                      disabled={updateStatus == "" ? true : false}
                      getOptionLabel={(option) => option.state}
                      renderInput={(params) => (
                        <TextField {...params} label="State" required />
                      )}
                    />
                  </span>
                  {/* City */}
                  <span>
                    <Autocomplete
                      id="tags-standard"
                      value={selectCity ? selectCity : null}
                      onChange={(event, newValue) => {
                        setSelectCity(newValue);
                        handlePincodeByCity(newValue);
                      }}
                      disabled={
                        updateStatus == ""
                          ? true
                          : selectState === null ||
                            selectState === undefined ||
                            selectState === ""
                            ? true
                            : false
                      }
                      options={city}
                      getOptionLabel={(option) => option.city}
                      renderInput={(params) => (
                        <TextField {...params} label="City" required />
                      )}
                    />
                  </span>

                  {/* Pincode */}
                  <span>
                    <Autocomplete
                      id="tags-standard"
                      value={selectPincode ? selectPincode : null}
                      onChange={(event, newValue) => {
                        setSelectPincode(newValue);
                      }}
                      disabled={
                        updateStatus == ""
                          ? true
                          : selectCity === null ||
                            selectCity === undefined ||
                            selectCity === ""
                            ? true
                            : false
                      }
                      options={pincode}
                      getOptionLabel={(option) => option.pincode}
                      renderInput={(params) => (
                        <TextField {...params} label="Pincode" required />
                      )}
                    />
                  </span>
                </div>

                <div className="textfields-row-container">
                  {/* L1 manager */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="L1 Manager "
                      variant="outlined"
                      type="text"
                      required
                      value={l1Manager}
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleL1Manager(event.target.value)}
                    />
                  </span>
                  {/* L1 Mobile Number */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="L1 Mobile Number"
                      variant="outlined"
                      type="text"
                      value={l1MobileNumber}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) =>
                        handleL1MobileNumber(event.target.value)
                      }
                    />
                  </span>
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="L2 Manager"
                      variant="outlined"
                      type="text"
                      value={l2Manager}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleL2Manager(event.target.value)}
                    />
                  </span>

                  {/* <span>
                                            <TextField
                                                className="input_text_fields"
                                                id="outlined-basic"
                                                label="PinCode"
                                                variant="outlined"
                                                type="text"
                                                value={pinCode}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                required
                                                onChange={(event) => handlepincode(event.target.value)}
                                            />
                                        </span> */}
                </div>

                <div className="textfields-row-container">
                  {/* L2 Mobile Number */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="L2 Mobile Number"
                      variant="outlined"
                      type="text"
                      value={l2MobileNumber}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) =>
                        handleL2MobileNumber(event.target.value)
                      }
                    />
                  </span>

                  {/* Field Employee */}
                  {/* <span>
                    <TextField
                      select
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Field Employee"
                      variant="outlined"
                      value={field_employee === 0 ? 'Yes' : 'No'}
                      type="text"
                      onChange={(event) => handleFieldEmployee(event)}
                      required
                      disabled={updateStatus == "" ? true : false}
                    >
                      {selectValues.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                    {console.log("filed Employee",field_employee)}
                    {console.log("filed Employee type",typeof(field_employee))}
                  </span> */}
                  {updateStatus != 0 ? (
                    <span>
                      <Autocomplete
                        id="tags-standard"
                        value={selectStatus ? selectStatus : null}
                        onChange={(event, newValue) => {
                          setSelectStatus(newValue);
                        }}
                        options={employeeStatus}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField {...params} label="Status" required />
                        )}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {updateStatus != "" ? (
                  <div className="cb-employee-btn-container">
                    <button className="cb-employee-submit-btn">
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="cb-employee-btn-container">
                    <button onClick={() => checkMobileNumber()} className="cb-employee-submit-btn">
                      Check
                    </button>
                  </div>
                  
                )}

                <br />
              </form>
            </div>
          </div>
    </div>
  );
}
//export default AddEmployee;
