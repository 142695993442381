import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../../components/sidebar.js";
import "../../Dashboard.css";
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import ask from "../../images/ask.png";
import axios from "axios";
import Select from "react-select";
import { Typography } from "@mui/material";
import Moment from "react-moment";
import "../style/Qc.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Qcheader(props) {
  const [TicketNo, setTicketNo] = useState("");
  const [agent_id, setAgentID] = useState("");
  const [Businessname, setBusinessName] = useState("");
  const [Child_mob_1, setChild_mob_1] = useState("");
  const [remarkUpdate, setRemarkUpdate] = useState(props.Remark);
  const [Category, setCategory] = useState("");

  React.useEffect(() => {
    console.log(props.data);
    setChild_mob_1(props.data.mobile_no);
    setCategory(props.data.l1_categoryname + ' > ' + props.data.l2_categoryname + ' > ' + props.data.l3_categoryname + ' > ' + props.data.l4_categoryname);
    setBusinessName(props.data.name);
    setTicketNo(props.data.ticket_no);
    setAgentID(props.data.agent_id);
    setRemarkUpdate(props.Remark);
  }, [props]);

  const handleRemark = (event) => {
    event.preventDefault();

    props.qcRemark(event);
    console.log(props.qcRemark);
    setRemarkUpdate(event.target.value);
  };

  return (
    <div>
      <Row className="pt-3 pd-3">
        <Col style={{display:'flex',flexdirection: 'row',}}>
          <div className="color_border_validation pt-3 pb-3 d-inline-block w-25 pl-2 text-left border border-secondary border-right-0">
            <div className="pb-1 pt-2">
              <b>Ticket number</b>
            </div>
            <div className="pb-3 pt-2">{TicketNo}</div>
          </div>
          <div className="color_border_validation pl-2 pt-3 pb-3 d-inline-block w-25  text-left border border-secondary border-right-0">
            <div className="pb-1 pt-2">
              <b>Agent Id</b>
            </div>
            <div className="pb-3 pt-2">{agent_id}</div>
          </div>
          <div className="color_border_validation pt-3 pb-3 d-inline-block w-25 pl-2 text-left border border-secondary border-right-0">
            <div className="pb-1 pt-2">
              <b>Rider Name</b>
            </div>
            <div className="pb-3 pt-2">{Businessname}</div>
          </div>
          <div className="color_border_validation pt-3 pb-3 d-inline-block w-25 pl-2 text-left border border-secondary">
            <div className="pb-1 pt-2">
              <b>Rider Number</b>
            </div>
            <div className="pb-3 pt-2">{Child_mob_1}</div>
          </div>
        </Col>
      </Row>

   
    </div>
  );
}

export default Qcheader;
