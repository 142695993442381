import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Label } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "./sidebar.js";
import '../Dashboard.css';
import axios from 'axios';

import { saveAs } from "file-saver";


const Details = props => {
  const BusinessProof = [
    { id: 0, name: "Select", value: "Select" },
    { id: 1, name: "GST certificate", value: "GST certificate" },
    { id: 2, name: "Shops & establishment certificate", value: "Shops & establishment certificate" },
    { id: 3, name: "Electricity bill of last 3 months", value: "Electricity bill of last 3 months" },
    { id: 4, name: "Business PAN card", value: "Business PAN card" },
    { id: 5, name: "Udyog Aadhar", value: "Udyog Aadhar" },
    { id: 6, name: "Cancelled cheque in name of business", value: "Cancelled cheque in name of business" },
    { id: 7, name: "Printed bill book", value: "Printed bill book" },
    { id: 8, name: "Rent agreement", value: "Rent agreement" },
  ];

  const [id, setId] = useState(props.match.params.id);
  const [ShopName, setShopName] = useState();
  const [Address, setAddress] = useState();
  const [City, setCity] = useState();
  const [Pincode, setPincode] = useState();
  const [MerchantName, setMerchantName] = useState();
  const [MerchantAadhar, setMerchantAadhar] = useState();
  const [Primary_mob_1, setPrimary_mob_1] = useState();
  const [Child_mob_1, setChild_mob_1] = useState();
  const [Child_mob_2, setChild_mob_2] = useState();
  const [kycdocumenttype, setkycdocumenttype] = useState();
  const [kycdocumentnumber, setkycdocumentnumber] = useState();
  const [BusinessProofType, setBusinessProofType] = useState();
  const [BusinessProofNumber, setBusinessProofNumber] = useState();
  const [BusinessProofExpiry, setBusinessProofExpiry] = useState();
  const [businessProof, setBusinessProof] = useState('');
  const [fssaiImage, setFssaiImage] = useState([]);
  const [licensenumber, setLicenscenumber] = useState("");
  console.log("businessProof", businessProof);
  const [businessProofPhotos, setBusinessProofPhotos] = useState([]);
  const [isFssaiFileChanged, setIsFssaiFileChanged] = useState(false);
  const [licenseUrl, setLicenseUrl] = useState([]);
  const [BankAccNumber, setBankAccNumber] = useState();
  const [BankName, setBankName] = useState();
  const [BankIfscCode, setBankIfscCode] = useState();
  const [OpenTime, setOpenTime] = useState();
  const [CloseTime, setCloseTime] = useState();
  const [OpenTime2, setOpenTime2] = useState();
  const [CloseTime2, setCloseTime2] = useState();
  const [StoreDescription, setStoreDescription] = useState();
  const [WeeklyOff, setWeeklyOff] = useState();
  const [MarketName, setMarketName] = useState();
  const [shopLocation, setShopLocation] = useState('');

  const [L1CategoryName, setL1CategoryName] = useState();
  const [L2CategoryName, setL2CategoryName] = useState();
  const [L3CategoryName, setL3CategoryName] = useState();
  const [L4CategoryName, setL4CategoryName] = useState();
  const [catalogPhotos, setCatalogphotos] = useState([]);
  const [ShopUrl, setShopUrl] = useState();
  const [MerchantStatus, setMerchantStatus] = useState();
  const [GstNumber, setGstNumber] = useState();
  const [AccNumber, setAccNumber] = useState();
  const [Chattybaodeliverystatus, setChattybaodeliverystatus] = useState();
  const [Deliveryradius, setDeliveryradius] = useState();
  const [PaymentNetworkCharges, setPaymentNetworkCharges] = useState();
  const [Baselogisticsrate, setBaselogisticsrate] = useState();
  const [Logisticvariablecharge, setLogisticvariablecharge] = useState();
  const [payoutLimit, setPayoutLimit] = useState();
  const [latestBalance, setLatestBalance] = useState();
  const [platformCharge, setPlatformCharge] = useState();
  const [Subscriptioncharges1, setSubscriptioncharges1] = useState();
  const [Subscriptioncharges2, setSubscriptioncharges2] = useState();
  const [Subscriptioncharges3, setSubscriptioncharges3] = useState();
  const [classification,setClassification] = useState();
  const [commission_sd_per,setCommission_sd_per] =useState('');
  const [gst_on_commission_per,setGst_on_commission_per] = useState('');
  const [commission_per,setCommission_per] =useState('');
  const [merchantalias,setMerchantalias]=useState('');
  const [chatlink,setChatlink]=useState('');
  const [chatlink2,setChatlink2]=useState('');
  const [gstStatus,setGstStatus]=useState('');
  const [catalogPdf, setCatlogPDF] = useState('');
  const [l3Id, setL3Id] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');


  React.useEffect(() => {
    axios.get(window.baseUrl + 'viewMerchant?id=' + id,{timeout:30000,timeoutErrorMessage:'Request Timedout'})
      .then((response) => {
        
        var res = response.data.data;
        console.log(res)
        var fullAddress = response.data.data.addressline1 + "," + response.data.data.addressline2;
        setShopName(response.data.data.shopname);
        setAddress(fullAddress);
        setCity(response.data.data.city);
        setPincode(response.data.data.pincode);
        setMerchantName(response.data.data.merchantname)
        setMerchantAadhar(response.data.data.owneraadharnumber);
        setPrimary_mob_1(response.data.data.mainmobilenumber);
        setCatlogPDF(response.data.data.menu_pdf);
        if (props.location.state !== undefined) {
          setL3Id(props.location.state.categoryl3);
        }
        

        var catalog_photos = [];
        catalog_photos = response.data.data.catalogphotos.split(',');
        setCatalogphotos([...catalog_photos]);
        if(response.data.data.licensephoto!=""||response.data.data.licensephoto!=null||response.data.data.licensephoto!='null'||response.data.data.licensephoto!=undefined){
         
          var licencephoto_img = response.data.data.licensephoto;
        
        setLicenseUrl([...licencephoto_img]);
        }else{
          var licencephoto_img = [];
          setLicenseUrl([...licencephoto_img]);
        }
        setLicenscenumber(response.data.data.lincenseno);
        setFssaiImage(response.data.data.fssaicertificateurl);
        setShopLocation(response.data.data.shoplocation);
        setClassification(response.data.data.classification);
        setMerchantalias(response.data.data.merchantalias);
        setChatlink(response.data.data.chatlink);
        setChatlink2(response.data.data.chatlink2);
        var bus_img = response.data.data.businessproofimg;
        var array_bus_img = [];
        { response.data.data.businessproofimg == "undefined" || response.data.data.businessproofimg == null ? array_bus_img = [] : array_bus_img = bus_img.split(',') };

        { response.data.data.businessproofimg == "undefined" || response.data.data.businessproofimg == 'null' || response.data.data.businessproofimg == null || response.data.data.businessproofimg == '' ? setBusinessProofPhotos('') : setBusinessProofPhotos(array_bus_img) };

        if (response.data.data.teammobilenumbers.length > 0) {
          if (response.data.data.teammobilenumbers.length >=2) {
            setChild_mob_1(response.data.data.teammobilenumbers[0]);
            setChild_mob_2(response.data.data.teammobilenumbers[1]);
          } else {
            setChild_mob_1(response.data.data.teammobilenumbers[0]);
            setChild_mob_2();
          }
        }

        setBankAccNumber(response.data.data.accnumber);
        setBankName(response.data.data.bankname);
        setBankIfscCode(response.data.data.bankifsc);
        setOpenTime(response.data.data.opentime);
        setCloseTime(response.data.data.closetime);
        setOpenTime2(response.data.data.opentime2);
        setCloseTime2(response.data.data.closetime2);
        setStoreDescription(response.data.data.storedescription);
        setWeeklyOff(response.data.data.weeklyoff);
        setL1CategoryName(response.data.data.l1_categoryname);
        setL2CategoryName(response.data.data.l2_categoryname);
        setL3CategoryName(response.data.data.l3_categoryname);
        setL4CategoryName(response.data.data.l4_categoryname);
        setShopUrl(response.data.data.picurl);
        setGstNumber(response.data.data.gstnumber);
        setGstStatus(response.data.data.gst_status);
        setChattybaodeliverystatus(response.data.data.chattybaodeliverystatus);
        setMerchantStatus(response.data.data.bizstatus);
        setAccNumber(response.data.data.accnumber);
        setDeliveryradius(response.data.data.deliveryradius);
        setPaymentNetworkCharges(response.data.data.paymentandnetworkcharges);
        setBaselogisticsrate(response.data.data.baselogisticsrate);
        setLogisticvariablecharge(response.data.data.logisticvariablecharge);
        setPayoutLimit(response.data.data.payout_limit);
        setLatestBalance(response.data.data.latest_balance);
        setPlatformCharge(response.data.data.platform_charge);
        setSubscriptioncharges1(response.data.data.subscriptioncharges1);
        setSubscriptioncharges2(response.data.data.subscriptioncharges2);
        setSubscriptioncharges3(response.data.data.subscriptioncharges3);
        setCommission_sd_per(response.data.data.commission_sd_per);
        setGst_on_commission_per(response.data.data.gst_on_commission_per);
        setCommission_per(response.data.data.commission_per);
        setLatitude(response.data.data.latitude);
        setLongitude(response.data.data.longitude);

        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        //First of month
        if (response.data.data.weeklyoff === '7') {
          var dayName = '';
          for (var i = 0; i < days.length; i++) {
            if (i != days.length - 1) {
              dayName += days[i] + ",";
            }
            else {
              dayName += days[i];
            }
          }
        } else {
          var dayName = days[response.data.data.weeklyoff];
        }
        setWeeklyOff(dayName);

        setMarketName(response.data.data.mandyorareaormarket);

        if (!response.data.data.kycdocumenttype) {
          setkycdocumenttype("-");
        } else {
          setkycdocumenttype(response.data.data.kycdocumenttype);
        }
        if (!response.data.data.kycdocumentnumber) {
          setkycdocumentnumber("-");
        } else {
          setkycdocumentnumber(response.data.data.kycdocumentnumber);
        }
        if (!response.data.data.businessprooftype) {
          setBusinessProofType("-");
        } else {
          setBusinessProofType(response.data.data.businessprooftype);
        }
        if (!response.data.data.businessproofnumber) {
          setBusinessProofNumber("-");
        } else {
          setBusinessProofNumber(response.data.data.businessproofnumber);
        }
        if (!response.data.data.businessproofexpiry) {
          setBusinessProofExpiry("-");
        } else {
          setBusinessProofExpiry(response.data.data.businessproofexpiry);
        }
        if (response.data.data.businessproof === "null") {
          setBusinessProof("");
        } else {
          setBusinessProof(response.data.data.businessproof);
        }

      });
  }, []);
  const handleDownload = (url) => {
    let url1 = url;
    let image_type = url1.split(/[#?]/)[0].split('.').pop().trim();
    let newimge = "image" + "." + image_type;
    saveAs(url1, newimge);
  }
  return (
    <div>
        <div>
          <Sidebar />
        </div>
          <div className="tittle">
            <h1>Merchant Details</h1>
          </div>

          <table class="merchantdetails">
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Primary Mobile No :</td>
              <td class="merchantdetails_td1">{Primary_mob_1}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Sec Mobile No 1 :</td>
              <td class="merchantdetails_td1">{Child_mob_1}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Sec Mobile No 2 :</td>
              <td class="merchantdetails_td1">{Child_mob_2}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">MID :</td>
              <td class="merchantdetails_td1">{id}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Business Name :</td>
              <td class="merchantdetails_td1">{ShopName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Merchant Name :</td>
              <td class="merchantdetails_td1">{MerchantName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Merchant Status :</td>
              <td class="merchantdetails_td1">{MerchantStatus === 0 ? 'Active' : 'In Active'}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Merchant Alias :</td>
              <td class="merchantdetails_td1">{merchantalias}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Chant Link 1:</td>
              <td class="merchantdetails_td1">{chatlink}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Chant Link 2:</td>
              <td class="merchantdetails_td1">{chatlink2}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">L1 Category Name :</td>
              <td class="merchantdetails_td1">{L1CategoryName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">L2 Category Name :</td>
              <td class="merchantdetails_td1">{L2CategoryName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">L3 Category Name :</td>
              <td class="merchantdetails_td1">{L3CategoryName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">L4 Category Name :</td>
              <td class="merchantdetails_td1">{L4CategoryName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Mandi or Area or Market  :</td>
              <td class="merchantdetails_td1"> {MarketName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Shop Location  :</td>
              <td class="merchantdetails_td1"> {shopLocation}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Shop opening time  :</td>
              <td class="merchantdetails_td1">{OpenTime}</td>
            </tr>
           
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Shop closing time  :</td>
              <td class="merchantdetails_td1"> {CloseTime}</td>
            </tr>

            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Shop opening time 2  :</td>
              <td class="merchantdetails_td1">{OpenTime2}</td>
            </tr>
           
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Shop closing time 2  :</td>
              <td class="merchantdetails_td1"> {CloseTime2}</td>
            </tr>

            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Weekly off  :</td>
              <td class="merchantdetails_td1">{WeeklyOff}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Shop Photo :</td>
              <td class="merchantdetails_td1"><img src={ShopUrl} class="w-25 h-25"></img></td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Address :</td>
              <td class="merchantdetails_td1">{Address}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">City :</td>
              <td class="merchantdetails_td1">{City}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">PinCode :</td>
              <td class="merchantdetails_td1">{Pincode}</td>
            </tr>

            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Latitude :</td>
              <td class="merchantdetails_td1">{latitude}</td>
            </tr>

            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Longitude :</td>
              <td class="merchantdetails_td1">{longitude}</td>
            </tr>

            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">GST No :</td>
              <td class="merchantdetails_td1">{GstNumber}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">GST Status :</td>
              <td class="merchantdetails_td1">{gstStatus=='0'?'Verified':'Unverified'}</td>
            </tr>
            
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Catalog Photos ( jpg, jpeg, png ):</td>
              <td class="merchantdetails_td1">{catalogPhotos == null || catalogPhotos == undefined || catalogPhotos == '' || catalogPhotos == 'null' ? '' : 

              catalogPhotos.length > 0 && catalogPhotos.map((data) => 
              <img src={data} class="w-25 h-25" style={{padding:"2px"}}></img>
              )} </td>
            </tr>
            <tr class="merchantdetails_tr">
            <td class="merchantdetails_td">
            {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" || l3Id == "c205e5d0-737f-11ec-9806-93163a488dc9" || l3Id == "57991b70-7381-11ec-9806-93163a488dc9" ?
            
                'Catalog PDF' : ''}:</td>
                <td class="merchantdetails_td1">
                {catalogPdf != '' && catalogPdf != 'null' && catalogPdf != null ? <p style={{ display: 'inline-flex', width: '60%', lineBreak:'anywhere'}}>{catalogPdf} </p> : ''}
              {/* {catalogPdf != '' && catalogPdf != 'null' && catalogPdf != null ? <button onClick={() => handleDownload(catalogPdf)} style={{ display: 'inline-flex', borderRadius: '0px' }}> Downlaod</button> : ''} */}
              </td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Fssai certificate image ( jpg, jpeg, png ):</td>
              <td class="merchantdetails_td1">{fssaiImage == null || fssaiImage == undefined || fssaiImage == '' || fssaiImage == 'null' ? '' :
              
              fssaiImage.length > 0 && fssaiImage.map((data) => 
              <img src={data} class="w-25 h-25" style={{padding:"2px"}}></img>
              )} </td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">License No :</td>
              <td class="merchantdetails_td1">{licensenumber}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">License Image ( jpg, jpeg, png ):</td>
              <td class="merchantdetails_td1">{licenseUrl.length==0 ||licenseUrl[0]==""||licenseUrl[0]=="null"||licenseUrl[0]==null ? '' : licenseUrl.length > 0 && licenseUrl.map((data) => 
              <img src={data} class="w-25 h-25" style={{padding:"2px"}}></img>)}</td>
            </tr>

            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Business Proof :</td>
              <td class="merchantdetails_td1"> {businessProof}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Classification :</td>
              <td class="merchantdetails_td1"> {classification}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Business Proof Image :</td>
              <td class="merchantdetails_td1">{businessProofPhotos && businessProofPhotos.map((imageurl) => (
                <img

                  src={imageurl}
                  alt={""}
                  className="w-25 h-25 mt-3 d-block"

                />
              ))}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Aadhar No :</td>
              <td class="merchantdetails_td1">{MerchantAadhar}</td>
            </tr>

            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Bank Account Details</td>
              <td class="merchantdetails_td1"></td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Bank Account Number  :</td>
              <td class="merchantdetails_td1">{AccNumber}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Bank Name  :</td>
              <td class="merchantdetails_td1">{BankName}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">IFSC Code  :</td>
              <td class="merchantdetails_td1">{BankIfscCode}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Store Description  :</td>
              <td class="merchantdetails_td1">{StoreDescription}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Commission Percentage :</td>
              <td class="merchantdetails_td1">{commission_per}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Gst Commission Percentage :</td>
              <td class="merchantdetails_td1">{gst_on_commission_per}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Commission Self Delivery Percentage :</td>
              <td class="merchantdetails_td1">{commission_sd_per}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Chattybao Delivery Status  :</td>
              <td class="merchantdetails_td1">{Chattybaodeliverystatus}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Delivery Radius  :</td>
              <td class="merchantdetails_td1">{Deliveryradius}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Payment & Network Charges  :</td>
              <td class="merchantdetails_td1">{PaymentNetworkCharges}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td">Logistics Base Charge  :</td>
              <td class="merchantdetails_td1">{Baselogisticsrate}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td"> Logistics Variable Charge  :</td>
              <td class="merchantdetails_td1">{Logisticvariablecharge}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td"> Payout Limit  :</td>
              <td class="merchantdetails_td1">{payoutLimit}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td"> Latest Balance  :</td>
              <td class="merchantdetails_td1">{latestBalance}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td"> Platform Charge  :</td>
              <td class="merchantdetails_td1">{platformCharge}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td"> Subscription Slab 1  :</td>
              <td class="merchantdetails_td1">{Subscriptioncharges1}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td"> Subscription Slab 2  :</td>
              <td class="merchantdetails_td1">{Subscriptioncharges2}</td>
            </tr>
            <tr class="merchantdetails_tr">
              <td class="merchantdetails_td"> Subscription Slab 3  :</td>
              <td class="merchantdetails_td1">{Subscriptioncharges3}</td>
            </tr>
          </table>
    </div>
  );
};
const MerchantDetails = withRouter(Details);
export default MerchantDetails