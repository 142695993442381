import React, { useEffect, useState } from "react";

import { Container, Row, Col, Card, Form, Button, input } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../components/sidebar.js";
import '../Dashboard.css';
import axios from 'axios';
import right from "../images/check.png";
import wrong from "../images/close.png";
import ask from "../images/ask.png";
import TicketDetails from "./ticket.js";
import PanImageCheck from "./components/panImageCheck.js";
import Chequevalidation from "./components/chequevalidation.js";
import SelfieImageCheck from "./components/selfieImageCheck.js";

import LicenseImageCheck from "./components/licenseImageCheck.js";

import Qcheader from "./components/Qcheader";




const Dash = props => {
  console.log('props1',props.match.params);
  console.log('props1',props.match.params.mobile_no);

  const [id, setId] = useState(props.match.params.id);
  const [ticket, setTicket] = useState(props.match.params.ticket);
  const [mobile_no, setMobile_No] = useState(props.match.params.id);
  const [data, setData] = useState([]);
  const [Category_Pharm, setCategory_Pharm] = useState("");
  const [Category_Res,setCategory_Res]=useState("");
  const [accverifystatus, setAccverifystatus] = useState("");
  const [Remark, setRemark] = useState("");
  const [business_qc, setBusiness_qc] = useState();
  const [license_qc, setLicense_qc] = useState();
  const [menu_pdf, setMenu_pdf] = useState('');
  const [catalogphotos, setCatalogphotos] = useState('')

  const [photo_qc, setPhoto_qc] = useState();
  const [bank_qc, setBank_qc] = useState();
  const [l1chategory, setL1chategory] = useState("Fixed Shop");




  React.useEffect(() => {
    console.log('mobileno',mobile_no)
    console.log('ticket_no',ticket)
    console.log('window.baseUrl =====',window.baseUrl)
    const token = localStorage.getItem('sessionId');
    const headers = { token };

    axios.get(window.baseUrl + 'getRiderTicketList?mobile_no='+mobile_no+'&ticketId='+ticket,{headers,timeout:30000,timeoutErrorMessage:'Request Timedout'})
      .then((response) => {
        console.log('response....',response)
        var res = response.data.data;
        console.log('res======>',res);
        setCategory_Pharm(response.data.data.categoryl2);
        setCategory_Res(response.data.data.categoryl3);
        setL1chategory(response.data.data.l1_categoryname);
        setAccverifystatus(response.data.data.accverifystatus);
        setRemark(response.data.data.remark);
        setBusiness_qc(response.data.data.business_qc);
        setPhoto_qc(response.data.data.photo_qc);
        setBank_qc(response.data.data.bank_qc);
        setLicense_qc(response.data.data.license_qc);
        setMenu_pdf(response.data.data.menu_pdf)
        setCatalogphotos(response.data.data.catalogphotos)



        console.log(Category_Pharm);
        setData(res);

        //setChild_mob_1(res.data.data.teammobilenumbers);
      });
  }, []);
  const right_image = (event) => {
    event.preventDefault();
    console.log("hello");
  }
  const qcRemark = (event) => {
    setRemark(event.target.value);
  }

  {console.log('data',data)}
  return (
    <>
      <div>
          <div className='sidebar'>
            <Sidebar />
          </div>
            <div id="container" className="qcpanelclass h-auto">
              {/* <TicketDetails handler = {qaRemark}  data={data}></TicketDetails>  */}
              
              {/* header */}
              <Qcheader data={data} qcRemark={qcRemark} Remark={Remark}/>
             

              {/* photo_qc */}
              {<LicenseImageCheck data={data} Remark={Remark} ></LicenseImageCheck>}
              
              {/* business_qc */}
              { <PanImageCheck Remark={Remark} data={data}></PanImageCheck> }

              {data && data.acc_verify_status !== 0 && data.can_cheque_url && <Chequevalidation Remark={Remark} data={data} />}

              <SelfieImageCheck Remark={Remark} data={data}></SelfieImageCheck>

            </div>
      </div>
    </>
  );
};
const Dashboard = withRouter(Dash);
export default Dashboard