import React, { useCallback, useState } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useDropzone } from "react-dropzone";
import './FileUploader.css'
import upload_image from '../../../images/upload_image.png'
const FileUploader = (props) => {
  const [fileFormat, setFileFormat] = useState(
    "image/jpeg, image/jpg, image/png"
  );

  React.useEffect(() => {
    if (props.isImg || props.catalogImg || props.isBusinessProofPhotos || props.fssaiImage || props.licenseUrl || props.masterCatalogImg) {
      setFileFormat("image/jpeg, image/jpg, image/png");
    } else if (props.csv) {
      setFileFormat(".csv");
    } else {
      setFileFormat("application/pdf");
    }
  }, [props]);

  const onDrop = useCallback((acceptedFiles) => {
    console.log("AcceptedFiles.........", acceptedFiles, "merID", props.merchantId);

    if (props.masterCatalogImg) {
      if (acceptedFiles.length > 5) {
        alert("Select only upto 5 images")
      } else {
        let item = [];
        acceptedFiles.forEach((file) => {
          console.log("Initial file.........", file);
          const reader = new FileReader();
          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onloadend = () => {
            const binaryStr = reader.result;
            item.push({ base64: binaryStr, file: file });
            props.handleNewImage(item);
          };
          reader.readAsDataURL(file);
        });
      }
    } else {
      let item = [];
      acceptedFiles.forEach((file) => {
        console.log("Initial file.........", file);
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onloadend = () => {
          const binaryStr = reader.result;
          // const binaryStr1 = reader.result;
          // file.uri = binaryStr1;
          item.push({ base64: binaryStr, file: file });
          if (props.isImg) {
            console.log("props *", props)
            props.getThumbnail(item);
          }
          if (props.catalogImg) {
            props.getCatalogImg(item);
          }
          if (props.isBusinessProofPhotos) {
            props.getBusinessProofPhotos(item);
          }
          if (props.fssaiImage) {
            props.getFssaiImage(item);
          }
          if (props.licenseUrl) {
            props.getLicenseImage(item);
          }
          if (props.masterCatalogImg) {
            props.handleNewImage(item);
          }
          if (props.csv) {
            if (item[0].file.type !== 'text/csv') {
              alert("Please upload csv files only")
            } else {
              props.handleCsv(item, props.merchantId);
            }
          }
          else {
            props.handleNewPDF(item);
          }
          //setFiles([...files, ...item]);
        };
        reader.readAsDataURL(file);
      });
    }

  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileFormat,
    multiple: props.isBusinessProofPhotos === true || props.fssaiImage === true || props.licenseUrl === true || props.catalogImg === true || props.masterCatalogImg === true ? true : false,
    onDrop,
  });

  return (
    <section className="container-fuild">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} disabled={props.isDefault ? true : false} />
        {console.log("isEditable", props.isEditable)}
        {!props.isEditable ? (
          <div>
            {props.isImg || props.catalogImg || props.isBusinessProofPhotos || props.getFssaiImage || props.getLicenseImage || props.newPdf || props.masterCatalogImg ? (
              <div
                className={`position-relative ${props.isDefault ? "cursor-none" : "cursor-pointer "
                  }`}
              >

                {
                  props.newPdf ? <Input value={props.value} placeholder="Upload New PDF" className="form-control common-form-control" style={{ border: '1px solid #333 ' }} /> : props.isForm ? <Input value={props.value} placeholder="Upload File" className="form-control common-form-control" />
                    :
                    <div>
                      {props.masterCatalogImg ? (
                        <div>
                          <img src={upload_image} className='upload-icon' alt="NA" />
                          <p>Upload image</p>
                        </div>

                      ) : (
                        <CloudUploadIcon
                          style={{
                            color: "#dfdfdf",
                            fontSize: "8rem",
                            border: "1px solid #dedede",
                            borderRadius: 4,
                            padding: 24,
                          }}
                        />
                      )}

                      <div className="img-thumbnail-container">
                        {props.isBusinessProofPhotos ?
                          <p className="p-small mt-2 my-2  internal-section--title title-light-dim-grey align-center">
                            Business template
                          </p>
                          :
                          <p className="p-small mt-2 my-2  internal-section--title title-light-dim-grey align-center">
                            {/* Category template */}
                          </p>

                        }
                      </div></div>}
              </div>
            ) : props.csv ? (
              <>
              {/* <Input value={props.value} placeholder="Upload New PDF" className="upload-catalog-btn" /> */}
              <button className='upload-catalog-btn'>Upload catalogue</button>
              </>
            ) : (
              <div className={`position-relative ${props.isDefault ? "cursor-none" : "cursor-pointer "
                }`}>
                <div>
                  <PictureAsPdfIcon
                    style={{
                      color: "#DC0D45",
                      fontSize: "2rem",
                      border: "1px solid #dedede",
                      borderRadius: 4,
                      padding: 4,
                      position: "absolute",
                      marginLeft: "95%",

                    }}
                  //onClick={() => handlePdfBitlyUrl(shortPdfUrl)}
                  />

                </div>
                <Input value={props.value} placeholder="Upload images" className="form-control common-form-control" />

                {/* <div className="img-thumbnail-container">
                  <p className="p-small mb-0 internal-section--title title-light-dim-grey align-center">
                    Upload PDF
                  </p>
                </div> */}
              </div>
            )}
          </div>
        ) : (
          <div
            className="edit-img-file"
          //onClick={() => handleImgFile(item)}
          >
            <EditIcon
              className='mg-left-1'
            // style={{
            //   color: "#0000",
            //   fontSize: "0.85rem",
            // }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default FileUploader;
