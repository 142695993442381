import swal from "sweetalert";

function alertDangerMessage(message) {
    swal({
        title: "",
        text: message,
        icon: "warning",
        dangerMode: true,
        buttons: "OK",
        // timer: 3000,
    });
}

function alertSuccessMessage(message) {
    swal({
        title: "",
        text: message,
        icon: "success",
        className: "swal-modal-succuess-small",
        // timer: 4000,
    }).then(function(){
        window.location.reload();
    })
}
function alertSuccessMessageMerchant(message) {
    swal({
        title: "",
        text: message,
        icon: "success",
        className: "swal-modal-succuess-small",
         timer: 4000,
    })
}

function alertDangerConfirmMessage(message) {
    swal({
        title: "",
        text: message,
        icon: "warning",
        className: "swal-modal-warning-small",
    });
}
function alertSearchFailMessage(message){
    swal({
        title: "",
        text: message,
        icon: "warning",
        dangerMode: true,
        buttons: "OK",
        // timer: 3000,
    }).then(function(){
        window.location.reload();
    })
}

export { alertDangerMessage, alertDangerConfirmMessage, alertSuccessMessage,alertSuccessMessageMerchant,alertSearchFailMessage };
