import React, { useState } from 'react'
import { Container } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import "../styles/home.css"
import "../Dashboard.css";
import Sidebar from "../components/sidebar.js";
import Papa from 'papaparse';
import * as XLSX from "xlsx";
import axios from 'axios';
import { APIConfig } from '../api/apiConfig/apiConfig';
import { constants } from '../utils/constants';

const BEmapping = () => {
  const [data, setData] = useState([]);
  const { baseUrl, addUpdateFSEMerchants } = APIConfig;
  const { dataUploadMessages } = constants;

  const updateFSEMerchants = () => {
    const token = localStorage.getItem('sessionId');
    const headers = {
      token
    }
    axios
      .post(baseUrl + addUpdateFSEMerchants, {
        data: data,
      }, { headers, timeout:30000,timeoutErrorMessage:'Request Timedout' })
      .then(function (response) {
        if (response.data.status === 0) {
          alert(dataUploadMessages.uploadSuccessMsg)
        } else {
          alert(dataUploadMessages.uploadFailMsg)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const contents = e.target.result;
      const extension = file.name.split('.').pop().toLowerCase();

      // Process the file based on the file extension
      if (extension === 'csv') {
        parseCSV(contents);
      } else if (extension === 'xlsx' || extension === 'xls') {
        parseExcel(contents);
      } else {
        alert(dataUploadMessages.invalidFormatMsg);
      }
    };

    reader.readAsText(file);
  };

  const parseCSV = (contents) => {
    const result = Papa.parse(contents, { header: true, skipEmptyLines: true });
    setData(result.data);
  };

  const parseExcel = (contents) => {
    const workbook = XLSX.read(contents, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
    const headers = data[0];
    const rows = data.slice(1);

    // Convert rows to objects
    const objects = rows.map((row) =>
      headers.reduce((obj, header, index) => {
        obj[header] = row[index];
        return obj;
      }, {})
    );

    setData(objects);
  };

  return (
    <div>
        <div className='sidebar'>
          <Sidebar />
        </div>
          {console.log("data", data)}
          <div className='page_container'>
            <div className='vip_history_input_container'>
              <input type="file" className='vip_history_input' onChange={handleFileUpload} />
              <button onClick={updateFSEMerchants} className='vip_history_submit_btn' >Submit</button>
            </div>

          </div>
    </div>
  )
}

export default BEmapping
