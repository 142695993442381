import React, { useEffect, useState } from 'react'
import { Container } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import "../../styles/home.css"
import "../../Dashboard.css";
import './MerchantCatalog.css'
import Sidebar from '../../components/sidebar.js'
import { TextField, Dialog } from "@mui/material";
import { APIConfig } from '../../api/apiConfig/apiConfig';
import axios from 'axios';
import { alertSuccessMessageMerchant, alertDangerConfirmMessage } from "../../components/customComponent/ConfirmationMessage/ConfirmationMessage";
import LoadingSpinner from '../../components/loader/loader';
import close_icon from '../../payout/close.png'
import { constants } from '../../utils/constants';

const availabilities = [
  { id: 0, value: "Available" },
  { id: 1, value: "Not Available" },
]

const MerchantCatalog = () => {
  const [merchantId, setMerchantId] = useState(null);
  const [merchantCatalogData, setMerchantCatalogData] = useState([]);
  const [merchantCatalogBackup, setMerchantCatalogBackup] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [updateIndex, setUpdateIndex] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [emptySearchResult, setEmptySearchResult] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [pdImage, setPdImage] = useState([]);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { baseUrl, getMerchantCatlogues, updateMerchantCataloguePrice } = APIConfig;
  const { currency, discountPriceErrorMsg, fullscreenImgCss } = constants;

  useEffect(() => {
    if (searchInput === '') {
      setMerchantCatalogData(merchantCatalogBackup);
      setEmptySearchResult(false);
    }
  }, [searchInput])

  // Api call to get products
  const getMerchantCatalogue = async () => {
    if (merchantId.trim().length === 0) {
      setErrorText('* This field cannot be empty');
    } else {
      setErrorText('');
    }
    if (merchantId.trim().length !== 0) {
      setSpinner(true);

      let data = {
        merchantID: merchantId
      }

      await axios
        .post(baseUrl + getMerchantCatlogues, data,{timeout:30000,timeoutErrorMessage:'Request Timedout'})
        .then((response) => {
          setSpinner(false);
          console.log("res", response.data)
          if (response.data.code === 0) {
            setMerchantCatalogData(response.data.data)
            setMerchantCatalogBackup(response.data.data);
            setUpdateIndex(null);
            setUpdateItem({});
            setEmptySearchResult(false);
          } else {
            alertDangerConfirmMessage(response.data.message)
            setMerchantCatalogData([]);
          }
        })
        .catch(function (error) {
          setSpinner(false);
        alertDangerConfirmMessage(error.message)
          console.log(error);
        });
    }
  }

  const handleViewImage = (image) => {
    setPdImage(image);
    setViewImageModal(true);
  }

  const handleUpdateBtn = (index) => {
    setUpdateIndex(index);
    let data = {
      discounted_rate: merchantCatalogData[index].discounted_rate,
      rate: merchantCatalogData[index].rate,
      merchantId: merchantCatalogData[index].merchant_id,
      status: merchantCatalogData[index].status
    }
    setUpdateItem(data);
  }

  // handle search
  const search = () => {
    let data = merchantCatalogBackup.filter((rows) => rows.item_name !== null && rows.item_name !== undefined && rows.item_name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1);
    setMerchantCatalogData(data);
    if (data.length === 0) {
      setEmptySearchResult(true);
    } else {
      setEmptySearchResult(false);
    }
  };

  // Api call to update price
  const handleSaveBtn = async () => {
    if (updateItem.discounted_rate && Number(updateItem.discounted_rate) >= Number(updateItem.rate)) {
      alertDangerConfirmMessage(discountPriceErrorMsg)
    } else {
      setSpinner(true);
 
      let data = {
        merchantID: updateItem.merchantId,
        productID: merchantCatalogData[updateIndex].id,
        price: updateItem.rate,
        discPrice: updateItem.discounted_rate,
        productStatus: Number(updateItem.status)
      }

      await axios
        .post(baseUrl + updateMerchantCataloguePrice, data,{timeout:30000,timeoutErrorMessage:'Request Timedout'})
        .then((response) => {
          setSpinner(false);
          console.log("res", response.data)
          if (response.data.code === 0) {
            setUpdateIndex(null);
            setUpdateItem({});
            getMerchantCatalogue();
            setSearchInput('');
            alertSuccessMessageMerchant("Update Success");
          } else {
            alertDangerConfirmMessage("Update Failed")
          }
        })
        .catch(function (error) {
          setSpinner(false);
          alertDangerConfirmMessage(error.message)
          console.log(error);
        });
    }
  }

  const handleBlur = () => {
    setErrorText('');
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>
          <div className='page_container'>
            {spinner && (
              <LoadingSpinner />
            )}

            <div className='category-inputs-container'>
              <div className='flex top-container vip_history_input_container'>
                <TextField
                  className='vip_history_input'
                  label="Merchant phone / Merchant ID"
                  onChange={(e) => setMerchantId(e.target.value)}
                  error={!!errorText}
                  helperText={errorText ? errorText : ''}
                  onBlur={handleBlur}
                />
                <button onClick={getMerchantCatalogue} className='view-all-btn master-catalog-btn'>View all</button>
              </div>
            </div>

            <div className='search-csv-container'>
              <div className='vip_history_input_container'>
                <TextField value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='vip_history_input' label="Search any item" />
                <button onClick={search} className='master-catalog-btn'>Submit</button>
              </div>
            </div>

            {emptySearchResult && (
              <p className='nodata-text-merchant'>
                No Data
              </p>
            )}
            {merchantCatalogData.length !== 0 && (
              <div>
                <table
                  style={{ marginTop: '20px', marginLeft: 0 }}
                >
                  <thead>
                    <tr>
                      <th colSpan="1" className="tableTextStyle large-cell">S1 Category</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Brand Name</th>
                      <th colSpan="1" className="tableTextStyle large-cell">Product Name</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Image</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">MRP</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Discounted Price</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Availability</th>
                      <th colSpan="1" className="tableTextStyle large-cell">Action</th>
                    </tr>
                  </thead>
                  {console.log("Item", updateItem)}
                  <tbody>

                    {merchantCatalogData.map((item, index) => {
                      if (updateIndex !== index) {
                        return (
                          <tr key={index} className="merchentList_tr">
                            <td className="merchentList_td td-value">
                              {item.category}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.brandName}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.item_name}
                            </td>
                            <td className="merchentList_td td-value-image">
                              <img onClick={() => handleViewImage(item.image_url[0])} src={item.image_url[0]} className="product-image" alt="NA" />
                            </td>
                            <td className="merchentList_td td-value">
                              {currency + item.rate}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.discounted_rate ? currency + item.discounted_rate : ''}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.status == 0 ? 'Available' : 'Not Available'}
                            </td>
                            <td className="merchentList_td td-value">
                              <button className='vip_history_submit_btn' onClick={() => handleUpdateBtn(index)}>Update</button>
                            </td>
                          </tr>
                        )
                      } else {
                        return (
                          <tr key={index} className="merchentList_tr">
                            <td className="merchentList_td td-value">
                              {item.category}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.brandName}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.item_name}
                            </td>
                            <td className="merchentList_td td-value-image">
                              <img src={item.image_url[0]} className="product-image" alt="NA" />
                            </td>
                            <td className="merchentList_td td-value">
                              <div className='price-edit-cell'><p>{currency}</p>
                                <input className='add-input-price' type='number' onChange={(e) => setUpdateItem((prevItem) => ({
                                  ...prevItem,
                                  rate: e.target.value
                                }))} value={updateItem.rate} />
                              </div>
                            </td>
                            <td className="merchentList_td td-value">
                              <div className='price-edit-cell'>
                                <p>{currency}</p>
                                <input className='add-input-price' type='number' onChange={(e) => setUpdateItem((prevItem) => ({
                                  ...prevItem,
                                  discounted_rate: e.target.value
                                }))} value={updateItem.discounted_rate ? updateItem.discounted_rate : ''} />
                              </div>
                            </td>
                            <td className="merchentList_td td-value">
                            <select value={updateItem.status} v-model="selected" className="master-catalog-select w-100" onChange={(e) => setUpdateItem((prevItem) => ({
                                ...prevItem,
                                status: e.target.value
                              }))}>
                                {availabilities.map(data => (
                                  <option key={data.id} value={data.id} >
                                    {data.value}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="merchentList_td td-value">
                              <div className='buttons-container'>
                                <button className='vip_history_submit_btn save-btn' onClick={handleSaveBtn}>Save</button>
                                <button className='cancel-btn' onClick={() => setUpdateIndex(null)}>Cancel</button>
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </table>

              </div>
            )}
          </div>

          <Dialog onClose={() => setViewImageModal(false)} open={viewImageModal} PaperProps={{ sx: fullscreenImgCss }}>
            <img
              className="close-img"
              src={close_icon}
              onClick={() => setViewImageModal(false)}
              alt="close"
            />
            <img src={pdImage} className='fullscreen-image' alt='Fullscreen' />
          </Dialog>
    </div>
  )
}

export default MerchantCatalog;
