import React from "react";
import { timestampToTime, handleEnterPress } from "../utils/messageUtils";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SendIcon from "@mui/icons-material/Send";

export default function MessageInput(props) {
  const { value, onChange, sendMessage, onFileInputChange } = props;

  return (
    <div className="message-input">
      <input className="message_input_msg" placeholder="Write a message" value={value} onChange={onChange} onKeyDown={(event) => handleEnterPress(event, sendMessage)} />
      <button className="send-message-button button_input" onClick={sendMessage} disabled={value === "" ? true : false}>
        <SendIcon></SendIcon>
      </button>
      <label className="file-upload-label" htmlFor="upload">
        <FileUploadIcon></FileUploadIcon>
      </label>

      <input id="upload" className="file-upload-button" type="file" hidden={true} onChange={onFileInputChange} onClick={() => {}} />
    </div>
  );
}



