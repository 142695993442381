import React, { useState, useEffect } from 'react'
import ApiService from '../../api/services/ApiService';
import {
    alertDangerConfirmMessage
} from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage';
import LoadingSpinner from '../../components/loader/loader';
import DatePicker from "react-datepicker";
import { ExportToCSV } from '../../components/ExportToCSV';
import { constants } from '../../utils/constants';
import moment from "moment";
import "moment-timezone";

const OndcIssues = () => {

    const [loader, setLoader] = useState(false);
    const [issuesDetails, setIssuesDetails] = useState([]);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00.000
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    const getIssuesDetails = async (eDate) => {
        const token = localStorage.getItem('OndcToken')
        setLoader(true);
        const data = {
            token: token,
            startDate: startDate === '' ? convertStartDate("") : convertStartDate(startDate),
            endDate: endDate === '' ? convertEndDate("") : convertEndDate(endDate)
        }
        try {
            const result = await ApiService.ondcIssues(data);
            if (result.data.code === 0) {
                setIssuesDetails(result.data.data)
            } else {
                alertDangerConfirmMessage(constants.ondcApiErrorMsg)
                setIssuesDetails([])
            }
        } catch (error) {
            alertDangerConfirmMessage(error.message)
        } finally {
            setLoader(false);
        }
    }


    const convertStartDate = (date) => {
        return moment(date).tz("Asia/Kolkata").startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        
    };

    const convertEndDate = (date) => {
        return moment(date).tz("Asia/Kolkata").endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    };
    useEffect(() => {
        getIssuesDetails();
    }, [])

    const handleApplyBtn = () => {
        getIssuesDetails()
    }

    return (
        <div>
            {loader && (
                <LoadingSpinner />
            )}
            <div className='ondc-header-div'>
                <h5 className='ondc-page-header'>ONDC Issues</h5>
                <div className='filters-export-container'>
                    <div className='filter-container'>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Start date"
                            className="ondc-date-filter"
                            maxDate={today}
                        />
                        <DatePicker
                            selected={endDate && endDate >= startDate ? endDate : null}
                            onChange={(date) =>
                                setEndDate(date)
                            }
                            dateFormat="dd-MM-yyyy"
                            placeholderText="End date"
                            className="ondc-date-filter"
                            minDate={startDate}
                            maxDate={today}
                        />
                        <button onClick={handleApplyBtn} className='ondc-filter-btn'>
                            Apply
                        </button>
                    </div>
                    <ExportToCSV orders={false} apiData={issuesDetails} dates={{ startDate: startDate, endDate: endDate }} fileName="chattybao-orders" />
                </div>
            </div>
            <table
                style={{ marginTop: '20px', marginLeft: 0 }}
            >
                <tr className='ondc-table-header'>
                    <th colSpan="1" className="normal-cell ondc-cell">S. No.</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Network Order Id</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Id</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Order Id</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Name of Seller NP</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Name of Logistics NP (On network)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Creation Date</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Creation Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Issue Category (L1)
                        (As per the IGM document)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Issue Subcategory (L2)
                        (As per the IGM document)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Order Category</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Status</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Closure Date</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Closure Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Relay Date</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket Relay Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket last update date</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ticket last update time</th>
                </tr>
                <tbody>
                    {issuesDetails && issuesDetails.length !== 0 && issuesDetails.map((data, index) => (
                        <tr className="tableRowAlign" key={index}>
                            <td className="ondc-data-cell">{index + 1}</td>
                            <td className="ondc-data-cell">{data.networkOrderId}</td>
                            <td className="ondc-data-cell">{data.ticketId}</td>
                            <td className="ondc-data-cell">{data.orderId}</td>
                            <td className="ondc-data-cell">{data.nameOfSellerNP}</td>
                            <td className="ondc-data-cell">{data.nameOfLogisticsNP}</td>
                            <td className="ondc-data-cell">{data.ticketCreationDateAndTime}</td>
                            <td className="ondc-data-cell">{data.ticketCreationDateAndTime}</td>
                            <td className="ondc-data-cell">{data.issueCategory}</td>
                            <td className="ondc-data-cell">{data.issueSubcategory}</td>
                            <td className="ondc-data-cell">{data.orderCategory}</td>
                            <td className="ondc-data-cell">{data.ticketStatus}</td>
                            <td className="ondc-data-cell">{data.ticketClosureDateAndTime}</td>
                            <td className="ondc-data-cell">{data.ticketClosureDateAndTime}</td>
                            <td className="ondc-data-cell">{data.ticketRelayDateAndTime}</td>
                            <td className="ondc-data-cell">{data.ticketRelayDateAndTime}</td>
                            <td className="ondc-data-cell">{data.ticketLastUpdateDateAndTime}</td>
                            <td className="ondc-data-cell">{data.ticketLastUpdateDateAndTime}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default OndcIssues;
