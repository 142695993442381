import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../../components/sidebar.js";
import '../../Dashboard.css';
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import ask from "../../images/ask.png";
import axios from 'axios';
import Select from 'react-select';
import { Typography } from "@mui/material";
import Moment from 'react-moment';
import '../style/Qc.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


const Shopimage = props => {
    const [Child_mob_1, setChild_mob_1] = useState("");
    const [Businessname, setBusinessName] = useState("");
    const [Shopimage, setShopImage] = useState("");
    const [Category, setCategory] = useState("");
    const [photo_qc, setPhoto_qc] = useState();
    const [TicketNoCheck, setTicketNoCheck] = useState("");
    const [MerchantidCheck, setMerchantidCheck] = useState("");
    const [show, setShow] = useState(false);
    const [wrongImage, setWrongImage] = useState(false);
    const [rightImage, setRightImage] = useState(false);
    const [TicketNo, setTicketNo] = useState("");
    const [remarkcheck, setremarkcheck] = useState("");
    const [agent_id, setAgentID] = useState("");
    const [sec1_reason, setSec1_reason] = useState("");
    const [reason, setReason] = useState("");
    const [updatedTimestamp, setupdatedTimestamp] = useState("");
    const [status, setstatus] = useState("");
    const [remarkUpdate, setRemarkUpdate] = useState(props.Remark);
    const [disable, setdisable] = useState(true);
    const [errormsgShopboard, seterrormsgShopboard] = useState("");
    const [mobileNo,setMobileNo]=useState("");
    const [shopName, setShopName] = useState("");

    const reason_shopimage = [
        { value: 0, label: "Photo  hazy" },
        { value: 1, label: "Photo is from inside the shop" },
        { value: 2, label: "Wrong photo" },
        { value: 3, label: "Partial photo" },
        { value: 4, label: "Others" }
    ];


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    React.useEffect(() => {
        console.log(props.data);
        setChild_mob_1(props.data.mainmobilenumber);
        setBusinessName(props.data.shopname);
        setShopImage(props.data.picurl);
        setCategory(props.data.l1_categoryname + ' > ' + props.data.l2_categoryname + ' > ' + props.data.l3_categoryname + ' > ' + props.data.l4_categoryname);
        setPhoto_qc(props.data.photo_qc);
        setTicketNoCheck(props.data.ticket_no);
        setMerchantidCheck(props.match.params.id);
        setTicketNo(props.data.ticket_no);
        setremarkcheck(props.data.remark);
        setAgentID(props.data.agent_id);
        setSec1_reason(props.data.sec1_reason);
        setupdatedTimestamp(props.data.sec1_updated_at);
        setstatus(props.data.photo_qc);
        setRemarkUpdate(props.Remark);
        setMobileNo(props.data.mainmobilenumber);
        setShopName(props.data.shopname);

    }, [props]);
    const handleRemark = (event) => {
        event.preventDefault();

        props.qcRemark(event);
        console.log(props.qcRemark);
        setRemarkUpdate(event.target.value)
    }
    const right_image = (event) => {
        event.preventDefault();
        setWrongImage(false);
        setRightImage(true);
        setPhoto_qc(0);
        setdisable(false);
        console.log(photo_qc);
    }
    const wrong_image = (event) => {
        event.preventDefault();
        setWrongImage(true);
        setRightImage(false);
        setPhoto_qc(1);
        setdisable(false);
        console.log(photo_qc);
    }
    const sec1_reasonChange = (item) => {
        setReason(item.label)
    }


    const submit_stage1 = (event) => {
        final_submit();
    }
    function final_submit() {
        if ((photo_qc == 1) && ((reason == "")||(reason==undefined))) {
            seterrormsgShopboard("Select reason");
        }
        else {
            console.log(remarkUpdate);
            console.log("agent_id===>", agent_id)
            const headers = {
                'Content-Type': 'application/json',
            }
            const qc_panelStage1 = {
                "ticket_no": TicketNoCheck,
                "photo_status": photo_qc,
                "merchantid": MerchantidCheck,
                "remark": remarkUpdate,
                // "remark": props.Remark,
                "reason": reason,
                "mobileNo":mobileNo,
                "shopname": shopName,
                "agent_id": agent_id,
            };
            console.log(qc_panelStage1);
            axios.post(window.baseUrl + `updateStage1`, qc_panelStage1, {
                headers: headers,
                timeout:30000,
                timeoutErrorMessage:'Request Timedout'
            })
                .then(res => {
                    console.log(res.data);
                    console.log(res.data.message);
                    window.location.reload();
                });
        }
    }

    return (
        <div className="background_validation pb-4">

            <Row>
                <Col >
                    <div className="d-inline-block w-75">
                        <p className='pt-2'><b className=" title">Section -1: Verify business photo for shop / Selfie for Services</b></p>
                        <ul className="list-group list-group-light pt-2 pd-4">
                            <li className="list-group-item border-0 p-0 "><b>Shop guidelines:</b></li>
                            <li className="list-group-item border-0 p-0 ">- Photo should be from outside the shop</li>
                            <li className="list-group-item border-0 p-0 ">- If there is shop board, shop name should be clearly visible</li>
                            <li className="list-group-item border-0 p-0 ">- If there is no shop board, shop type should be clear from the</li>
                            <li className="list-group-item border-0 p-0 pl-2">photo. e.g. coconut water shop</li>
                        </ul>
                    </div>
                    <div className="d-inline-block float-right w-25 mt-3 border border-secondary">
                        <div className="d-flex flex-row border-bottom border-secondary last_update">
                            <p className="pl-2 mb-0"><b>Last Update</b></p>

                        </div>
                        <div className="d-flex flex-row mt-2">
                            <p className="pl-2">Status : </p>
                            <p>{status == 0 ? "Match" : status == 1 ? "Resubmit" : "New"}</p>
                        </div>
                        <div className="d-flex flex-row">
                            <p className="pl-2">Reason : </p>
                            <p style={{ width: '70%' }}>{sec1_reason == null ? 'Null' : sec1_reason}</p>
                        </div>
                        <div className="d-flex flex-row">
                            <p className="pl-2">Timestamp : </p>
                            <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row >
                <Col md={3} className="pt-4">
                    <div className=" color_border_validation height_cheque  position-absolute">
                        <img className="w-100 h-100  cursor-pointer" src={Shopimage} onClick={handleShow}></img>
                    </div>
                </Col>
                <Col md={1} className="pt-4 pl-0">
                    <div className="image_wrong_div  mx-auto" onClick={right_image} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}><img className="image_wrong cursor-pointer" src={right} ></img></div>
                    <div className="text-center pt-1"><b>OK</b></div>
                    <div className="text-center pt-2">As per guidelines</div>
                </Col>
                <Col md={1} className="pt-4 pl-0">
                    <div className="image_wrong_div  mx-auto" onClick={wrong_image} style={{ backgroundColor: wrongImage == true ? "#FD626B" : "" }}><img className="image_wrong cursor-pointer" src={wrong} ></img></div>
                    <div className="text-center pt-1"><b>Resubmit</b></div>
                    <div className="text-left pt-2">
                        <Typography style={{ width: "215px",fontSize:"0.875rem" }} noWrap>
                            Hazy, not clear
                        </Typography>
                        <Typography style={{ width: "215px",fontSize:"0.875rem" }} noWrap>
                            Inside photo, partial photo
                        </Typography>
                        <Typography style={{ width: "215px",fontSize:"0.875rem" }} noWrap>
                            Wrong photo
                        </Typography>
                    </div>
                </Col>


                <Col className="pt-4 ">
                    {wrongImage == true ?
                        <Select options={reason_shopimage} className="float-right reason_shopimage" onChange={sec1_reasonChange}></Select> : ''}

                </Col>
            </Row>
            <Row className="d-table pt-5">

                <Col className='d-table-cell align-center '>
                    <div className="text_instructions p-3 mb-2 w-75 ">
                        <b>Instructions:</b> If you resubmit,please call the merchant to update new photo on ChattyBao partnerApp from settings section
                    </div>
                </Col>
                <Col className='d-table-cell  align-center text-center pr-4'><button className="btn" disabled={disable} onClick={submit_stage1}>Submit</button>
                    <p className="text-danger text-center pt-3">{errormsgShopboard}</p></Col>
            </Row>
            <Modal show={show} onHide={handleClose}>

                <Modal.Body><TransformWrapper>
                    <TransformComponent><img className="w-100 h-100" src={Shopimage} ></img></TransformComponent></TransformWrapper></Modal.Body>

            </Modal>
        </div>

    );
};
const Dashboard = withRouter(Shopimage);
export default Dashboard