import React, { useEffect } from "react";
import * as FileSaver from "file-saver";
import axios from 'axios';
import { APIConfig } from "../api/apiConfig/apiConfig";
import { alertDangerConfirmMessage } from "./customComponent/ConfirmationMessage/ConfirmationMessage";

export const ExportToCSV = ({ apiData, fileName, setSpinner, dates, orders }) => {
  const fileType = "text/csv;charset=UTF-8";
  const fileExtension = ".csv";
  const { baseUrl, getMasterCatalogueForPanel } = APIConfig;

  const formatDateToExcel = (dateString) => {
    const dateObj = new Date(dateString);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const getMasterCatalogData = async (apiData, fileName) => {
    setSpinner(true);
    const selectedL3 = apiData;

    const data = {
      merchantCat: selectedL3,
      topSellers_flag: 1,
      lastEvaluatedKey: "",
      export_csv: true,
    }


    await axios
      .post(baseUrl + getMasterCatalogueForPanel, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        setSpinner(false);
        console.log("res", response.data)
        if (response.data.code === 0) {
          console.log("res", response.data.data)
          let masterData = [];
          response.data.topSellers.map((data) => {
            masterData.push(data);
          })
          exportToCSV(masterData, fileName)
        }
      })
      .catch(function (error) {
        setSpinner(false);
        alertDangerConfirmMessage(error.message)
        console.log(error.message);
      });
  }

  // useEffect(() => {
  //     console.log("file", fileName)
  //     if (fileName === 'Master Catalog') {
  //         getMasterCatalogData();
  //     }
  // }, [])

  const exportToCSV = (apiData, fileName) => {
    // Extract column names from the first element of the apiData array
    const columns = Object.keys(apiData[0]);

    // Convert the data to CSV format
    const csvData =
      columns.join(",") +
      "\n" +
      apiData
        .map((row) => columns.map((column) => (column === "date" ? formatDateToExcel(row[column]) : row[column])).join(","))
        .join("\n");

    // Create a Blob from the CSV data
    const data = new Blob([csvData], { type: fileType });

    // Save the Blob as a CSV file
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleCsvFormat = () => {
    let data = [{
      s1_category_name: '',
      s2_category_name: '',
      product_name: '',
      images: '',
      hero_sku: '',
      Brand: ''
    }]
    data[0]["Top Sellers"] = '';
    exportToCSV(data, fileName);
  }

  const formatDateFromiOSString = (iOSString) => {
    const iosDate = new Date(iOSString);

    const year = iosDate.getFullYear();
    const month = String(iosDate.getMonth() + 1).padStart(2, '0');
    const day = String(iosDate.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;
  };

  function processString(inputString) {
    // Trim trailing '\n' at the end
    let processedString = inputString.endsWith('\n') ? inputString.slice(0, -1) : inputString;

    // Replace internal '\n' with '&'
    processedString = processedString.replace(/\n/g, '&');

    // Replace internal ',' with '&'
    processedString = processedString.replace(/,/g, '&');

    return processedString;
}

  const handleOndcCsv = async () => {
    fileName = fileName + `-${formatDateFromiOSString(dates.startDate)}-${formatDateFromiOSString(dates.endDate)}`
    let ondcData = [];
    if (orders) {
      await apiData.map(async (data) => {
        let obj = {
          "Buyer NP Name": data.buyerNPName ? data.buyerNPName : '',
          "Seller NP Name": data.sellerNPName ? data.sellerNPName : '',
          "Order Create Date & Time": data.orderCreateDateTime ? data.orderCreateDateTime : '',
          "Network Order Id": data.networkOrderId  ? data.networkOrderId : '',
          "Network Transaction Id": data.networkTransactionId ? data.networkTransactionId : '',
          "Seller NP Order Id": data.sellerNPOrderId ?  data.sellerNPOrderId : '',
          "Item Id": data.itemId  ? data.itemId : '',
          "Qty": data.qty ? data.qty : '',
          "Seller NP Type (MSN/ ISN)": data.sellerNPType ?  data.sellerNPType : '',
          "Order Status": data.orderStatus ? data.orderStatus : '',
          "Name of Seller": data.nameOfSeller ? data.nameOfSeller : '',
          "Seller Pincode": data.sellerPincode ? data.sellerPincode : '',
          "Seller City": data.sellerCity ? data.sellerCity : '',
          "SKU Name": data.skuName ? data.skuName : '',
          "SKU Code": data.skuCode ? data.skuCode : '',
          "Order Category (F&B/ Grocery/ Home & Decor)": data.orderCategory ? data.orderCategory : '',
          "Ready to Ship At Date & Time": data.readyToShipAtDateTime ? data.readyToShipAtDateTime : '',
          "Shipped At Date & Time": data.shippedAtDateTime ? data.shippedAtDateTime : '',
          "Delivered At Date & Time": data.deliveredAtDateTime ? data.deliveredAtDateTime : '',
          "Delivery Type (On-network/ Off-network)": data.deliveryType ? data.deliveryType : '',
          "Logistics Seller NP Name (For on-network delivery)": data.logisticsSellerNPName ? data.logisticsSellerNPName : '',
          "Logistics Network Order Id (For on-network delivery)": data.logisticsNetworkOrderId ? data.logisticsNetworkOrderId : '',
          "Logistics Network Transaction Id (For on-network delivery)": data.logisticsNetworkTransactionId ? data.logisticsNetworkTransactionId : '', 
          "Delivery City": data.deliveryCity ? data.deliveryCity : '',
          "Delivery Pincode": data.deliveryPincode ?  data.deliveryPincode : '',
          "Cancelled At Date & Time": data.cancelledAtDateTime ? data.cancelledAtDateTime : '',
          "Cancelled By (Buyer/ Seller/ Logistics)": data.cancelledBy ?  data.cancelledBy : '',
          "Cancellation Reason / Return Reason (Network Error Description)": data.cancellationOrReturnReason ? processString(data.cancellationOrReturnReason) : '',
          "Total Shipping Charges": data.totalShippingCharges ? data.totalShippingCharges : '',
          "Total Order Value": data.totalOrderValue ? data.totalOrderValue : '',
          "Total Refund Amount": data.totalRefundAmount ? data.totalRefundAmount : '',
        }
        ondcData.push(obj)
      })
    } else {
      await apiData.map(async (data) => {
        let obj = {
          "Network Order Id": data.networkOrderId ? data.networkOrderId : '',
          "Ticket Id": data.ticketId ? data.ticketId : '',
          "Order Id": data.orderId ? data.orderId : '',
          "Name of Seller NP": data.nameOfSellerNP ? data.nameOfSellerNP: '',
          "Name of Logistics NP (On network)": data.nameOfLogisticsNP ? data.nameOfLogisticsNP : '',
          "Ticket Creation Date": data.ticketCreationDateAndTime ? data.ticketCreationDateAndTime : '',
          "Issue Category (L1) (As per the IGM document)": data.issueCategory ? data.issueCategory : '',
          "Issue Subcategory (L2) (As per the IGM document)": data.issueSubcategory ? data.issueSubcategory : '',
          "Order Category": data.orderCategory ? data.orderCategory : '',
          "Ticket Status": data.ticketStatus ? data.ticketStatus : '',
          "Ticket Closure Date": data.ticketClosureDateAndTime ? data.ticketClosureDateAndTime : '',
          "Ticket Relay Date": data.ticketRelayDateAndTime ? data.ticketRelayDateAndTime : '',
          "Ticket last update date": data.ticketLastUpdateDateAndTime ? data.ticketLastUpdateDateAndTime : '',
        }
        ondcData.push(obj)
      })
    }

    exportToCSV(ondcData, fileName)
  }

  return (
    <>
      {fileName === 'Csv format' ? (
        <button
          className="csv-format-btn"
          onClick={handleCsvFormat}
        >
          CSV Format
        </button>
      ) : fileName === 'chattybao-orders' ? (
        <button
          className="ondc-csv-btn"
          onClick={handleOndcCsv}
        >
          Export CSV
        </button>
      ) : (
        <button
          style={{ background: "#E98424", color: "#ffffff", boxSizing: "border-box", position: "relative", borderRadius: "10px", padding: "8px 70px 8px 70px" }}
          onClick={fileName === 'Master Catalog' ? (e) => getMasterCatalogData(apiData, fileName) : (e) => exportToCSV(apiData, fileName)}
        >
          Export in CSV
        </button>
      )
      }

    </>
  );
};