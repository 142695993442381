import React from 'react'
import './index.scss';

const DeliveryTC = () => {
  return (
    <div class="main">
      <div class="content">
        <h2>Delivery Fee Terms and conditions :</h2>
        <ul>
          <li className='offer'>Get Free delivery on orders over ₹150</li>
          <li className='offer'>In some special instances (far away orders, bad weather etc), a nominal delivery charge is applicable</li>
        </ul>
      </div>
    </div>
  )
}

export default DeliveryTC
