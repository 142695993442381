import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { Container } from "reactstrap";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Row, Col, Form } from "react-bootstrap";
import EditCategory from "./../components/customComponent/EditCategory";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "reactstrap";
import { APIConfig } from "../api/apiConfig/apiConfig";
import CategoryService from "./../api/services/CategoryService";
import { useHistory } from "react-router-dom";
import AwsService from "../api/services/AwsImageService";
import Sidebar from "./../components/sidebar.js";
import "../Dashboard.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../components/customComponent/ConfirmationMessage/ConfirmationMessage";
import Logo from "../chatty_bao_logo.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import LogisticsService from "./../api/services/LogisticsService";
import swal from "sweetalert";
import { ExportToExcel } from "./../components/ExportToExcel";

const deliveryOption = [
  { value: 0, label: "Yes", isCheck: false },
  { value: 1, label: "No", isCheck: false },
];

export default function Logistics() {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm();

  const [selectState, setSelectState] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [selectPincode, setSelectPincode] = useState("");
  const [selectChattyBaoDelivery, setSelectChattyBaoDelivery] = useState("");
  const [baseKM, setBaseKM] = useState("");
  const [baseCharges, setBaseCharges] = useState("");
  const [chargesPerAdditionalKm, setChargesPerAdditionalKm] = useState("");
  const [freeDeliveryCount, setFreeDeliveryCount] = useState("");
  const [gstOnLogisticsRate, setGstOnLogisticsRate] = useState("");
  const [logisticsId, setLogisticsId] = useState("");
  console.log("selectState", selectState && selectState.state);
  console.log("selectCity", selectCity && selectCity.city);
  console.log("selectPincode", selectPincode && selectPincode.pincode);
  console.log("selectChattyBaoDelivery", selectChattyBaoDelivery);
  console.log("baseKM", baseKM);
  console.log("baseCharges", baseCharges);
  console.log("chargesPerAdditionalKm", chargesPerAdditionalKm);
  console.log("freeDeliveryCount", freeDeliveryCount);
  console.log("gstOnLogisticsRate", gstOnLogisticsRate);
  console.log("logisticsId", logisticsId);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [allData, setAllData] = useState([]);
  console.log("allData", allData);

  const [DataForExcel, setDataForExcel] = useState([]);
  console.log("DataForExcel", DataForExcel);

  React.useEffect(() => {
    getStates();
    getLogisticsdata();
  }, []);

  const getLogisticsdata = async () => {
    await LogisticsService.getLogisticslist()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                setDataForExcel(result.data);
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStates = async () => {
    await LogisticsService.getStates()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                setState([...result.data]);
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCityByState = async (newValue) => {
    if (newValue === null || newValue === undefined) {
      setTimeout(() => {
        setAllData([]);
        setSelectCity("");
        setSelectPincode("");
        setSelectChattyBaoDelivery("");
        setBaseKM("");
        setBaseCharges("");
        setChargesPerAdditionalKm("");
        setFreeDeliveryCount("");
        setGstOnLogisticsRate("");
      }, 500);
    } else {
      let data = { state: newValue && newValue.state };
      await LogisticsService.getCitiesByState(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  setCity([...result.data]);
                } else {
                  console.log(result.message);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handlePincodeByCity = async (newValue) => {
    if (newValue === null || newValue === undefined) {
      setTimeout(() => {
        setAllData([]);
        setSelectPincode("");
        setSelectChattyBaoDelivery("");
        setBaseKM("");
        setBaseCharges("");
        setChargesPerAdditionalKm("");
        setFreeDeliveryCount("");
        setGstOnLogisticsRate("");
      }, 500);
    } else {
      let data = { city: newValue && newValue.city };
      await LogisticsService.getPincodes(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  setPincode([...result.data]);
                } else {
                  console.log(result.message);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAllDataByPincode = async (newValue) => {
    if (newValue === null || newValue === undefined) {
      setTimeout(() => {
        setAllData([]);
        setSelectChattyBaoDelivery("");
        setBaseKM("");
        setBaseCharges("");
        setChargesPerAdditionalKm("");
        setFreeDeliveryCount("");
        setGstOnLogisticsRate("");
      }, 500);
    } else {
      let data = { pincode: newValue && newValue.pincode };
      await LogisticsService.getLogistics(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  setAllData(result.data);
                  setLogisticsId(result.data.id);
                  setBaseKM(result.data.base_km);
                  setBaseCharges(result.data.base_charges);
                  setChargesPerAdditionalKm(result.data.charges_per_additional_km);
                  setFreeDeliveryCount(result.data.free_delivery_count);
                  setGstOnLogisticsRate(result.data.gst_on_logistics_rate);

                  var filterData = [];
                  const isDeliveryItem = result.data.cb_delivery;
                  if (isDeliveryItem != null) {
                    const deliveryItem = deliveryOption.find((it) => it.value === isDeliveryItem);

                    filterData.push(deliveryItem);
                  }
                  setSelectChattyBaoDelivery(filterData[0]);
                } else {
                  // alert("Data not found");
                  console.log(result.message);
                  setAllData([]);
                  setSelectChattyBaoDelivery("");
                  setBaseKM("");
                  setBaseCharges("");
                  setChargesPerAdditionalKm("");
                  setFreeDeliveryCount("");
                  setGstOnLogisticsRate("");
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleBaseKM = async (e) => {
    setBaseKM(e);
  };
  const handleBaseCharges = async (e) => {
    setBaseCharges(e);
  };
  const handleChargesPerAdditionalKm = async (e) => {
    setChargesPerAdditionalKm(e);
  };
  const handleFreeDeliveryCount = async (e) => {
    setFreeDeliveryCount(e);
  };
  const handleGstOnLogisticsRate = async (e) => {
    setGstOnLogisticsRate(e);
  };

  const handlesubmit = async (datas) => {
    console.log(datas);

    if (allData.length === 0) {
      let data = {
        state: selectState.state,
        city: selectCity.city,
        pincode: selectPincode.pincode,
        cb_delivery: selectChattyBaoDelivery.value,
        base_km: baseKM,
        base_charges: baseCharges,
        charges_per_additional_km: chargesPerAdditionalKm,
        free_delivery_count: freeDeliveryCount,
        GST_on_logistics_rate: gstOnLogisticsRate,
      };

      console.log("WithoutID", data);
      await LogisticsService.addUpdateLogistics(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  swal({
                    title: "",
                    text: "New logistics added successfully",
                    icon: "success",
                    className: "swal-modal-succuess-small",
                    // timer: 4000,
                  }).then(function () {
                    window.location.reload();
                  });
                } else {
                  alert(result.message);
                  console.log(result.message);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let data = {
        id: logisticsId !== "" && logisticsId !== undefined && logisticsId !== null ? logisticsId : null,
        state: selectState.state,
        city: selectCity.city,
        pincode: selectPincode.pincode,
        cb_delivery: selectChattyBaoDelivery.value,
        base_km: baseKM,
        base_charges: baseCharges,
        charges_per_additional_km: chargesPerAdditionalKm,
        free_delivery_count: freeDeliveryCount,
        GST_on_logistics_rate: gstOnLogisticsRate,
      };

      console.log("WithID", data);
      await LogisticsService.addUpdateLogistics(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  swal({
                    title: "",
                    text: "Logistics updated successfully",
                    icon: "success",
                    className: "swal-modal-succuess-small",
                    // timer: 4000,
                  }).then(function () {
                    window.location.reload();
                  });
                } else {
                  alert(result.message);
                  console.log(result.message);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
        <div className='sidebar'>
          <Sidebar />
        </div>
          <div className="App">
            <h1 className="center">Logistics</h1>
            <Container className="center"></Container>

            <div style={{ position: "fixed", right: "78px", margin: "10px 0px 40px 0px" }}>
              <ExportToExcel apiData={DataForExcel} fileName="Logistics Listing" />
            </div>
            <div className="" style={{ margin: "65px 80px 10px 80px" }}>
              <form onSubmit={handleSubmit(handlesubmit)}>
                {/* State */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2%", margin: "4% 0", height: "auto", width: "100%" }}>
                  <span>
                    <Autocomplete
                      id="tags-standard"
                      onChange={(event, newValue) => {
                        setSelectState(newValue);
                        handleCityByState(newValue);
                      }}
                      options={state}
                      getOptionLabel={(option) => option.state}
                      renderInput={(params) => <TextField {...params} label="State" required />}
                    />
                  </span>

                  {/* City */}
                  <span>
                    <Autocomplete
                      id="tags-standard"
                      value={selectCity ? selectCity : null}
                      onChange={(event, newValue) => {
                        setSelectCity(newValue);
                        handlePincodeByCity(newValue);
                      }}
                      disabled={selectState === null || selectState === undefined || selectState === "" ? true : false}
                      options={city}
                      getOptionLabel={(option) => option.city}
                      renderInput={(params) => <TextField {...params} label="City" required />}
                    />
                  </span>

                  {/* Pincode */}
                  <span>
                    <Autocomplete
                      id="tags-standard"
                      value={selectPincode ? selectPincode : null}
                      onChange={(event, newValue) => {
                        setSelectPincode(newValue);
                        handleAllDataByPincode(newValue);
                      }}
                      disabled={selectCity === null || selectCity === undefined || selectCity === "" ? true : false}
                      options={pincode}
                      getOptionLabel={(option) => option.pincode}
                      renderInput={(params) => <TextField {...params} label="Pincode" required />}
                    />
                  </span>
                </div>

                {/* ChattyBao Delivery */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2%", margin: "4% 0", height: "auto", width: "100%" }}>
                  <span>
                    <Autocomplete
                      id="tags-standard"
                      value={selectChattyBaoDelivery ? selectChattyBaoDelivery : null}
                      onChange={(event, newValue) => {
                        setSelectChattyBaoDelivery(newValue);
                      }}
                      options={deliveryOption}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="ChattyBao Delivery" required />}
                    />
                  </span>

                  {/* Base KM */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Base KM"
                      variant="outlined"
                      type="text"
                      value={baseKM}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => handleBaseKM(event.target.value)}
                    />
                  </span>

                  {/* Base Charges */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Base Charges"
                      variant="outlined"
                      type="text"
                      value={baseCharges}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => handleBaseCharges(event.target.value)}
                    />
                  </span>
                </div>

                {/* Charges per additional KM */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2%", margin: "4% 0", height: "auto", width: "100%" }}>
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Charges Per Additional KM"
                      variant="outlined"
                      type="text"
                      value={chargesPerAdditionalKm}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => handleChargesPerAdditionalKm(event.target.value)}
                    />
                  </span>

                  {/* Free delivery count */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Free Delivery Count"
                      variant="outlined"
                      type="text"
                      value={freeDeliveryCount}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => handleFreeDeliveryCount(event.target.value)}
                    />
                  </span>

                  {/* GST On Logistics Rate (%) */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="GST On Logistics Rate (%)"
                      variant="outlined"
                      type="text"
                      value={gstOnLogisticsRate}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => handleGstOnLogisticsRate(event.target.value)}
                    />
                  </span>
                </div>

                <div style={{ position: "fixed", right: "78px" }}>
                  <button
                    type="submit"
                    style={{ background: "#E98424", color: "#ffffff", boxSizing: "border-box", position: "relative", borderRadius: "10px", padding: "8px 70px 8px 70px" }}
                    // onClick={() => props.addUpdateCategory(props.categoryId, props.categoryType, props.parent_id, "0")}
                  >
                    Submit
                  </button>
                </div>
                <br />
              </form>
            </div>
          </div>
    </div>
  );
}
