import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import '../../Dashboard.css';
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import '../style/Qc.css';
import "moment-timezone";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Typography } from "@mui/material";
import Moment from 'react-moment';
import LoadingSpinner from "../../components/loader/loader.js";
import { alertDangerConfirmMessage, alertSuccessMessageMerchant } from "../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js";
import ApiService from "../../api/services/ApiService.js";
import { constants } from "../../utils/constants.js";


const SelfieImageCheck = (props) => {
  const [showShopPhoto, setShowshopPhoto] = useState(false);

  const [TicketNoCheck, setTicketNoCheck] = useState("");
  const [errormsgShopboard, seterrormsgShopboard] = useState("");
  const [rightImage, setRightImage] = useState(false);
  const [updatedTimestamp, setupdatedTimestamp] = useState("");
  const [status, setstatus] = useState("");
  const [disable, setdisable] = useState(true);
  const [mobileNo, setMobileNo] = useState("");
  const [agent_id, setAgent_id] = useState("")
  const [licenseQc, setLicenseQc] = useState();
  const [panQc, setPanQc] = useState();
  const [bankQc, setBankQc] = useState();
  const [selfieQc, setSelfieQc] = useState();
  const [selfieImage, setSelfieImage] = useState("")
  const [wrongImage, setWrongImage] = useState("")
  const [isLicenseUpdated, setIsLicenseUpdated] = useState("")
  const [isPanUpdated, setIsPanUpdated] = useState("")
  const [isBankUpdated, setIsBankUpdated] = useState("")
  const [isSelfieUpdated, setIsSelfieUpdated] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const { requestTimedOutError } = constants;

  const handleCloseShopPhoto = () => setShowshopPhoto(false);

  const handleShowShopPhoto = () => setShowshopPhoto(true);

  useEffect(() => {

    setTicketNoCheck(props.data.ticket_no);
    setupdatedTimestamp(props.data.selfie_updated_at || props.data.created_at);
    setstatus(props.data.business_qc);
    setMobileNo(props.data.mobile_no);
    setAgent_id(props.data.agent_id);
    setSelfieImage(props.data.selfie_image)
    setSelfieQc(props.data.selfie_qc)
    setPanQc(props.data.pan_qc)
    setLicenseQc(props.data.license_qc)
    setIsLicenseUpdated(props.data.is_license_updated)
    setIsPanUpdated(props.data.is_pan_updated)
    setIsBankUpdated(props.data.is_bank_updated)
    setIsSelfieUpdated(props.data.is_selfie_updated)
    if (props.data.acc_verify_status === 0) {
      setBankQc(0)
    } else {
      setBankQc(props.data.bank_qc)
    }
    //setRemark(props.data.remark);
  }, [props]);

  const rightSelfieImage = (event) => {
    setSelfieQc(0);
    setdisable(false);
    setRightImage(true);
    setWrongImage(false)
  };

  const wrongSelfieImage = (event) => {
    setWrongImage(true)
    setRightImage(false)
    setSelfieQc(1);
    setdisable(false);
  };

  const shopBoardFinal = async (event) => {
    const token = localStorage.getItem('sessionId');
    const data = {
      "ticket_no": TicketNoCheck,
      "license_qc": licenseQc,
      "pan_qc": panQc,
      "bank_qc": bankQc,
      "selfie_qc": selfieQc,
      "mobile_no": mobileNo,
      "agent_id": agent_id,
      "ticketStatus": isLicenseUpdated == 1 && isPanUpdated == 1 && isBankUpdated == 1 && isSelfieUpdated == 1 ? 2 : 1
    };
    try {
      setIsLoading(true)
      const result = await ApiService.approveRejectSelfie(token, data);
      if (result.code === requestTimedOutError) {
        alertDangerConfirmMessage(result.message)
      } else if (result.data.code === 0) {
        alertSuccessMessageMerchant("Success")
        window.location.reload();
      } else {
        alertDangerConfirmMessage("ERROR")
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="background_validation pb-2">
        <Row>
          <Col className="mt-4 ">
            <div className=" pt-2 header">
              <b className="pt-3 title">Section -4: Verify Selfie  </b>

              <div className="d-inline-block float-right w-25 mt-3 border border-secondary">
                <div className="d-flex flex-row border-bottom border-secondary last_update">
                  <p className="pl-2 mb-0"><b>Last Update</b></p>

                </div>
                <div className="d-flex flex-row mt-2">
                  <p className="pl-2">Status : </p>
                  <p>{props.data.selfie_qc == 0 ? "Match" : props.data.selfie_qc == 1 ? "No match" : "New"}</p>
                </div>
                <div className="d-flex flex-row">
                  <p className="pl-2">Timestamp : </p>
                  <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
                </div>
              </div>
            </div>


          </Col>
        </Row>

        <Row>
          <Col md={3} className="pt-4" style={{ display: 'flex', flexDirection: 'column', }}>
            <Row>
              <div className=" color_border_validation height_cheque ">
                <img className="w-100 h-100  cursor-pointer" src={selfieImage} onClick={handleShowShopPhoto} alt="selfie" />
              </div>
            </Row>
          </Col>
          <Col style={{ border: '0px solid red' }} >
            <Row>
              <Col md={1} className="pt-4 pl-0">
                <div className="image_wrong_div  mx-auto" onClick={rightSelfieImage} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}><img className="image_wrong cursor-pointer" src={right} ></img></div>
                <div className="text-center pt-1"><b>OK</b></div>
              </Col>
              <Col md={1} className="pt-4 pl-0" >
                <div className="image_wrong_div  mx-auto" onClick={wrongSelfieImage} style={{ backgroundColor: wrongImage == true ? "#FD626B" : "" }}><img className="image_wrong cursor-pointer" src={wrong} ></img></div>
                <div className="text-center pt-1"><b>Reject</b></div>
              </Col>
            </Row>
          </Col>
        </Row>

        <br />
        <br />

        <Row>
          <Col className="mt-5" style={{ display: "grid", justifyContent: "end" }}>
            <span>
              <button className="btn" disabled={disable} onClick={shopBoardFinal}>
                Submit
              </button>
            </span>
            <p className="text-danger text-center pt-3">{errormsgShopboard}</p>
          </Col>
        </Row>
        {isLoading && (
          <LoadingSpinner />
        )}

        <Modal show={showShopPhoto} onHide={handleCloseShopPhoto}>
          <Modal.Body>
            <TransformWrapper>
              <TransformComponent>
                <img className="w-100 h-100" src={selfieImage} alt="selfie" />
              </TransformComponent>
            </TransformWrapper>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const Dashboard = withRouter(SelfieImageCheck);
export default Dashboard;
