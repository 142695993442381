import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { Container } from "reactstrap";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Row, Col, Form } from "react-bootstrap";
import EditCategory from "../components/customComponent/EditCategory";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "reactstrap";
import { APIConfig } from "../api/apiConfig/apiConfig";
import CategoryService from "../api/services/CategoryService";
import { useHistory } from "react-router-dom";
import AwsService from "../api/services/AwsImageService";
import Sidebar from "../components/sidebar.js";
import "../Dashboard.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../components/customComponent/ConfirmationMessage/ConfirmationMessage";
import Logo from "../chatty_bao_logo.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import QcPanelService from "../api/services/QcPanelService";
import swal from "sweetalert";
import { ExportToExcel } from "./../components/ExportToExcel";


const SelectStatus = [
  { value: 0, label: "ACTIVE", isCheck: false },
  { value: 1, label: "INACTIVE", isCheck: false },
];

export default function Qcuser() {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm();

  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [role, setRole] = useState("");
  const [agentId, setAgentId] = useState("");
  const [location, setLocation] = useState("");
  const [supervisiorName, setSupervisiorName] = useState("");
  const [supervisiorMobile, setSupervisiorMobile] = useState("");
  const [status, setStatus] = useState("");
  const [userId, setUserId] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [DataForExcel, setDataForExcel] = useState([]);
  const [errorText, setErrorText] = useState('');

  console.log("mobileNumber", mobileNumber);
  console.log("name", name);
  console.log("emailId", emailId);
  console.log("role", role);
  console.log("agentId", agentId);
  console.log("location", location);
  console.log("supervisiorName", supervisiorName);
  console.log("supervisiorMobile", supervisiorMobile);
  console.log("status", status);

  React.useEffect(() => {
    getQcuser();
  }, []);

  const getQcuser = async () => {
    await QcPanelService.getQcuserlist()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                setDataForExcel(result.data);
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const checkMobileNumber = async (e) => {
    if (mobileNumber.trim().length == 0) {
      setErrorText('* This field cannot be empty');
    } else {
      setErrorText('');
    }
    if (mobileNumber.trim().length !== 0) {
      let data = { mobileNo: mobileNumber };
      await QcPanelService.getAgentDetail(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  if (result.data === "Agent not exist") {
                    setUpdateStatus("0");
                    setUserId("");
                    setMobileNumber(mobileNumber);
                    setName("");
                    setEmailId("");
                    setRole("");
                    setAgentId("");
                    setLocation("");
                    setSupervisiorName("");
                    setSupervisiorMobile("");
                    setStatus("");
                    // alert("User not exist");
                    swal({
                      title: "",
                      text: "User not exist",
                      icon: "error",
                      className: "swal-modal-error-small",
                      // timer: 4000,
                    });
                  } else {
                    setUpdateStatus("1");
                    setUserId(result.data.id);
                    setMobileNumber(result.data.mobile_no);
                    setName(result.data.name);
                    setEmailId(result.data.email_id);
                    setRole(result.data.role);
                    setAgentId(result.data.agent_id);
                    setLocation(result.data.location);
                    setSupervisiorName(result.data.supervisior_name);
                    setSupervisiorMobile(result.data.supervisior_mobile);

                    const Status = SelectStatus.find((it) => it.value === result.data.status);
                    setStatus(Status);
                  }
                } else {
                  setUpdateStatus("0");
                  setUserId("");
                  setMobileNumber("");
                  setName("");
                  setEmailId("");
                  setRole("");
                  setAgentId("");
                  setLocation("");
                  setSupervisiorName("");
                  setSupervisiorMobile("");
                  setStatus("");
                }
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            setUpdateStatus("0");
            setUserId("");
            setMobileNumber("");
            setName("");
            setEmailId("");
            setRole("");
            setAgentId("");
            setLocation("");
            setSupervisiorName("");
            setSupervisiorMobile("");
            setStatus("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleMobileNumber = async (e) => {
    setMobileNumber(e);
    setUpdateStatus("");
    setUserId("");
    setName("");
    setEmailId("");
    setRole("");
    setAgentId("");
    setLocation("");
    setSupervisiorName("");
    setSupervisiorMobile("");
    setStatus("");
  };
  const handleName = async (e) => {
    setName(e);
  };
  const handleEmailId = async (e) => {
    setEmailId(e);
  };
  const handleRole = async (e) => {
    setRole(e);
  };
  const handleAgentId = async (e) => {
    setAgentId(e);
  };
  const handleLocation = async (e) => {
    setLocation(e);
  };
  const handleSupervisiorName = async (e) => {
    setSupervisiorName(e);
  };
  const handleSupervisiorMobile = async (e) => {
    setSupervisiorMobile(e);
  };

  const handlesubmit = async () => {
    if (updateStatus === "0") {
      let data = {
        mobile_no: mobileNumber,
        name: name,
        email_id: emailId,
        role: role,
        agentId: agentId,
        location: location,
        supervisior_name: supervisiorName,
        supervisior_mobile: supervisiorMobile,
        status: 0,
      };
      console.log("WithoutID", data);
      await QcPanelService.addAgent(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.message === "Agent id or mobile number already exist") {
                  swal({
                    title: "",
                    text: result.message,
                    icon: "error",
                    className: "swal-modal-error-small",
                    // timer: 4000,
                  });
                } else {
                  if (result.code === 0) {
                    swal({
                      title: "",
                      text: "New qc user added successfully",
                      icon: "success",
                      className: "swal-modal-succuess-small",
                      // timer: 4000,
                    }).then(function () {
                      window.location.reload();
                    });
                  } else {
                    alert(result.message);
                    console.log(result.message);
                  }
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let data = {
        key_id: userId,
        mobile_no: mobileNumber,
        name: name,
        email_id: emailId,
        role: role,
        agentId: agentId,
        location: location,
        supervisior_name: supervisiorName,
        supervisior_mobile: supervisiorMobile,
        status: status.value,
      };
      console.log("WithID", data);
      await QcPanelService.updateAgentDetail(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.message === "agentid or mobile number already exist") {
                  swal({
                    title: "",
                    text: result.message,
                    icon: "error",
                    className: "swal-modal-error-small",
                    // timer: 4000,
                  });
                } else {
                  if (result.code === 0) {
                    swal({
                      title: "",
                      text: "Qc user updated successfully",
                      icon: "success",
                      className: "swal-modal-succuess-small",
                      // timer: 4000,
                    }).then(function () {
                      window.location.reload();
                    });
                  } else {
                    alert(result.message);
                    console.log(result.message);
                  }
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleBlur = () => {
    setErrorText('');
  };

  return (
    <div>
        <div>
          <Sidebar />
        </div>
          <div className="App">
            <h1 className="center">QC User</h1>
            <Container className="center"></Container>
            <div style={{ position: "fixed", right: "78px", margin: "10px 0px 40px 0px" }}>
              <ExportToExcel apiData={DataForExcel} fileName="QC user Listing" />
            </div>
            <div className="" style={{ margin: "65px 80px 10px 80px" }}>
              <form onSubmit={handleSubmit(handlesubmit)}>
                {/* Mobile No */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2%", margin: "4% 0", height: "auto", width: "100%" }}>
                  <span>
                    <TextField
                      className="input_text_fields"
                      error={!!errorText}
                      helperText={errorText ? errorText : ''}
                      onBlur={handleBlur}
                      id="outlined-basic"
                      label="Mobile Number"
                      variant="outlined"
                      type="text"
                      value={mobileNumber}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                      onChange={(event) => handleMobileNumber(event.target.value)}
                    />
                  </span>
                  {/* Name */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      type="text"
                      value={name}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleName(event.target.value)}
                    />
                  </span>

                  {/* Email Id */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Email Id"
                      variant="outlined"
                      type="email"
                      value={emailId}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleEmailId(event.target.value)}
                    />
                  </span>
                </div>

                {/* Role */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2%", margin: "4% 0", height: "auto", width: "100%" }}>
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Role"
                      variant="outlined"
                      type="text"
                      value={role}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleRole(event.target.value)}
                    />
                  </span>

                  {/* Agent Id */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Agent Id"
                      variant="outlined"
                      type="text"
                      value={agentId}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleAgentId(event.target.value)}
                    />
                  </span>

                  {/* Location */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Location"
                      variant="outlined"
                      type="text"
                      value={location}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleLocation(event.target.value)}
                    />
                  </span>
                </div>

                {/* Supervisior Name */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2%", margin: "4% 0", height: "auto", width: "100%" }}>
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Supervisior Name"
                      variant="outlined"
                      type="text"
                      value={supervisiorName}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleSupervisiorName(event.target.value)}
                    />
                  </span>

                  {/* Supervisior Mobile */}
                  <span>
                    <TextField
                      className="input_text_fields"
                      id="outlined-basic"
                      label="Supervisior Mobile"
                      variant="outlined"
                      type="text"
                      value={supervisiorMobile}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                      disabled={updateStatus == "" ? true : false}
                      onChange={(event) => handleSupervisiorMobile(event.target.value)}
                    />
                  </span>

                  {/* Status */}
                  {updateStatus == 1 && (
                    <span>
                      <Autocomplete
                        id="tags-standard"
                        value={status ? status : null}
                        onChange={(event, newValue) => {
                          setStatus(newValue);
                        }}
                        options={SelectStatus}
                        getOptionLabel={(option) => option.label}
                        disabled={updateStatus == "" ? true : false}
                        renderInput={(params) => <TextField {...params} label="Status" required />}
                      />
                    </span>
                  )}
                </div>
                {updateStatus != "" ? (
                  <div style={{ position: "fixed", right: "78px" }}>
                    <button
                      type="submit"
                      style={{ background: "#E98424", color: "#ffffff", boxSizing: "border-box", position: "relative", borderRadius: "10px", margin: "20px 0px 0px 0px", padding: "8px 70px 8px 70px" }}
                    >
                      Submit
                    </button>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </form>
              <br />
              {updateStatus == "" ? (
                <div style={{ position: "fixed", right: "78px" }}>
                  <button
                    style={{ background: "#E98424", color: "#ffffff", boxSizing: "border-box", position: "relative", borderRadius: "10px", padding: "8px 70px 8px 70px" }}
                    onClick={() => checkMobileNumber()}
                  >
                    Check
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
    </div>
  );
}
