import { APIConfig } from '../apiConfig/apiConfig';
import { ApiCallGet, ApiCallPost } from './ApiCall';

const ApiService = {

    getCityByMerchandId: async (token, data) => {
        const { baseUrl, getCityByMerchandId } = APIConfig;
        const url = baseUrl + getCityByMerchandId
        const headers = {
            token,
            timeout: 30000, timeoutErrorMessage: 'Request Timedout'
        }
        return ApiCallPost(url, data, headers)
    },

    getSpecialMerchants: async (token, data) => {
        const { baseUrl, getSpecialMerchants } = APIConfig;
        const url = baseUrl + getSpecialMerchants
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    addUpdateSpecialMerchant: async (token, data) => {
        const { baseUrl, addUpdateSpecialMerchant } = APIConfig;
        const url = baseUrl + addUpdateSpecialMerchant
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    deleteSpecialMerchant: async (token, data) => {
        const { baseUrl, deleteSpecialMerchant } = APIConfig;
        const url = baseUrl + deleteSpecialMerchant
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    updateMerchEligibilityForOndc: async (data) => {
        const { baseUrl, updateMerchEligibilityForOndc } = APIConfig;
        const url = baseUrl + updateMerchEligibilityForOndc
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    ondcAdminLogin: async (data) => {
        const { ondcApiUrl, ondcAdminLogin } = APIConfig;
        const url = ondcApiUrl + ondcAdminLogin
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    ondcOrders: async (data) => {
        const { ondcApiUrl, ondcOrders } = APIConfig;
        const url = ondcApiUrl + ondcOrders
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    ondcIssues: async (data) => {
        const { ondcApiUrl, ondcIssues } = APIConfig;
        const url = ondcApiUrl + ondcIssues
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getOrderDetailsForDeliveryDashboard: async (data) => {
        const { baseUrl, getOrderDetailsForDeliveryDashboard } = APIConfig;
        const url = baseUrl + getOrderDetailsForDeliveryDashboard
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getNearbyAgents: async (data) => {
        const { baseUrl, nearbyDeliveryAgents } = APIConfig;
        const url = baseUrl + nearbyDeliveryAgents
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    arrangeFromAnotherMerchants: async (token, data) => {
        const { baseUrl, arrangeFromAnotherMerchants } = APIConfig;
        const url = baseUrl + arrangeFromAnotherMerchants
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    updatesOrderSummary: async (token, data) => {
        const { baseUrl, updatesOrderSummary } = APIConfig;
        const url = baseUrl + updatesOrderSummary
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    restartRleay: async (token, data) => {
        const { baseUrl, restartRleay } = APIConfig;
        const url = baseUrl + restartRleay
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    getOrderDetailsForOrderHistoryv1: async (token, data) => {
        const { baseUrl, getOrderDetailsForOrderHistoryv1 } = APIConfig;
        const url = baseUrl + getOrderDetailsForOrderHistoryv1
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    getOrdersSummary: async (token, data) => {
        const { baseUrl, getOrdersSummary } = APIConfig;
        const url = baseUrl + getOrdersSummary
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    getUserDeliveryAddress: async (token, data) => {
        const { baseUrl, getUserDeliveryAddress } = APIConfig;
        const url = baseUrl + getUserDeliveryAddress
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    updateRiderStatus: async (token, data) => {
        const { baseUrl, updateRiderStatus } = APIConfig;
        const url = baseUrl + updateRiderStatus
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    getTodaysSummary: async (token, data) => {
        const { baseUrl, getTodaysSummary } = APIConfig;
        const url = baseUrl + getTodaysSummary
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    customRefund: async (data) => {
        const { baseUrl, customRefund } = APIConfig;
        const url = baseUrl + customRefund
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    createPreSignedURL: async (data, token) => {
        const { baseUrl, createPreSignedURL } = APIConfig;
        const url = baseUrl + createPreSignedURL
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },


    approveRejectLicense: async (token, data) => {
        const { baseUrl, approveRejectLicense } = APIConfig;
        const url = baseUrl + approveRejectLicense
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    approveRejectPan: async (token, data) => {
        const { baseUrl, approveRejectPan } = APIConfig;
        const url = baseUrl + approveRejectPan
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    approveRejectBank: async (token, data) => {
        const { baseUrl, approveRejectBank } = APIConfig;
        const url = baseUrl + approveRejectBank
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    approveRejectSelfie: async (token, data) => {
        const { baseUrl, approveRejectSelfie } = APIConfig;
        const url = baseUrl + approveRejectSelfie
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    getRateCardByCity: async (token, data) => {
        const { baseUrl, getRateCardByCity } = APIConfig;
        const url = baseUrl + getRateCardByCity
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    addUpdateForceAppRateCard: async (token, data) => {
        const { baseUrl, addUpdateForceAppRateCard } = APIConfig;
        const url = baseUrl + addUpdateForceAppRateCard
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    logOutRider: async (token, data) => {
        const { baseUrl, logOutRider } = APIConfig;
        const url = baseUrl + logOutRider
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    getLiveOrders: async (data) => {
        const { baseUrl, getLiveOrders } = APIConfig;
        const url = baseUrl + getLiveOrders
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getUpdatedPennyStatus: async (token, data) => {
        const { baseUrl, getUpdatedPennyStatus } = APIConfig;
        const url = baseUrl + getUpdatedPennyStatus
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },

    getAutomationMerchantData: async (data) => {
        const { baseUrl, getAutomationMerchantData } = APIConfig;
        const url = baseUrl + getAutomationMerchantData
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    addCatalogueByMapping: async (data) => {
        const { baseUrl, addCatalogueByMapping } = APIConfig;
        const url = baseUrl + addCatalogueByMapping
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getCatalogStatus: async (data) => {
        const { baseUrl, getCatalogStatus } = APIConfig;
        const url = baseUrl + getCatalogStatus
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getMatchedUnmatchedProducts: async (data) => {
        const { baseUrl, getMatchedUnmatchedProducts } = APIConfig;
        const url = baseUrl + getMatchedUnmatchedProducts
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    updateMatchedProductsToUnmatch: async (data) => {
        const { baseUrl, updateMatchedProductsToUnmatch } = APIConfig;
        const url = baseUrl + updateMatchedProductsToUnmatch
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getCatalogSuggestion: async (data) => {
        const { baseUrl, getCatalogSuggestion } = APIConfig;
        const url = baseUrl + getCatalogSuggestion
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

}
export default ApiService
