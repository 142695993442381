import React, { useEffect, useState, useRef } from "react";
import { Container } from "reactstrap";
import { Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./../components/sidebar.js";
import "../Dashboard.css";
import Channel from "../components/channel/Index";

const Chat = (props) => {
  return (
    <div>
        <div>
          <Sidebar />
        </div>
          <div className="App">
            <h1 className="center">Chat Panel</h1>
            <Container className="center"></Container>
            <div className="" style={{ margin: "10px 80px 10px 80px" }}>
              <Channel />
            </div>
          </div>
    </div>
  );
};

export default Chat;
