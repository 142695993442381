import React, { useState } from 'react'
import { TextField } from "@mui/material";
import './Ondc.css'
import { useLocation } from 'react-router-dom';
import { BeatLoader, ClipLoader } from "react-spinners";
import OndcOrders from './OndcOrders';
import OndcIssues from './OndcIssues';
import ApiService from '../../api/services/ApiService';
import { alertDangerConfirmMessage } from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage';

const OndcLogin = () => {

    const [loggedIn, setLoggedIn] = useState(false);
    const [loader, setLoader] = useState(false);
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const location = useLocation();
    const currentPath = location.pathname;

    const ondcAdminLogin = async () => {
        if (adminEmail === '') {
            alertDangerConfirmMessage("Email cannot be empty")
        } else if (adminPassword === '') {
            alertDangerConfirmMessage("Password cannot be empty")
        } else {
            let data = {
                email: adminEmail,
                password: adminPassword
            }
            setLoader(true);

            try {
                const result = await ApiService.ondcAdminLogin(data);
                if (result.data.code === 0) {
                    setLoggedIn(true)
                    localStorage.setItem("OndcToken", result.data.token)
                } else {
                    alertDangerConfirmMessage("Log In Failed")
                }
            } catch (error) {
                alertDangerConfirmMessage(error.message)
            } finally {
                setLoader(false);
            }
        }
    }

    return (
        <div>
            {loggedIn ? (currentPath === '/ondc-orders' ? (
                <OndcOrders />
            ) : (
                <OndcIssues />
            )) : (
                <div className='login-div'>
                    <div>
                        <p className='ondc-login-text'>Enter E-mail address</p>
                        <input onChange={(e) => setAdminEmail(e.target.value)} className='ondc-input' />
                        <p className='ondc-login-text'>Enter the password</p>
                        <input onChange={(e) => setAdminPassword(e.target.value)} type='password' className='ondc-input' />
                    </div>

                    <button onClick={loader ? null : ondcAdminLogin} className='master-catalog-btn'>
                        {loader ? <ClipLoader className='clip-loader' color="#36d7b7" loading={true} /> : 'Login'}
                    </button>
                </div>
            )}
        </div>

    )
}

export default OndcLogin
