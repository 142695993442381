/**
 * Fetch function uses the fetch api to invoke requests.
 * @param url https/http endpoint
 * @param method PUT,POST,GET etc
 * @param params Data (form-url-encoded/ json)
 * @param {string} screenName From which screen the function is invoked
 * @param {boolean} showResponse If response for req should be shown
 * @returns {result} result
 **/
 export const Fetch = (
    url,
    method,
    headers,
    params,
    screenName = "defaultScreen",
    showResponse = false
  ) => {
    // console.log(
    //   `URL: ${url}\nMethod: ${method}\nHeaders: ${JSON.stringify(
    //     headers
    //   )}\nParams: ${params}\nScreen: ${screenName}`
    // )
    let fetchParams = {
      method: method,
      // credentials: "same-origin",
      headers: headers,
      body: params,
    };
    if (method === "GET") {
      fetchParams = {
        method: method,
        // credentials: "same-origin",
        headers: headers,
      };
    }
    return (
      fetch(url, fetchParams)
        .then((res) => {
          if (showResponse)
            if (res.status != 200 && res.status != 201) {
              //console.log(res)
              const err_msg = `Fetch request failed to get a 200 OK\nErrorStatus: ${true}\nStatus-Code: ${
                res.status
              }\nErrorMessage: ${
                res._bodyInit
              }\nUrl= ${url}\nScreen: ${screenName}`;
              console.log(err_msg);
            }
          return res;
        })
        //network failures, dns lookup failures etc.
        .catch((error) => {
          console.log("We will be back soon.");
          // console.log(`Url: ${url}\nScreen: ${screenName}\nError: ${error}`)
          return { status: 999 };
        })
    );
  };
  