import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar.js";
import './DeliveryDetails.css'
import { BeatLoader, ClipLoader } from 'react-spinners';
import { Dialog } from "@mui/material";
import close_icon from '../../images/cross-icon.png'
import ApiService from '../../api/services/ApiService';
import { alertDangerConfirmMessage, alertSuccessMessageMerchant } from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js';
import { constants } from '../../utils/constants.js';
import moment from "moment-timezone";
import LoadingSpinner from '../../components/loader/loader.js';
import { faRefresh, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from "@mui/material";

const DeliveryDetails = () => {

    const [showDeliveryAction, setShowDeliveryAction] = useState(false);
    const [orders, setOrders] = useState([]);
    // const [todaysSummData, setTodaysSummData] = useState({});
    const [city, setCity] = useState('');
    const [showOrderAction, setShowOrderAction] = useState(false);
    const [orderSummaryData, setOrderSummaryData] = useState([]);
    const [orderSummCopy, setOrderSummCopy] = useState([]);
    const [showOrderMod, setShowOrderMod] = useState(false);
    const [arrangeReason, setArrangeReason] = useState('');
    const [showAnotherMerchant, setShowAnotherMerchant] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [showArrangedView, setShowArrangedView] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastpage] = useState(false);
    const [orderIdforApi, setOrderIdForApi] = useState('');
    const [shipmentStatus, setShipmentStatus] = useState(null);
    const [showRefundBtn, setShowRefundBtn] = useState(false);
    const [refundStatus, setRefundStatus] = useState(null);
    const [arrangedMerchantStatus, setArrangedMerchantStatus] = useState(null);
    const [showCustomRefundDialog, setShowCustomRefundDialog] = useState(false);
    const [noActionDialog, setNoActionDialog] = useState(false);
    const [isOndc, setIsOndc] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [comment, setComment] = useState('');
    const [refAmount, setRefAmount] = useState(null);
    const [parentOrder, setParentOrder] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const { currency, deliveryDetailPageTexts, cities, orderActions, businessTrackingApiMessages, businessTrackingStrings, requestTimedOutError } = constants;

    useEffect(() => {
        if (searchValue === '') {
            fetchData()
        }
    }, [searchValue])

    const getOrdersSummary = async (offset, limit) => {
        const token = localStorage.getItem('sessionId');
        let data = {};
        if (offset === undefined && limit === undefined) {
            data = {
                city: city,
                limit: 10,
                offset: 0,
            };
        } else {
            data = {
                city: city,
                limit: limit,
                offset: offset,
            };
        }

        try {
            const result = await ApiService.getOrdersSummary(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                if (offset === undefined && limit === undefined) {
                    setPage(1);
                }
                setOrders(result.data.data);
                if (result.data.data.length !== 10) {
                    setLastpage(true);
                } else {
                    setLastpage(false);
                }
            } else {
                alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    // const getTodaySummary = async () => {
    //     setIsLoading(true);
    //     const token = localStorage.getItem('sessionId');
    //     let data = {
    //         city: city,
    //     };
    //     try {
    //         const result = await ApiService.getTodaysSummary(token, data);
    //         if (result.data.code === 0) {
    //             setTodaysSummData(result.data.data)
    //         } else {
    //             alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
    //         }
    //     } catch (error) {
    //         setIsLoading(false);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }

    const fetchData = () => {
        getOrdersSummary();
        // getTodaySummary();
    };

    const fetchDataAndSetInterval = () => {
        fetchData();

        // Set up interval to fetch data every 5 minutes
        const newIntervalId = setInterval(fetchData, 5 * 60 * 1000);
        setIntervalId(newIntervalId);
    };

    useEffect(() => {

        fetchDataAndSetInterval();
        return () => {
            clearInterval(intervalId);
        };
    }, [city]);

    const restartRleay = async (orderId) => {
        setLoader(true);
        var data = { orderId: orderIdforApi };
        const token = localStorage.getItem('sessionId');
        try {
            const result = await ApiService.restartRleay(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                alertSuccessMessageMerchant(businessTrackingApiMessages.successfullyUpdated);
                fetchData();
                handleDeliveryOptionClose();
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }

    const getOrderSummary = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('sessionId');
        const data = {
            order_id: orderIdforApi,
        }
        try {
            const result = await ApiService.getUserDeliveryAddress(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setOrderSummaryData(result.data.data.orderSummary)
                setRefundStatus(result.data.data.refund_status)
                setArrangedMerchantStatus(result.data.data.mapp_order_status)
                setOrderSummCopy(result.data.data.orderSummary)
            } else {
                alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const handlePlusMinusBtn = (index, val) => {
        if (val === businessTrackingStrings.add) {
            if (orderSummaryData[index].count === orderSummaryData[index].originalCount) {
                alert(businessTrackingStrings.moreQuantityError)
            } else {
                setShowRefundBtn(true);
                let orderS = [...orderSummaryData]
                orderS[index].count = orderSummaryData[index].count + 1
                if (orderS[index].discounted_rate !== null &&
                    orderS[index].discounted_rate !== undefined &&
                    orderS[index].discounted_rate !== "") {
                    orderS[index].discounted_rate = (orderS[index].count) * orderS[index].discountedRatePerItem;
                }
                orderS[index].rate = (orderS[index].count) * orderS[index].ratePerItem;
                setOrderSummaryData(orderS);
            }

        } else {
            if (orderSummaryData[index].count === 0) {
                alert(businessTrackingStrings.lessQuantityError)
            } else {
                setShowRefundBtn(true);
                let orderS = [...orderSummaryData]
                orderS[index].count = orderSummaryData[index].count - 1
                if (orderS[index].discounted_rate !== null &&
                    orderS[index].discounted_rate !== undefined &&
                    orderS[index].discounted_rate !== "") {
                    orderS[index].discounted_rate = (orderS[index].count) * orderS[index].discountedRatePerItem;
                }
                orderS[index].rate = (orderS[index].count) * orderS[index].ratePerItem;
                setOrderSummaryData(orderS);
            }
        }

    }

    const handleOrderActionBtn = (value) => {
        if (value === '2') {
            setShowOrderMod(true);
            getOrderSummary();
        } else if (value === '1') {
            setShowAnotherMerchant(true);
            getOrderSummary();
        } else if (value === '3') {
            setNoActionDialog(true);
        }
    }

    const arrangeFromAnotherMerchant = async () => {
        setLoader(true);
        const token = localStorage.getItem('sessionId');
        var data = { order_id: orderIdforApi };
        try {
            const result = await ApiService.arrangeFromAnotherMerchants(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setShowAnotherMerchant(false);
                setShowArrangedView(true);
                setArrangeReason('');
                setShowOrderAction(false);
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }

    const updatesOrderSummary = async () => {
        setLoader(true);
        const token = localStorage.getItem('sessionId');
        var data = {
            order_id: orderIdforApi,
            orderSummary: orderSummaryData
        };
        try {
            const result = await ApiService.updatesOrderSummary(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                alertSuccessMessageMerchant(businessTrackingStrings.success);
                setShowOrderMod(false);
                setShowOrderAction(false);
                setShowRefundBtn(false);
                fetchData();
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }

    const convertTime = (originalTime) => {
        const inputMoment = moment(originalTime, 'YYYY-MM-DD HH:mm:ss');
        const formattedTime = inputMoment.format('hh:mm A');
        return formattedTime
    }

    const checkTATforAccepted = (time1Str, time2Str) => {
        const time1 = new Date(time1Str);
        const time2 = new Date(time2Str);

        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(time2 - time1);

        // Check if the time difference is greater than 1 minute
        return timeDifference > 60000;
    };

    const checkTATforAssigned = (time1Str, time2Str) => {
        const time1 = new Date(time1Str);
        const time2 = new Date(time2Str);

        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(time2 - time1);

        // Check if the time difference is greater than 10 minutes
        return timeDifference > 600000;
    };

    const checkTATforPickUp = (time1Str, time2Str) => {
        const time1 = new Date(time1Str);
        const time2 = new Date(time2Str);

        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(time2 - time1);

        // Check if the time difference is greater than 15 minutes
        if (timeDifference > 900000) {
            return true;
        } else {
            return false;
        }
    };

    const checkTATforDelivered = (time1Str, time2Str) => {
        const time1 = new Date(time1Str);
        const time2 = new Date(time2Str);

        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(time2 - time1);

        // Check if the time difference is greater than 10 minutes
        if (timeDifference > 600000) {
            return true;
        } else {
            return false;
        }
    };

    const checkTATforReachedShop = (time1Str, time2Str, fm) => {
        const time1 = new Date(time1Str);
        const time2 = new Date(time2Str);

        const timeDifference = Math.abs(time2 - time1);

        const timeDifference2 = 300000 * Number(fm);

        // Check if the time difference is greater than 5 minutes x first mile distance or 15 minutes whichever is higher
        if (timeDifference > Math.max(timeDifference2, 900000)) {
            return true;
        } else {
            return false;
        }
    }

    const checkTATforReachedDelLocation = (time1Str, time2Str, lm) => {
        const time1 = new Date(time1Str);
        const time2 = new Date(time2Str);

        const timeDifference = Math.abs(time2 - time1);
        const timeDifference2 = 300000 * Number(lm);

        // Check if the time difference is greater than 5 minutes x last mile distance or 15 minutes whichever is higher
        if (timeDifference > Math.max(timeDifference2, 900000)) {
            return true;
        } else {
            return false;
        }
    };

    const handlePageChange = (value) => {
        let pgNo = page;
        let offset;
        if (value === 'next') {
            setPage(pgNo + 1)
            offset = page * 10;
        } else {
            pgNo = pgNo - 1
            offset = ((pgNo * 10) - 10)
            setPage(pgNo);
        }
        let limit = 10;
        console.log("offset:", offset, "limit:", limit)
        getOrdersSummary(offset, limit)
    };

    const handleDeliveryAction = (orderId, shipmentStatus) => {
        setShowDeliveryAction(true);
        setOrderIdForApi(orderId);
        setShipmentStatus(shipmentStatus);
    }

    const updateRiderStatus = async () => {
        setLoader(true);
        const token = localStorage.getItem('sessionId');
        var data = {
            orderId: orderIdforApi,
            orderStatus: deliveryStatus
        };
        setIsLoading(false);
        try {
            const result = await ApiService.updateRiderStatus(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                alertSuccessMessageMerchant(businessTrackingStrings.riderStatusUpdateSuccess);
                handleDeliveryOptionClose();
                fetchData();
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }

    const logOutRider = async () => {
        setLoader(true);
        var data = { orderId: orderIdforApi };
        const token = localStorage.getItem('sessionId');
        try {
            const result = await ApiService.logOutRider(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                await restartRleay();
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const handleDeliveryActionSubmit = () => {
        if (deliveryOption === businessTrackingStrings.deliveryOptions.deliveryChange) {
            updateRiderStatus()
        } else if (deliveryOption === businessTrackingStrings.deliveryOptions.riderChange) {
            logOutRider();
        }
    }

    const handleDeliveryOptionClose = () => {
        setShowDeliveryAction(false);
        setDeliveryStatus(null);
        setDeliveryOption('');
    }

    const handleCityChange = (e) => {
        if (e.target.value === 'View All') {
            setCity('');
        } else {
            setCity(e.target.value)
        }
    }

    const calculateTotalTAT = (startTime, endTime) => {
        const startDateTime = moment(startTime);
        const endDateTime = moment(endTime);

        // Calculate the difference using moment
        const duration = moment.duration(endDateTime.diff(startDateTime));

        // Extract hours, minutes, and seconds from the duration
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        // Format the time gap based on non-zero values
        let timeGap = '';

        if (hours > 0) {
            timeGap += `${hours}h`;
        }

        if (minutes > 0) {
            if (timeGap.length > 0) {
                timeGap += ' ';
            }
            timeGap += `${minutes}m`;
        }

        if (seconds > 0) {
            if (timeGap.length > 0) {
                timeGap += ' ';
            }
            timeGap += `${seconds}s`;
        }

        return timeGap;
    };

    const isSameDate = (dateString) => {

        const inputDate = new Date(dateString);
        inputDate.setHours(0, 0, 0, 0); // Set time to midnight

        // Get the current date
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight

        // Calculate the date two days ago
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(currentDate.getDate() - 2);
        twoDaysAgo.setHours(0, 0, 0, 0); // Set time to midnight

        // Check if the input date is within the past two days
        const isWithinPastTwoDays =
            inputDate >= twoDaysAgo && inputDate <= currentDate;

        return isWithinPastTwoDays;
    };

    const handleOrderActionSelect = (orderId, type, parentOrderId) => {
        setShowOrderAction(true);
        setOrderIdForApi(orderId);
        setParentOrder(parentOrderId);
        if (type === 'ONDC') {
            setIsOndc(true);
        } else {
            setIsOndc(false);
        }
    }

    const handleCustomRefund = async (value) => {
        let data = {};
        if (value === businessTrackingStrings.noAction) {
            data = {
                orderId: orderIdforApi,
                amount: 0,
                comment: comment,
            }
        } else {
            data = {
                orderId: orderIdforApi,
                amount: refAmount,
                comment: comment,
            }
        }

        if (value === businessTrackingStrings.custom) {
            if (refAmount) {
                try {
                    setLoader2(true);
                    const result = await ApiService.customRefund(data);
                    if (result.code === requestTimedOutError) {
                        alertDangerConfirmMessage(result.message)
                    } else if (result.data.code === 0) {
                        alertSuccessMessageMerchant(businessTrackingStrings.refundSuccessful);
                        setShowCustomRefundDialog(false);
                        setShowOrderMod(false);
                        setShowOrderAction(false);
                        setShowRefundBtn(false);
                        setRefAmount(null);
                        setComment('');
                    } else {
                        alertDangerConfirmMessage(result.data.message)
                    }
                } catch (error) {
                    setLoader2(false);
                } finally {
                    setLoader2(false);
                }
            } else {
                alert(businessTrackingStrings.enterValidAmt)
            }
        } else {
            try {
                setLoader2(true);
                const result = await ApiService.customRefund(data);
                if (result.code === requestTimedOutError) {
                    alertDangerConfirmMessage(result.message)
                } else if (result.data.code === 0) {
                    setNoActionDialog(false);
                    setComment('');
                    setShowOrderAction(false);
                } else {
                    alertDangerConfirmMessage(result.data.message)
                }
            } catch (error) {
                setLoader2(false);
            } finally {
                setLoader2(false);
            }
        }


    }

    const handleOrderIdClick = (orderId) => {
        const newTab = window.open('/order-details', '_blank');
        if (newTab) {
            newTab.location.href = `/order-details?orderID=${orderId}`;
        }
    };

    const handleSearch = async () => {
        const token = localStorage.getItem('sessionId');
        const data = {
            orderIdToSearch: searchValue
        }
        try {
            setIsLoading(true);
            const result = await ApiService.getOrdersSummary(token, data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setOrders(result.data.data);
                setLastpage(true);
                setPage(1);
            } else {
                alertDangerConfirmMessage(result.data.message)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className='sidebar'>
                <Sidebar />
            </div>
            <div className='header-refresh-container'>
                <h3 className='delivery-details-header'>Business Tracking</h3>
                <FontAwesomeIcon onClick={() => { clearInterval(intervalId); fetchDataAndSetInterval(); }} className='refresh-icon' icon={faRefresh} />
            </div>
            <div className='city-select-container'>
                <p className='select-city-label'>Select City:</p>
                <select v-model="selected" className="delivery-details-select" onChange={handleCityChange}>
                    {cities.map(data => (
                        <option key={data.id} value={data.value} >
                            {data.value}
                        </option>
                    ))}
                </select>
            </div>
            {/* <div className='today-sumary-container'>
                {isLoading && (
                    <LoadingSpinner />
                )}
                <div className='summary-city-container'>
                    <h6 className='today-summary-header'>Today's Summary</h6>
                    <select v-model="selected" className="delivery-details-select" onChange={handleCityChange}>
                        {cities.map(data => (
                            <option key={data.id} value={data.value} >
                                {data.value}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='status-count-container'>
                    <div>
                        <p>Requested</p>
                        <p className='summary-count'>{todaysSummData.requestCount && todaysSummData.requestCount}</p>
                    </div>
                    <div>
                        <p>Delivered</p>
                        <p className='summary-count delivered-count'>{todaysSummData.deliveryCount && todaysSummData.deliveryCount}</p>
                    </div>
                    <div>
                        <p>On Track</p>
                        <p className='summary-count'>{todaysSummData.onTrackCount && todaysSummData.onTrackCount}</p>
                    </div>
                    <div>
                        <p>Action Pending</p>
                        <p className='summary-count action-pending-count'>{todaysSummData.actionPendingCount && todaysSummData.actionPendingCount}</p>
                    </div>
                    <div>
                        <p>Cancelled</p>
                        <p className='summary-count cancelled-count'>{todaysSummData.cancelCount && todaysSummData.cancelCount}</p>
                    </div>
                </div>
            </div> */}
            <div className='stage-info-search-container'>
                <div className='stage-info-container'>
                    <div className='stage-box-text-container'>
                        <div className='amber-box' />
                        <p>Stage Completed with Delay</p>
                    </div>
                    <div className='stage-box-text-container'>
                        <div className='red-box' />
                        <p>Stage Pending, Action Needed</p>
                    </div>
                </div>
                <div className='search-container'>
                    <TextField type='number' className='vip_history_input' label="Search Order" onChange={(e) => setSearchValue(e.target.value)} />
                    <button disabled={searchValue === null || searchValue === ''} className={searchValue ? 'master-catalog-btn' : 'action-btn-disabled'} onClick={handleSearch}>Search</button>
                </div>
            </div>

            <div>
                {orders.length !== 0 ? (
                    <table className='delivery-table'>
                        <tr className='table-header-row'>
                            <th colSpan="1" className="table-header-cell order-id-cell">Order ID</th>
                            <th colSpan="1" className="table-header-cell">Type</th>
                            <th colSpan="1" className="table-header-cell">Accept Time</th>
                            <th colSpan="1" className="table-header-cell">Request Time</th>
                            <th colSpan="1" className="table-header-cell">Assigned</th>
                            <th colSpan="1" className="table-header-cell">Reached Shop</th>
                            <th colSpan="1" className="table-header-cell">Picked Up</th>
                            <th colSpan="1" className="table-header-cell">Reached Destination</th>
                            <th colSpan="1" className="table-header-cell">Delivered</th>
                            <th colSpan="1" className="table-header-cell">TAT</th>
                            <th colSpan="1" className="table-header-cell">Delivery Status</th>
                            <th colSpan="1" className="table-header-cell">Delivery Action</th>
                            <th colSpan="1" className="table-header-cell">Order Action</th>
                        </tr>
                        <tbody>
                            {Object.keys(orders).length !== 0 && orders.map((data, index) => (
                                <tr className='table-data-row' key={index}>
                                    <td className="table-data-cell first-cell">
                                        {data.parentOrderId ? (
                                            <div>
                                                <p onClick={() => handleOrderIdClick(data.orderId)} className='first-cell-data'>C - {data.parentOrderId}</p>
                                                <p className=''>{data.orderId}</p>
                                            </div>
                                        ) : (
                                            <p onClick={() => handleOrderIdClick(data.orderId)} className='first-cell-data'>{data.orderId}</p>
                                        )}
                                    </td>
                                    <td className="table-data-cell td-value">{data.type}</td>
                                    {data.acceptTime && data.acceptTime !== 'null' ? (
                                        <td className="table-data-cell">
                                            <p className={checkTATforAccepted(data.orderCreatedDate, data.acceptTime) ? 'amber-text' : ''}>
                                                {convertTime(data.acceptTime)}
                                            </p>
                                        </td>
                                    ) : (data.type !== 'Home delivery' && data.shipmentStatus !== 7) ? (
                                        <td className="table-data-cell-for-block">
                                            {checkTATforAccepted(data.orderCreatedDate, Date.now()) ? (
                                                <div className='red-box'>-</div>
                                            ) : ''}
                                        </td>
                                    ) : <td className="table-data-cell"></td>}
                                    <td className="table-data-cell">{data.requestTime && convertTime(data.requestTime)}</td>
                                    {data.assignedTime ? (
                                        <td className="table-data-cell">
                                            <p className={checkTATforAssigned(data.requestTime, data.assignedTime) ? 'amber-text' : ''}>
                                                {convertTime(data.assignedTime)}
                                            </p>
                                        </td>
                                    ) : (data.shipmentStatus === 0 && data.requestTime !== null && data.deliveryMode !== 1) ? (
                                        <td className="table-data-cell-for-block">
                                            {checkTATforAssigned(data.requestTime, Date.now()) ? (
                                                <div className='red-box'>-</div>
                                            ) : ''}
                                        </td>
                                    ) : <td className="table-data-cell"></td>}
                                    {data.reachedShopTime ? (
                                        <td className="table-data-cell">
                                            <p className={checkTATforReachedShop(data.assignedTime, data.reachedShopTime, data.fm) ? 'amber-text' : ''}>
                                                {convertTime(data.reachedShopTime)}
                                            </p>
                                        </td>
                                    ) : data.shipmentStatus === 1 ? (
                                        <td className="table-data-cell-for-block">
                                            {checkTATforReachedShop(data.assignedTime, Date.now(), data.fm) ? (
                                                <div className='red-box'>-</div>
                                            ) : ''}
                                        </td>
                                    ) : <td className="table-data-cell"></td>}
                                    {data.pickupTime ? (
                                        <td className="table-data-cell">
                                            <p className={checkTATforPickUp(data.reachedShopTime, data.pickupTime) ? 'amber-text' : ''}>
                                                {convertTime(data.pickupTime)}
                                            </p>
                                        </td>
                                    ) : data.shipmentStatus === 2 ? (
                                        <td className="table-data-cell-for-block">
                                            {checkTATforPickUp(data.reachedShopTime, Date.now()) ? (
                                                <div className='red-box'>-</div>
                                            ) : ''}
                                        </td>
                                    ) : <td className="table-data-cell"></td>}
                                    {data.reachedDestinationTime ? (
                                        <td className="table-data-cell">
                                            <p className={checkTATforReachedDelLocation(data.pickupTime, data.reachedDestinationTime, data.lm) ? 'amber-text' : ''}>
                                                {convertTime(data.reachedDestinationTime)}
                                            </p>
                                        </td>
                                    ) : data.shipmentStatus === 3 ? (
                                        <td className="table-data-cell-for-block">
                                            {checkTATforReachedDelLocation(data.pickupTime, Date.now(), data.lm) ? (
                                                <div className='red-box'>-</div>
                                            ) : ''}
                                        </td>
                                    ) : <td className="table-data-cell"></td>}
                                    {data.deliveredTime ? (
                                        <td className="table-data-cell">
                                            <p className={checkTATforDelivered(data.reachedDestinationTime, data.deliveredTime) ? 'amber-text' : ''}>
                                                {convertTime(data.deliveredTime)}
                                            </p>
                                        </td>
                                    ) : data.shipmentStatus === 4 ? (
                                        <td className="table-data-cell-for-block">
                                            {checkTATforDelivered(data.reachedDestinationTime, Date.now()) ? (
                                                <div className='red-box'>-</div>
                                            ) : ''}
                                        </td>
                                    ) : <td className="table-data-cell"></td>}
                                    <td className="table-data-cell">
                                        {data.shipmentStatus === 5 && (
                                            <p>{calculateTotalTAT(data.requestTime, data.deliveredTime)}</p>
                                        )}
                                    </td>
                                    <td className="status-cell table-data-cell">{data.shipmentStatus === 1 ? deliveryDetailPageTexts.assigned : data.shipmentStatus === 2 ? deliveryDetailPageTexts.arrived :
                                        data.shipmentStatus === 3 ? deliveryDetailPageTexts.pickedUp : data.shipmentStatus === 4 ? deliveryDetailPageTexts.reached : data.shipmentStatus === 5 ? deliveryDetailPageTexts.delivered :
                                            data.shipmentStatus === 6 ? deliveryDetailPageTexts.rejected : data.shipmentStatus === 7 ? deliveryDetailPageTexts.cancelled :
                                                data.orderRejectedDate != null ? deliveryDetailPageTexts.rejected :
                                                    data.requestTime == null ? deliveryDetailPageTexts.deliveryNotSelected :
                                                    (data.requestTime != null && data.assignedTime == null && data.deliveryMode != 1) ? deliveryDetailPageTexts.lookingForDeliveryPartner :
                                                        (data.requestTime != null && data.deliveryMode != 1 && data.relayCount > 0) ? deliveryDetailPageTexts.noDeliveryPartnerFound :
                                                                data.requestTime !== null ? deliveryDetailPageTexts.requested : ''}</td>
                                    <td className="table-data-cell">
                                        <button disabled={data.shipmentStatus === 5} className={data.shipmentStatus === 5 ? 'action-btn-disabled' : 'master-catalog-btn'} onClick={() => handleDeliveryAction(data.orderId, data.shipmentStatus)}>Select</button>
                                    </td>
                                    <td className="table-data-cell last-cell">
                                        <button disabled={(data.type === 'Chat' || data.type === 'Home delivery') || !isSameDate(data.orderCreatedDate)} onClick={() => handleOrderActionSelect(data.orderId, data.type, data.parentOrderId)} className={((data.type === 'Chat' || data.type === 'Home delivery') || !isSameDate(data.orderCreatedDate)) ? 'action-btn-disabled' : 'master-catalog-btn'}>Select</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <h6 className='no-data-text'>No data available</h6>
                )}

            </div>

            {/* Delivery Action Pop up */}

            <Dialog onClose={handleDeliveryOptionClose} open={showDeliveryAction}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={handleDeliveryOptionClose}
                    alt="close"
                />
                <h6 className='action-header'>Select Delivery Option</h6>
                <div className="delivery-option-dialog">
                    {!false ? (
                        <div className="order-summ-modal">
                            <div onClick={() => setDeliveryOption('deliveryChange')} className='radio-container'>
                                <input
                                    className='radio-input'
                                    type='radio'
                                    value="deliveryChange"
                                    checked={deliveryOption === 'deliveryChange'}
                                />
                                <p>Change Delivery Status</p>
                            </div>
                            {deliveryOption === 'deliveryChange' && (
                                <div className='intented-data'>
                                    <div onClick={shipmentStatus > 1 || shipmentStatus === 0 ? null : () => setDeliveryStatus(2)} className='radio-container'>
                                        <input disabled={shipmentStatus > 1 || shipmentStatus === 0} checked={deliveryStatus === 2} type='checkbox' className='checkbox-input' />
                                        <p>Reached at pickup location</p>
                                    </div>
                                    <div onClick={shipmentStatus > 2 || shipmentStatus === 0 ? null : () => setDeliveryStatus(3)} className='radio-container'>
                                        <input disabled={shipmentStatus > 2 || shipmentStatus === 0} checked={deliveryStatus === 3} type='checkbox' className='checkbox-input' />
                                        <p>Pick up</p>
                                    </div>
                                    <div onClick={shipmentStatus > 3 || shipmentStatus === 0 ? null : () => setDeliveryStatus(4)} className='radio-container'>
                                        <input disabled={shipmentStatus > 3 || shipmentStatus === 0} checked={deliveryStatus === 4} type='checkbox' className='checkbox-input' />
                                        <p>Reached at the destination</p>
                                    </div>
                                    <div onClick={shipmentStatus > 4 || shipmentStatus === 0 ? null : () => setDeliveryStatus(5)} className='radio-container'>
                                        <input disabled={shipmentStatus > 4 || shipmentStatus === 0} checked={deliveryStatus === 5} type='checkbox' className='checkbox-input' />
                                        <p>Delivered</p>
                                    </div>
                                    <div onClick={shipmentStatus > 4 ? null : () => setDeliveryStatus(6)} className='radio-container'>
                                        <input disabled={shipmentStatus > 4} checked={deliveryStatus === 6} type='checkbox' className='checkbox-input' />
                                        <p>Cancelled</p>
                                    </div>
                                </div>
                            )}
                            <div onClick={() => setDeliveryOption('riderChange')} className='radio-container'>
                                <input
                                    className='radio-input'
                                    type='radio'
                                    value="riderChange"
                                    checked={deliveryOption === 'riderChange'}
                                />
                                <p>Change Rider</p>
                            </div>
                            {((deliveryOption !== '' && deliveryOption === 'riderChange') || (deliveryOption === 'deliveryChange' && deliveryStatus !== null)) && (
                                <button onClick={loader ? null : handleDeliveryActionSubmit} className={deliveryOption === 'riderChange' && deliveryStatus === null ? 'delivery-option-submit-btn' : 'delivery-option-submit-btn'}>{loader ? <ClipLoader className='clip-loader' color="#36d7b7" loading={true} /> : 'Submit'}</button>
                            )}
                        </div>
                    ) : (
                        <div className="beat-loader-container">
                            <BeatLoader color="#36d7b7" loading={true} />
                        </div>
                    )}

                </div>

            </Dialog>

            {/* Order Action Pop up */}

            <Dialog onClose={() => setShowOrderAction(false)} open={showOrderAction}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => setShowOrderAction(false)}
                    alt="close"
                />
                <h6 className='action-header'>Order Action</h6>
                <div className="delivery-option-dialog">
                    {!isLoading ? (
                        <div>
                            <select v-model="selected" className="delivery-details-select" onChange={(e) => handleOrderActionBtn(e.target.value)}>
                                {orderActions.map(data => {
                                    if (data.id === 3) {
                                        if (isOndc) {
                                            return (
                                                <option key={data.id} value={data.id} >
                                                    {data.value}
                                                </option>
                                            )
                                        }
                                    } else {
                                        return (
                                            <option hidden={data.id === 0} key={data.id} value={data.id} >
                                                {data.value}
                                            </option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                    ) : (
                        <div className="beat-loader-container">
                            <BeatLoader color="#36d7b7" loading={true} />
                        </div>
                    )}

                </div>

            </Dialog>

            {/* Refund Customer Pop up */}

            <Dialog onClose={() => { setShowOrderMod(false); setShowRefundBtn(false); }} open={showOrderMod}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => { setShowOrderMod(false); setShowRefundBtn(false); }}
                    alt="close"
                />
                <div className='order-mod-dialog'>
                    {!isLoading ? (
                        <>
                            {orderSummaryData && orderSummaryData.length !== 0 && (

                                <table className='delivery-table'>
                                    <h6 className='order-det-header'>Order Details ({orderSummaryData.length} Items)</h6>
                                    <tbody>
                                        {orderSummaryData.length !== 0 && orderSummaryData.map((data, index) => (
                                            <tr key={index}>
                                                <td colSpan={2} className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    <div className='pdname-image-container'>
                                                        <img src={data.image_url[0]} className='order-mod-img' alt='NA' />
                                                        <div>
                                                            <p className='order-item-name'>{data.item_name}</p>
                                                            <p className='req-qty-text'>Req Qty x{data.originalCount}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    <div className='mod-btn-container'>
                                                        <div className="plus-minus-container">
                                                            <p className="plus_minus_text_grid" onClick={() => handlePlusMinusBtn(index, 'Decrease')}>-</p>
                                                            <p className="product_count">{data.count}</p>
                                                            <p className="plus_minus_text_grid" onClick={() => handlePlusMinusBtn(index, 'Add')}>+</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    {(data.discounted_rate && data.discounted_rate !== "0") ? (
                                                        <div className="price-container-view">
                                                            <p className="cutout-price">{currency}{data.rate}</p>
                                                            <p>{currency}{data.discounted_rate}</p>
                                                        </div>
                                                    ) : (
                                                        <div className="price-container-view">
                                                            <p>{currency}{data.rate}</p>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <div className='refund-buttons-container'>
                                <button
                                    disabled={!showRefundBtn}
                                    className={showRefundBtn ? 'delivery-option-submit-btn' : 'delivery-option-submit-btn-disabled'}
                                    onClick={loader ? null : updatesOrderSummary}
                                >
                                    {(loader ? <ClipLoader className='clip-loader' color="#36d7b7" loading={true} /> : 'Save Changes & Refund Customer')}
                                </button>
                                <h6 onClick={() => setShowCustomRefundDialog(true)} className={true ? 'custom-refund-text' : 'custom-refund-text-disabled'}>Custom Refund?</h6>
                            </div>
                        </>
                    ) : (
                        <div className="beat-loader-container">
                            <BeatLoader color="#36d7b7" loading={true} />
                        </div>
                    )}

                </div>
            </Dialog>

            {/* Arrange from another merchant Pop up */}

            <Dialog onClose={() => { setShowAnotherMerchant(false); setArrangeReason(''); }} open={showAnotherMerchant}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => { setShowAnotherMerchant(false); setArrangeReason(''); }}
                    alt="close"
                />
                <div className="delivery-option-dialog">
                    {!isLoading ? (
                        <>
                            <div className='sticky-header'>
                                <h6 className='arrange-from-header'>Arrange from Another Merchant</h6>
                                {arrangedMerchantStatus !== 1 && (
                                    <>
                                        <h6>Select Reason</h6>
                                        <div className='two-btn-container'>
                                            <div onClick={() => setArrangeReason('shopClosed')} className='radio-container'>
                                                <input
                                                    className='radio-input'
                                                    type='radio'
                                                    value="deliveryChange"
                                                    checked={arrangeReason === 'shopClosed'}
                                                />
                                                <p>Shop Closed / Merchant Denied</p>
                                            </div>
                                            <div onClick={() => setArrangeReason('noItem')} className='radio-container'>
                                                <input
                                                    className='radio-input'
                                                    type='radio'
                                                    value="deliveryChange"
                                                    checked={arrangeReason === 'noItem'}
                                                />
                                                <p>No item available</p>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>

                            <div>
                                <table className='delivery-table'>
                                    {orderSummCopy.length !== 0 && (
                                        <h6 className='order-det-header'>Order Details ({orderSummCopy.length} Items)</h6>
                                    )}
                                    <tbody>
                                        {orderSummCopy.length !== 0 && orderSummCopy.map((data, index) => (
                                            <tr key={index}>
                                                <td colSpan={2} className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    <div className='pdname-image-container'>
                                                        <img src={data.image_url[0]} className='order-mod-img' alt='NA' />
                                                        <div>
                                                            <p className='order-item-name'>{data.item_name}</p>
                                                            <p className='req-qty-text'>Req Qty x{data.originalCount}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    <p className='order-summ-count'>x {data.count}</p>
                                                </td>
                                                <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    {(data.discounted_rate && data.discounted_rate !== "0") ? (
                                                        <div className="price-container-view">
                                                            <p className="cutout-price">{currency}{data.rate}</p>
                                                            <p>{currency}{data.discounted_rate}</p>
                                                        </div>
                                                    ) : (
                                                        <div className="price-container-view">
                                                            <p>{currency}{data.rate}</p>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <button onClick={loader ? null : arrangeFromAnotherMerchant} disabled={arrangeReason === '' || arrangedMerchantStatus === 1 || refundStatus !== 0 || parentOrder !== null} className={(arrangeReason !== '' && arrangedMerchantStatus !== 1 && refundStatus === 0 && (parentOrder === null || parentOrder === undefined)) ? 'delivery-option-submit-btn sticky-btn' : 'delivery-option-submit-btn btn-disabled sticky-btn'}>{loader ? <ClipLoader className='clip-loader' color="#36d7b7" loading={true} /> : arrangedMerchantStatus === 1 ? 'Already arranged' : 'Arrange from Another Merchant'}</button>
                        </>
                    ) : (
                        <div className="beat-loader-container">
                            <BeatLoader color="#36d7b7" loading={true} />
                        </div>
                    )}

                </div>

            </Dialog>

            {/* Arrange from another merchant summary pop up */}

            <Dialog onClose={() => setShowArrangedView(false)} open={showArrangedView}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => setShowArrangedView(false)}
                    alt="close"
                />
                <div className="delivery-option-dialog">
                    {!isLoading ? (
                        <div>
                            <h6 className='arrange-view-header'>Call Rider and Arrange below Products</h6>
                            <div>
                                <table className='delivery-table'>
                                    {orderSummCopy.length !== 0 && (
                                        <h6 className='order-det-header'>Order Details ({orderSummCopy.length} Items)</h6>
                                    )}
                                    <tbody>
                                        {orderSummCopy.length !== 0 && orderSummCopy.map((data, index) => (
                                            <tr key={index}>
                                                <td colSpan={2} className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    <div className='pdname-image-container'>
                                                        <img src={data.image_url[0]} className='order-mod-img' alt='NA' />
                                                        <div>
                                                            <p className='order-item-name'>{data.item_name}</p>
                                                            <p className='req-qty-text'>Req Qty x{data.originalCount}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    <p className='order-summ-count'>x {data.count}</p>
                                                </td>
                                                <td className={index === 0 ? "order-summ-table-data-cell" : "order-summ-table-data-cell border-top-border"}>
                                                    {(data.discounted_rate && data.discounted_rate !== "0") ? (
                                                        <div className="price-container-view">
                                                            <p className="cutout-price">{currency}{data.rate}</p>
                                                            <p>{currency}{data.discounted_rate}</p>
                                                        </div>
                                                    ) : (
                                                        <div className="price-container-view">
                                                            <p>{currency}{data.rate}</p>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="beat-loader-container">
                            <BeatLoader color="#36d7b7" loading={true} />
                        </div>
                    )}

                </div>

            </Dialog>

            {/* Custom Refund Dialog */}

            <Dialog onClose={() => { setShowCustomRefundDialog(false); setRefAmount(null); setComment(''); }} open={showCustomRefundDialog}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => { setShowCustomRefundDialog(false); setRefAmount(null); setComment(''); }}
                    alt="close"
                />
                <div className="delivery-option-dialog">
                    <h5 className='custom-refund-header'>Custom Refund</h5>
                    <div className='custom-inputs-container'>
                        <TextField value={refAmount} type="number" className='delivery-details-amount-input' label="Enter Amount" onChange={(e) => setRefAmount(e.target.value)} />
                        <TextField value={comment} className='delivery-details-comment-input' label="Enter Comment" onChange={(e) => setComment(e.target.value)} />
                    </div>
                    <button onClick={loader2 ? null : () => handleCustomRefund(businessTrackingStrings.custom)} className='delivery-option-submit-btn'>{loader2 ? <ClipLoader className='clip-loader' color="#36d7b7" loading={true} /> : 'Refund Customer'}</button>
                </div>

            </Dialog>

            {/* No Action Dialog */}

            <Dialog onClose={() => setNoActionDialog(false)} open={noActionDialog}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => setNoActionDialog(false)}
                    alt="close"
                />
                <div className="delivery-option-dialog">
                    <h5 className='no-action-header'>No Action</h5>
                    <TextField value={comment} className='no-action-text-box' label="Enter Comment" onChange={(e) => setComment(e.target.value)} />
                    <button onClick={loader2 ? null : () => handleCustomRefund(businessTrackingStrings.noAction)} className='delivery-option-submit-btn'>{loader2 ? <ClipLoader className='clip-loader' color="#36d7b7" loading={true} /> : 'Submit'}</button>
                </div>

            </Dialog>

            <div className='pagination-div'>
                <div onClick={page === 1 ? null : () => handlePageChange('prev')} className='next-prev-container'>
                    <FontAwesomeIcon className={page === 1 ? 'next-prev-icon-disabled' : 'next-prev-icon'} icon={faChevronLeft} />
                    <p className={page === 1 ? 'next-prev-text-disabled' : ''}>Prev</p>
                </div>
                <p>Page {page}</p>
                <div onClick={lastPage ? null : () => handlePageChange('next')} className='next-prev-container'>
                    <p className={lastPage ? 'next-prev-text-disabled' : ''}>Next</p>
                    <FontAwesomeIcon className={lastPage ? 'next-prev-icon-disabled' : 'next-prev-icon'} icon={faChevronRight} />
                </div>
            </div>
        </div>
    )
}

export default DeliveryDetails
