import { APIConfig } from "../api/apiConfig/apiConfig";

const { sendbirdBaseUrl } = APIConfig;
export const sendBirdGroupChannelUrl = (url) => `${sendbirdBaseUrl}/group_channels/${url}/messages`;

const constants = {
    distance: 'km',
    currency: '₹',
    defaultCity: 'Lucknow',
    dataUploadMessages: {
        uploadSuccessMsg: 'Data Upload Success',
        uploadFailMsg: 'Data Upload Failed',
        invalidFormatMsg: 'Invalid file format. Please upload a CSV or Excel file.'
    },
    orderCancelMessages: {
        success: 'Order cancelled',
        fail: 'Failed'
    },
    discountPriceErrorMsg: 'Discounted price cannot be higher than or equal to MRP',
    fullscreenImgCss: {
        borderRadius: '1.25rem'
    },
    sfyTexts: {
        descriptionValidation: 'Description text cannot be empty',
        editSuccessText: 'Successful',
        editFailText: 'Failed',
        addSuccessText: 'Merchant Added',
        addFailText: 'Failed',
        deleteSuccessText: 'Delete Success',
        deleteFailText: 'Failed',
        merchantNotExist: 'Merchant does not exist',
        merchantExistsText: 'Merchant already Exists',
        merchantExistText: 'Merchant already Exist'
    },
    cashbackMessages: {
        cashbackLimitErr: 'Please enter an amount less than or equal to 1000',
        cashbackSuccess: 'Cashback Success',
        cashbackFailed: 'Cashback Failed'
    },
    deliveryDetailPageTexts: {
        assigned: "Assigned",
        arrived: "Arrived",
        pickedUp: "Picked Up",
        reached: "Reached",
        rejected: "Rejected",
        cancelled: "Cancelled",
        requested: "Requested",
        forceApp: "ForceApp",
        porter: 'Porter',
        loadShare: "Loadshare",
        delivered: 'Delivered',
        deliveryNotSelected:'Waiting for merchant to select delivery',
        lookingForDeliveryPartner:'Looking for Delivery Partner',
        noDeliveryPartnerFound :'No Delivery Partner Found'

    },
    groceryL3Id: '8650b2d0-737b-11ec-9806-93163a488dc9',
    cashbackTnCData: [
        'Flat ₹50 cashback on first 5 orders',
        'Minimum order value shall be ₹100',
        'No coupon code is required',
        'Cashback will be eligible on the 1st order from every shop',
        'Maximum 2 cashbacks can be claimed in a day, and 5 times overall',
        'Cashback will be credited within 24 hours, directly in the bank account',
        'Cashback is applicable on orders delivered by ChattyBao',
        'Offer valid till 31 Mar 2024',
        'Other T&Cs may apply'
    ],
    cashbackTnCHeader: 'ChattyBao Welcome Cashback Offer',
    cashbackTnCsubHeader: 'Terms and conditions :',
    cities : [
        { id: 0, value: 'View All' },
        { id: 1, value: "Lucknow" },
        { id: 2, value: "Noida" },
    ],
    catalogSelect : [
        { id: 0, value: 'Select Option' },
        { id: 1, value: "Matched" },
        { id: 2, value: "Not matched" },
    ],
    orderActions : [
        { id: 0, value: 'Select Order Action' },
        { id: 1, value: "Arrange from another merchant" },
        { id: 2, value: "Refund Customer" },
        { id: 3, value: "No Action" }
    ],
    businessTrackingApiMessages: {
        dataFetchError: 'Error fetching data',
        successfullyUpdated: 'Successfully Updated'
    },
    businessTrackingStrings: {
        add: 'Add',
        moreQuantityError: 'Modified quantity cannot be more than ordered quantity',
        lessQuantityError: 'Modified quantity cannot be less than 0',
        success: 'Success',
        riderStatusUpdateSuccess: 'Successfully updated rider status',
        deliveryOptions: {
            deliveryChange: 'deliveryChange',
            riderChange: 'riderChange'
        },
        noAction: 'No Action',
        custom: 'Custom',
        refundSuccessful: 'Refund Successful',
        enterValidAmt: 'Enter a valid amount',

    },
    ondcApiErrorMsg: 'No orders found for selected period',
    bucketNames: {
        pdf: 'menu_pdf',
        masterCatalog: 'catalog_panel',
        merchantEdit: 'category_panel',
        csvBucketName: 'catalog_csv'
    },
    awsfileNames: {
        pdf: 'panelpdf_',
        masterCatalog: 'panelmaster_',
        merchantEdit: 'panelupload_',
    },
    apiFileType: {
        image: 'jpeg',
        pdf: 'pdf',
        csv: 'csv'
    },
    awsUploadFileType: {
        image: 'image/jpeg',
        pdf: 'application/pdf',
        csv: 'text/csv'
    },
    apiRequestTimeOut: 'Request timed out',
    riderQcChequeErrorMsgs: {
        accountName: 'Enter Account name',
        bankName: 'Enter Bank name',
        accountNumber: 'Enter Account Number',
        reAccountNumber: 'Re-enter Account Number',
        accountNumberMatch: "Entered account numbers doesn't match",
        ifscCode: 'Enter IFSC code',
    },
    requestTimedOutError: 'ECONNABORTED',
    riderIncentiveCities: [
        { id: 0, value: 'Select City' },
        { id: 1, value: "Lucknow" },
        { id: 2, value: "Noida" },
    ]
}

export { constants };