import React, { useEffect, useState } from "react";

import { Container, Row, Col, Card, Form, Button, input } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../components/sidebar.js";
import '../Dashboard.css';
import axios from 'axios';
import right from "../images/check.png";
import wrong from "../images/close.png";
import ask from "../images/ask.png";
import TicketDetails from "./ticket.js";
import Shopboardvalidation from "./components/shopboardvalidation.js";
import Chequevalidation from "./components/chequevalidation.js";
import Matchbusinessname from "./components/matchbusinessname.js";
import BusinessProof from "./components/businessproof.js";
import ShopImagecheck from "./components/shopimagecheck";
import MenuValidation from "./components/menuValidation";

import Qcheader from "./components/Qcheader";




const Dash = props => {
  console.log(props.match.params.ticket);

  const [id, setId] = useState(props.match.params.id);
  const [ticket, setTicket] = useState(props.match.params.ticket);
  const [data, setData] = useState([]);
  const [Category_Pharm, setCategory_Pharm] = useState("");
  const [Category_Res,setCategory_Res]=useState("");
  const [accverifystatus, setAccverifystatus] = useState("");
  const [Remark, setRemark] = useState("");
  const [business_qc, setBusiness_qc] = useState();
  const [license_qc, setLicense_qc] = useState();
  const [menu_pdf, setMenu_pdf] = useState('');
  const [catalogphotos, setCatalogphotos] = useState('')

  const [photo_qc, setPhoto_qc] = useState();
  const [bank_qc, setBank_qc] = useState();
  const [l1chategory, setL1chategory] = useState("Fixed Shop");


  const Pharmacy_id = "4c9aae60-737b-11ec-9806-93163a488dc9";
  const Pharmacy_id1="4c9aae60-737b-11ec-9806-93163a488dc9";
  const Resturant_id = "8e827a10-737b-11ec-9806-93163a488dc9";
  const CakeAndBakery_id = "c205e5d0-737f-11ec-9806-93163a488dc9";
  const SweetShop_id = "57991b70-7381-11ec-9806-93163a488dc9";
  const DairyAndMilkProducts_id = "b6541c60-737b-11ec-9806-93163a488dc9";
  const DryFruitsAndChoclates_id = "c50ae540-737b-11ec-9806-93163a488dc9";
  const MeatAndNonVeg_id = "3c6935d0-7c62-11ec-90de-8dac060fa840";
  const NutritionSupliments_id = "1ebfc1c0-7389-11ec-9806-93163a488dc9";
  const SpecialityFood_id = "d3c44510-7c61-11ec-90de-8dac060fa840";

  React.useEffect(() => {
    axios.get(window.baseUrl + 'getTicketList?merchant_id=' + id + '&ticket_no=' + ticket)
      .then((response) => {
        var res = response.data.data;
        console.log(res);
        setCategory_Pharm(response.data.data.categoryl2);
        setCategory_Res(response.data.data.categoryl3);
        setL1chategory(response.data.data.l1_categoryname);
        setAccverifystatus(response.data.data.accverifystatus);
        setRemark(response.data.data.remark);
        setBusiness_qc(response.data.data.business_qc);
        setPhoto_qc(response.data.data.photo_qc);
        setBank_qc(response.data.data.bank_qc);
        setLicense_qc(response.data.data.license_qc);
        setMenu_pdf(response.data.data.menu_pdf)
        setCatalogphotos(response.data.data.catalogphotos)



        console.log(Category_Pharm);
        setData(res);

        //setChild_mob_1(res.data.data.teammobilenumbers);
      });
  }, []);
  const right_image = (event) => {
    event.preventDefault();
    console.log("hello");
  }
  const qcRemark = (event) => {
    setRemark(event.target.value);
  }

  return (
    <>
      <div>
          <div className='sidebar'>
            <Sidebar />
          </div>
            <div id="container" className="qcpanelclass h-auto">
              {/* <TicketDetails handler = {qaRemark}  data={data}></TicketDetails>  */}
              
              {/* header */}
              <Qcheader data={data} qcRemark={qcRemark} Remark={Remark}/>
             

              {/* photo_qc */}
              {data.is_sec1_updated === 0 && data.length != 0 ? <ShopImagecheck data={data} Remark={Remark} ></ShopImagecheck> : ''}
              
              {/* business_qc */}
              {data.is_sec2_updated === 0 && (data.length != 0) && (photo_qc == 0) ? <Shopboardvalidation Remark={Remark} data={data}></Shopboardvalidation> : ''}

              {/* bank_qc (== Fixed Shop) */}
              {data.is_sec3_updated === 0 && (l1chategory == 'Fixed Shop') && (data.length != 0) && (photo_qc == 0) && (business_qc != 2) ? (accverifystatus != 0) ? <Chequevalidation Remark={Remark} data={data}></Chequevalidation> : bank_qc != 2 ? <Chequevalidation Remark={Remark} data={data}></Chequevalidation> : (bank_qc != 0 && data.ticket_type!=0)?  <Chequevalidation Remark={Remark} data={data}></Chequevalidation>:'':''}

              {/* bank_qc (!= Fixed Shop) */}
              {data.is_sec3_updated === 0 && (l1chategory != 'Fixed Shop') && (data.length != 0) && (photo_qc == 0) && (business_qc != 2) ? (accverifystatus != 0) ? <Chequevalidation Remark={Remark} data={data}></Chequevalidation> : bank_qc != 2 ? <Chequevalidation Remark={Remark} data={data}></Chequevalidation>: (bank_qc != 0 && data.ticket_type!=0)?  <Chequevalidation Remark={Remark} data={data}></Chequevalidation> : '':''}

               {/* Matchbusinessname */}
               {data.is_sec4_updated === 0 && (l1chategory == 'Fixed Shop') && (data.length != 0)   && ((Category_Pharm == Pharmacy_id1)||(Category_Pharm == Pharmacy_id) || (Category_Res == Resturant_id) || (Category_Res == CakeAndBakery_id) || (Category_Res == SweetShop_id) || (Category_Res == DairyAndMilkProducts_id) || (Category_Res == DryFruitsAndChoclates_id) || (Category_Res == MeatAndNonVeg_id) || (Category_Res == NutritionSupliments_id) || (Category_Res == NutritionSupliments_id) || (Category_Res == SpecialityFood_id)) ?(business_qc != 2 && accverifystatus == 0)&& (photo_qc == 0)? <Matchbusinessname Remark={Remark} data={data}></Matchbusinessname> :(data.ticket_type!=0 && accverifystatus != 0 )?<Matchbusinessname Remark={Remark} data={data}></Matchbusinessname>:'':''}

              {/* Bill Collection (BusinessProof)*/}
              {data.is_sec4_updated === 0 && ((l1chategory == 'Bill Collection')||(l1chategory == 'Fixed Shop')) && (data.length != 0)  && ((Category_Pharm != Pharmacy_id1)&&(Category_Pharm != Pharmacy_id) && (Category_Res != Resturant_id) && (Category_Res != CakeAndBakery_id) && (Category_Res != SweetShop_id) && (Category_Res != DairyAndMilkProducts_id) && (Category_Res != DryFruitsAndChoclates_id) && (Category_Res != MeatAndNonVeg_id) && (Category_Res != NutritionSupliments_id) && (Category_Res != SpecialityFood_id))?((business_qc != 2) && (accverifystatus == 0)&& (photo_qc == 0))?  <BusinessProof Remark={Remark} data={data}></BusinessProof> :(data.ticket_type!=0 && accverifystatus != 0 )?<BusinessProof Remark={Remark} data={data}></BusinessProof>: '':''}
              
              {/* menu card validation */}
              {data.is_sec5_updated === 0 &&((Category_Res == CakeAndBakery_id)|| (Category_Res == SweetShop_id))&& (data.length != 0) && (photo_qc == 0) && (business_qc != 2) &&(license_qc==0) ? <MenuValidation data={data} Remark={Remark}></MenuValidation> : data.is_sec5_updated === 0 && (Category_Res == Resturant_id) && (data.length != 0) && (photo_qc == 0) && (business_qc != 2) &&(license_qc==0) && (accverifystatus == 0)  ?<MenuValidation data={data} Remark={Remark}></MenuValidation> :(data.ticket_type!=0 && accverifystatus != 0 ) &&(license_qc==0) && ((Category_Res == Resturant_id) ||(Category_Res == CakeAndBakery_id)|| (Category_Res == SweetShop_id))?<MenuValidation data={data} Remark={Remark}></MenuValidation> :''}
              {console.log("data.ticketype=========>", data.ticket_type)}
              {console.log("accverifystatus=========>", accverifystatus)}
              {console.log("license_qc=========>", license_qc)}

            </div>
      </div>
    </>
  );
};
const Dashboard = withRouter(Dash);
export default Dashboard