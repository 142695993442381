import React, { useCallback, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import './FileUploader.css'
import close_icon from "../../../payout/close.png";
import delete_icon from '../../../images/delete.png'
import edit_icon from '../../../images/edit.png'

import {
    Dialog,
} from "@mui/material";
import AwsImageService from "../../../api/services/AwsImageService";

const FileUploaderMasCatalog = (props) => {
    const [openModal, setOpenModal] = useState(false);
    // const [indexForEdit, setIndexForEdit] = useState(null);
    const fileInputRef = useRef(null);

    const handleNewImage = async (item) => {
        console.log("Executed", item);
        try {
            const imgData = await Promise.all(item.map(async (imgpaths) => {
                try {
                    const data = await AwsImageService.uploadImageToS3(imgpaths.file, 'Master');
                    if (data !== '') {
                        return data;
                    }
                } catch (err) {
                    console.error(err);
                }
            }));

            props.setNewProductData((prevItem) => {
                const newPicUrl = [...prevItem.picUrl]; // Create a copy of the existing array
                newPicUrl.push(...imgData); // Append the new data to the existing array

                return {
                    ...prevItem,
                    picUrl: newPicUrl
                };
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditImage = async (item, indexForEdit) => {
        console.log("edit")
        try {
            const imgData = await Promise.all(item.map(async (imgpaths) => {
                try {
                    const data = await AwsImageService.uploadImageToS3(imgpaths.file, 'Master');
                    if (data !== '') {
                        return data;
                    }
                } catch (err) {
                    console.error(err);
                }
            }));
            console.log("IMGData", imgData)
            props.setUpdateItem((prevItem) => {
                console.log("index", indexForEdit)
                const newPicUrl = [...prevItem.image_url]; // Create a copy of the existing array
                newPicUrl[indexForEdit] = imgData[0]; // Replace the data at the specified index with new data

                return {
                    ...prevItem,
                    image_url: newPicUrl
                };
            });

        } catch (error) {
            console.error(error);
        }
    }

    // Updating uploaded file for updating existing image
    const onDrop = useCallback((acceptedFiles) => {
        console.log("AcceptedFiles.........", acceptedFiles);

        let item = [];
        acceptedFiles.forEach((file) => {
            console.log("Initial file.........", file);
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (props.fromValue === 'Add') {
                    handleNewImage(item);
                } else {
                    handleEditImage(item);
                }

            };
            reader.readAsDataURL(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/jpg, image/png",
        multiple: false,

        onDrop,
    });

    const handleDelete = (index, fromValue) => {
        if (fromValue === 'Add') {
            props.setNewProductData((prevItem) => {
                const newPicUrl = [...prevItem.picUrl]; // Create a copy of the existing array
                newPicUrl.splice(index, 1); // Remove the item at the specified index

                return {
                    ...prevItem,
                    picUrl: newPicUrl
                };
            });
        } else {
            if (props.updateItem.image_url.length === 1) {
                alert("One Image is mandatory")
            } else {
                props.setUpdateItem((prevItem) => {
                    const newPicUrl = [...prevItem.image_url]; // Create a copy of the existing array
                    newPicUrl.splice(index, 1); // Remove the item at the specified index

                    return {
                        ...prevItem,
                        image_url: newPicUrl
                    };
                });
            }
        }
    }

    const handleUpdateImage = async (item) => {
        console.log("Executed", item);
        try {
            const imgData = await Promise.all(item.map(async (imgpaths) => {
                try {
                    const data = await AwsImageService.uploadImageToS3(imgpaths.file, 'Master');
                    if (data !== '') {
                        return data;
                    }
                } catch (err) {
                    console.error(err);
                }
            }));

            props.setUpdateItem((prevItem) => {
                const newPicUrl = [...prevItem.image_url]; // Create a copy of the existing array
                newPicUrl.push(...imgData); // Append the new data to the existing array

                return {
                    ...prevItem,
                    image_url: newPicUrl
                };
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleFileUpload = (e) => {
        let item = [];
        let file = e.target.files[0];
            console.log("Initial file.........", file);
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                handleUpdateImage(item)
            };
            reader.readAsDataURL(file);
    }

    const handleFileForEdit = (e, index) => {
        let file = e.target.files[0];
        let item = [];
            console.log("Initial file.........", file);
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                handleEditImage(item, index);
            };
            reader.readAsDataURL(file);
    }

    const handleEditClick = (index) => {
        const fileInput = document.getElementById(index);
        if (fileInput) {
            fileInput.click();
        }
    }

    return (
        <>
            {props.fromValue === 'Add' ? (
                <div>
                    <p className="image-count-text" onClick={() => setOpenModal(true)}>{props.newProductData.picUrl.length} {props.newProductData.picUrl.length === 1 ? 'Image' : 'Images'} selected</p>

                    <Dialog onClose={() => setOpenModal(false)} open={openModal}>
                        <img
                            className="close-img"
                            src={close_icon}
                            onClick={() => setOpenModal(false)}
                            alt="close"
                        />
                        <div className="images-dialog-container">
                            <div className="images-container">
                                {props.newProductData.picUrl.map((image, index) => (
                                    <div className="each-image-div">
                                        <img src={image} className="each-image" alt="NA" />
                                        <img onClick={() => handleDelete(index, props.fromValue)} src={delete_icon} className="delete-img" alt="NA" />
                                    </div>
                                ))}

                            </div>
                            {props.newProductData.picUrl.length < 5 && (
                                <div className="add-more-btn-container" >
                                    <button {...getRootProps()} className="vip_history_submit_btn">
                                        Add more
                                        <input {...getInputProps()} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </Dialog>
                </div>
            ) : (
                <div className="images-dialog-container">
                    <div className="edit-images-container">
                        {props.updateItem.image_url.map((image, index) => (
                            <div key={index} className="each-image-div">
                                <img src={image} className="each-image" alt="NA" />
                                <img onClick={() => handleDelete(index, props.fromValue)} src={delete_icon} className="delete-img" alt="NA" />
                                <div onClick={() => handleEditClick(index)} className="edit-img-container">
                                    <input onChange={(e) => handleFileForEdit(e, index)} id={index} type="file" style={{display: 'none'}} />
                                    <img src={edit_icon} className="edit-img" alt="NA" />
                                </div>
                            </div>
                        ))}

                    </div>
                    {props.updateItem.image_url.length < 5 && (
                        <div className="add-more-btn-container" >
                            <button onClick={() => fileInputRef.current.click()} className="vip_history_submit_btn">
                                Add
                                <input onChange={handleFileUpload} ref={fileInputRef} type="file" style={{display: 'none'}} />
                            </button>
                        </div>
                    )}

                </div>

            )}
        </>

    )
}

export default FileUploaderMasCatalog
