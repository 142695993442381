import React, { useState } from "react";
import { Col, Modal, ModalBody, Row, Input, Label, FormGroup, Button, Form } from "reactstrap";
import Strip from "./Strip/Strip";
import Select from "react-select";
import FileUploader from "../customComponent/FileUploader/FileUploader";
import AwsService from "../../../src/api/services/AwsImageService";

// const classifiOption = [
//   { value: 0, label: "Select classification", isCheck: false },
//   { value: 1, label: "Gourmet", isCheck: false },
//   { value: 2, label: "Supermarket", isCheck: false },
//   { value: 3, label: "Emergency Use", isCheck: false },
// ];

const deliveryOption = [
  { value: 0, label: "Yes", isCheck: false },
  { value: 1, label: "No", isCheck: false },
];

const EditCategory = (props) => {
  const [categoryImage, setCategoryImage] = useState("");
  const [CategoryUpdateImage, setCategoryUpdateImage] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [categoryName, setCategoryName] = useState("");
  const [range1, setRange1] = useState("");
  const [range2, setRange2] = useState("");
  const [range3, setRange3] = useState("");
  const [range4, setRange4] = useState("");
  const [range5, setRange5] = useState("");
  const [radius, setRadius] = useState("");
  // const [merchantDeliveryCharges, setMerchantDeliveryCharges] = useState("");
  // const [subscriptionCharges, setSubscriptionCharges] = useState("");
  const [chattyBaoHomeDelivery, setChattyBaoHomeDelivery] = useState("");
  const [deliveryTAT, setDeliveryTAT] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [dryRunRadius, setDryRunRadius] = useState("");
  const [paymentAndNetworkCharges, setPaymentAndNetworkCharges] = useState("");
  const [commissionSDPer,setCommissionSDPer]=useState('');
  // const [classification, setClassification] = useState("");
  const [deliveryChargeOnCustomer, setDeliveryChargeOnCustomer] = useState("");
  const [gstOnDeliveryChargeOnCustomer, setGSTOnDeliveryChargeOnCustomer] = useState("");
  const [photo, setPhoto] = useState("");
  const [photoFileName, setPhotoFileName] = useState("");
  const [photoFile, setPhotoFile] = useState("");
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [eligibilityForCBDelivery, setEligibilityForCBDelivery] = useState("");
  const [gstOnSubscriptionCharges3, setGstOnSubscriptionCharges3] = useState("");
  const [subscriptionCharges3, setSubscriptionCharges3] = useState("");
  const [gstOnPaymentAndNetworkCharges, setGstOnPaymentAndNetworkCharges] = useState("");
  const [subscriptionCharges1, setSubscriptionCharges1] = useState("");
  const [gstOnSubscriptionCharges1, setGstOnSubscriptionCharges1] = useState("");
  const [subscriptionCharges2, setSubscriptionCharges2] = useState("");
  const [gstOnSubscriptionCharges2, setGstOnSubscriptionCharges2] = useState("");
  const [regularSettlementCharge, setRegularSettlementCharge] = useState("");
  const [vipSettlementCharge, setVipSettlementCharge] = useState("");

  React.useEffect(() => {
    setIsAdd(props.addOrUpdate ? false : true);
    setCategoryName(props.categoryName);
    if (props.categoryType === "l2") {
      setEligibilityForCBDelivery(eligibilityForCBDelivery);
    }
    if (props.categoryType === "l3") {
      setRadius(props.datas.radius);
      setCategoryImage(props.datas.categoryImage ? props.datas.categoryImage.replace(/,/g, "").trim() : "");
    } else if (props.categoryType === "l4") {
      console.log("categoryType:44", props.id);
      setRadius(props.datas.radius);
      // setSubscriptionCharges(props.datas.dryRunRadius);
      setDryRunRadius(props.datas.dryrunradius);
      setPaymentAndNetworkCharges(props.datas.commissionPer);
      setDeliveryChargeOnCustomer(props.datas.deliveryChargeOnCustomer);
      setGSTOnDeliveryChargeOnCustomer(props.datas.gstOnDeliveryChargeOnCustomer);
      const isDeliveryItem = props.datas.chattyBaoHomeDelivery;
      if (isDeliveryItem != null) {
        const deliveryItem = deliveryOption.find((it) => it.value === isDeliveryItem);
        setChattyBaoHomeDelivery(deliveryItem);
      }
      setCategoryDescription(props.datas.categoryDescription);
      setEligibilityForCBDelivery(props.datas.eligibilityForCBDelivery);
      setGstOnPaymentAndNetworkCharges(props.datas.gstOnCommissionPer);
      setCommissionSDPer(props.datas.commissionSDPer)
      setSubscriptionCharges1(props.datas.subscriptionCharges1);
      setGstOnSubscriptionCharges1(props.datas.gstOnSubscriptionCharges1);
      setSubscriptionCharges2(props.datas.subscriptionCharges2);
      setGstOnSubscriptionCharges2(props.datas.gstOnSubscriptionCharges2);
      setGstOnSubscriptionCharges3(props.datas.gstOnSubscriptionCharges3);
      setSubscriptionCharges3(props.datas.subscriptionCharges3);
      setPaymentAndNetworkCharges(props.datas.commissionPer);
      setRegularSettlementCharge(props.datas.regular_settlement_charge);
      setVipSettlementCharge(props.datas.vip_settlement_charge);
      setDryRunRadius(props.datas.dryRunRadius);
      setRange1(props.datas.range1);
      setRange2(props.datas.range2);
      setRange3(props.datas.range3);
      setRange4(props.datas.range4);
      setRange5(props.datas.range5);
    }

    // console.log("cate = "+props.categoryId);
  });

  // };
  const getThumbnail = (item) => {
    setPhoto(item[0].base64);
    setPhotoFile(item[0].file);
    setPhotoFileName(item[0].file.name);
    // await setAudioFileName(item[0].file);
    // await setAudioFileUrl(item[0].file.name);
    console.log("item[0].file", item[0].file);
    let url = "";
    setIsFileChanged(true);
    if (item[0].file != "") {
      AwsService.uploadImageToS3(item[0].file)
        .then((data) => {
          url = data.uri;
          if (data.uri !== "") {
            console.log("item[0].file done");
            console.log(categoryImage);
            console.log(url);
            setIsFileChanged(true);
            setCategoryImage(url);
            setCategoryUpdateImage(url);
            props.handleImageDetails(data.uri);
            console.log("data.uri", categoryImage);
          }
        })
        .catch((err) => console.error(err));
    }
    console.log("url", url);
    if (url) {
    }
    // let CurFile = imageUrl;
    // CurFile[0].fileName = item[0].file.name;
    // console.log("CurFile",item[0].file.name)
    // console.log("CurFile",CurFile)
    // setImageUrl([...CurFile]);
  };
  return (
    <div>
      <Strip id="tst-confirmation" className="strip strip-no-padding" containerType="container-fluid">
        <Row>
          <Col xs={10} sm={10} md={12} lg={12} xl={12}>
            <Modal
              isOpen={props.isModal}
              //  toggle={props.toggle}
              className="modal-container modal-lg modal-dialog-centered"
            >
              <div className="modal-header-container bg-theme-color">
                <Row className="no-margin align-items-center py-2">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5 className=" center mb-0 p-large internal-page__title align-center title-white fw-blod">Category {!isAdd ? "Add" : "Update "}</h5>
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
                    <Row className="m-auto">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3 pb-5">
                        <Row className="mt-2">
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form>
                              <div>
                                {props.categoryType === "l1" && props.parent_id !== "" && props.isEdit === false ? (
                                  <FormGroup row className="mx-0 align-items-center pb-3">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Label className="common-label text-left">L1 Category Name*:</Label>
                                      <Input type="text" value={props.datas.name} disabled />
                                    </Col>
                                  </FormGroup>
                                ) : null}
                              </div>
                              <div>
                                {props.categoryType === "l2" && props.parent_id !== "" && props.isEdit === false ? (
                                  <FormGroup row className="mx-0 align-items-center pb-3">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Label className="common-label text-left">L1 Category Name*:</Label>
                                      {console.log("AFFRICa", props.datas)}
                                      <Input type="text" value={props.datas.l1name} disabled />
                                    </Col>
                                  </FormGroup>
                                ) : null}
                              </div>
                              <div>
                                {props.categoryType === "l2" && props.parent_id !== "" && props.isEdit === false ? (
                                  <FormGroup row className="mx-0 align-items-center pb-3">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Label className="common-label text-left">L2 Category Name*:</Label>
                                      {console.log("AFFRICa", props.datas)}
                                      <Input type="text" value={props.datas.name} disabled />
                                    </Col>
                                  </FormGroup>
                                ) : null}
                              </div>
                              {props.categoryType === "l3" && (
                                <>
                                  <div>
                                    {props.parent_id !== "" && props.isEdit === false ? (
                                      <FormGroup row className="mx-0 align-items-center pb-3">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <Label className="common-label text-left">L1 Category Name*:</Label>
                                          {console.log("AFFRICa", props.datas)}
                                          <Input type="text" value={props.datas.l1name} disabled />
                                        </Col>
                                      </FormGroup>
                                    ) : null}
                                  </div>
                                  <div>
                                    {props.parent_id !== "" && props.isEdit === false ? (
                                      <FormGroup row className="mx-0 align-items-center pb-3">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <Label className="common-label text-left">L2 Category Name*:</Label>
                                          <Input type="text" value={props.datas.l2name} disabled />
                                        </Col>
                                      </FormGroup>
                                    ) : null}
                                  </div>
                                  <div>
                                    {props.parent_id !== "" && props.isEdit === false ? (
                                      <FormGroup row className="mx-0 align-items-center pb-3">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <Label className="common-label text-left">L3 Category Name*:</Label>
                                          {console.log("AFFRICa", props.datas)}
                                          <Input type="text" value={props.datas.name} disabled />
                                        </Col>
                                      </FormGroup>
                                    ) : null}
                                  </div>
                                </>
                              )}
                              {props.categoryType !== "l1" && isAdd ? (
                                <div>
                                  <FormGroup row className="mx-0 align-items-center pb-3">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Label className="common-label text-left">L1 Category Name*:</Label>
                                      <Input type="text" value={props.l1name} disabled />
                                    </Col>
                                  </FormGroup>
                                  {props.categoryType === "l2" && !isAdd ? (
                                    <FormGroup row className="mx-0 align-items-center pb-3">
                                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Label className="common-label text-left">L2 Category Name*:</Label>
                                        {console.log("l2", props.type)}
                                        <Input type="text" value={props.l2name} disabled />
                                      </Col>
                                    </FormGroup>
                                  ) : null}

                                  {props.categoryType !== "l2" ? (
                                    <div>
                                      <FormGroup row className="mx-0 align-items-center pb-3">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <Label className="common-label text-left">L2 Category Name*:</Label>
                                          <Input type="text" value={props.l2name} disabled />
                                        </Col>
                                      </FormGroup>
                                      {props.categoryType === "l3" && !isAdd ? (
                                        <FormGroup row className="mx-0 align-items-center pb-3">
                                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Label className="common-label text-left">L3 Category Name*:</Label>
                                            <Input type="text" value={props.l3name} disabled />
                                          </Col>
                                        </FormGroup>
                                      ) : null}
                                      {props.categoryType !== "l3" ? (
                                        <div>
                                          <FormGroup row className="mx-0 align-items-center pb-3">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                              <Label className="common-label text-left">L3 Category Name*:</Label>
                                              <Input type="text" value={props.l3name} disabled />
                                            </Col>
                                          </FormGroup>
                                          {props.categoryType === "l4" && !isAdd ? (
                                            <FormGroup row className="mx-0 align-items-center pb-3">
                                              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Label className="common-label text-left">L3 Category Name*:</Label>
                                                <Input type="text" value={props.l3name} disabled />
                                              </Col>
                                            </FormGroup>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {console.log("star", props.categoryType)}
                              {console.log("star*", props.addL1)}
                              {!isAdd ? (
                                <FormGroup row className="mx-0 align-items-center pb-3">
                                  {props.addL1 ? (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      {props.categoryType === "l1" && <Label className="common-label text-left"> L1 Category Name*:</Label>}
                                      <Input type="text" onChange={(event) => props.handleCategoryName(event.target.value)} />
                                    </Col>
                                  ) : (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      {props.categoryType === "l1" && <Label className="common-label text-left"> L2 Category Name*:</Label>}
                                      {props.categoryType === "l2" && <Label className="common-label text-left"> L3 Category Name*:</Label>}
                                      {props.categoryType === "l3" && <Label className="common-label text-left"> L4 Category Name*:</Label>}
                                      {/* {props.categoryType === "l4" && <Label className="common-label text-left"> L4 Category Name*:</Label>} */}
                                      <Input type="text" value={categoryName} onChange={(event) => props.handleCategoryName(event.target.value)} />
                                    </Col>
                                  )}
                                </FormGroup>
                              ) : (
                                <FormGroup row className="mx-0 align-items-center pb-3">
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {props.categoryType === "l1" && <Label className="common-label text-left"> L1 Category Name*:</Label>}
                                    {props.categoryType === "l2" && <Label className="common-label text-left"> L2 Category Name*:</Label>}
                                    {props.categoryType === "l3" && <Label className="common-label text-left"> L3 Category Name*:</Label>}
                                    {props.categoryType === "l4" && <Label className="common-label text-left"> L4 Category Name*:</Label>}
                                    <Input type="text" value={categoryName} onChange={(event) => props.handleCategoryName(event.target.value)} />
                                  </Col>
                                </FormGroup>
                              )}
                              {props.categoryType !== "l1" && props.addL3 ? (
                                <div>
                                  <FormGroup row className="mx-0 align-items-center pb-3">
                                    {props.addL4 && (
                                      <>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3">
                                          <Label className="common-label text-left">Category Description*:</Label>
                                          <Input type="textarea" defaultValue={categoryDescription} onChange={(event) => props.handleCategoryDescription(event.target.value)} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Range 1:</Label>
                                          <Input type="text" defaultValue={range1} onChange={(event) => props.handleRange1(event.target.value)} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Range 2:</Label>
                                          <Input type="text" defaultValue={range2} onChange={(event) => props.handleRange2(event.target.value)} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Range 3:</Label>
                                          <Input type="text" defaultValue={range3} onChange={(event) => props.handleRange3(event.target.value)} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Range 4:</Label>
                                          <Input type="text" defaultValue={range4} onChange={(event) => props.handleRange4(event.target.value)} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Range 5:</Label>
                                          <Input type="text" defaultValue={range5} onChange={(event) => props.handleRange5(event.target.value)} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Delivery Radius (km)*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={radius}
                                            onChange={(event) => props.handleRadius(event.target.value)}
                                          />
                                        </Col>
                                        {/* <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left ">Subscription charges*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={subscriptionCharges}
                                            onChange={(event) => props.handleSubscriptionCharges(event.target.value)}
                                          />
                                        </Col> */}
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Dry Run Radius*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={dryRunRadius}
                                            onChange={(event) => props.handleDryRunRadius(event.target.value)}
                                          />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Eligibility For CB Delivery*:</Label>
                                          <Select
                                            defaultValue={chattyBaoHomeDelivery ? chattyBaoHomeDelivery : chattyBaoHomeDelivery.value}
                                            onChange={props.handleChattyBaohomeDelivery}
                                            options={deliveryOption}
                                            placeholder={"Select"}
                                            classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                            isSearchable={false}
                                            isClearable
                                          />
                                        </Col>
                                      </>
                                    )}
                                    {props.addL3 && !props.addL4 && props.categoryType !== "l1" && props.categoryType !== "l4" && (
                                      <>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3">
                                          <Label className="common-label text-left">Delivery Radius (km)*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={radius}
                                            onChange={(event) => props.handleRadius(event.target.value)}
                                          />
                                        </Col>
                                      </>
                                    )}

                                    {console.log("handleChattyBaohomeDelivery", deliveryOption)}
                                  </FormGroup>

                                  {console.log("ani", props.addL3, props.addL4, props)}
                                  {props.addL3 && !props.addL4 ? (
                                    <div>
                                      {/* <FormGroup row className="mx-0 align-items-center pb-3">
                                        <Col xs={12} sm={12} md={6} lg={12} xl={12} className="pb-3">
                                          <Label className="common-label text-left">Delivery TAT (hr)*:</Label>
                                          <Input type="number" defaultValue={deliveryTAT} onChange={(event) => props.handleDeliveryTAT(event.target.value)} />
                                        </Col>
                                      </FormGroup> */}
                                      <FormGroup row className="mx-0 align-items-center pb-3"></FormGroup>
                                      <FormGroup row className="mx-0 align-items-center justify-content-center">
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="d-flex px-0 py-2 justify-content-center align-items-center pb-3">
                                          {!categoryImage ? (
                                            <FileUploader
                                              isImg={true}
                                              getThumbnail={(item) => getThumbnail(item)}
                                              isEditable={false}
                                              // isDefault={isDefaultThumbnail}
                                            />
                                          ) : (
                                            // null
                                            <>
                                              {console.log("thumbnail", categoryImage)}
                                              <img src={`${isFileChanged ? `${CategoryUpdateImage}` : `${categoryImage}`}`} alt={""} className="w-100" />
                                            </>
                                          )}
                                          {categoryImage ? <FileUploader isImg={true} getThumbnail={(item) => getThumbnail(item)} isEditable={true} /> : null}
                                        </Col>
                                      </FormGroup>
                                    </div>
                                  ) : null}
                                  {!props.addL3 || props.addL4 ? (
                                    <div>
                                      <FormGroup row className="mx-0 align-items-center pb-3">
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Commission Percentage*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={paymentAndNetworkCharges}
                                            onChange={(event) => props.handlePaymentAndNetworkCharges(event.target.value)}
                                          />
                                        </Col>
                                        {console.log("*", props.datas)}
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Gst On Commission Percentage*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={gstOnPaymentAndNetworkCharges}
                                            onChange={(event) => props.handleGstOnPaymentAndNetworkCharges(event.target.value)}
                                          />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Commission SD Percentage*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={commissionSDPer}
                                            onChange={(event) => props.handleCommissionSDPer(event.target.value)}
                                          />
                                        </Col>

                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Subscription Charges 1*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={subscriptionCharges1}
                                            onChange={(event) => props.handleSubscriptionCharges1(event.target.value)}
                                          />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">GST On Subscription Charge 1 (%)*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={gstOnSubscriptionCharges1}
                                            onChange={(event) => props.handleGSTonsubscriptioncharge1(event.target.value)}
                                          />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Subscription Charges 2*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={subscriptionCharges2}
                                            onChange={(event) => props.handleSubscriptionCharges2(event.target.value)}
                                          />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">GST On Subscription Charge 2 (%)*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={gstOnSubscriptionCharges2}
                                            onChange={(event) => props.handleGSTonsubscriptioncharge2(event.target.value)}
                                          />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">Subscription Charges 3*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={subscriptionCharges3}
                                            onChange={(event) => props.handleSubscriptionCharges3(event.target.value)}
                                          />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                          <Label className="common-label text-left">GST On Subscription Charge 3 (%)*:</Label>
                                          <Input
                                            type="text"
                                            onKeyPress={(event) => {
                                              if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            defaultValue={gstOnSubscriptionCharges3}
                                            onChange={(event) => props.handleGSTonsubscriptioncharge3(event.target.value)}
                                          />
                                        </Col>
                                        {console.log("paymentAndNetworkCharges 2", paymentAndNetworkCharges)}

                                        {props.addL4 && (
                                          <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                              <Label className="common-label text-left">Delivery Charge on Customer*:</Label>
                                              <Input
                                                type="text"
                                                onKeyPress={(event) => {
                                                  if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                defaultValue={deliveryChargeOnCustomer}
                                                onChange={(event) => props.handleDeliveryChargeOnCustomer(event.target.value)}
                                              />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                              <Label className="common-label text-left">GST On Delivery Charge on Customer(%)*:</Label>
                                              <Input
                                                type="text"
                                                onKeyPress={(event) => {
                                                  if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                defaultValue={gstOnDeliveryChargeOnCustomer}
                                                onChange={(event) => props.handleGSTOnDeliveryChargeOnCustomer(event.target.value)}
                                              />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                              <Label className="common-label text-left">Regular Settlement Charge(%)*:</Label>
                                              <Input
                                                type="text"
                                                onKeyPress={(event) => {
                                                  if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                defaultValue={regularSettlementCharge}
                                                onChange={(event) => props.handleRegularSettlementCharge(event.target.value)}
                                              />
                                            </Col>

                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pb-3">
                                              <Label className="common-label text-left">VIP Settlement Charge(%)*:</Label>
                                              <Input
                                                type="text"
                                                onKeyPress={(event) => {
                                                  if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                defaultValue={vipSettlementCharge}
                                                onChange={(event) => props.handleVipSettlementCharge(event.target.value)}
                                              />
                                            </Col>
                                          </>
                                        )}
                                      </FormGroup>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </Form>
                          </Col>
                        </Row>
                        <Row className="no-margin pt-3">
                          <Col xs={12} sm={12} md={{ offset: 2, size: 8 }} lg={{ offset: 2, size: 8 }} xl={{ offset: 2, size: 8 }}>
                            <Row>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button color="secondary" className="btn btn-block custom-danger-btn custom-btn--small" onClick={() => props.handleCancelBtn()}>
                                  Cancel
                                </Button>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                {isAdd ? (
                                  <Button
                                    color="success"
                                    className="btn btn-block custom-theme-btn custom-btn--small"
                                    onClick={() => props.addUpdateCategory(props.categoryId, props.categoryType, props.parent_id, "1")}
                                    // disabled={
                                    //   title &&
                                    //   subTitle &&
                                    //   sharingText &&
                                    //   category &&
                                    //   language &&
                                    //   disableSubmitBtn
                                    //     ? false
                                    //     : true
                                    // }
                                  >
                                    Update
                                  </Button>
                                ) : (
                                  <Button
                                    color="success"
                                    className="btn btn-block custom-theme-btn custom-btn--small"
                                    onClick={() => props.addUpdateCategory(props.categoryId, props.categoryType, props.parent_id, "0")}
                                    // disabled={
                                    //   title &&
                                    //   subTitle &&
                                    //   sharingText &&
                                    //   category &&
                                    //   language &&
                                    //   disableSubmitBtn
                                    //     ? false
                                    //     : true
                                    // }
                                  >
                                    Add
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>

              {console.log("data.uri", imageUrl)}
            </Modal>
          </Col>
        </Row>
      </Strip>
    </div>
  );
};

export default EditCategory;
