import React from 'react';
import './index.scss';
import { constants } from '../../utils/constants';

const Offer = () => {
    const { cashbackTnCData, cashbackTnCHeader, cashbackTnCsubHeader } = constants;


    return (
        <div class="main">
            <div class="content">
                <h2>{cashbackTnCHeader}</h2>
                <h3>{cashbackTnCsubHeader}</h3>
                <ul>
                    {cashbackTnCData.map((data) => (
                        <li className='offer'>{data}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export default Offer;