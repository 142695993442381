import React, { useState, useEffect } from 'react';
import { Container } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import "../../styles/home.css";
import "../../Dashboard.css";
import './SFY.css';
import Sidebar from '../../components/sidebar.js';
import { TextField } from "@mui/material";
import ApiService from '../../api/services/ApiService';
import LoadingSpinner from '../../components/loader/loader';
import close_icon from "../../../src/payout/close.png";
import { Dialog } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    alertSuccessMessageMerchant,
    alertDangerConfirmMessage
} from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage';
import { constants } from '../../utils/constants';

const SFY = () => {

    const [errorText, setErrorText] = useState('');
    const [specialMerchantList, setSpecialMerchantList] = useState([]);
    const [merchantId, setMerchantId] = useState('');
    const [newMerchantDataDetails, setNewMerchantDataDetails] = useState({});
    const [merchantCheck, setMerchantCheck] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [updateIndex, setUpdateIndex] = useState(null);
    const [updateItem, setUpdateItem] = useState({});
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [indexForDelete, setIndexForDelete] = useState(null);
    const [displayPagination, setDisplayPagination] = useState(false);
    const [pageCount, setPageCount] = useState('');
    const [displayStart, setDisplayStart] = useState('');
    const [displayEnd, setDisplayEnd] = useState('');
    const [page, setPage] = useState(1);
    const [totalMerchant, setTotalMerchant] = useState('');
    const { sfyTexts } = constants;

    useEffect(() => {
        getSpecialMerchantList();
    }, [])

    const getSpecialMerchantList = async (pageNum) => {
        const token = localStorage.getItem('sessionId')
        let data = {};
        if (pageNum) {
            data = {
                pageNumber: pageNum,
                pageSize: 10
            }
        } else {
            data = {
                pageNumber: 1,
                pageSize: 10
            }
        }
        setSpinner(true);

        try {
            const result = await ApiService.getSpecialMerchants(token, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' });
            if (result.data.code === 0) {
                setSpecialMerchantList(result.data.data);
                setDisplayPagination(true);
                let pageCount1 = result.data.totalCount.total_count / 10;
                setTotalMerchant(result.data.totalCount.total_count);
                pageCount1 = Math.ceil(pageCount1);
                if (pageNum === undefined || pageNum === 1) {
                    setPageCount(pageCount1);
                    setDisplayStart('1');
                    if (result.data.totalCount.total_count < 10) {
                        setDisplayEnd(result.data.totalCount.total_count);
                    } else {
                        setDisplayEnd('10');
                    }
                }
            } else {
                console.log("Error fetching data")
            }
        } catch (error) {
            setSpinner(false);
            alertDangerConfirmMessage(error.message)
            console.log(error)
        } finally {
            setSpinner(false);
        }
    }

    const addEditDetails = async (action) => {
        const token = localStorage.getItem('sessionId')
        if (action === 'Edit') {
            if (updateItem.description_text.trim() === '') {
                alertDangerConfirmMessage(sfyTexts.descriptionValidation)
            } else {
                setSpinner(true);

                let data = {
                    merchant_id: updateItem.merchantId,
                    description_text: updateItem.description_text.trim(),
                    city: updateItem.city
                }
                try {
                    const result = await ApiService.addUpdateSpecialMerchant(token, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' });
                    if (result.data.code === 0) {
                        alertSuccessMessageMerchant(sfyTexts.editSuccessText)
                        setUpdateIndex(null);
                        setUpdateItem({});
                        setMerchantId('');
                        await getSpecialMerchantList();
                    } else {
                        alertDangerConfirmMessage(sfyTexts.editFailText)
                    }
                } catch (error) {
                    setSpinner(false);
                    alertDangerConfirmMessage(error.message)
                    console.log(error)
                } finally {
                    setSpinner(false);
                }
            }

        } else {
            if (newMerchantDataDetails.description.trim() === '' || newMerchantDataDetails.description === undefined) {
                alertDangerConfirmMessage(sfyTexts.descriptionValidation)
            } else {
                setSpinner(true);

                let data = {
                    merchant_id: merchantId,
                    description_text: newMerchantDataDetails.description.trim(),
                    city: newMerchantDataDetails.city
                }
                try {
                    const result = await ApiService.addUpdateSpecialMerchant(token, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' });
                    console.log("newR", result.data.message)
                    if (result.data.code === 0) {
                        setMerchantCheck(false);
                        alertSuccessMessageMerchant(sfyTexts.addSuccessText)
                        setMerchantId('');
                        await getSpecialMerchantList();
                    } else {
                        alertDangerConfirmMessage(sfyTexts.addFailText)
                    }
                } catch (error) {
                    setSpinner(false);
                    alertDangerConfirmMessage(error.message)
                    console.log(error)
                } finally {
                    setSpinner(false);
                }
            }

        }

    }

    const deleteSpecialMerchant = async () => {
        const token = localStorage.getItem('sessionId')
        let data = {
            merchant_id: specialMerchantList[indexForDelete].merchant_id,
        }
        setSpinner(true);

        try {
            const result = await ApiService.deleteSpecialMerchant(token, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' });
            if (result.data.code === 0) {
                alertSuccessMessageMerchant(sfyTexts.deleteSuccessText);
                setShowConfirmDialog(false);
                setIndexForDelete(null);
                setMerchantId('');
                await getSpecialMerchantList();
            } else {
                alertDangerConfirmMessage(sfyTexts.deleteFailText)
            }
        } catch (error) {
            setSpinner(false);
            alertDangerConfirmMessage(error.message)
            console.log(error)
        } finally {
            setSpinner(false);
        }
    }

    const getCityOfMerchant = async () => {
        if (merchantId.trim().length == 0) {
            setErrorText('* This field cannot be empty');
        } else {
            setErrorText('');
        }
        if (merchantId.trim().length !== 0) {
            const token = localStorage.getItem('sessionId')
            setSpinner(true);

            let data = {
                merchant_id: merchantId,
            }
            try {
                const result = await ApiService.getCityByMerchandId(token, data);
                if (result.data.code === 0) {
                    let resData = {
                        shopName: result.data.data.shopname,
                        city: result.data.data.city
                    }
                    setNewMerchantDataDetails(resData);
                    setMerchantCheck(true);
                    setUpdateIndex(null);
                } else if (result.data.data !== undefined && result.data.data.description_text) {
                    let spData = [];
                    spData.push(result.data.data);
                    setSpecialMerchantList(spData);
                } else {
                    console.log("Merchannnnn")
                    alertDangerConfirmMessage(sfyTexts.merchantNotExist)
                }
            } catch (error) {
                setSpinner(false);
                alertDangerConfirmMessage(error.message)
                console.log(error)
            } finally {
                setSpinner(false);
            }
        }
    };

    const handleBlur = () => {
        setErrorText('');
    };

    const handleEditBtn = (index) => {
        setUpdateIndex(index);
        let data = {
            merchantId: specialMerchantList[index].merchant_id,
            shopname: specialMerchantList[index].shopname,
            description_text: specialMerchantList[index].description_text,
            city: specialMerchantList[index].city,
        }
        setUpdateItem(data);
    }

    const handleNoBtn = () => {
        setIndexForDelete(null);
        setShowConfirmDialog(false);
    }

    const handleDeleteBtn = (index) => {
        setIndexForDelete(index);
        setShowConfirmDialog(true);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        let offset2 = (value) * 10;

        if (value == 1) {
            setDisplayStart('1');
            if (totalMerchant < 10) {
                setDisplayEnd(totalMerchant);
            } else {
                setDisplayEnd('10');
            }

        } else
            if (offset2 > totalMerchant) {
                setDisplayStart(((value - 1) * 10) + 1);
                setDisplayEnd(totalMerchant);
            }
            else {
                setPage(page + 1);
                setDisplayStart(((value - 1) * 10) + 1);
                setDisplayEnd(value * 10);
            }
        getSpecialMerchantList(value);

    };

    const handleDescAdd = (e) => {
        if (e.target.value.length <= 25) {
            const newText = e.target.value;

            const words = newText.split(' ');

            const capitalizedWords = words.map((word) => {
                if (word.length > 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }
                return word;
            });

            const convertedText = capitalizedWords.join(' ');
            setNewMerchantDataDetails({ ...newMerchantDataDetails, description: convertedText })
        }
    }

    const handleDescriptionEdit = (e) => {
        if (e.target.value.length <= 25) {
            const newText = e.target.value;

            const words = newText.split(' ');

            const capitalizedWords = words.map((word) => {
                if (word.length > 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }
                return word;
            });

            const convertedText = capitalizedWords.join(' ');
            setUpdateItem((prevItem) => ({ ...prevItem, description_text: convertedText }))
        }
    }

    return (
        <div>
            <div>
                <Sidebar />
            </div>
            <div className='page_container'>
                {spinner && (
                    <LoadingSpinner />
                )}
                <div>
                    <div
                        className="first-input-cotainer"
                    >
                        <TextField
                            error={!!errorText}
                            helperText={errorText ? errorText : ''}
                            onBlur={handleBlur}
                            className='vip_history_input'
                            label="Merchant ID"
                            onChange={(e) => setMerchantId(e.target.value)}
                            value={merchantId}
                        />
                        <button onClick={getCityOfMerchant} className='add-edit-button'>Submit</button>
                    </div>

                    <table
                        className='widget-table'
                    >
                        {(merchantCheck || specialMerchantList.length !== 0) && (
                            <thead>
                                <tr>
                                    <th colSpan="1" className="tableTextStyle">Shop Name</th>
                                    <th colSpan="1" className="tableTextStyle">Description Text</th>
                                    <th colSpan="1" className="tableTextStyle">City</th>
                                    <th colSpan="1" className="tableTextStyle">Action</th>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {merchantCheck && (
                                <tr>
                                    <td className='data-cell add-cell'>
                                        <div className="curved-cell">{newMerchantDataDetails.shopName}</div>
                                    </td>
                                    <td className='data-cell add-cell'>
                                        <div className='input-div'>
                                            {console.log("mercc", newMerchantDataDetails)}
                                            <input
                                                className='merchant-id-input'
                                                placeholder="Description Text *"
                                                onChange={handleDescAdd}
                                                value={newMerchantDataDetails.description}
                                            />
                                            <p className='remaining-text'>Remaining {newMerchantDataDetails.description === undefined ? 25 : (25 - newMerchantDataDetails.description.length)} characters</p>
                                        </div>

                                    </td>
                                    <td className='data-cell add-cell'>
                                        <div className="curved-cell">{newMerchantDataDetails.city}</div>
                                    </td>
                                    <td className="btn-cell add-cell">
                                        <button onClick={addEditDetails} className='add-btn-widget'>Add</button>
                                        <button onClick={() => setMerchantCheck(false)} className='delete-btn'>Cancel</button>
                                    </td>
                                    <td className=""></td>

                                </tr>
                            )}
                            {specialMerchantList.map((merchant, index) => {
                                if (updateIndex !== index) {
                                    return (
                                        <tr>
                                            <td className='data-cell'>
                                                <div className="curved-cell">{merchant.shopname}</div>
                                            </td>
                                            <td className='data-cell'>
                                                <div className="curved-cell">{merchant.description_text}</div>
                                            </td>
                                            <td className='data-cell'>
                                                <div className="curved-cell">{merchant.city}</div>
                                            </td>
                                            <td className="btn-cell">
                                                <button onClick={() => handleEditBtn(index)} className='add-edit-button'>Edit</button>
                                                <button onClick={() => handleDeleteBtn(index)} className='delete-btn'>Delete</button>
                                            </td>
                                            <td className=""></td>
                                        </tr>
                                    )
                                } else {
                                    return (
                                        <tr>
                                            <td className='data-cell'>
                                                <div className="curved-cell">{updateItem.shopname}</div>
                                            </td>
                                            <td className='data-cell'>
                                                <div className='input-div'>
                                                    <input
                                                        className='merchant-id-input'
                                                        placeholder="Description Text *"
                                                        value={updateItem.description_text}
                                                        onChange={handleDescriptionEdit}
                                                    />
                                                    <p className='remaining-text'>Remaining {25 - updateItem.description_text.length} characters</p>
                                                </div>

                                            </td>
                                            <td className='data-cell'>
                                                <div className="curved-cell">{updateItem.city}</div>
                                            </td>
                                            <td className="btn-cell">
                                                <button onClick={() => addEditDetails('Edit')} className='add-edit-button'>Save</button>
                                                <button onClick={() => setUpdateIndex(null)} className='delete-btn'>Cancel</button>
                                            </td>
                                            <td className=""></td>
                                        </tr>
                                    )
                                }
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
            {displayPagination == true ?
                <Stack spacing={2} style={{ display: 'block', width: '100%', marginBottom: '5px', left: 0, right: '0px', position: 'fixed', bottom: '0px', backgroundColor: '#CDECF4', padding: '5px 3px ' }}>
                    <Typography style={{ display: 'inline-block', width: '30%' }}>Showing Results {displayStart} to {displayEnd} of {totalMerchant} </Typography>
                    <Pagination style={{ display: 'inline-block', marginTop: '-3px', float: 'right' }} count={pageCount} page={page} className="PaginationCheck" onChange={handlePageChange} />
                </Stack>
                : ''}
            <Dialog onClose={handleNoBtn} open={showConfirmDialog}>
                <img
                    className="close_icon"
                    src={close_icon}
                    onClick={handleNoBtn}
                    alt="close"
                />
                <div className="cashback_dialog">
                    <div>
                        <p className="confirm-dialog-text">Are you sure you want to delete this merchant?</p>
                        <div className="yn-btn-container">
                            <button className="yes-btn" onClick={deleteSpecialMerchant}>Yes</button>
                            <button className="no-btn" onClick={handleNoBtn}>No</button>
                        </div>

                    </div>
                </div>

            </Dialog>
        </div>
    )
}

export default SFY;
