import { Fetch } from "../apiConfig/Fetch";
import { APIConfig } from "../apiConfig/apiConfig";

const CategoryService = {
  
    getCategoryTree: async () => {
    const { baseUrl, getCategoryTree } = APIConfig;

    const url = baseUrl + getCategoryTree;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const getCategoryTreeServicePromise = await Fetch(
      url,
      "GET",
      headers,
      "services_ getCategoryTree",
      true
    );
    return getCategoryTreeServicePromise;
  },

  addUpdateCategory: async (item) => {
    const { baseUrl, addUpdateCategory } = APIConfig;

    const url = baseUrl + addUpdateCategory;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
    let params;
    if(item.btn==="0"){ //add
        if(item.type==="l1"){
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type
            });
        }else if(item.type==="l2"){
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type,
                l1CategoryId:item.l1CategoryId
            });
        }else if(item.type==="l3"){
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type,
                l2CategoryId:item.l2CategoryId,
                radius:item.radius,
                categoryImage: item.categoryImage,
            });
        }else{
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type,
                l3CategoryId:item.l3CategoryId,
                radius:item.radius,
                // merchantDeliveryCharges:item.merchantDeliveryCharges,
                // subscriptionCharges:item.subscriptionCharges,
                dryRunRadius:item.dryRunRadius,
                commissionPer:item.paymentAndNetworkCharges,
                // classification:item.classification,
                deliveryChargeOnCustomer:item.deliveryChargeOnCustomer,
                gstOnDeliveryChargeOnCustomer:item.gstOnDeliveryChargeOnCustomer,
                categoryDescription: item.categoryDescription,
                chattyBaoHomeDelivery:item.chattyBaoHomeDelivery,
                eligibilityForCBDelivery: item.eligibilityForCBDelivery,
                gstOnCommissionPer: item.gstOnPaymentAndNetworkCharges,
                commissionSDPer:item.commissionSDPer,
                subscriptionCharges1: item.subscriptionCharges1,
                gstOnSubscriptionCharges1: item.gstOnSubscriptionCharges1,
                subscriptionCharges2: item.subscriptionCharges2,
                gstOnSubscriptionCharges2: item.gstOnSubscriptionCharges2,
                subscriptionCharges3: item.subscriptionCharges3,
                gstOnSubscriptionCharges3: item.gstOnSubscriptionCharges3,
                dateTime:item.dateTime,
                range1: item.range1,
                range2: item.range2,
                range3: item.range3,
                range4: item.range4,
                range5: item.range5,
                regularSettlementCharge : item.regularSettlementCharge,
                vipSettlementCharge : item.vipSettlementCharge,
            });
        }
    }else{
        if(item.type==="l1"){
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type,
                id:item.id
            });
        }else if(item.type==="l2"){
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type,
                l1CategoryId:item.l1CategoryId,
                id:item.id
            });
        }else if(item.type==="l3"){
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type,
                l2CategoryId:item.l2CategoryId,
                radius:item.radius,
                id:item.id,
                categoryImage: item.categoryImage, 
            });
        }else{
            params = JSON.stringify({
                categoryName:item.categoryName,
                type:item.type,
                l3CategoryId:item.l3CategoryId,
                categoryDescription:item.categoryDescription,
                radius:item.radius,
                // merchantDeliveryCharges:item.merchantDeliveryCharges,
                // subscriptionCharges:item.subscriptionCharges,
                dryRunRadius:item.dryRunRadius,
                commissionPer:item.paymentAndNetworkCharges,
                // classification:item.classification,
                deliveryChargeOnCustomer:item.deliveryChargeOnCustomer,
                gstOnDeliveryChargeOnCustomer:item.gstOnDeliveryChargeOnCustomer,
                id:item.id,
                chattyBaoHomeDelivery:item.chattyBaoHomeDelivery,
                eligibilityForCBDelivery: item.eligibilityForCBDelivery,
                gstOnCommissionPer: item.gstOnPaymentAndNetworkCharges,
                commissionSDPer:item.commissionSDPer,
                subscriptionCharges1: item.subscriptionCharges1,
                gstOnSubscriptionCharges1: item.gstOnSubscriptionCharges1,
                subscriptionCharges2: item.subscriptionCharges2,
                gstOnSubscriptionCharges2: item.gstOnSubscriptionCharges2,
                subscriptionCharges3: item.subscriptionCharges3,
                gstOnSubscriptionCharges3: item.gstOnSubscriptionCharges3,
                dateTime:item.dateTime,
                range1: item.range1,
                range2: item.range2,
                range3: item.range3,
                range4: item.range4,
                range5: item.range5,
                id:item.id,
                regularSettlementCharge : item.regularSettlementCharge,
                vipSettlementCharge : item.vipSettlementCharge,
            });
        }
    }

    const addUpdateCategoryServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_ addUpdateCategory",
      true
    );
    return addUpdateCategoryServicePromise;
  },

};

export default CategoryService;
