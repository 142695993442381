import React, { useState, useEffect } from 'react'
import ApiService from '../../api/services/ApiService';
import {
    alertDangerConfirmMessage
} from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage';
import LoadingSpinner from '../../components/loader/loader';
import DatePicker from "react-datepicker";
import { ExportToCSV } from '../../components/ExportToCSV';
import { constants } from '../../utils/constants';
import moment from "moment";
import "moment-timezone";

const OndcOrders = () => {

    const [orderDetails, setOrderDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00.000
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    const getorderDetails = async (eDate) => {
        const token = localStorage.getItem('OndcToken')
        setLoader(true);

        const data = {
            token: token,
            startDate: startDate === '' ? convertStartDate("") : convertStartDate(startDate),
            endDate: endDate === '' ? convertEndDate("") : convertEndDate(endDate)
        }
        try {
            const result = await ApiService.ondcOrders(data); 
            if (result.data.code === 0) {
                setOrderDetails(result.data.data)
            } else {
                alertDangerConfirmMessage(constants.ondcApiErrorMsg)
                setOrderDetails([])
            }
        } catch (error) {
            alertDangerConfirmMessage(error.message)
        } finally {
            setLoader(false);
        }
    }


    const convertStartDate = (date) => {
        return moment(date).tz("Asia/Kolkata").startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        
    };

    const convertEndDate = (date) => {
        return moment(date).tz("Asia/Kolkata").endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    };

    useEffect(() => {
        getorderDetails();
    }, [])

    const handleApplyBtn = () => {
        getorderDetails()
    }

    return (
        <div>
            {console.log("StartDate", startDate, "EndDAte", endDate)}
            {loader && (
                <LoadingSpinner />
            )}
            <div className='ondc-header-div'>
                <h5 className='ondc-page-header'>ONDC Orders</h5>
                <div className='filters-export-container'>
                    <div className='filter-container'>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Start date"
                            className="ondc-date-filter"
                            maxDate={today}
                        />
                        <DatePicker
                            selected={endDate && endDate >= startDate ? endDate : null}
                            onChange={(date) =>
                                setEndDate(date)
                            }
                            dateFormat="dd-MM-yyyy"
                            placeholderText="End date"
                            className="ondc-date-filter"
                            minDate={startDate}
                            maxDate={today}
                        />
                        <button onClick={handleApplyBtn} className='ondc-filter-btn'>
                            Apply
                        </button>
                    </div>
                    <ExportToCSV orders={true} apiData={orderDetails} dates={{ startDate: startDate, endDate: endDate }} fileName="chattybao-orders" />
                </div>

            </div>

            <table
                style={{ marginTop: '20px', marginLeft: 0 }}
            >
                <tr className='ondc-table-header'>
                    <th colSpan="1" className="normal-cell ondc-cell">S. No.</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Buyer NP Name</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Seller NP Name</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Order Create Date & Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Network Order Id</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Network Transaction Id</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Seller NP Order Id</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Item Id</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Qty</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Seller NP Type (MSN/ ISN)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Order Status</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Name of Seller</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Seller Pincode</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Seller City</th>
                    <th colSpan="1" className="normal-cell ondc-cell">SKU Name</th>
                    <th colSpan="1" className="normal-cell ondc-cell">SKU Code</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Order Category (F&B/ Grocery/ Home & Decor)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Ready to Ship At Date & Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Shipped At Date & Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Delivered At Date & Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Delivery Type (On-network/ Off-network)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Logistics Seller NP Name (For on-network delivery)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Logistics Network Order Id (For on-network delivery)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Logistics Network Transaction Id (For on-network delivery)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Delivery City</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Delivery Pincode</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Cancelled At Date & Time</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Cancelled By (Buyer/ Seller/ Logistics)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Cancellation Reason / Return Reason (Network Error Description)</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Total Shipping Charges</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Total Order Value</th>
                    <th colSpan="1" className="normal-cell ondc-cell">Total Refund Amount</th>
                </tr>
                <tbody>
                    {orderDetails && orderDetails.length !== 0 && orderDetails.map((data, index) => (
                        <tr className="ondc-data-cell" key={index}>
                            <td className="ondc-data-cell">{index + 1}</td>
                            <td className="ondc-data-cell">{data.buyerNPName}</td>
                            <td className="ondc-data-cell">{data.sellerNPName}</td>
                            <td className="ondc-data-cell">{data.orderCreateDateTime}</td>
                            <td className="ondc-data-cell">{data.networkOrderId}</td>
                            <td className="ondc-data-cell">{data.networkTransactionId}</td>
                            <td className="ondc-data-cell">{data.sellerNPOrderId}</td>
                            <td className="ondc-data-cell">{data.itemId}</td>
                            <td className="ondc-data-cell">{data.qty}</td>
                            <td className="ondc-data-cell">{data.sellerNPType}</td>
                            <td className="ondc-data-cell">{data.orderStatus}</td>
                            <td className="ondc-data-cell">{data.nameOfSeller}</td>
                            <td className="ondc-data-cell">{data.sellerPincode}</td>
                            <td className="ondc-data-cell">{data.sellerCity}</td>
                            <td className="ondc-data-cell">{data.skuName}</td>
                            <td className="ondc-data-cell">{data.skuCode}</td>
                            <td className="ondc-data-cell">{data.orderCategory}</td>
                            <td className="ondc-data-cell">{data.readyToShipAtDateTime}</td>
                            <td className="ondc-data-cell">{data.shippedAtDateTime}</td>
                            <td className="ondc-data-cell">{data.deliveredAtDateTime}</td>
                            <td className="ondc-data-cell">{data.deliveryType}</td>
                            <td className="ondc-data-cell">{data.logisticsSellerNPName}</td>
                            <td className="ondc-data-cell">{data.logisticsNetworkOrderId}</td>
                            <td className="ondc-data-cell">{data.logisticsNetworkTransactionId}</td>
                            <td className="ondc-data-cell">{data.deliveryCity}</td>
                            <td className="ondc-data-cell">{data.deliveryPincode}</td>
                            <td className="ondc-data-cell">{data.cancelledAtDateTime}</td>
                            <td className="ondc-data-cell">{data.cancelledBy}</td>
                            <td className="ondc-data-cell">{data.cancellationOrReturnReason}</td>
                            <td className="ondc-data-cell">{data.totalShippingCharges}</td>
                            <td className="ondc-data-cell">{data.totalOrderValue}</td>
                            <td className="ondc-data-cell">{data.totalRefundAmount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default OndcOrders;
