import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { v4 as uuid } from "uuid";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelHandler, GroupChannelModule, HiddenChannelFilter } from "@sendbird/chat/groupChannel";
import { SENDBIRD_APP_ID } from "../../api/apiConfig/apiConfig";
import { APIConfig } from "../../api/apiConfig/apiConfig";
import { timestampToTime, handleEnterPress } from "../utils/messageUtils";
import LoadingSpinner from "../loader/loader";
import axios from "axios";
import "./chat.css";
import MessagesList from "./MessagesList";
import MessageInput from "./MessageInput";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LogisticsService from "../../api/services/LogisticsService";
import { sendBirdGroupChannelUrl } from '../../utils/constants';


let sb;

const Index = (props) => {
  const {ApiToken} = APIConfig;
  const [usersData, setUsersData] = useState([]);

  const [id, setID] = useState();
  const [messageInputValue, setMessageInputValue] = useState("");
  const [selectUser, setSelectUser] = useState("");
  const [apiChannels, setApiChannels] = useState([])
  const [selectChannel, setSelectChannel] = useState("");
  const [merchantNumber, setMerchantNumber] = useState('')
  const [userNumber, setUserNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, updateState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    userNameInputValue: "",
    userIdInputValue: "",
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    loading: false,
    error: false,
    messageMarkAsDelivered: false,
    isMessagesRead: false,
  });

  //need to access state in message received callback
  const stateRef = useRef();
  stateRef.current = state;

  const channelRef = useRef();

  const scrollToBottom = (item, smooth) => {
    item?.scrollTo({
      top: item.scrollHeight,
      behavior: smooth,
    });
  };

  const sendbirdApiCall = async (value) => {
    const val = `user_${value}`;
      window.sessionStorage.removeItem("UserId");

      const { userNameInputValue } = state;
      const sendbirdChat = await SendbirdChat.init({
        appId: SENDBIRD_APP_ID.appId,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChat.connect(val);
      await sendbirdChat.setChannelInvitationPreference(true);

      const userUpdateParams = {};
      userUpdateParams.nickname = userNameInputValue;
      userUpdateParams.userId = val;
      await sendbirdChat.updateCurrentUserInfo(userUpdateParams);

      sb = sendbirdChat;
      const [channels, error] = await loadChannels();
      if (error) {
        return onError(error);
      }
      setSelectChannel("");
      console.log("channels from sendbird api====>", channels)
      // updateState({ ...state, channels: channels, loading: false, settingUpUser: false });
      return [channels];
  }

  const setSendbirdUser = async (channelUrl) => {
    window.sessionStorage.removeItem("UserId");
    const {channels} = state;
    const channel = channels.find((channel) => channel.url === channelUrl);
    const Val = channel.members.find((value) => 
      value.userId === userNumber || value.userId === `user_${userNumber}`
    )
    let val = '';
    if (Val.userId === userNumber) {
      val = userNumber
    } else {
      val = `user_${userNumber}`
    }

      const { userNameInputValue } = state;
      const sendbirdChat = await SendbirdChat.init({
        appId: SENDBIRD_APP_ID.appId,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChat.connect(val);
      await sendbirdChat.setChannelInvitationPreference(true);

      const userUpdateParams = {};
      userUpdateParams.nickname = userNameInputValue;
      userUpdateParams.userId = val;
      await sendbirdChat.updateCurrentUserInfo(userUpdateParams);

      sb = sendbirdChat;
  }

  const handleGetId = async (newValue) => {
    console.log("number==>", newValue)
    if (newValue === null || id === null || newValue === undefined) {
      updateState({
        applicationUsers: [],
        groupChannelMembers: [],
        currentlyJoinedChannel: null,
        messages: [],
        channels: [],
        userIdInputValue: "",
        channelNameUpdateValue: "",
      });
      setMessageInputValue("");
      setID("");
      setSelectChannel("");
      window.sessionStorage.removeItem("UserId");
    } else {
      const value = newValue;
      setUserNumber(value)
      window.sessionStorage.removeItem("UserId");

      const { userNameInputValue } = state;
      const sendbirdChat = await SendbirdChat.init({
        appId: SENDBIRD_APP_ID.appId,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChat.connect(value);
      await sendbirdChat.setChannelInvitationPreference(true);

      const userUpdateParams = {};
      userUpdateParams.nickname = userNameInputValue;
      userUpdateParams.userId = value;
      await sendbirdChat.updateCurrentUserInfo(userUpdateParams);

      sb = sendbirdChat;
      const [channels, error] = await loadChannels();
      if (error) {
        return onError(error);
      }
      setSelectChannel("");
      console.log("channels from sendbird api====>", channels)
      const api1Len = channels.length
      let [ch2] = await sendbirdApiCall(value);
      const api2Len = ch2.length;
      
      ch2.map((channel) => {
        channels.push(channel);
      })
      console.log("mergerd c==>",channels)

      // let selectedChannels = null
      // if (api2Len >= api1Len) {
      //   selectedChannels = ch2;
      // } else {
      //   selectedChannels = channels;
      //   await sendbirdChat.connect(value);
      //   userUpdateParams.userId = value;
      // }

      // updateState({ ...state, channels: ch, loading: false, settingUpUser: false });
      // const filteredChannels = channels.filter((channel) => {
      //   return channel.memberCount >= 2;
      // });
      // updateState({ ...state, channels: filteredChannels, loading: false, settingUpUser: false });
      // console.log('filteredchannels================>', filteredChannels)

      setIsLoading(true);
      await LogisticsService.getUserMerchantChannels(value)
        .then(async (res) => {
          setIsLoading(false);
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  const chan = result.data.channels.filter((channel) => channel.merchant_id!=='')
                  setApiChannels(chan)
                  console.log("channels fetched from api==============>", chan)
                  updateState({
                    ...state, channels: channels, loading: false, settingUpUser: false
                  })
                }
              })
            }
              catch (err) {
                console.log(err);
                updateState({
                  applicationUsers: [],
                  groupChannelMembers: [],
                  currentlyJoinedChannel: null,
                  messages: [],
                  channels: [],
                  userIdInputValue: "",
                  channelNameUpdateValue: "",
                });
                setMessageInputValue("");
                setSelectUser("");
                setID("");
                setSelectChannel("");
                setUsersData([]);
              }
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
      
      
      setID(value);
      
      
      
    }
  };

  useEffect(() => {
    scrollToBottom(channelRef.current);
  }, [state.currentlyJoinedChannel]);

  useEffect(() => {
    scrollToBottom(channelRef.current, "smooth");
  }, [state.messages]);

  const onError = (error) => {
    updateState({ ...state, error: error.message });
    console.log(error);
  };

  const handleRead = async (channel) => {
    if (channel.unreadMessageCount === 0) {
      try {
        await channel.markAsRead();
        updateState({ ...stateRef.current, isMessagesRead: true });
      } catch (error) {
        console.log("error", error);
      }
    }
  };


  const handleJoinChannel = async (member, channelUrl) => {
    console.log("channelUrl", channelUrl);
    console.log("member", member);
    setMerchantNumber(member)
    const UserId = member;
    // const UserId = member && member !== null && member.length > 0 && member.map((datas) => datas.userId).filter((data) => !data.includes(id));
    window.sessionStorage.setItem("UserId", UserId);

    // if (state.currentlyJoinedChannel?.url === channelUrl) {
    //   return null;
    // }
    const { channels } = state;
    // console.log("channels=====>", channels)
    const channel = channels.find((channel) => channel.url === channelUrl);


        const [messages, error] = await joinChannel(channel)
          if (error) {
            return onError(error);
          }

          console.log("messages===>", messages)
          const channelHandler = new GroupChannelHandler();
          channelHandler.onUserJoined = () => {};
          channelHandler.onChannelChanged = () => {};
          channelHandler.onUnreadMemberCountUpdated = (channel) => {
            
            if (channel.unreadMessageCount === 0) {
              updateState({ ...stateRef.current, isMessagesRead: true });
            }
          };
          console.log("cnl",channel)
          channelHandler.onMessageReceived = (channel, message) => {
            const updatedMessages = [...stateRef.current.messages, message];
            setTimeout(() => {
              updateState({ ...stateRef.current, messages: updatedMessages });
            }, 1000);
          };
      
          channelHandler.onMessageDeleted = (channel, message) => {
            const updatedMessages = stateRef.current.messages.filter((messageObject) => {
              return messageObject.messageId !== message;
            });
            updateState({ ...stateRef.current, messages: updatedMessages });
          };
          sb.groupChannel.addGroupChannelHandler(uuid(), channelHandler);
          updateState({ ...state, currentlyJoinedChannel: channel, messages: messages, loading: false, messageMarkAsDelivered: true });
    
    
    
    // listen for incoming messages
    
    // channelHandler.onMessageUpdated = (channel, message) => {
    //   const messageIndex = stateRef.current.messages.findIndex((item) => item.messageId == message.messageId);
    //   console.log("messageIndexonMessageUpdated",messageIndex);
    //   const updatedMessages = [...stateRef.current.messages];
    //   updatedMessages[messageIndex] = message;
    //   console.log("updatedMessagesonMessageUpdated",updatedMessages);
    //   updateState({ ...stateRef.current, messages: updatedMessages });
    // };

    
  };

  const handleLeaveChannel = async () => {
    const { currentlyJoinedChannel } = state;
    await currentlyJoinedChannel.leave();

    updateState({ ...state, currentlyJoinedChannel: null });
  };

  const handleCreateChannel = async (channelName = "testChannel") => {
    const [groupChannel, error] = await createChannel(channelName, state.groupChannelMembers);
    if (error) {
      return onError(error);
    }

    const updatedChannels = [groupChannel, ...state.channels];
    updateState({ ...state, channels: updatedChannels, applicationUsers: [] });
  };

  const handleUpdateChannelMembersList = async () => {
    const { currentlyJoinedChannel, groupChannelMembers } = state;
    await inviteUsersToChannel(currentlyJoinedChannel, groupChannelMembers);
    updateState({ ...state, applicationUsers: [] });
  };

  const handleDeleteChannel = async (channelUrl) => {
    const [channel, error] = await deleteChannel(channelUrl);
    if (error) {
      return onError(error);
    }
    const updatedChannels = state.channels.filter((channel) => {
      return channel.url !== channelUrl;
    });
    updateState({ ...state, channels: updatedChannels });
  };

  const handleMemberInvite = async () => {
    const [users, error] = await getAllApplicationUsers();
    if (error) {
      return onError(error);
    }
    updateState({ ...state, applicationUsers: users });
  };

  const onUserNameInputChange = (e) => {
    const userNameInputValue = e.currentTarget.value;
    updateState({ ...state, userNameInputValue });
  };

  const onUserIdInputChange = (e) => {
    const userIdInputValue = e.currentTarget.value;
    updateState({ ...state, userIdInputValue });
  };

  const onMessageInputChange = (e) => {
    const messageInput = e.currentTarget.value;
    // updateState({ ...state, messageInputValue });
    setMessageInputValue(messageInput);
  };

  const sendMessage = async () => {
    const { messageToUpdate, currentlyJoinedChannel, messages } = state;

    // const userId = currentlyJoinedChannel !== null && currentlyJoinedChannel.members.length > 0 && currentlyJoinedChannel.members.map((datas) => datas.userId).filter((data) => !data.includes(id));
    const userId = merchantNumber;
    console.log(currentlyJoinedChannel)

    // console.log("userId", userId);

    if (userId !== null && userId !== '') {
      var data = JSON.stringify({
        message_type: "MESG",
        user_id: userId,
        message: messageInputValue,
      });
      var config = {
        method: "post",
        url: sendBirdGroupChannelUrl(currentlyJoinedChannel.url),
        headers: {
          "Api-Token": ApiToken,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setMessageInputValue("");
          handleJoinChannel(userId, currentlyJoinedChannel.url)
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("please select a valid merchant");
    }
  };

  const handleUserNumberSubmit = (e) => {
    if (e.target.value.length <=12) {
      setSelectUser(e.target.value)
    }
    if (e.target.value.length === 12) {
      handleGetId(e.target.value);
    }
  }

  const onFileInputChange = async (e) => {
    console.log("onFileInputChange", e.currentTarget.files);
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const { messageToUpdate, currentlyJoinedChannel, messages } = state;

      // const userId = currentlyJoinedChannel !== null && currentlyJoinedChannel.members.length > 0 && currentlyJoinedChannel.members.map((datas) => datas.userId).filter((data) => !data.includes(id));
      const userId = merchantNumber;

      // console.log("userId", userId);

      if (userId !==null && userId !=='') {
        var myHeaders = new Headers();
        myHeaders.append("Api-Token", ApiToken);

        var formdata = new FormData();
        formdata.append("message_type", "FILE");
        formdata.append("user_id", userId);
        formdata.append("file", e.currentTarget.files[0]);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        fetch(`https://api-${SENDBIRD_APP_ID.appId}.sendbird.com/v3/group_channels/${currentlyJoinedChannel.url}/messages`, requestOptions)
          .then((response) => response.text())
          .then(function (result) {
            console.log(result)
            handleJoinChannel(userId, currentlyJoinedChannel.url)
          })
          .catch((error) => console.log("error", error));
      } else {
        alert("please select a valid merchant");
      }
    }
  };

  const handleDeleteMessage = async (messageToDelete) => {
    const { currentlyJoinedChannel } = state;
    await deleteMessage(currentlyJoinedChannel, messageToDelete); // Delete
  };

  const updateMessage = async (message) => {
    updateState({ ...state, messageToUpdate: message });
    setMessageInputValue(message.message);
  };

  const handleLoadMemberSelectionList = async () => {
    updateState({ ...state, currentlyJoinedChannel: null });
    const [users, error] = await getAllApplicationUsers();
    if (error) {
      return onError(error);
    }
    updateState({ ...state, currentlyJoinedChannel: null, applicationUsers: users, groupChannelMembers: [sb.currentUser.userId] });
  };

  if (state.loading) {
    return (
      <div>
        {" "}
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  if (state.error) {
    return <div className="error">{state.error} check console for more information.</div>;
  }

  const UserId = window.sessionStorage.getItem("UserId");
  return (
    <div className="">
      <ChannelList
        usersData={usersData}
        id={id}
        selectUser={selectUser}
        selectChannel={selectChannel}
        setSelectUser={setSelectUser}
        setSelectChannel={setSelectChannel}
        channels={state.channels}
        handleGetId={handleGetId}
        handleJoinChannel={handleJoinChannel}
        handleCreateChannel={handleLoadMemberSelectionList}
        handleDeleteChannel={handleDeleteChannel}
        handleLoadMemberSelectionList={handleLoadMemberSelectionList}
        apiChannels={apiChannels}
        setSendbirdUser={setSendbirdUser}
        handleUserNumberSubmit={handleUserNumberSubmit}
        isLoading={isLoading}
      />
      {UserId !== null && UserId !== undefined && UserId !== "" && (
        <Channel currentlyJoinedChannel={state.currentlyJoinedChannel} handleLeaveChannel={handleLeaveChannel} channelRef={channelRef} id={id}>
          <MessagesList
            messages={state.messages}
            isMessagesRead={state.isMessagesRead}
            handleDeleteMessage={handleDeleteMessage}
            updateMessage={updateMessage}
            messageMarkAsDelivered={state.messageMarkAsDelivered}
          />
          <MessageInput value={messageInputValue} onChange={onMessageInputChange} sendMessage={sendMessage} fileSelected={state.file} onFileInputChange={onFileInputChange} />
        </Channel>
      )}
    </div>
  );
};

// Chat UI Components
const ChannelList = ({
  channels,
  handleJoinChannel,
  handleDeleteChannel,
  handleLoadMemberSelectionList,
  usersData,
  handleGetId,
  id,
  city,
  selectUser,
  setSelectUser,
  selectChannel,
  setSelectChannel,
  apiChannels,
  setSendbirdUser,
  handleUserNumberSubmit,
  isLoading
}) => {
  return (
    <div>
      {isLoading && (
          <LoadingSpinner />
        
      )}
      <div className="channel-type">
        <span className="user-input-container">
          <TextField className="user-input-field" value={selectUser} type="number" label="Enter User number *" onChange={(e) => handleUserNumberSubmit(e)} />
        </span>
        <span className="merchant-input-container">
          <Autocomplete
            id="tags-standard"
            value={selectChannel ? selectChannel : null}
            onChange={(event, newValue) => {
              setSendbirdUser(newValue.channel_url)
              .then(() => {
                handleJoinChannel(newValue.merchant_id, newValue.channel_url);
              })
              setSelectChannel(newValue);
              
            }}
            options={apiChannels}
            getOptionLabel={(option) => option.merchant_id}
            renderInput={(params) => <TextField {...params} label="Merchant" required />}
          />
        </span>
      </div>
    </div>
  );
};

const Channel = ({ currentlyJoinedChannel, children, handleLeaveChannel, channelRef, id }) => {
  // const merchantmembersToDisplay =
  //   currentlyJoinedChannel !== null && currentlyJoinedChannel.members.length > 0 && currentlyJoinedChannel.members.map((datas) => datas.userId).filter((data) => !data.includes(id));
  if (currentlyJoinedChannel) {
    return (
      <div className="channel" ref={channelRef}>
        {/* <ChannelHeader>{merchantmembersToDisplay}</ChannelHeader> */}
        {/* <div>
            <button className="leave-channel" onClick={handleLeaveChannel}>
              Leave Channel
            </button>
          </div> */}
        <div>{children}</div>
      </div>
    );
  }
  return <div className="channel"></div>;
};

const ChannelHeader = ({ children }) => {
  return <div className="channel-header">{children}</div>;
};

// Helpful functions that call Sendbird
const loadChannels = async () => {
  try {
    const groupChannelQuery = sb.groupChannel.createMyGroupChannelListQuery({ limit: 100, includeEmpty: true, hiddenChannelFilter: HiddenChannelFilter.ALL });
    const channels = await groupChannelQuery.next();
    

    return [channels, null];
  } catch (error) {
    return [null, error];
  }
};

const joinChannel = async (channel) => {
  try {
    const messageListParams = {};
    messageListParams.prevResultSize = 200;
    messageListParams.nextResultSize = 200;
    // messageListParams.reverse=true;
    const message = await channel.getMessagesByTimestamp(Date.now(), messageListParams);
    console.log("messagefom===>", message)
    return [message, null];
  } catch (error) {
    return [null, error];
  }
};

const inviteUsersToChannel = async (channel, userIds) => {
  await channel.inviteWithUserIds(userIds);
};

const createChannel = async (channelName, userIdsToInvite) => {
  try {
    const groupChannelParams = {};
    groupChannelParams.addUserIds = userIdsToInvite;
    groupChannelParams.name = channelName;
    groupChannelParams.operatorUserIds = userIdsToInvite;
    const groupChannel = await sb.groupChannel.createChannel(groupChannelParams);
    return [groupChannel, null];
  } catch (error) {
    return [null, error];
  }
};

const deleteChannel = async (channelUrl) => {
  try {
    const channel = await sb.groupChannel.getChannel(channelUrl);
    await channel.delete();
    return [channel, null];
  } catch (error) {
    return [null, error];
  }
};

const deleteMessage = async (currentlyJoinedChannel, messageToDelete) => {
  await currentlyJoinedChannel.deleteMessage(messageToDelete);
};

const getAllApplicationUsers = async () => {
  try {
    const userQuery = sb.createApplicationUserListQuery({ limit: 100 });
    const users = await userQuery.next();
    return [users, null];
  } catch (error) {
    return [null, error];
  }
};

export default Index;
