import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Container } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import "../../styles/home.css"
import "../../Dashboard.css";
import './MasterCatalog.css'
import Sidebar from '../../components/sidebar.js'
import { TextField, Dialog } from "@mui/material";
import { APIConfig } from '../../api/apiConfig/apiConfig';
import axios from 'axios';
import Papa from 'papaparse';
import * as XLSX from "xlsx";
import FileUploader from '../../components/customComponent/FileUploader/FileUploader';
import edit_icon from '../../images/edit.png'
import { useDropzone } from "react-dropzone";
import { alertSuccessMessageMerchant, alertDangerConfirmMessage } from "../../components/customComponent/ConfirmationMessage/ConfirmationMessage";
import LoadingSpinner from '../../components/loader/loader';
import moment from 'moment';
import { ExportToCSV } from '../../components/ExportToCSV';
import FileUploaderMasCatalog from '../../components/customComponent/FileUploader/FileUploaderMasCatalog';
import close_icon from '../../payout/close.png'
import AwsImageService from '../../api/services/AwsImageService';

const tags = [
  { id: 0, name: "2 Star", value: 'KVI' },
  { id: 1, name: "1 Star", value: "y" },
  { id: 2, name: "Regular", value: 'n' },
]

const skus = [
  { id: 0, name: "Yes", value: "y" },
  { id: 1, name: "No", value: "n" },
]

const cities = [
  { id: 0, value: "Bangalore" },
  { id: 1, value: "Mumbai" },
]

const MasterCatalog = () => {
  const [l3Categories, setL3Categories] = useState([]);
  const [s1Categories, setS1Categories] = useState([{ id: 0, categoryname: 'Select S1 Category' }]);
  const [s2Categories, setS2Categories] = useState([{ id: 0, categoryname: 'Select S2 Category' }]);
  const [selectedS1, setSelectedS1] = useState('');
  const [selectedL3, setSelectedL3] = useState('');
  const [masterCatalogData, setMasterCatalogData] = useState([]);
  const [masterCatalogCount, setMasterCatalogCount] = useState(null);
  const [showAddItem, setShowAddItem] = useState(false);
  const [newProductData, setNewProductData] = useState({ tag: 'n', sku: 'n' });
  const [updateItem, setUpdateItem] = useState({});
  const [updateIndex, setUpdateIndex] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [emptySearchResult, setEmptySearchResult] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [fileFormat] = useState(
    "image/jpeg, image/jpg, image/png"
  );
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const [topSellersFlag, setTopSellersFlag] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [imageModal, setImageModal] = useState(false);
  const [pdImages, setPdImages] = useState([]);
  const [editImages, setEditImages] = useState(false);
  const { baseUrl, getL3ForMasterCataloguePanel, getMasterCatalogueS1Categories, getMasterCatalogueForPanel, addMasterCatalog, getMasterCatalogue, searchInMasterCatalogue } = APIConfig;
  const fileInputRef = useRef(null);

  useEffect(() => {
    getL3Categories();
  }, [])

  const getL3Categories = async () => {
    await axios
      .get(baseUrl + getL3ForMasterCataloguePanel, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        console.log("res", response.data)
        if (response.data.code === 0) {
          console.log("res", response.data.data)
          let l3Data = [{ id: 0, categoryname: 'Select L3 Category' }];
          response.data.data.map((data) => {
            l3Data.push(data);
          })
          console.log("L3dat", l3Data)
          setL3Categories(l3Data);
        }
      })
      .catch(function (error) {
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  // Function to get s1/s2 categories dropdown data
  const getS1S2Categories = async (type, l3Id) => {
    const data = {
      panel_flag: 1,
      categoryid: l3Id ? l3Id : selectedL3,
      type: type
    }
    await axios
      .post(baseUrl + getMasterCatalogueS1Categories, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        console.log("res", response.data)
        if (response.data.code === 0) {
          console.log("res", response.data.data)
          let s1s2Data = [{ id: 0, categoryname: `Select ${type === 'S1' ? 'S1' : 'S2'} Category` }];
          response.data.data.map((data) => {
            s1s2Data.push(data);
          })
          console.log("L3dat", s1s2Data)
          if (type === 'S1') {
            setS1Categories(s1s2Data);
          } else {
            setS2Categories(s1s2Data);
          }
        } else {
          if (type === 'S1') {
            setS1Categories([{ id: 0, categoryname: 'Select S1 Category' }]);
          } else {
            setS2Categories([{ id: 0, categoryname: 'Select S2 Category' }]);
          }
        }
      })
      .catch(function (error) {
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  // Function to get first set of products
  const getMasterCatalog = async () => {
    let s1CategoryName = ''
    if (selectedS1) {
      s1Categories.map((item) => {
        if (item.id === selectedS1) {
          s1CategoryName = item.categoryname
        }
      });
    }

    setSpinner(true);
    let data = {}
    if (s1CategoryName !== '') {
      data = {
        merchantCat: selectedL3,
        topSellers_flag: 1,
        lastEvaluatedKey: "",
        category: s1CategoryName,
        panel_flag: 1,
      }
    } else {
      data = {
        merchantCat: selectedL3,
        topSellers_flag: 1,
        lastEvaluatedKey: "",
      }
    }

    await axios
      .post(baseUrl + (s1CategoryName !== '' ? getMasterCatalogue : getMasterCatalogueForPanel), data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        setSpinner(false);
        console.log("res", response.data)
        if (response.data.code === 0) {
          console.log("res", response.data.data)
          let masterData = [];
          response.data.topSellers.map((data) => {
            masterData.push(data);
          })
          setMasterCatalogData(masterData);
          setLastEvaluatedKey(response.data.lastEvaluatedKey)
          setTopSellersFlag(response.data.topSellers_flag)
          setEmptySearchResult(false);
          setShowAddItem(false);
          setUpdateIndex(null);
          setSearchResult(false);
        } else {
          setMasterCatalogData([]);
        }
      })
      .catch(function (error) {
        setSpinner(false);
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
    getS1S2Categories('S1');
    handleMasterCatalogCount();
  }

  // Function to get next set of products
  const loadMoreData = async () => {
    setSpinner(true);

    let s1CategoryName = ''
    if (selectedS1) {
      s1Categories.map((item) => {
        if (item.id === selectedS1) {
          s1CategoryName = item.categoryname
        }
      });
    }
    let data = {};
    if (s1CategoryName !== '') {
      data = {
        merchantCat: selectedL3,
        topSellers_flag: topSellersFlag,
        lastEvaluatedKey: lastEvaluatedKey,
        category: s1CategoryName,
        panel_flag: 1,
      }
    } else {
      data = {
        merchantCat: selectedL3,
        topSellers_flag: topSellersFlag,
        lastEvaluatedKey: lastEvaluatedKey,
      }
    }
    await axios
      .post(baseUrl + (s1CategoryName !== '' ? getMasterCatalogue : getMasterCatalogueForPanel), data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        setSpinner(false);
        console.log("res", response.data)
        if (response.data.code === 0) {
          console.log("res", response.data.data)
          let masterData = [];
          response.data.topSellers.map((data) => {
            masterData.push(data);
          })
          setMasterCatalogData([...masterCatalogData, ...masterData]);
          setLastEvaluatedKey(response.data.lastEvaluatedKey);
          setTopSellersFlag(response.data.topSellers_flag);
        } else {
          setMasterCatalogData([]);
        }
      })
      .catch(function (error) {
        setSpinner(false);
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  // New image for new product uploading to s3 bucket
  const handleNewImage = async (item) => {
    try {
      const imgData = await Promise.all(item.map(async (imgpaths) => {
        try {
          const data = await AwsImageService.uploadImageToS3(imgpaths.file, 'Master');
          if (data !== '') {
            return data;
          }
        } catch (err) {
          console.error(err);
        }
      }));

      setNewProductData((prevItem) => ({
        ...prevItem,
        picUrl: imgData
      }));
    } catch (error) {
      console.error(error);
    }
  };


  // updating new image for existing product
  const handleUpdateImage = (item) => {
    console.log("item", item)
    item.map((imgpaths) => {
      AwsImageService.uploadImageToS3(imgpaths.file, 'Master')
        .then(data => {
          if (data !== '') {
            console.log("IMG EXE")
            setUpdateItem((prevItem) => ({
              ...prevItem,
              image_url: [data]
            }))
          }
        }
        )
        .catch(err => console.error(err));
    })
  }

  // Converting csv to JSON object
  const handleFileUpload = (event) => {
    console.log("file upload executed", event)
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const contents = e.target.result;
      const extension = file.name.split('.').pop().toLowerCase();

      // Process the file based on the file extension
      if (extension === 'csv') {
        parseCSV(contents);
      } else if (extension === 'xlsx' || extension === 'xls') {
        parseExcel(contents);
      } else {
        alert('Invalid file format. Please upload a CSV or Excel file.');
      }
    };

    reader.readAsText(file);
  };

  const parseCSV = (contents) => {
    const result = Papa.parse(contents, { header: true, skipEmptyLines: true });
    uploadCsvData(result.data);
  };

  const parseExcel = (contents) => {
    const workbook = XLSX.read(contents, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
    const headers = data[0];
    const rows = data.slice(1);

    // Convert rows to objects
    const objects = rows.map((row) =>
      headers.reduce((obj, header, index) => {
        obj[header] = row[index];
        return obj;
      }, {})
    );
    uploadCsvData(objects);
  };

  const uploadCsvData = async (uplData) => {
    setSpinner(true);

    const data = {
      is_csvupload_flag: 0,
      l3_category_id: selectedL3,
      jsonItems: uplData
    }
    await axios
      .post(baseUrl + addMasterCatalog, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        setSpinner(false);
        console.log("res", response.data)
        if (response.data.code === 0) {
          console.log("res", response.data.data)
          alertSuccessMessageMerchant("File Uploaded")
          getMasterCatalog();
        } else {
          alertDangerConfirmMessage("File Upload Failed")
        }
      })
      .catch(function (error) {
        setSpinner(false);
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  // api call for adding new product
  const addNewData = async () => {
    const data = {
      is_csvupload_flag: 1,
      l3_category_id: selectedL3,
      product_name: newProductData.pdName,
      s1_category_name: newProductData.s1CatName,
      categoryid: newProductData.s1CatId,
      images: newProductData.picUrl,
      Top_Sellers: newProductData.tag,
      Brand: newProductData.brand,
      hero_sku: newProductData.sku,
      s2_category_name: newProductData.s2CatName,
      s2categoryid: newProductData.s2CatId !== "0" ? newProductData.s2CatId : ""
    }
    console.log("dt", data)
    if (data.images[0] === undefined || data.product_name === undefined || data.categoryid === undefined || data.categoryid === '0') {
      alert("S1 category, product name and image fields are mandatory!")
    } else {
      await axios
        .post(baseUrl + addMasterCatalog, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
        .then((response) => {
          console.log("res", response.data)
          if (response.data.code === 0) {
            console.log("res", response.data.data)
            setShowAddItem(false);
            getMasterCatalog();
            setNewProductData({ tag: 'KVI', sku: 'y' });
            alertSuccessMessageMerchant("Item Added")
          } else {

          }
        })
        .catch(function (error) {
          alertDangerConfirmMessage(error.message)
          console.log(error);
        });
    }

  }

  // api call for updating an existing product
  const handleNewUpdateData = async () => {
    let s1CategoryName = '';
    let s2CategoryName = '';
    s1Categories.map((item) => {
      if (item.id === updateItem.categoryid) {
        s1CategoryName = item.categoryname
      }
    });
    s2Categories.map((item) => {
      if (item.id === updateItem.s2categoryid) {
        s2CategoryName = item.categoryname
      }
    });
    const data = {
      is_csvupload_flag: 1,
      l3_category_id: selectedL3,
      product_name: updateItem.item_name,
      s1_category_name: s1CategoryName,
      categoryid: updateItem.categoryid,
      images: updateItem.image_url,
      Top_Sellers: updateItem.Top_Sellers ? updateItem.Top_Sellers : 'n',
      Brand: updateItem.brandName,
      hero_sku: updateItem.hero_sku ? updateItem.hero_sku : 'n',
      id: updateItem.id,
      s2_category_name: s2CategoryName,
      s2categoryid: updateItem.s2categoryid !== "0" ? updateItem.s2categoryid : "",
    }
    console.log("data is", data)
    await axios
      .post(baseUrl + addMasterCatalog, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        console.log("res", response.data)
        if (response.data.code === 0) {
          console.log("res", response.data.data)
          setUpdateIndex(null);
          setUpdateItem({});
          getMasterCatalog();
        } else {

        }
      })
      .catch(function (error) {
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  const handleUpdate = (item, index) => {
    setUpdateItem(item);
    setUpdateIndex(index);
    setUpdateItem((prevItem) => ({
      ...prevItem,
      id: item.id
    }))
    getS1S2Categories('S2');
  }

  const handleS1ChangeFromAdd = (e) => {
    let categoryName = ''
    s1Categories.map((item) => {
      if (item.id === e.target.value) {
        categoryName = item.categoryname
      }
    });
    setNewProductData((prevItem) => ({
      ...prevItem,
      s1CatId: e.target.value,
      s1CatName: categoryName
    }))
    getS1S2Categories('S2');
  }

  const handleS2ChangeFromAdd = (e) => {
    let categoryName = ''
    s2Categories.map((item) => {
      if (item.id === e.target.value) {
        categoryName = item.categoryname
      }
    });
    setNewProductData((prevItem) => ({
      ...prevItem,
      s2CatId: e.target.value,
      s2CatName: categoryName
    }))
  }

  // Updating uploaded file for updating existing image
  const onDrop = useCallback((acceptedFiles) => {
    console.log("AcceptedFiles.........", acceptedFiles);

    let item = [];
    acceptedFiles.forEach((file) => {
      console.log("Initial file.........", file);
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onloadend = () => {
        const binaryStr = reader.result;
        // const binaryStr1 = reader.result;
        // file.uri = binaryStr1;
        item.push({ base64: binaryStr, file: file });
        handleUpdateImage(item);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileFormat,
    multiple: true,

    onDrop,
  });

  const handleMasterCatalogCount = () => {
    console.log("selectedS1inFn==>", selectedS1)
    if (selectedS1 !== "0" && selectedS1 !== "") {
      s1Categories.map((item) => {
        if (item.id === selectedS1) {
          setMasterCatalogCount(item.master_catalogue_count);
        }
      });
    } else {
      l3Categories.map((item) => {
        if (item.id === selectedL3) {
          setMasterCatalogCount(item.master_catalogue_count);
        }
      });
    }

  }

  function convertedDate(date) {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    return formattedDate
  }

  const handleSearch = async () => {
    setSpinner(true);

    const data = {
      keyword: searchInput,
      categoryid: selectedL3,
      panel_flag: 1,
    }
    console.log("data is", data)
    await axios
      .post(baseUrl + searchInMasterCatalogue, data, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        setSpinner(false);
        console.log("res", response.data)
        if (response.data.code === 0) {
          setMasterCatalogData(response.data.searchResult)
          setSearchResult(true);
          if (response.data.searchResult.length === 0) {
            setEmptySearchResult(true);
          } else {
            setEmptySearchResult(false);
          }
          setLastEvaluatedKey("END");
        } else {

        }
      })
      .catch(function (error) {
        setSpinner(false);
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  const handleViewImages = (images) => {
    setPdImages(images);
    setImageModal(true)
  }


  return (
    <div>
      <div>
        <Sidebar />
      </div>
          <div className='page_container'>
            {spinner && (
              <LoadingSpinner />
            )}

            <div className='category-inputs-container'>
              <div className='flex top-container'>
                {console.log("data", updateItem)}
                <select v-model="selected" className="master-catalog-select" onChange={(e) => { setSelectedL3(e.target.value); getS1S2Categories('S1', e.target.value); }}>
                  {l3Categories.map(data => (
                    <option key={data.id} value={data.id} >
                      {data.categoryname}
                    </option>
                  ))}
                </select>
                {/* <select disabled={l3Categories.length === 0 ? true : false} v-model="selected" className="master-catalog-select">
                  {cities.map(data => (
                    <option key={data.id} value={data.value} >
                      {data.value}
                    </option>
                  ))}
                </select> */}
                <select disabled={l3Categories.length === 0 ? true : false} v-model="selected" className="master-catalog-select" onChange={(e) => setSelectedS1(e.target.value)}>
                  {s1Categories.map(data => (
                    <option key={data.id} value={data.id} >
                      {data.categoryname}
                    </option>
                  ))}
                </select>
                <button onClick={getMasterCatalog} className='view-all-btn master-catalog-btn'>View all</button>
              </div>

              <div className='flex'>
                <button onClick={() => setShowAddItem(true)} className='master-catalog-btn add-upload-btn'>Add</button>
                <div>
                  <input type="file" className='invisible-file-input' onChange={handleFileUpload} ref={fileInputRef} />
                  <button onClick={() => fileInputRef.current.click()} className='master-catalog-btn add-upload-btn'>Upload</button>
                </div>
                <ExportToCSV apiData={selectedL3} fileName="Csv format" />
              </div>
            </div>

            <div className='search-csv-container'>
              <div className='vip_history_input_container'>
                <TextField onChange={(e) => setSearchInput(e.target.value)} className='vip_history_input' label="Search any item" />
                <button onClick={searchInput.length === 0 ? null : handleSearch} className={searchInput.length !== 0 ? 'master-catalog-btn' : 'master_cat-search-btn-disabled'}>Submit</button>
              </div>
              <div className='export-csv-btn'>
                <ExportToCSV setSpinner={setSpinner} apiData={selectedL3} fileName="Master Catalog" />
              </div>

            </div>

            {emptySearchResult && (
              <p className='nodata-text'>
                No Data
              </p>
            )}
            {masterCatalogData.length !== 0 && (
              <div>
                {searchResult ? (
                  <p className='master-catalog-count'>Search Results</p>
                ) : (
                  <p className='master-catalog-count'>Total Count: {masterCatalogCount}</p>
                )}

                <table
                  style={{ marginTop: '20px', marginLeft: 0 }}
                >
                  <thead>
                    <tr>
                      <th colSpan="1" className="tableTextStyle normal-cell">Created Date</th>
                      <th colSpan="1" className="tableTextStyle large-cell">S1 Category</th>
                      <th colSpan="1" className="tableTextStyle large-cell">S2 Category</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Brand Name</th>
                      <th colSpan="1" className="tableTextStyle large-cell">Product Name</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Image</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Tag</th>
                      <th colSpan="1" className="tableTextStyle normal-cell">Hero SKU</th>
                      <th colSpan="1" className="tableTextStyle large-cell">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {showAddItem && (
                      <tr className="merchentList_tr">
                        <td className="merchentList_td td-value"></td>
                        <td className="merchentList_td td-value">
                          <select disabled={l3Categories.length === 0 ? true : false} v-model="selected" className="master-catalog-select w-100" onChange={handleS1ChangeFromAdd}>
                            {s1Categories.map(data => (
                              <option key={data.id} value={data.id} >
                                {data.categoryname}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="merchentList_td td-value">
                          <select disabled={l3Categories.length === 0 ? true : false} v-model="selected" className="master-catalog-select w-100" onChange={handleS2ChangeFromAdd}>
                            {s2Categories && s2Categories.map(data => (
                              <option key={data.id} value={data.id} >
                                {data.categoryname}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="merchentList_td td-value">
                          <input maxLength='35' className='add-inputs' onChange={(e) => setNewProductData((prevItem) => ({
                            ...prevItem,
                            brand: e.target.value
                          }))} />
                        </td>
                        <td className="merchentList_td td-value">
                          <input className='add-inputs' onChange={(e) => setNewProductData((prevItem) => ({
                            ...prevItem,
                            pdName: e.target.value
                          }))} />
                        </td>
                        <td className="merchentList_td td-value-image td-value">
                          {(newProductData.picUrl && newProductData.picUrl.length !== 0) ? (
                            <FileUploaderMasCatalog setNewProductData={setNewProductData} newProductData={newProductData} fromValue={'Add'} />
                          ) : (
                            <FileUploader masterCatalogImg={true} className='add-inputs' type='file' handleNewImage={
                              (item) =>
                                handleNewImage(item)
                            } isEditable={false} />
                          )}

                        </td>
                        <td className="merchentList_td td-value">
                          <select defaultValue="n" v-model="selected" className="master-catalog-select w-100" onChange={(e) => setNewProductData((prevItem) => ({
                            ...prevItem,
                            tag: e.target.value
                          }))}>
                            {tags.map(data => (
                              <option key={data.id} value={data.value} >
                                {data.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="merchentList_td td-value">
                          <select defaultValue="n" v-model="selected" className="master-catalog-select w-100" onChange={(e) => setNewProductData((prevItem) => ({
                            ...prevItem,
                            sku: e.target.value
                          }))}>
                            {skus.map(data => (
                              <option key={data.id} value={data.value} >
                                {data.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="merchentList_td td-value">
                          <div className='buttons-container'>
                            <button className='vip_history_submit_btn' onClick={addNewData}>Submit</button>
                            <button className='cancel-btn' onClick={() => { setShowAddItem(false); setNewProductData({ tag: 'n', sku: 'n' }); }}>Cancel</button>
                          </div>
                        </td>
                      </tr>
                    )}

                    {masterCatalogData.map((item, index) => {
                      if (updateIndex !== index) {
                        return (
                          <tr key={index} className="merchentList_tr">
                            <td className="merchentList_td td-value">
                              {item.created_date && convertedDate(item.created_date)}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.category}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.s2category}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.brandName}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.item_name}{(item.add_type && item.add_type === 'csv_upload') && '*'}
                            </td>
                            <td className="merchentList_td td-value-image">
                              {item.image_url.length > 1 ? (
                                <p className="image-count-text" onClick={() => handleViewImages(item.image_url)}>{item.image_url.length} Images</p>
                              ) : (
                                <img src={item.image_url[0]} className="product-image" alt="NA" />
                              )}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.Top_Sellers === 'KVI' ? '2 Star' : item.Top_Sellers === 'y' ? '1 Star' : 'Regular'}
                            </td>
                            <td className="merchentList_td td-value">
                              {item.hero_sku === 'y' ? 'Yes' : 'No'}
                            </td>
                            <td className="merchentList_td td-value">
                              <button className='vip_history_submit_btn' onClick={() => handleUpdate(item, index)}>Update</button>
                            </td>
                          </tr>
                        )
                      } else {
                        return (
                          <tr key={index} className="merchentList_tr">
                            <td className="merchentList_td td-value">
                              {item.created_date && convertedDate(item.created_date)}
                            </td>
                            <td className="merchentList_td td-value">
                              <select disabled={l3Categories.length === 0 ? true : false} value={updateItem.categoryid} v-model="selected" className="master-catalog-select w-100" onChange={(e) => setUpdateItem((prevItem) => ({
                                ...prevItem,
                                categoryid: e.target.value
                              }))}>
                                {s1Categories.map(data => (
                                  <option key={data.id} value={data.id} >
                                    {data.categoryname}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="merchentList_td td-value">
                              <select value={updateItem.s2categoryid} v-model="selected" className="master-catalog-select w-100" onChange={(e) => setUpdateItem((prevItem) => ({
                                ...prevItem,
                                s2categoryid: e.target.value
                              }))}>
                                {s2Categories.map(data => (
                                  <option key={data.id} value={data.id} >
                                    {data.categoryname}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="merchentList_td td-value">
                              <input maxLength='35' className='add-inputs' onChange={(e) => setUpdateItem((prevItem) => ({
                                ...prevItem,
                                brandName: e.target.value
                              }))} value={updateItem.brandName} />
                            </td>
                            <td className="merchentList_td td-value">
                              <input className='add-inputs' onChange={(e) => setUpdateItem((prevItem) => ({
                                ...prevItem,
                                item_name: e.target.value
                              }))} value={updateItem.item_name} />
                            </td>
                            <td className="merchentList_td td-value-image">
                              {updateItem.image_url.length > 0 && (
                                <div onClick={() => setEditImages(true)} className='images-edit-text'>
                                  <p className="image-count-text">{updateItem.image_url.length} {updateItem.image_url.length === 1 ? 'Image' : 'Images'}</p>
                                  <img src={edit_icon} style={{ width: '20px' }} alt="NA" />
                                </div>
                              )}

                            </td>
                            <td className="merchentList_td td-value">
                              <select v-model="selected" className="master-catalog-select w-100" defaultValue={updateItem.Top_Sellers} onChange={(e) => setUpdateItem((prevItem) => ({
                                ...prevItem,
                                Top_Sellers: e.target.value
                              }))}>
                                {tags.map(data => (
                                  <option key={data.id} value={data.value} >
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="merchentList_td td-value">
                              <select v-model="selected" className="master-catalog-select w-100" defaultValue={updateItem.hero_sku ? updateItem.hero_sku : 'n'} onChange={(e) => setUpdateItem((prevItem) => ({
                                ...prevItem,
                                hero_sku: e.target.value
                              }))}>
                                {skus.map(data => (
                                  <option key={data.id} value={data.value} >
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="merchentList_td td-value">
                              <div className='buttons-container'>
                                <button className='vip_history_submit_btn save-btn' onClick={handleNewUpdateData}>Save</button>
                                <button className='cancel-btn' onClick={() => setUpdateIndex(null)}>Cancel</button>
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </table>

                {lastEvaluatedKey !== 'END' && (
                  <div className='showmore-container'>
                    <p className='showmore-text' onClick={loadMoreData}>Show more..</p>
                  </div>
                )}

              </div>
            )}

          </div>
          <Dialog onClose={() => setImageModal(false)} open={imageModal}>
            <img
              className="close-img"
              src={close_icon}
              onClick={() => setImageModal(false)}
              alt="close"
            />
            <div className="images-dialog-container">
              <div className="images-container">
                {pdImages.map((image) => (
                  <div className="each-image-div fit-content">
                    <img src={image} className="each-image-view" alt="NA" />
                  </div>
                ))}
              </div>
            </div>
          </Dialog>
          <Dialog onClose={() => setEditImages(false)} open={editImages}>
            <img
              className="close-img"
              src={close_icon}
              onClick={() => setEditImages(false)}
              alt="close"
            />
            <FileUploaderMasCatalog setEditImages={setEditImages} setUpdateItem={setUpdateItem} fromValue={'Edit'} updateItem={updateItem} updateIndex={updateIndex} />
          </Dialog>
          </div>
  )
}

export default MasterCatalog
