import { Fetch } from "../apiConfig/Fetch";
import { APIConfig } from "../apiConfig/apiConfig";

const CBAddEmployeeService = {
  getCBemployee: async (data) => {
    const { baseUrl, getCbEmployeeDetail } = APIConfig;
    const url = baseUrl + getCbEmployeeDetail;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      mobileNo: data.mobileNo,
    });

    const getCBemployeeByMobileNumberServicePromise = await Fetch(url, "POST", headers, params, "services_ getCBemployee", true);
    console.log(getCBemployeeByMobileNumberServicePromise);
    return getCBemployeeByMobileNumberServicePromise;

  },
  updateCBemployee: async (data) => {
    const { baseUrl, updateCbEmployeeDetail } = APIConfig;

    const url = baseUrl + updateCbEmployeeDetail;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      key_id:data.key_id,
      name: data.name,
      e_code: data.e_code,
      mobile_no: data.mobile_no,
      city: data.city,
      l1_manager: data.l1_manager,
      l1_mobile_no: data.l1_mobile_no,
      l2_manager: data.l2_manager,
      l2_mobile_no: data.l2_mobile_no,
      pincode: data.pincode,
      state:data.state,
      status:data.status,
      // field_employee: data.field_employee,
    });

    const CBUpdateEmployeeServicePromise = await Fetch(url, "POST", headers, params, "services_updateCBemployee", true);
    return CBUpdateEmployeeServicePromise;
  },
  getCBEmployeelist: async () => {
    const { baseUrl, getCBEmployee } = APIConfig;

    const url = baseUrl + getCBEmployee;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const getQcuserlistServicePromise = await Fetch(url, "GET", headers, "services_ getCBEmployee", true);
    return getQcuserlistServicePromise;
  },
  addCBemployee: async (data) => {
    const { baseUrl, addCbEmployee } = APIConfig;

    const url = baseUrl + addCbEmployee;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      name: data.name,
      e_code: data.e_code,
      mobile_no: data.mobile_no,
      city: data.city,
      l1_manager: data.l1_manager,
      l1_mobile_no: data.l1_mobile_no,
      l2_manager: data.l2_manager,
      l2_mobile_no: data.l2_mobile_no,
      pincode: data.pincode,
      state:data.state,
      status:data.status,
      field_employee: data.field_employee,
    });

    const CBAddEmployeeServicePromise = await Fetch(url, "POST", headers, params, "services_addCBemployee", true);
    return CBAddEmployeeServicePromise;
  },
};


export default CBAddEmployeeService;