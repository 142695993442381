import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import '../../Dashboard.css';
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import '../style/Qc.css';
import "moment-timezone";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Typography } from "@mui/material";
import Moment from 'react-moment';
import LoadingSpinner from "../../components/loader/loader.js";
import ApiService from "../../api/services/ApiService.js";
import { alertDangerConfirmMessage, alertSuccessMessageMerchant } from "../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js";
import { constants } from "../../utils/constants.js";


const PanImageCheck = (props) => {

  const [showShopPhoto, setShowshopPhoto] = useState(false);
  const [name, setName] = useState("");

  const [TicketNoCheck, setTicketNoCheck] = useState("");
  const [errormsgShopboard, seterrormsgShopboard] = useState("");
  const [rightImage, setRightImage] = useState(false);

  const [updatedTimestamp, setupdatedTimestamp] = useState("");
  const [disable, setdisable] = useState(true);
  const [mobileNo, setMobileNo] = useState("");

  const [agent_id, setAgent_id] = useState("")
  const [licenseQc, setLicenseQc] = useState();
  const [panQc, setPanQc] = useState();
  const [bankQc, setBankQc] = useState();
  const [selfieQc, setSelfieQc] = useState();
  const [panNumber, setPanNumber] = useState();
  const [panFrontImage, setPanFrontImage] = useState("")
  const [panBackImage, setPanBackImage] = useState("")
  const [wrongImage, setWrongImage] = useState(false);
  const [isLicenseUpdated, setIsLicenseUpdated] = useState("")
  const [isPanUpdated, setIsPanUpdated] = useState("")
  const [isBankUpdated, setIsBankUpdated] = useState("")
  const [isSelfieUpdated, setIsSelfieUpdated] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState('');
  const { requestTimedOutError } = constants;


  const handleCloseShopPhoto = () => setShowshopPhoto(false);
  const handleShowShopPhoto = (image) => {
    setEnlargedImage(image);
    setShowshopPhoto(true);
  }

  useEffect(() => {

    setName(props.data.name);
    setTicketNoCheck(props.data.ticket_no);
    setupdatedTimestamp(props.data.pan_updated_at || props.data.created_at);
    setMobileNo(props.data.mobile_no);
    setAgent_id(props.data.agent_id);
    setPanNumber(props.data.pan_number)
    if (props.data.pan_image) {
      setPanFrontImage(props.data.pan_image.split(',')[0])
      setPanBackImage(props.data.pan_image.split(',')[1])
    }
    setPanQc(props.data.pan_qc)
    setLicenseQc(props.data.license_qc)
    setSelfieQc(props.data.selfie_qc)
    setIsLicenseUpdated(props.data.is_license_updated)
    setIsPanUpdated(props.data.is_pan_updated)
    setIsBankUpdated(props.data.is_bank_updated)
    setIsSelfieUpdated(props.data.is_selfie_updated)
    if (props.data.acc_verify_status === 0) {
      setBankQc(0)
    } else {
      setBankQc(props.data.bank_qc)
    }
  }, [props]);



  const rightPanImage = (event) => {
    setIsPanUpdated(1)
    setPanQc(0);
    setdisable(false);
    setRightImage(true)
    setWrongImage(false)
  };
  const wrongPanImage = (event) => {
    setIsPanUpdated(1)
    setPanQc(1);
    setdisable(false);
    setWrongImage(true)
    setRightImage(false)
  };

  const handlePanSubmit = async (event) => {
    setIsLoading(true)
    const token = localStorage.getItem('sessionId');
    const data = {
      ticket_no: TicketNoCheck,
      license_qc: licenseQc,
      pan_qc: panQc,
      bank_qc: bankQc,
      selfie_qc: selfieQc,
      mobile_no: mobileNo,
      name: name,
      agent_id: agent_id,
      pan_number: panNumber,
      ticketStatus: isLicenseUpdated == 1 && isPanUpdated == 1 && isBankUpdated == 1 && isSelfieUpdated == 1 ? 2 : 1
    };
    try {
      setIsLoading(true)
      const result = await ApiService.approveRejectPan(token, data);
      if (result.code === requestTimedOutError) {
        alertDangerConfirmMessage(result.message)
      } else if (result.data.code === 0) {
        alertSuccessMessageMerchant("Success");
        window.location.reload();
      } else {
        alertDangerConfirmMessage("ERROR")
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>

      <div className="background_validation pb-2">
        <Row>
          <Col className="mt-4 ">
            <div className=" pt-2 header">
              <b className="pt-3 title">Section -2: Verify Pan Card </b>

              <div className="d-inline-block float-right w-25 mt-3 border border-secondary">
                <div className="d-flex flex-row border-bottom border-secondary last_update">
                  <p className="pl-2 mb-0"><b>Last Update</b></p>

                </div>
                <div className="d-flex flex-row mt-2">
                  <p className="pl-2">Status : </p>
                  <p>{props.data.pan_qc == 0 ? "Match" : props.data.pan_qc == 1 ? "No match" : "New"}</p>
                </div>
                <div className="d-flex flex-row">
                  <p className="pl-2">Timestamp : </p>
                  <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
                </div>
              </div>
            </div>


          </Col>
        </Row>

        <Row>
          <Col md={3} className="pt-4" style={{ display: 'flex', flexDirection: 'column', }}>
            <Row>
              <div className=" color_border_validation height_cheque ">
                <img className="w-100 h-100  cursor-pointer" src={panFrontImage} onClick={() => handleShowShopPhoto(panFrontImage)} alt="PAN-front" />
              </div>
            </Row>
            <Row className="pt-4">
              <div className=" color_border_validation height_cheque">
                <img className="w-100 h-100  cursor-pointer" src={panBackImage} onClick={() => handleShowShopPhoto(panBackImage)} alt="PAN-back" />
              </div>
            </Row>
          </Col>
          <Col style={{ border: '0px solid red' }} >
            <Row>
              <Col md={1} className="pt-4 pl-0">
                <div className="image_wrong_div  mx-auto" onClick={rightPanImage} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}><img className="image_wrong cursor-pointer" src={right} alt="NA" /></div>
                <div className="text-center pt-1"><b>OK</b></div>
              </Col>
              <Col md={1} className="pt-4 pl-0" >
                <div className="image_wrong_div  mx-auto" onClick={wrongPanImage} style={{ backgroundColor: wrongImage == true ? "#FD626B" : "" }}><img className="image_wrong cursor-pointer" src={wrong} alt="NA" /></div>
                <div className="text-center pt-1"><b>Reject</b></div>
              </Col>
            </Row>

            {rightImage && (
              <Col md={8}>
                <div class="form-group">
                  <div className="pt-3">
                    <label className="label_cheque text-justify">Enter Name on Pan :</label>
                    <input className="input_cheque mt-0" name="bankname" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="pt-3">
                    <label className="label_cheque text-justify">Enter Pan Number : </label>
                    <input className="input_cheque mt-0" name="bankname" value={panNumber} onChange={(e) => setPanNumber(e.target.value)} />
                  </div>
                </div>
              </Col>
            )}
          </Col>
        </Row>

        <br />
        <br />

        <Row>
          <Col className="mt-5" style={{ display: "grid", justifyContent: "end" }}>
            <span>
              {rightImage ? (
                <button className="btn" disabled={disable || name === '' || panNumber === ''} onClick={handlePanSubmit}>
                  Submit
                </button>
              ) : (
                <button className="btn" disabled={disable} onClick={handlePanSubmit}>
                  Submit
                </button>
              )}

            </span>
            <p className="text-danger text-center pt-3">{errormsgShopboard}</p>
          </Col>
        </Row>

        {isLoading && (
          <LoadingSpinner />
        )}

        <Modal show={showShopPhoto} onHide={handleCloseShopPhoto}>
          <Modal.Body>
            <TransformWrapper>
              <TransformComponent>
                <img className="w-100 h-100" src={enlargedImage} alt="NA" />
              </TransformComponent>
            </TransformWrapper>
          </Modal.Body>
        </Modal>
      </div>


    </>
  );
};

const Dashboard = withRouter(PanImageCheck);
export default Dashboard;
