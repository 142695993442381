import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 190;
const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  drawerLastItem: {
    marginTop: theme.spacing(6)
  },
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    "&:focus": {
      border: '2px solid black',
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#CDECF4",
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey',
      borderRadius: 6,
    },
  },
  appBarGrid: {
    // backgroundColor: theme.palette.primary.main,
    width: "100%",
    marginLeft: 0,
    alignItems: "center"
  },
  logo: {
    alignItems: "center",
    width: 120,
    height: 120,
    resize: "contain",
    backgroundColor: "#fff !important"
  },
  title: {
    color: "#000",
    marginRight: theme.spacing(1),
    paddingRight: 0
  },
  button: {
    backgroundColor: "#0000",
    color: "#000",
    alignItems: "center",
    "&:focus": {
      border: '2px solid black',
    }
  },
  drawerTitle: {
    color: "#000",
    fontSize: "1.5rem"
  },
  userCard: {
    width: 300,
    height: 130,
    margin: 20,
    flexDirection: "row",
    display: "flex",
    padding: 10,
    paddingBottom: 0
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: 1,
    padding: theme.spacing(1),
    display: "flex",
    width: "70%",
    flexDirection: "column",
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary[200]
  },
  cover: {
    width: theme.spacing(4.8),
    height: theme.spacing(4.8),
    padding: 9,
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    borderStyle: "solid"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  childComponent: {
    marginLeft: drawerWidth + theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main
  },
  balance: {
    width: "100%",
    marginBottom: theme.spacing(1.5)
  },
  infoGrid: {
    textAlign: "end"
  },
  logoDiv: {
    textAlign: "center",
    backgroundColor: "#fff",
    width:"100%",
    // paddingTop: 7,
    // paddingBottom: 7,
  },
  drawerList: {
    marginTop: theme.spacing(1)
  },
  footerStlye: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  listStyle: {
    '&..MuiButtonBase-root.MuilistItem-root.MuilistItem-gutters.MuilistItem-button.Mui-selected.Mui-focusVisible.Mui-focusVisible': {
      backgroundColor: 'white'
    },
    "&.MuiListItem-root.Mui-selected": {
      border: '2px solid black',
    },
  },
  focusStyle: {
    "&:focus": {
      border: '2px solid black',
    }
  },
  paginationNumbers: {
    display: 'inline-block',
    marginTop: '-3px',
    float: 'right'
  }
  }));
export default useStyles;