import React, { useState, useEffect } from 'react';
import OrderListService from "../api/services/orderlist.js";
import searchService from "../api/services/SearchService.js";
import Moment from 'react-moment';
import { Container } from "reactstrap";
import { Row, Col, Table } from "react-bootstrap";
import "../styles/home.css"
import Link from '@mui/material/Link';
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import "../Dashboard.css";
import Sidebar from "./../components/sidebar.js";

const SearchOrders = (props) => {
    const [orderDetail, setOrderDetail] = useState([]);
    const [date, setDate] = useState(new Date());
    const [ordersGenerated, setOrdersGenerated] = useState(0);
    const [ordersAccepted, setOrdersAccepted] = useState(0);
    const [paymentLink, setPayment_link] = useState(0);
    const [paymentReceived, setPayment_received] = useState(0);
    const [paidLink, setpaidLink] = useState(0);
    const [deliveryReq, setDeliveryReq] = useState(0);
    const [deliveryPartner, setDeliveryPartner] = useState(0);
    const [deliveryCompleted, setDeliveryCompleted] = useState(0);
    const [orderID, setOrderID] = useState('');
    const [displayStatus, setDissplayStatus] = useState('0');
    const [custMobileNum, setCustMobileNum] = useState('');
    const [merchantMobileNum, setMerchantMobileNum] = useState('');
    const [orderData, setOrderData] = useState([]);
    const [orderIDList, setorderIDList] = useState([]);

    //Search Code

    const OrderListDetail = (e) => {
        // console.log(e.target.value);

        let data = {
            "orderId": e.target.value
        }
        orderIdSearch(data);
    }
    const orderIdSearch = async (data) => {
        await searchService.orderIdSearch(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log(result);
                            if (result.message !== "No order found") {
                                // alertSuccessMessageMerchant(`${result.message}`);
                                setOrderData([...result.data])
                                console.log(result.data);
                            } else {
                                setOrderData([]);
                                swal({
                                    title: "",
                                    text: `${result.message}`,
                                    icon: "error",
                                    className: "swal-modal-error-small",
                                    // timer: 4000,
                                });

                            }
                        });
                    } catch (err) {
                        // console.log(err);
                        // setIsLoading(false);
                    }
                }
            })

    }
    const merchantOrderList = async (data) => {
        await searchService.merchantMobileNumsearch(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log(result);
                            if (result.message !== "No order found") {
                                setDissplayStatus('1');
                                // alertSuccessMessageMerchant(`${result.message}`);
                                //setOrderData([...result.data])
                                setorderIDList([...result.data])
                            } else {
                                // alertDangerConfirmMessage(`${result.message}`);
                                setorderIDList([]);
                                setOrderData([]);
                                setDissplayStatus('0');
                                swal({
                                    title: "",
                                    text: `${result.message}`,
                                    icon: "error",
                                    className: "swal-modal-error-small",
                                    // timer: 4000,
                                });
                            }
                        });
                    } catch (err) {
                        // console.log(err);
                        // setIsLoading(false);
                    }
                }
            })

    }
    const customerOrderList = async (data) => {
        await searchService.customerMobileNumsearch(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log(result);
                            if (result.message !== "No order found") {
                                // alertSuccessMessageMerchant(`${result.message}`);
                                //setOrderData([...result.data])
                                setDissplayStatus('1');
                                setorderIDList([...result.data]);
                            } else {
                                // alertDangerConfirmMessage(`${result.message}`);
                                setorderIDList([]);
                                setOrderData([]);
                                setDissplayStatus('0');
                                swal({
                                    title: "",
                                    text: `${result.message}`,
                                    icon: "error",
                                    className: "swal-modal-error-small",
                                    // timer: 4000,
                                });
                            }
                        });
                    } catch (err) {
                        // console.log(err);
                        // setIsLoading(false);
                    }
                }
            })

    }
    const handleOrderID = async (e) => {
        e.preventDefault();
        setDissplayStatus('0');
        setOrderID(e.target.value);
        setCustMobileNum('');
        setMerchantMobileNum('');
        setorderIDList([]);
        setOrderData([]);
    }
    const handleCustMobileNumber = async (e) => {
        e.preventDefault();
        setDissplayStatus('0');
        setOrderID('');
        setMerchantMobileNum('');
        setCustMobileNum(e.target.value);
        // alert(e.target.value);
        setorderIDList([]);
        setOrderData([]);
    }
    const handleMerchantMobileNumber = async (e) => {
        e.preventDefault();
        setDissplayStatus('0');
        setOrderID('');
        setCustMobileNum('');
        setMerchantMobileNum(e.target.value);
        setorderIDList([]);
        setOrderData([]);
    }
    const checkSearchStatus = async (e) => {
        console.log(orderID);
        if (orderID.trim() !== '') {
            let data = {
                "orderId": orderID
            }
            orderIdSearch(data);
            setMerchantMobileNum('');
            setCustMobileNum('');
        }
        else if (merchantMobileNum.trim() !== '') {
            let data = {
                "merchantNumber": merchantMobileNum
            }
            console.log(data);
            merchantOrderList(data);
            setOrderID('');
            setCustMobileNum('');
        }
        else if (custMobileNum.trim() !== '') {
            let data = {
                "customerNumber": custMobileNum
            }
            console.log(data);
            customerOrderList(data);
            setOrderID('');
            setMerchantMobileNum('');
        }
        else {
            alert('Please enter');
        }



        // }
    }
    //Search Code
    const getorderList = async () => {
        await OrderListService.getOrderDetail()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    console.log(result.data.last_updated);
                                    setDate(result.data.last_updated);
                                    setOrdersGenerated(result.data.total_order);
                                    setOrdersAccepted(result.data.order_accepted);
                                    setPayment_link(result.data.order_with_payment_link);
                                    if (result.data.total_amt_recevied == null || result.data.total_amt_recevied == '') {

                                        setPayment_received(0);
                                    }
                                    else {
                                        setPayment_received(result.data.total_amt_recevied);
                                    }
                                    setpaidLink(result.data.order_with_paid_link);
                                    setDeliveryReq(result.data.cb_delivery_req);
                                    setDeliveryCompleted(result.data.cb_delivery_delivered);
                                    setDeliveryPartner(result.data.cb_delivery_allocated);

                                }
                            }
                        });
                    } catch (err) {
                        console.log(err);

                    }

                }
            });

    }
    const getOrderDetail = async () => {
        await OrderListService.getList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 1) {
                                if (result.data) {
                                    console.log([...result.data]);
                                    setOrderDetail([...result.data]);
                                    var res = [...result.data];
                                    res.sort(function (a, b) {
                                        return new Date(b.order_accepted_date) - new Date(a.order_accepted_date)
                                    })
                                    setOrderDetail(res);


                                }
                            }
                        });
                    } catch (err) {
                        console.log(err);

                    }

                }
            });

    }





    return (

        <div>
                <div>
                    <Sidebar />
                </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "2%", margin: " 0px auto", width: "90%", paddingTop:"140px" }}>
                            <span>
                                <TextField
                                    className="input_text_fields"
                                    id="outlined-basic"
                                    label="Order ID"
                                    variant="outlined"
                                    type="orderid"
                                    value={orderID}
                                    inputProps={{ maxLength: 10 }}
                                    required

                                    onChange={handleOrderID}
                                />
                            </span>
                            <span>
                                <TextField
                                    className="input_text_fields"
                                    id="outlined-basic"
                                    label="Customer Mobile Number"
                                    variant="outlined"
                                    type="text"
                                    value={custMobileNum}
                                    inputProps={{ maxLength: 15 }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    required
                                    onChange={handleCustMobileNumber}
                                />
                            </span>
                            {/* Merchant Mobile Number */}
                            <span>
                                <TextField
                                    className="input_text_fields"
                                    id="outlined-basic"
                                    label=" Merchant Mobile Number"
                                    variant="outlined"
                                    type="text"
                                    value={merchantMobileNum}
                                    inputProps={{ maxLength: 15 }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    required
                                    onChange={handleMerchantMobileNumber}
                                />
                            </span>
                            <span>
                                <button
                                    style={{ background: "#E98424", color: "#ffffff", boxSizing: "border-box", position: "relative", borderRadius: "10px", padding: "8px 70px 8px 70px" }}
                                    onClick={() => checkSearchStatus()}
                                >Submit</button>
                            </span>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 300px ", gap: "2%", margin: " 0px auto", height: "auto", width: "90%",paddingTop:"4%" ,paddingBottom:"4%"}}>
                            {orderData.length == 0 ? '' :
                                <span style={{ border: "1px solid #000", width: "100%" }}>
                                    

                                    <table >
                                        <tr >
                                            <td className='order_td'>Order id :</td>
                                            <td>{orderData[0].order_id}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>ONDC status :</td>
                                            <td>{orderData[0].is_ondc_order ? 'Yes' : 'No'}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Date :</td>
                                            <td>{orderData[0].date == null || orderData[0].date == "null" || orderData[0].date == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{orderData[0].date}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>City :</td>
                                            <td>{orderData[0].city}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>MID :</td>
                                            <td>{orderData[0].merchantid}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Merchant Mobile Number :</td>
                                            <td>{orderData[0].mainmobilenumber}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Merchant Lat & Long :</td>
                                            <td>{orderData[0].latitude+','+orderData[0].longitude}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Business Name :</td>
                                            <td>{orderData[0].bussinessname}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Business Address :</td>
                                            <td>{orderData[0].priority == 1 ? orderData[0].bussinessaddress1 : orderData[0].priority == 2 ? orderData[0].bussinessaddress2 : orderData[0].derived_address}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Business PinCode :</td>
                                            <td>{orderData[0].bussinesspincode}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Cust ID :</td>
                                            <td>{orderData[0].customer_id}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Customer Mobile Number :</td>
                                            <td>{orderData[0].usermobilenumber}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Customer Lat & Long :</td>
                                            <td>{orderData[0].ulatitude+','+orderData[0].ulongitude}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Customer Address :</td>
                                            <td>{orderData[0].address_line1!="" && orderData[0].address_line2!=""?orderData[0].address_line1+''+orderData[0].address_line2:orderData[0].address_line1!=""?orderData[0].address_line1:orderData[0].address_line2!=""?orderData[0].address_line2:''}</td>
                                        </tr>
                                        
                                        {/* <tr >
                                <td className='order_td'>Customer PinCode :</td>
                                <td></td>
                            </tr> */}
                                        <tr >
                                            <td className='order_td'>Business L1,L2,L3,L4 :</td>
                                            <td>{orderData[0].categoryList[0].l1categoryname},{orderData[0].categoryList[0].l2categoryname},{orderData[0].categoryList[0].l3categoryname},{orderData[0].categoryList[0].l4categoryname}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Order ID Status :</td>
                                            <td>{orderData[0].order_status == 1 ? 'Accepted' : orderData[0].order_status == 2 ? 'Amount Paid' : orderData[0].order_status == 3 ? 'Selected Delivery' : orderData[0].order_status == 4 ? 'Confirm Dispatch' : ''}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Order ID accept timestamp :</td>
                                            <td>{orderData[0].order_accepted_date == null || orderData[0].order_accepted_date == "null" || orderData[0].order_accepted_date == '' ? '' : <Moment format="MMM Do YYYY hh:mm:ss A">{orderData[0].order_accepted_date}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Order ID creation time :</td>
                                            <td>{orderData[0].order_created_date == null || orderData[0].order_created_date == "null" || orderData[0].order_created_date == '' ? '' : <Moment format="MMM Do YYYY hh:mm:ss A">{orderData[0].order_created_date}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Payment link ID :</td>
                                            <td>{orderData[0].paymentlist.length == 0 ? '' : orderData[0].paymentlist[0].payment_link_id == null || orderData[0].paymentlist[0].payment_link_id == 'null' || orderData[0].paymentlist[0].payment_link_id == '' ? '' : orderData[0].paymentlist[0].payment_link_id}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Payment link creation time :</td>
                                            <td>{orderData[0].paymentlist.length == 0 ? '' : orderData[0].paymentlist[0].paymentlinkcreateddate == null || orderData[0].paymentlist[0].paymentlinkcreateddate == 'null' || orderData[0].paymentlist[0].paymentlinkcreateddate == '' ? '' : <Moment format="MMM Do YYYY hh:mm:ss A">{(orderData[0].paymentlist[0].paymentlinkcreateddate.replace('T',' ')).replace('.000Z','')}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Payment link amount :</td>
                                            <td>{orderData[0].paymentlist.length == 0 ? '' : orderData[0].paymentlist[0].payment_link_amount == null || orderData[0].paymentlist[0].payment_link_amount == 'null' || orderData[0].paymentlist[0].payment_link_amount == '' ? '' : orderData[0].paymentlist[0].payment_link_amount}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Paid account time :</td>
                                            <td>{orderData[0].paymentlist.length == 0 ? '' : orderData[0].paymentlist[0].amountpaiddate == null || orderData[0].paymentlist[0].amountpaiddate == 'null' || orderData[0].paymentlist[0].amountpaiddate == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{orderData[0].paymentlist[0].amountpaiddate}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Delivery type :</td>
                                            <td>{orderData[0].delivery_mode == 0 ? 'CB delivery' : orderData[0].delivery_mode == 2?'Home delivery':'Self delivery'}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>CB delivery request time :</td>
                                            <td>{(orderData[0].set_delivery_mode_datetime && orderData[0].set_delivery_mode_datetime !== 'null') && <Moment format="MMM Do YYYY hh:mm A">{orderData[0].set_delivery_mode_datetime}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>CB delivery allocation status :</td>
                                            <td>{orderData[0].delivery_mode == 0 ? orderData[0].shipment_status == 1 ? "Assigned" : orderData[0].shipment_status == 2 ? "Arrived" : orderData[0].shipment_status == 3 ? "Picked Up" : orderData[0].shipment_status == 4 ? "Reached" : orderData[0].shipment_status == 5 ? "Delivered" : orderData[0].shipment_status == 6 ? "Rejected" : '' : ''}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>CB delivery partner allocation time :</td>
                                            <td>{orderData[0].cb_allocation_time == null || orderData[0].cb_allocation_time == "null" || orderData[0].cb_allocation_time == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{orderData[0].cb_allocation_time}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>CB delivery partner arrived at pickup time :</td>
                                            <td>{orderData[0].cb_picked_up_time == null || orderData[0].cb_picked_up_time == "null" || orderData[0].cb_picked_up_time == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{orderData[0].cb_picked_up_time}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Delivery Status :</td>
                                            <td>{orderData[0].delivery_mode == 0 ? orderData[0].shipment_status == 1 ? "Assigned" : orderData[0].shipment_status == 2 ? "Arrived" : orderData[0].shipment_status == 3 ? "Picked Up" : orderData[0].shipment_status == 4 ? "Reached" : orderData[0].shipment_status == 5 ? "Delivered" : orderData[0].shipment_status == 6 ? "Rejected" : 'Cancelled' : ''}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>CB delivery partner delivered time :</td>
                                            <td>{orderData[0].cb_delivered_time == null || orderData[0].cb_delivered_time == "null" || orderData[0].cb_delivered_time == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{orderData[0].cb_delivered_time}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Customer rating :</td>
                                            <td>{orderData[0].ratings}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Self delivery time selection :</td>
                                            <td>{orderData[0].set_delivery_mode_datetime == null || orderData[0].set_delivery_mode_datetime == "null" || orderData[0].set_delivery_mode_datetime == '' ? 'NULL' : <Moment format="MMM Do YYYY hh:mm A">{orderData[0].set_delivery_mode_datetime}</Moment>}</td>
                                        </tr>
                                        <tr >
                                            <td className='order_td'>Self delivery partner details :</td>
                                            <td>{orderData[0].selfDeliveryList.length != 0 ? orderData[0].selfDeliveryList[0].self_delivery_partner_detail_name : ''}</td>
                                        </tr>

                                    </table>

                                </span>
                            }
                            {displayStatus == 1 ?
                                <span className="orderIdList">
                                    {orderIDList.map((val, key) => {
                                        return (
                                            <Link
                                                component="button"
                                                variant="body2"
                                                className="pd-3 align-center mx-auto"
                                                value={val.order_id}
                                                // onClick={OrderList(val.order_id)
                                                //}
                                                onClick={OrderListDetail}
                                            >
                                                {val.order_id}
                                            </Link>
                                        )
                                    })}

                                </span> : ''
                            }

                        </div>

                        {/* Role */}
        </div>
    );
};

export default SearchOrders;