import { Fetch } from "../apiConfig/Fetch";
import { APIConfig } from "../apiConfig/apiConfig";

const merchantService = {
    updateMerchant: async (data) => {
        const { baseUrl, updateMerchant } = APIConfig;
        const url = baseUrl + updateMerchant;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            bizId: data.bizId,
            bizStatus: data.bizStatus,
            ownerAadharNumber: data.ownerAadharNumber,
            gstNumber: data.gstNumber,
            catalogPhotos: data.catalogPhotos,
            teamMembers: data.teamMembers,
            verifyStatus: data.verifyStatus,
            canChequeurl: data.canChequeurl,
            teamMobileNumbers: data.teamMobileNumbers,
            merchantId: data.merchantId,
            referralCode: data.referralCode,
            accVerifyStatus: data.accVerifyStatus,
            ratings: data.ratings,
            latitude: data.latitude,
            longitude: data.longitude,
            kycDocumentType: data.kycDocumentType,
            kycDocumentNumber: data.kycDocumentNumber,
            businessProofType: data.businessProofType,
            businessProofNumber: data.businessProofNumber,
            businessProofExpiry: data.businessProofExpiry,
            businessProof: data.buisnessProof,
            businessProofImg: data.buisnessProofImg,
            storeDescription: data.storeDescription,
            accessControl: data.accessControl,
            merchantName: data.merchantName,
            categoryl1: data.categoryl1,
            categoryl2: data.categoryl2,
            categoryl3: data.categoryl3,
            categoryl4: data.categoryl4,
            shopName: data.shopName,
            closeTime: data.closeTime,
            openTime: data.openTime,
            openTime2: data.openTime2,
            closeTime2: data.closeTime2,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            weeklyOff: data.weeklyOff,
            pincode: data.pincode,
            picurl: data.picurl,
            city: data.city,
            bankName: data.bankName,
            bankIfsc: data.bankIfsc,
            accName: data.accName,
            accNumber: data.accNumber,
            shopLocation: data.shopLocation,
            mandyOrAreaOrMarket: data.mandyOrAreaOrMarket,
            fssai: data.fssai,
            licenseUrl: data.licenseUrl,
            photo_qc: data.photo_qc,
            license_qc: data.license_qc,
            bank_qc: data.bank_qc,
            business_qc: data.business_qc,
            classification:data.classification,
            commission_per:data.commission_per,
            gst_on_commission_per:data.gst_on_commission_per,
            commission_sd_per:data.commission_sd_per,
            gstStatus:data.gstStatus,
            secondary_no1:data.child_mob_1,
            secondary_no2:data.child_mob_2,
            catalogPdf:data.catalogPdf,
            lincenseno: data.lincenseno,
            logged_mobile_num: data.logged_mobile_num,
            subscription_flag: data.subscription_flag,
            renewal_flag: data.renewal_flag,

        });
        const UpdateMerchantPromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_UpdateMerchant",
            true
        );
        return UpdateMerchantPromise;
    },
    getList: async () => {
        const { baseUrl, viewMerchant } = APIConfig;

        const url = baseUrl + viewMerchant;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const merchantListPromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_merchantList",
            true
        );
        return merchantListPromise;
    },
};

export default merchantService;