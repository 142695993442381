import { withRouter } from "react-router";
import React , { useEffect, useState }from "react";
import { useHistory } from "react-router-dom";

const Dash = props => {
    const [admin,setAdmin]=useState("");
    const history = useHistory();
    function remove() {

        localStorage.removeItem("adminMobileNumber");
        localStorage.removeItem('L3CategoryData');
        localStorage.clear();
        localStorage.clear();
        history.push("/");
    }
    useEffect(() => {
        remove();
      }, []);
     
    return (
        <>
         
        </>
        );
  };
  const Dashboard = withRouter(Dash);
  export default Dashboard