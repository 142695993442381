const StatusData = [
    {
        label: 'Inactive',
        value: 1,
    },
    {
        label: 'Active',
        value: 0,
    },
];

export default StatusData;