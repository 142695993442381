import axios from 'axios';
import ApiService from './ApiService';
import { constants } from '../../utils/constants';

const {bucketNames, awsfileNames, apiFileType, awsUploadFileType, apiRequestTimeOut} = constants;

const AwsImageService = {
  uploadImageToS3: async (file, masterCatalog) => {

    const timestamp = Date.now();
    const contentDisposition = 'inline;filename="' + file.fileName + '"';

    try {
      const token = localStorage.getItem('sessionId');
      let data = {};
      if (masterCatalog !== undefined) {
        data = {
          fileName: awsfileNames.masterCatalog + timestamp,
          fileType: apiFileType.image,
          bucketName: bucketNames.masterCatalog
        };
      } else {
        data = {
          fileName: awsfileNames.merchantEdit + timestamp,
          fileType: apiFileType.image,
          bucketName: bucketNames.merchantEdit
        };
      }

      try {
        const result = await ApiService.createPreSignedURL(data, token);
        if (result.data.code === 0) {
          const signedURL = result.data.presignedUrl;
          const parts = signedURL.split('?');
          const imageUrl = parts[0];

          try {
            await axios({
              method: 'PUT',
              url: signedURL,
              data: file,
              headers: {
                'Content-Type': awsUploadFileType.image,
                'Content-Disposition': contentDisposition
              },
              timeout: 15000,
              timeoutErrorMessage: apiRequestTimeOut
            });

            if (masterCatalog !== undefined) {
              return imageUrl;
            } else {
              return { uri: imageUrl };
            }
          } catch (error) {
            console.error("Error uploading image to S3:", error);
          }
        } else if (result.code === 1) {
          console.log("err")
        } else if (result.code === 'ECONNABORTED') {
          alert(apiRequestTimeOut)
        }
      } catch (error) {
        console.error("Error creating pre-signed URL:", error);
      }
    } catch (error) {
      console.error("Error reading file:", error);
    }
  },
}
export default AwsImageService; 