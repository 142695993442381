import React, { useState } from 'react'
import Sidebar from "../../components/sidebar.js";
import './CatalogAutomation.css'
import { BeatLoader, ClipLoader } from 'react-spinners';
import { Dialog, TextField } from "@mui/material";
import close_icon from '../../images/cross-icon.png'
import cashback_image from '../../images/cashback_image.jpg'
import ApiService from '../../api/services/ApiService';
import { alertDangerConfirmMessage, alertSuccessMessageMerchant } from '../../components/customComponent/ConfirmationMessage/ConfirmationMessage.js';
import { constants } from '../../utils/constants.js';
import LoadingSpinner from '../../components/loader/loader.js';
import AwsPDFService from '../../api/services/AwsPDFService.js';
import FileUploader from '../../components/customComponent/FileUploader/FileUploader.js';

const CatalogAutomation = () => {

    const { catalogSelect, businessTrackingApiMessages, requestTimedOutError } = constants;
    const [selectOption, setSelectOption] = useState('0');
    const [merchantId, setMerchantId] = useState('');
    const [productMatches, setProductMatches] = useState(false);
    const [twoFactorForMatch, setTwoFactorForMatch] = useState(false);
    const [twoFactorForUnmatch, setTwoFactorForUnmatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [merchantData, setMerchantData] = useState({});
    const [matchedData, setMatchedData] = useState([]);
    const [matchedDataCopy, setMatchedDataCopy] = useState([]);
    const [unmatchedData, setUnmatchedData] = useState([]);
    const [s1Categories, setS1Categories] = useState([]);
    const [dataForApi, setDataForApi] = useState({});
    const [nearestMatchesData, setNearestMatchesData] = useState({});
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [selectedS1Category, setSelectedS1Category] = useState('Select S1 Category');


    const handleCsv = async (item, merId) => {

        // setMenuStatus('0');
        console.log(item[0].file);

        let newName = 'mercsv'
        var fileExtension = '.' + item[0].file.name.split('.')[1];
        var name = newName + new Date().getTime() + fileExtension;
        var blob = item[0].file.slice(0, item[0].size, item[0].type);
        var newFile = new File([blob], name, { type: item[0].type });


        await AwsPDFService.uploadCsv(newFile, merchantData.merchantid)
            .then(data => {
                if (data.uri !== '') {
                    console.log("CSVURL", data.uri);
                    console.log("MERIDINSIDE", merId)
                    uploadCsv(data.uri, merchantId);

                }
            }
            )
            .catch(err => console.error(err));

    };

    const getMerchantData = async () => {
        setIsLoading(true);
        const data = {
            merchantId: merchantId
        }
        try {
            const result = await ApiService.getAutomationMerchantData(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setMerchantData(result.data.data)
                if (result.data.matchResult.length !== 0) {
                    setSelectOption('1')
                    setMatchedData(result.data.matchResult)
                    setMatchedDataCopy(result.data.matchResult);
                } else {
                    setMatchedData([])
                    setMatchedDataCopy([]);
                    setSelectOption('0')
                }
                const initData = { categoryid: 0, category: 'Select S1 Category' }
                result.data.categoryResult.unshift(initData)
                setS1Categories(result.data.categoryResult);
                sessionStorage.setItem('catMerchantData', JSON.stringify(result.data.data));
            } else {
                alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const uploadCsv = async (csvLink, merId) => {
        let val = JSON.parse(sessionStorage.getItem('catMerchantId'))
        setIsLoading(true);
        const data = {
            merchantId: val,
            tolerance: 5,
            csv_link: csvLink
        }
        console.log("DATA", data, "merID", merId)
        try {
            const result = await ApiService.addCatalogueByMapping(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                let merData = JSON.parse(sessionStorage.getItem('catMerchantData'))
                // const merData = { ...merchantData };
                console.log("MERDATA", merData)
                merData.overall_status = result.data.statusRes;
                setMerchantData(merData);
                sessionStorage.setItem('catMerchantData', JSON.stringify(merData));
            } else {
                alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const getMappingStatus = async () => {
        setIsLoading(true);
        const data = {
            merchantId: merchantId
        }
        try {
            const result = await ApiService.getCatalogStatus(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                const merData = { ...merchantData };
                merData.matched_count = result.data.statusData.matched_count;
                merData.skipped_product_count = result.data.statusData.skipped_product_count;
                merData.total_catalogs_count = result.data.statusData.total_catalogs_count;
                merData.unmatched_count = result.data.statusData.unmatched_count;
                merData.matched_with_master = result.data.statusData.matched_with_master;
                merData.overall_status = result.data.statusRes;
                setMerchantData(merData);
                sessionStorage.setItem('catMerchantData', JSON.stringify(merData));
            } else {
                alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const getMatchedUnmatchedProducts = async (flag) => {
        setIsLoading(true);
        const data = {
            merchantid: merchantId,
            match_flag: flag
        }
        try {
            const result = await ApiService.getMatchedUnmatchedProducts(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setSelectOption(flag === 0 ? '1' : '2')
                if (flag === 0) {
                    setMatchedData(result.data.matchResult)
                    setMatchedDataCopy(result.data.matchResult)
                    const initData = { categoryid: 0, category: 'Select S1 Category' }
                    result.data.categoryResult.unshift(initData)
                    setS1Categories(result.data.categoryResult);
                    setSelectedS1Category('Select S1 Category')
                } else {
                    setUnmatchedData(result.data.matchResult)
                }
            } else {
                alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const updateMatchedProductsToUnmatch = async () => {
        setIsLoading(true);
        const data = {
            merchantid: dataForApi.merchantid,
            id: dataForApi.id,
            mapped_with: dataForApi.mapped_with
        }
        try {
            const result = await ApiService.updateMatchedProductsToUnmatch(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                let flag = 0;
                await getMatchedUnmatchedProducts(flag);
                setTwoFactorForUnmatch(false);
                await getMerchantData();
            } else {
                alertDangerConfirmMessage(businessTrackingApiMessages.dataFetchError)
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const handleMerchantId = (e) => {
        setMerchantId(e.target.value);
        sessionStorage.setItem('catMerchantId', JSON.stringify(e.target.value))
    }

    const handleOptionSelect = (e) => {
        if (e.target.value == 1) {
            const flag = 0;
            getMatchedUnmatchedProducts(flag)
        } else {
            const flag = 1;
            getMatchedUnmatchedProducts(flag)
        }
    }

    const handleUnmatchBtn = (mId, itemId, mappedWith) => {
        const data = {
            merchantid: mId,
            id: itemId,
            mapped_with: mappedWith
        }
        setDataForApi(data);
        setTwoFactorForUnmatch(true);
    }

    const handleSelectButton = async (pId, mId, mrp, sRate, pName, mappedWith) => {
        setIsLoading(true);
        const data = {
            id: pId,
            merchantid: mId,
            MRP: mrp,
            S_RATE: sRate,
            product_name: pName,
            old_mapping: mappedWith,
            tolerance: 10
        }
        try {
            const result = await ApiService.getCatalogSuggestion(data);
            if (result.code === requestTimedOutError) {
                alertDangerConfirmMessage(result.message)
            } else if (result.data.code === 0) {
                setNearestMatchesData(result.data.merchantProduct);
                setSuggestedProducts(result.data.suggestedProducts);
                setProductMatches(true)
            } else {
                alertDangerConfirmMessage(result.data.message);
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const handleS1CategoySelect = (e) => {
        if (e.target.value !== 'Select S1 Category') {
            const filteredData = matchedDataCopy.filter(item => item.category === e.target.value);
            setMatchedData(filteredData);
            setSelectedS1Category(e.target.value);
        } else {
            setSelectedS1Category(e.target.value);
        }
    }

    return (
        <div>
            <div className='sidebar'>
                <Sidebar />
            </div>
            {isLoading && (
                <LoadingSpinner />
            )}
            <div className='catalogue-container'>
                {console.log("MID", merchantId)}
                <div className='catalog-search-container'>
                    <TextField value={merchantId} className='vip_history_input' label="Enter phone / MID" onChange={handleMerchantId} />
                    <button className='catalog-search-btn' onClick={getMerchantData}>Search</button>
                </div>
                {Object.keys(merchantData).length !== 0 && (
                    <div>
                        <table className='delivery-table individual-table'>
                            <tbody className='table-data-row'>
                                <tr>
                                    <td className="first-row first-cell">
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>{merchantData.shopname}</p>
                                        </div>
                                    </td>
                                    <td className="first-row">
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>{merchantData.merchantid}</p>
                                        </div>
                                    </td>
                                    <td className='first-row'>
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>{merchantData.mainmobilenumber}</p>
                                        </div></td>
                                    <td className='first-row'>
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>{merchantData.addressline1}<br />{merchantData.addressline2}</p>
                                        </div>
                                    </td>
                                    <td className='first-row'>
                                        <div className='row-flex'>
                                            <FileUploader
                                                csv={true}
                                                handleCsv={(item) =>
                                                    handleCsv(item, merchantId)
                                                }
                                                isEditable={false}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-last-row first-cell">
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>Total Products:</p>
                                            <p className='data-text'>{merchantData.total_catalogs_count}</p>
                                        </div>
                                    </td>
                                    <td className="table-last-row">
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>Matched:</p>
                                            <p className='data-text'>
                                                {(
                                                    merchantData.matched_count +
                                                    merchantData.matched_with_master +
                                                    merchantData.skipped_product_count
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                    <td className="table-last-row">
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>Not Matched:</p>
                                            <p className='data-text'>{merchantData.unmatched_count + merchantData.unmatched_manually}</p>
                                        </div>
                                    </td>
                                    <td className="table-last-row">
                                        <div className='row-flex'>
                                            <p className='data-header data-label'>Status:</p>
                                            <p className='data-text uncut-text'>{merchantData.overall_status && merchantData.overall_status}</p>
                                            {(merchantData.overall_status && merchantData.overall_status !== 'COMPLETED') && (
                                                <p onClick={getMappingStatus} className='refresh-text uncut-text'>click to refresh</p>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <div className='export-excel-select-container'>
                                <select v-model="selected" className="delivery-details-select ml-20" onChange={handleOptionSelect} value={selectOption}>
                                    {catalogSelect.map(data => (
                                        <option hidden={data.id === 0} key={data.id} value={data.id} >
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                                {selectOption === '1' && (
                                    <select v-model="selected" className="delivery-details-select ml-20" onChange={handleS1CategoySelect} value={selectedS1Category}>
                                        {s1Categories.map(data => (
                                            <option hidden={data.categoryid === 0} key={data.categoryid} value={data.category} >
                                                {data.category}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {selectOption !== '0' && (
                                    <button className='upload-catalog-btn' onClick={{}}>Export excel</button>
                                )}
                            </div>
                            {selectOption === '1' ? (
                                <div>
                                    <table title='Matched table' className='delivery-table'>
                                        <tr className='table-header-row'>
                                            <th colSpan="1" className="table-header-cell large-font">Name</th>
                                            <th colSpan="1" className="table-header-cell price-cell-catalog large-font">MRP</th>
                                            <th colSpan="1" className="table-header-cell price-cell-catalog large-font">SP</th>
                                            <th colSpan="1" className="table-header-cell large-font">Photo</th>
                                            <th colSpan="1" className="table-header-cell large-font">Master catalog item</th>
                                            <th colSpan="1" className="table-header-cell large-font">Action</th>
                                        </tr>
                                        <tbody>
                                            {Object.keys(matchedData).length !== 0 && matchedData.map((data, index) => (
                                                <tr className='table-data-row' key={index}>
                                                    <td className="table-data-cell first-cell large-font normal-text">
                                                        {data.product_name}
                                                    </td>
                                                    <td className="table-data-cell first-cell large-font">
                                                        {data.MRP}
                                                    </td>
                                                    <td className="table-data-cell first-cell large-font">
                                                        {data.S_RATE}
                                                    </td>
                                                    <td className="table-data-cell first-cell">
                                                        <img src={data.image_url[0]} className='unmatched-pd-image' alt='NA' />
                                                    </td>
                                                    <td className="table-data-cell first-cell large-font normal-text">
                                                        {data.item_name}
                                                    </td>
                                                    <td className="table-data-cell first-cell">
                                                        <div className='unmatch-btn-container'>
                                                            <button onClick={() => handleUnmatchBtn(data.merchant_id, data.id, data.mapped_with)} className='incentive-cancel-btn'>Unmatch</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : selectOption === '2' ? (
                                <div>
                                    <table className='delivery-table'>
                                        <tbody>
                                            {Object.keys(unmatchedData).length !== 0 && unmatchedData.map((data, index) => (
                                                <tr className='table-data-row' key={index}>
                                                    <td className="unmatched-table-first-data">
                                                        <p className='large-font normal-text'>{data.product_name}</p>
                                                    </td>
                                                    <td className="table-data-cell first-cell">
                                                        <button className='catalog-search-btn' onClick={() => handleSelectButton(data.id, data.merchant_id, data.MRP, data.S_RATE, data.product_name, data.mapped_with)}>Select</button>
                                                    </td>
                                                    <td className="table-data-cell first-cell"></td>
                                                    <td className="table-data-cell first-cell"></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                )}


            </div>

            <Dialog onClose={() => setProductMatches(false)} open={productMatches}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => setProductMatches(false)}
                    alt="close"
                />
                <div className='order-mod-dialog'>
                    {true ? (
                        <>
                            <div className='name-prices-container'>
                                <h6 className='large-font'>{nearestMatchesData.product_name}</h6>
                                <h6 className='large-font'>MRP {nearestMatchesData.MRP}</h6>
                                <h6 className='large-font'>SP {nearestMatchesData.S_RATE}</h6>
                            </div>
                            <table className='delivery-table'>
                                <p className='large-font'>Nearest matches</p>
                                <tbody>
                                    {Object.keys(suggestedProducts).length !== 0 ? suggestedProducts.map((data, index) => (
                                        <tr key={{}}>
                                            <td style={{ borderBottom: '1px solid lightgrey', paddingBottom: '20px' }} className='pd-name-image-field'>
                                                <img src={cashback_image} className='unmatched-pd-image' alt='NA' />
                                                <p className='large-font'>Product Name</p>
                                            </td>
                                            <td style={{ textAlign: 'end', borderBottom: '1px solid lightgrey' }}>
                                                <p className='large-font'>50 (+x%)</p>
                                            </td>
                                            <td style={{ borderBottom: '1px solid lightgrey' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <button className='catalog-search-btn' onClick={() => setTwoFactorForMatch(true)}>Match</button>

                                                </div>
                                            </td>
                                        </tr>
                                    )) : (
                                        <p className='center-align'>No data available</p>
                                    )}

                                    {/* <tr key={{}}>
                                        <td className='pd-name-image-field'>
                                            <img src={cashback_image} className='unmatched-pd-image' alt='NA' />
                                            <p className='large-font'>Product Name</p>
                                        </td>
                                        <td style={{ textAlign: 'end' }}>
                                            <p className='large-font'>50 (+x%)</p>
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <button className='catalog-search-btn' onClick={() => setTwoFactorForMatch(true)}>Match</button>

                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <div className="beat-loader-container">
                            <BeatLoader color="#36d7b7" loading={true} />
                        </div>
                    )}

                </div>
            </Dialog>

            {/* 2 factor for match */}
            <Dialog onClose={() => setTwoFactorForMatch(false)} open={twoFactorForMatch}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => setTwoFactorForMatch(false)}
                    alt="close"
                />
                <div className="cashback_dialog">
                    <div>
                        <p className="confirm-dialog-text">Sure, you want to match?</p>
                        <div className="yes-no-btn-container">
                            <button className="upload-catalog-btn" onClick={{}}>Yes, Match</button>
                            <button className="no-btn-style" onClick={() => setTwoFactorForMatch(false)}>No</button>
                        </div>

                    </div>
                </div>

            </Dialog>

            {/* 2 factor for unmatch */}
            <Dialog onClose={() => setTwoFactorForUnmatch(false)} open={twoFactorForUnmatch}>
                <img
                    className="cross-icon"
                    src={close_icon}
                    onClick={() => setTwoFactorForUnmatch(false)}
                    alt="close"
                />
                <div className="cashback_dialog">
                    <div>
                        <p className="confirm-dialog-text">Sure, you want to unmatch?</p>
                        <div className="yes-no-btn-container">
                            <button className="upload-catalog-btn" onClick={updateMatchedProductsToUnmatch}>Yes, Unmatch</button>
                            <button className="no-btn-style" onClick={() => setTwoFactorForUnmatch(false)}>No</button>
                        </div>

                    </div>
                </div>

            </Dialog>

        </div>
    )
}

export default CatalogAutomation
