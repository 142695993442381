import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../components/sidebar.js";
import "../Dashboard.css";
import axios from "axios";
import view from "../images/view.png";
import edit_icon from "../images/edit.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import merchantService from "../api/services/MerchantService.js";
import CategoryService from "../api/services/CategoryService.js";
import Moment from "react-moment";
import moment from "moment-timezone";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { alertDangerConfirmMessage, alertSuccessMessage, alertSuccessMessageMerchant } from "../components/customComponent/ConfirmationMessage/ConfirmationMessage.js";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DatePicker from "react-datepicker";
import { APIConfig } from "../api/apiConfig/apiConfig";
import LoadingSpinner from "../components/loader/loader.js";
import { BeatLoader, ClipLoader } from "react-spinners";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import {
  Container,
  Button,
  TextField,
  Typography,
  TableContainer,
  Dialog,
  DialogTitle,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import close_icon from "../../src/payout/close.png";
import edit from "../../src/payout/edit.png";
import { constants } from "../utils/constants.js";
import { ExportToCSV } from "../components/ExportToCSV.js";

const Payout = (props) => {
  const { baseUrl, qcPayoutCSV, rejectCheckoutOrder } = APIConfig;
  const { distance, currency, orderCancelMessages } = constants;
  const [searchResponse, setSearchResponse] = useState({});
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [dialogueData, setDialogueData] = useState([]);
  const [negativeBalance, setNegativeBalance] = useState([]);
  const [sDateForAPI, setSDateForAPI] = useState("");
  const [eDateForAPI, setEDateForAPI] = useState("");
  const [totalAmount, setTotalAmount] = useState();
  const [totalGST, setTotalGST] = useState();
  const [noOrderData, setNoOrderData] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [amtReceivedDialog, setAmtReceivedDialog] = useState(false);
  const [amtReceivedData, setAmtReceivedData] = useState({});
  const [cartData, setCartData] = useState({});
  const [showPayoutInput, setShowPayoutInput] = useState(false);
  const [newPayoutBalance, setNewPayoutBalance] = useState(null);
  const [csvExport, setCSVExport] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [orderIdForCancel, setOrderIdForCancel] = useState(null);
  const [orderIdForPartialCancel, setOrderIdForPartialCancel] = useState(null);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [orderSummaryData, setOrderSummaryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');


  const csvDownload = async () => {
    await axios
      .post(baseUrl + qcPayoutCSV, {
        merchant_id: searchResponse["merchantid"],
        start_date: sDateForAPI,
        end_date: eDateForAPI,
      }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then((response) => {
        var responseStatus = response.data.status;
        if (responseStatus !== 1) {
          setCSVExport(response.data.data);
        } else {
          setCSVExport([]);
        }

      })
      .catch(function (error) {
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  const search = () => {
    if (searchText.trim().length === 0) {
      setErrorText('* This field cannot be empty');
    } else {
      setErrorText('');
    }
    if (searchText.trim().length !== 0) {
      setShowOrderDetails(false);
      axios
        .post(baseUrl + "getQcSettlement", {
          nameNumber: searchText,
        }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
        .then(function (response) {
          // console.log(response.data.data);
          if (response.data.code === 0) {
            setSearchResponse(response?.data?.data);
          } else {
            alert("Merchant does not exist")
          }

        })
        .catch(function (error) {
          alertDangerConfirmMessage(error.message)
          console.log(error);
        });
    }

  };

  useEffect(() => {
    var currentDate = new Date();
    setCurrentDate(currentDate);
  }, [])

  const orders = () => {
    console.log(searchResponse["merchantid"], " start");
    axios
      .post(baseUrl + "getQcSettlement", {
        merchant_id: searchResponse["merchantid"],
        start_date: sDateForAPI,
        end_date: eDateForAPI,
      }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then(async function (response) {
        setShowOrderDetails(true);
        setShowOrderDetails(true);
        console.log(" response ", response?.data?.data);
        if (response?.data?.data) {
          setOrderList(response.data.data);
          await csvDownload();
        } else {
          setOrderList([]); // Set an empty array as a fallback value
        }
        if (response.data.message === "No data available") {
          console.log("TRUe===>", response.data.message);
          setNoOrderData(true);
        } else {
          setNoOrderData(false);
        }
      })
      .catch(function (error) {
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  };

  const dialogueBox = (id) => {
    axios
      .post(baseUrl + "getQcPayoutDetails", {
        id: id,
      }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then(function (response) {
        console.log("TRUe===>", response.data);
        // console.log(response?.data["negative_balance"]);
        setDialogueData(response?.data?.data[0]);
        let resData = response?.data?.data[0];
        let negBalance = response?.data;
        setNegativeBalance(response?.data);
        let totalAmount =
          (Number(resData.commission_charge) || 0) +
          (Number(resData.settlement_charge) || 0) +
          (Number(resData.logistics_charge) || 0) +
          (Number(negBalance?.negative_balance) || 0) +
          (Number(resData.subscription_charge) || 0);
        setTotalAmount(totalAmount);

        let gstTotal =
          (Number(resData.commission_gst_charge) || 0) +
          (Number(resData.cgst_on_settlement_charge) || 0) +
          (Number(resData.sgst_on_settlement_charge) || 0) +
          (Number(resData.tcs_sgst) || 0) +
          (Number(resData.tcs_cgst) || 0) +
          (Number(resData.logistics_gst_charge) || 0) +
          (Number(negBalance?.negative_balance) || 0) +
          (Number(resData.govt_gst) || 0) +
          (Number(resData.subscription_gst_charge) || 0);
        setTotalGST(gstTotal);
        // console.log("asdasd", response?.data?.data?.cashback_amount);
      })
      .catch(function (error) {
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  };
  // console.log(startDate, " ", endDate);

  function DateConverter(dateString) {
    // Parse the date string using moment-timezone
    const date = moment(dateString);
    console.log(dateString);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = date.format("YYYY-MM-DD");
    console.log(formattedDate);
    return formattedDate;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to 00:00:00.000
  const excludeDates = [today]; // Exclude present day

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (id) => {
    setOpen(true);
    // console.log("asa", payout_temp_id);
    dialogueBox(id);
    // setDataIndex(index)
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(" asa", searchText);

  const refundAmount = (bill_id, receivedAmount, key, orderId) => {
    console.log("receivedamount==>", typeof receivedAmount)
    let refundAmt = document.getElementById(`input${key}`).value
    console.log("refundAmt==>", typeof refundAmt)
    if (refundAmt === '' || refundAmt === null) {
      alert("Enter the amount")
    } else if (Number(receivedAmount) < Number(refundAmt)) {
      alert("Refund amount should be less than or equal to Amount Received")
    } else {
      setSpinner(true);
      let request = axios
        .post(baseUrl + "qcRefund", {
          orderId: orderId,
          amount: Number(refundAmt),
          platformBillID: bill_id,
          merchantId: searchResponse["merchantid"],
        }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' });
      let timer = setTimeout(() => {
        setSpinner(false);
        alert("The refund request has been initiated and is currently being processed by the bank. Please check the final status after some time.");
        window.location.reload();
      }, 5000);
      request
        .then(function (response) {
          clearTimeout(timer);
          setSpinner(false);
          if (response.data.status === 0) {
            document.getElementById(`input${key}`).value = '';
            alert("Success")
            window.location.reload();
          } else {
            alert("Refund failed")
            document.getElementById(`input${key}`).value = '';
          }

        })
        .catch(function (error) {
          clearTimeout(timer);
          console.log(error);
        });
    }
  };

  const refundDateExceeded = (orderDate) => {
    var dateParts = orderDate.split(" ");
    var date = dateParts[0];
    var time = dateParts[1];
    var datePartsSplit = date.split("-");
    var year = parseInt(datePartsSplit[0]);
    var month = parseInt(datePartsSplit[1]) - 1; // Month is zero-based
    var day = parseInt(datePartsSplit[2]);
    var timePartsSplit = time.split(":");
    var hour = parseInt(timePartsSplit[0]);
    var minute = parseInt(timePartsSplit[1]);
    var second = parseInt(timePartsSplit[2]);

    var actualDate = new Date(year, month, day, hour, minute, second);
    console.log("orderDate", actualDate)

    var timeDifference = currentDate.getTime() - actualDate.getTime();
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    if (hoursDifference < 24) {
      return true;
    } else {
      return false;
    }
  }

  const handleAmtReceivedOpen = async (val) => {
    var data = {};

    await axios
      .post(baseUrl + "getOrderSummaryQc", {
        order_id: val.order_id,
      }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then(function (response) {
        if (response.data.code === 0) {
          data = {
            amountToPay: response.data.data.amt_to_pay,
            govtTax: response.data.data.govt_gst,
            deliveryCharge: response.data.data.total_user_logistic_charge,
            itemTotal: response.data.data.order_total_amount,
            deliveryDistance: response.data.data.user_merch_distance
          }
          setAmtReceivedData(data);
          setCartData(response.data.data.order_summary);
          setAmtReceivedDialog(true);
        }
      })
      .catch(function (error) {
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });


  }

  const updatePayoutBalance = async () => {
    await axios
      .post(baseUrl + "addUpdatePayoutBalance", {
        mobileNumber: searchText,
        payout_balance: newPayoutBalance,
      }, { timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then(function (response) {
        if (response.data.code === 0) {
          alert("Success");
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Update Failed");
      });
  }

  const handleCancelBtn = (val) => {
    setOrderIdForCancel(val.order_id);
    setShowConfirmDialog(true);
  }

  const handlePartialCancel = (val) => {
    getOrderSummary(val.order_id);
    setOrderIdForPartialCancel(val.order_id);
    setShowOrderSummary(true);
  }

  const rejectOrder = async () => {
    const token = localStorage.getItem('sessionId');
    const headers = {
      token
    }
    const data = {
      order_id: orderIdForCancel,
    }
    setSpinner(true);

    await axios
      .post(baseUrl + rejectCheckoutOrder,
        data, { headers, timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then(function (response) {
        setSpinner(false);
        if (response.data.status === 0) {
          alertSuccessMessage(response.data.cancelOrderMsg.msg)
        } else {
          alertDangerConfirmMessage(orderCancelMessages.fail)
        }
      })
      .catch(function (error) {
        console.log(error);
        setSpinner(false);
        alertDangerConfirmMessage(error.message)
      });
  }

  const handleNoBtn = () => {
    setOrderIdForCancel(null);
    setShowConfirmDialog(false);
  }

  const getOrderSummary = (orderId) => {
    const token = localStorage.getItem('sessionId');
    const headers = {
      token
    }
    const data = {
      order_id: orderId,
    }
    setSpinner(true);

    axios
      .post(baseUrl + "getUserDeliveryAddress", data, { headers, timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
      .then(function (response) {
        setSpinner(false);
        let dat = {
          shipmentStatus: response.data.data.shipment_status,
          orderSummary: response.data.data.orderSummary
        }
        setOrderSummaryData(dat)
        console.log(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
        alertDangerConfirmMessage(error.message)
        console.log(error);
      });
  }

  const changeOrderSummary = (item, val) => {
    if (item.originalCount === item.count && val === 'Add') {
      alert("Modified quantity cannot be more than ordered quantity")
    } else if (item.count === 0 && val === 'Decrease') {
      alert("Modified quantity cannot be less than 0")
    } else {
      const token = localStorage.getItem('sessionId');
      const headers = {
        token
      }
      const data = {
        order_id: orderIdForPartialCancel,
        id: item.id,
        type: 1,
        updatedQty: val === 'Add' ? item.count + 1 : item.count - 1
      }
      setIsLoading(true);
      axios
        .post(baseUrl + "merchUpdatesOrderSummary", data, { headers, timeout: 30000, timeoutErrorMessage: 'Request Timedout' })
        .then(function (response) {
          setIsLoading(false);
          let dat = {
            shipmentStatus: orderSummaryData.shipmentStatus,
            orderSummary: response.data.data.orderSummary
          }
          setOrderSummaryData(dat)
        })
        .catch(function (error) {
          alertDangerConfirmMessage(error.message)
          setIsLoading(false);
          console.log(error);
        });
    }

  }

  const handleBlur = () => {
    setErrorText('');
  };

  return (
    <div className="whole-container">
        <div>
          <Sidebar />
        </div>
          <div className="tittle">
            <h1>Payout</h1>
            {spinner && (
              <LoadingSpinner />
            )}
          </div>
          <div
            className="initial-search-container"
          >
            <TextField
              placeholder="Enter Phone no/ Name"
              variant="outlined"
              size="small"
              onChange={(e) => setSearchText(e.target.value)}
              error={!!errorText}
              helperText={errorText ? errorText : ''}
              onBlur={handleBlur}
            />
            <Button variant="contained" color="primary" onClick={search}>
              Search
            </Button>
            {!searchResponse && (
              <Typography variant="subtitle1">Merchant not found</Typography>
            )}
          </div>
          <Typography variant="h5" component="h1" align="left" mt={4} mb={2}>
            Merchant Details
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <div style={{ display: "flex", gap: "9rem" }}>
              <Typography variant="subtitle1">
                Business Name: {searchResponse && searchResponse["shopname"]}
              </Typography>
              <Typography variant="subtitle1">
                Phone no:{" "}
                {searchResponse && searchResponse["mainmobilenumber"]}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1">
                Address:{" "}
                {[
                  searchResponse && searchResponse["addressline1"],
                  searchResponse && searchResponse["addressline2"],
                  searchResponse && searchResponse["city"],
                  searchResponse && searchResponse["pincode"],
                ]
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            </div>
            <div>
              <div className="payout_balance_change_container">
                <Typography variant="subtitle1">
                  Payout Balance:
                </Typography>
                <p className="payout_balance">{searchResponse["payout_balance"] && Number(searchResponse["payout_balance"]).toFixed(2)}</p>
                {(!showPayoutInput && Object.keys(searchResponse).length !== 0) && (
                  <img src={edit_icon} onClick={() => setShowPayoutInput(true)} className="edit_icon" alt="edit" />
                )}
              </div>
              {showPayoutInput && (
                <input className="payout_balance_edit_input" type="number" onChange={(e) => setNewPayoutBalance(e.target.value)} />
              )}
              <div>
                {showPayoutInput && (
                  <div className="add_cancel_container">
                    <button className="shop_status_submit_btn" onClick={updatePayoutBalance}>Add</button>
                    <p className="cancel_text" onClick={() => setShowPayoutInput(false)}>Cancel</p>
                  </div>
                )}
              </div>
            </div>

            <div style={{ display: "flex", gap: "9rem" }}>
              <Typography variant="subtitle1">
                Bank Name:
                <br />
                {searchResponse && searchResponse["bankname"]}
              </Typography>
              <Typography variant="subtitle1">
                Account no:
                <br />
                {searchResponse && searchResponse["accnumber"]}
              </Typography>
              <Typography variant="subtitle1">
                IFSC code:
                <br />
                {searchResponse && searchResponse["bankifsc"]}
              </Typography>
              <Typography variant="subtitle1">
                Current Balance:
                <br />
                {console.log("NAN", searchResponse)}
                {Object.keys(searchResponse).length !== 0 && Number(searchResponse["latest_balance"]).toFixed(2)}
              </Typography>
              {csvExport !== null && csvExport.length !== 0 && showOrderDetails &&
                <ExportToCSV apiData={csvExport} fileName="Payout" />}

            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                marginRight: "auto",
              }}
            >
              <Typography style={{ width: "10rem" }} variant="subtitle1">
                Select Date:
              </Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  let strDate = DateConverter(date);
                  setStartDate(date);
                  setSDateForAPI(strDate);
                }}
                dateFormat="dd-MM-yyyy"
                placeholderText="Start date"
                className="payout-calender"
                maxDate={tomorrow}
              // excludeDates={excludeDates}
              />
              <DatePicker
                selected={endDate && endDate >= startDate ? endDate : null}
                onChange={(date) => {
                  let strDate = DateConverter(date);
                  console.log("inside ", strDate);
                  setEDateForAPI(strDate);
                  setEndDate(date);
                }}
                dateFormat="dd-MM-yyyy"
                placeholderText="End date"
                className="payout-calender"
                minDate={startDate}
                maxDate={tomorrow}
              // excludeDates={excludeDates}
              />
              <Button variant="contained" color="primary" onClick={orders}>
                Search
              </Button>
            </div>
          </div>
          {showOrderDetails && (
            <div>
              <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                Order Details:
              </Typography>

              <div className="Merchentlist" style={{ marginBottom: "5%" }}>
                <table
                  border="1"
                  cellpadding="0"
                  cellSpacing="0"
                  className="merchentList_table"
                >
                  <tr className="merchentList_tr">
                    <th style={{ width: '100px' }} className="merchentList_th">Order Date</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Day</th>
                    <th style={{ width: '100px' }} className="merchentList_th">ONDC status</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Order Id</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Child Order Id</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Amount Received</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Refund</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Refund Status</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Deductions</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Net Amount</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Settlement Date</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Settlement Status</th>
                    <th style={{ width: '100px' }} className="merchentList_th">Payout ID</th>
                    <th style={{ width: '100px' }} className="merchentList_th">UTR</th>
                    <th style={{ width: '120px' }} className="merchentList_th">Refund amount</th>
                    <th style={{ width: '120px' }} className="merchentList_th">Order Cancel</th>
                    <th style={{ width: '120px' }} className="merchentList_th">Partial Cancel</th>
                  </tr>
                  {console.log("Total===>", totalAmount, "==>", totalGST)}
                  <tbody>
                    {orderList.map((val, key) => {
                      return (
                        <tr className="merchentList_tr" key={key}>
                          <td className="merchentList_td">
                            {(val.created_date && val.created_date !== 'null') && (
                              <Moment
                              style={{ wordBreak: "break-word" }}
                              format="DD-MM-yyyy"
                              >
                                {val.created_date}
                              </Moment>
                            )}
                          </td>
                          <td className="merchentList_td">
                            {(val.created_date && val.created_date !== 'null') && (
                              <Moment format="dddd">{val.created_date}</Moment>
                            )}
                          </td>
                          <td className="merchentList_td">
                            {val.is_ondc_order ? 'Yes' : 'No'}
                          </td>
                          <td className="merchentList_td">{val.order_id}</td>
                          <td className="merchentList_td">{val.parent_order_id ? `C - ${val.parent_order_id}` : 'none'}</td>
                          <td className="merchentList_td">
                            {val.total_amount}
                            <span
                              style={{ color: "blue" }}
                              className="deduction_field"
                              onClick={() => handleAmtReceivedOpen(val)}
                            >
                              (i)
                            </span>
                          </td>
                          <td className="merchentList_td">
                            {val.refund_amount}
                          </td>
                          <td className="merchentList_td">
                            {val.refund_status === '0' ? 'Not initiated' : val.refund_status === '1' ? 'Success' : val.refund_status === '2' ? 'Progressing' : val.refund_status === '3' ? 'Failed' : 'Reversed'}
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className="merchentList_td"
                          >
                            {val.deduction
                              ? Number(val.deduction).toFixed(2)
                              : "-"}
                            {val.deduction && (
                              <span
                                style={{ color: "blue" }}
                                className="deduction_field"
                                onClick={() => handleClickOpen(val.id)}
                              >
                                (i)
                              </span>
                            )}
                          </td>
                          <td className="merchentList_td">
                            {Number(
                              val.total_amount -
                              val.refund_amount -
                              val.deduction
                            ).toFixed(2)}
                          </td>
                          <td className="merchentList_td">
                            {(val.payout_settlement_date !== null && val.payout_settlement_date !== 'undefined') && (
                              <Moment
                                style={{ wordBreak: "break-word" }}
                                format="DD-MM-yyyy"
                              >
                                {val.payout_settlement_date}
                              </Moment>
                            )}
                          </td>
                          <td className="merchentList_td">
                            {(val.payout_status !== null && val.payout_status !== 'undefined') && val.payout_status}
                          </td>
                          {console.log("id==>")}
                          <td className="merchentList_td">{(val.payout_id !== null && val.payout_id !== undefined && val.payout_id !== 'undefined') && val.payout_id}</td>
                          <td className="merchentList_td">{(val.utr !== null && val.utr !== 'undefined') && val.utr}</td>
                          <td className="refund_input_column">
                            {refundDateExceeded(val.updated_date) ? (val.refund_status === "0") ? (
                              <>
                                <input id={`input${key}`} type='text' style={{ width: '80%' }} placeholder="Amount" />
                                <button className="cashback_btn" onClick={() => { refundAmount(val.payment_link_id, val.total_amount, key, val.order_id) }} style={{ margin: '20px' }}>Submit</button>
                              </>
                            ) : (
                              <p style={{ width: '100%', height: '70px', textAlign: 'center', paddingTop: '20px' }}>Refunded</p>
                            ) : (
                              <div style={{ width: '80%', height: '70px' }} />
                            )}

                          </td>
                          <td className="merchentList_td">
                            {(val.shipment_status < 3 && (val.refund_status === '0' || val.refund_status === '3') && val.overall_status === 0) && (
                              <button onClick={() => handleCancelBtn(val)} className="cashback_btn">Cancel</button>
                            )}
                          </td>
                          <td className="merchentList_td">
                            {(val.overall_status === 0 && val.shipment_status < 3 && val.is_checkout_order === 0) && (
                              <button onClick={() => handlePartialCancel(val)} className="cashback_btn">Partial Cancel</button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {noOrderData && (
                  <p style={{ textAlign: "center", marginTop: "20px" }}>
                    No data available
                  </p>
                )}
              </div>

              <Dialog onClose={handleClose} open={open}>
                <img
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "30px",
                    marginTop: "-5px",
                    marginRight: "2px",
                  }}
                  src={close_icon}
                  onClick={handleClose}
                  alt="close"
                ></img>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell
                          className="payout-tblcell"
                          align="center"
                          colSpan={3}
                        >
                          Deductions
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell className="payout-tblcell">
                          Amount
                        </TableCell>
                        <TableCell className="payout-tblcell">GST</TableCell>
                        <TableCell className="payout-tblcell">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Commission
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {dialogueData && dialogueData.commission_charge}{" "}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {dialogueData && dialogueData.commission_gst_charge}{" "}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {parseFloat(Number(dialogueData && dialogueData.commission_gst_charge) + Number(dialogueData && dialogueData.commission_charge)).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ wordBreak: 'break-word' }} className="payout-tblcell">
                          Settlement Charge
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {dialogueData && dialogueData.settlement_charge}{" "}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {dialogueData &&
                            Number(dialogueData.cgst_on_settlement_charge) +
                            Number(dialogueData.sgst_on_settlement_charge)}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {parseFloat(Number(dialogueData && dialogueData.settlement_charge) + Number(dialogueData && dialogueData.cgst_on_settlement_charge) + Number(dialogueData && dialogueData.sgst_on_settlement_charge)).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Govt Taxes
                        </TableCell>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell className="payout-tblcell">
                          {dialogueData &&
                            Number(
                              Number(dialogueData.tcs_sgst) +
                              Number(dialogueData.tcs_cgst) +
                              Number(dialogueData.govt_gst)
                            )}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {(dialogueData && parseFloat(Number(dialogueData.tcs_sgst) + Number(dialogueData.tcs_cgst) + Number(dialogueData.govt_gst)).toFixed(2))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Delivery Charge
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {" "}
                          {dialogueData &&
                            Number(dialogueData.logistics_charge).toFixed(2)}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {dialogueData && dialogueData.logistics_gst_charge}{" "}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {(dialogueData && parseFloat(Number(dialogueData.logistics_charge) + Number(dialogueData.logistics_gst_charge)).toFixed(2))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ wordBreak: "break-word" }}
                          className="payout-tblcell"
                        >
                          Negative Balance Adjustment
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {negativeBalance &&
                            negativeBalance["negative_balance"]}{" "}
                        </TableCell>
                        <TableCell className="payout-tblcell"> </TableCell>
                        <TableCell className="payout-tblcell">
                          {(negativeBalance && negativeBalance["negative_balance"])}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ wordBreak: "break-word" }}
                          className="payout-tblcell"
                        >
                          Subscription deduction
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {dialogueData && parseFloat(dialogueData.subscription_charge).toFixed(2)}
                        </TableCell>
                        <TableCell className="payout-tblcell">{dialogueData && parseFloat(dialogueData.subscription_gst_charge).toFixed(2)}</TableCell>
                        <TableCell className="payout-tblcell">
                          {(dialogueData && parseFloat(Number(dialogueData.subscription_charge) + Number(dialogueData.subscription_gst_charge)).toFixed(2))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className="payout-tblcell"
                          style={{ fontWeight: "700" }}
                        >
                          Total
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {Number(totalAmount).toFixed(2)}{" "}
                        </TableCell>
                        <TableCell className="payout-tblcell">
                          {Number(totalGST).toFixed(2)}{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Dialog>
              <Dialog onClose={() => setAmtReceivedDialog(false)} open={amtReceivedDialog}>
                <img
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "30px",
                    marginTop: "-5px",
                    marginRight: "2px",
                  }}
                  src={close_icon}
                  onClick={() => setAmtReceivedDialog(false)}
                  alt="close"
                ></img>
                {/* {Object.keys(cartData).length !== 0 && cartData.map(item => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '60px', marginLeft: '10px' }}>

                  <div style={{ display: 'flex', flex: 2 }}>
                    <p className="summary_pd_text line_clamp2" style={{ alignSelf: 'center', margin: 0, marginLeft: '5px' }}>{item.item_name}</p>
                  </div>

                  <p className="summary_pd_num" style={{ flex: 1, textAlign: 'center' }}>x {item.count}</p>

                  <div style={{ display: 'flex', flex: 2, justifyContent: 'center', alignItems: 'flex-end' }}>
                    <p>₹ {item.rate}</p>
                    {item.discounted_rate && (
                      <p className="summary_pd_num">{item.discounted_rate}</p>
                    )}
                  </div>

                </div>
              ))} */}
                <TableContainer>
                  <Table>
                    <TableBody>
                      {Object.keys(cartData).length !== 0 && cartData.map(item => (
                        <TableRow>
                          <TableCell width="36%" className="payout-tblcell-summary payout-pd-name">{item.item_name}</TableCell>
                          <TableCell className="payout-tblcell-summary align-center">
                            <p>x {item.count}</p>
                            {item.originalCount && (
                              <p className="grey-text">QTY {item.originalCount}</p>
                            )}
                          </TableCell>
                          <TableCell className="payout-tblcell-summary price-cell">
                            <p className={item.discounted_rate ? 'summary_cutout_price' : 'summary_pd_num'}>{currency + item.rate}</p>
                            {item.discounted_rate && (
                              <p className="summary_pd_num">{currency + item.discounted_rate}</p>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Item Total
                        </TableCell>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell className="payout-tblcell align-center">
                          {amtReceivedData.itemTotal && (currency + amtReceivedData.itemTotal)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Delivery distance
                        </TableCell>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell className="payout-tblcell align-center">
                          {amtReceivedData.deliveryDistance && (amtReceivedData.deliveryDistance + ' ' + distance)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Delivery charges
                        </TableCell>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell className="payout-tblcell align-center">
                          {amtReceivedData.deliveryCharge && (currency + amtReceivedData.deliveryCharge)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Govt. Taxes
                        </TableCell>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell className="payout-tblcell align-center">
                          {amtReceivedData.govtTax && (currency + amtReceivedData.govtTax)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="payout-tblcell">
                          Total amount
                        </TableCell>
                        <TableCell className="payout-tblcell"></TableCell>
                        <TableCell className="payout-tblcell align-center">{amtReceivedData.amountToPay && (currency + amtReceivedData.amountToPay)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Dialog>
            </div>
          )}

          {/* <Typography variant="subtitle1"> </Typography> */}
        <Dialog onClose={handleNoBtn} open={showConfirmDialog}>
          <img
            className="close_icon"
            src={close_icon}
            onClick={handleNoBtn}
            alt="close"
          />
          <div className="cashback_dialog">
            <div>
              <p className="confirm-dialog-text">Are you sure you want to cancel the order?</p>
              <div className="yn-btn-container">
                <button className="yes-btn" onClick={rejectOrder}>Yes</button>
                <button className="no-btn" onClick={handleNoBtn}>No</button>
              </div>

            </div>
          </div>

        </Dialog>
        <Dialog onClose={handleNoBtn} open={showOrderSummary}>
          <img
            className="close_icon"
            src={close_icon}
            onClick={() => setShowOrderSummary(false)}
            alt="close"
          />
          <div className="cashback_dialog">
            {!isLoading ? (
              <div className="order-summ-modal">
                {Object.keys(orderSummaryData).length !== 0 && orderSummaryData.orderSummary.map((product, index) => (
                  <div className={index === 0 ? "order-summary-container" : 'order-summary-container-next'}>
                    <div className="img-name-container">
                      <img src={product.image_url[0]} className="pd-image" alt="NA" />
                      <p>{product.item_name}</p>
                    </div>
                    <div>
                      <p className="fixed-count">x {product.count}</p>
                      <p className="grey-text">QTY {product.originalCount}</p>
                    </div>
                    {(product.discounted_rate !== undefined && product.discounted_rate !== null && product.discounted_rate !== "") ? (
                      <div className="price-container">
                        <p className="cutout-price">{currency}{product.rate}</p>
                        <p>{currency}{product.discounted_rate}</p>
                      </div>
                    ) : (
                      <div className="price-container">
                        <p>₹{product.rate}</p>
                      </div>
                    )}

                    <div className="plus-minus-container">
                      <p className="plus_minus_text_grid" onClick={() => changeOrderSummary(product, 'Decrease')}>-</p>
                      <p className="product_count">{product.count}</p>
                      <p className="plus_minus_text_grid" onClick={() => changeOrderSummary(product, 'Add')}>+</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="beat-loader-container">
                <BeatLoader color="#36d7b7" loading={true} />
              </div>
            )}

          </div>

        </Dialog>
    </div>
  );
};
export default Payout;
